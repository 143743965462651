import styled from 'styled-components';

export const Table = styled.table`
  border-spacing: inherit;
  margin-top: 15px;
  overflow-x: auto;
  overflow-y: hidden;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  @media (min-width: 1164px) {
    display: inline-table;
  }
`;
export const TableHead = styled.thead<{ $radius: number }>`
  & td {
    background-color: #3c505a;
    color: #fff;

    &:first-child {
      border-top-left-radius: ${(props) => props.$radius}px;
      border-bottom-left-radius: ${(props) => props.$radius}px;
    }
    &:last-child {
      border-top-right-radius: ${(props) => props.$radius}px;
      border-bottom-right-radius: ${(props) => props.$radius}px;
    }
  }
`;
export const TableBody = styled.tbody`
  & tr {
    background-color: #fff;
    border-bottom: 4px solid #f1f1f1;
  }
`;
export const TableRow = styled.tr`
  font-size: 13px;
`;
export const TableCell = styled.td`
  font-size: 12px;
  font-weight: normal;
  height: 36px;
  padding: 8px;
  text-align: center;
`;
