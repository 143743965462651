export const fixDate = (date: any) => {
  if (date) {
    let fixedDate: any = new Date(date)
    let day = fixedDate.getUTCDate()
    day = day < 10 ? `0${day}` : day
    let month = fixedDate.getUTCMonth() + 1
    month = month < 10 ? `0${month}` : month
    let year = fixedDate.getUTCFullYear()
    let hour = fixedDate.getHours()
    let minutes = fixedDate.getMinutes()

    fixedDate = `${day}/${month}/${year}`
    return fixedDate
  }
}

export const removerAcento = (str: string) => {
  let com_acento =
    "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ"

  let sem_acento =
    "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr"
  let novastr = ""
  if (str == undefined) {
    return str
  }

  for (var i = 0; i < str.length; i++) {
    let troca = false
    for (var a = 0; a < com_acento.length; a++) {
      if (str.substr(i, 1) == com_acento.substr(a, 1)) {
        novastr += sem_acento.substr(a, 1)
        troca = true
        break
      }
    }
    if (troca == false) {
      novastr += str.substr(i, 1)
    }
  }
  return novastr
}
