import styled, { css } from "styled-components"

import Tooltip from "../Tooltip"

interface ContainerProps {
  isFocused: boolean
  isFilled: boolean
  isErrored: boolean
}

export const Container = styled.div<ContainerProps>`
    border-radius: 10px;
    border: 2px solid #CCC;
    padding: 13px;
    position: relative;
    width: 100%;

    display: flex;
    align-items: center;

    & + div {
         margin-top: 8px
    }

    ${(props) =>
      props.isErrored &&
      css`
        border-color: #c53030;
      `}

    ${(props) =>
      props.isFocused &&
      css`
        color: #0161af;
        border-color: #0161af;
      `}

    ${(props) =>
      props.isFilled &&
      css`
        color: #0161af;
      `}

    input {
        flex: 1;
        background: transparent;
        border: 0;

        &::placeholder {
            color: #666360;
        }
    }


    label{
      bottom: 55px;
      color: #0C5FAF;
      font-size: 15px;
      font-weight: 500;
      position: absolute;
    }

`

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  position: absolute;
  right: 0;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`
