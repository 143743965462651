import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 2px 9px;

  &:not(:last-child) {
    border-bottom: 1px solid #0606da;
  }
`;

type Props = {
  children: React.ReactNode;
};

function ListItemUI({ children, ...props }: Props) {
  return <StyledContainer {...props}>{children}</StyledContainer>;
}

export default ListItemUI;
