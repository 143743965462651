import styled from 'styled-components';

export const Container = styled.div`
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
  color: #3c505a;
  min-height: calc(100vh - 73px);
  width: 80%;
  margin: 0 auto;

  @media (max-width: 1101px) {
    width: 90%;
  }
`;

export const Select = styled.select`
  width: 300px;
  height: 31px;
  margin-left: 0px;
  font-size: 14px;
  color: #8994a0;
  border: 1px solid #cfd7d9;
  background: #fff;
  left: 0px;
`;

export const WrapperTable = styled.div`
  margin: 0px 0px 0px 0px;
  overflow-x: auto;
  width: 100%;
`;

export const WrapperPagination = styled.div`
  margin: 0px 0px 0px 0px;
  width: 100%;

  .pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    button {
      margin: 2px;
    }

    .btn-page {
      width: 27px;
      height: 27px;
      border: 1px solid #e8e8e8;
      color: #e8e8e8;
      font: normal normal bold 11px/15px Open Sans;
      background-color: white;

      &.active {
        background: #0c5faf 0% 0% no-repeat padding-box;
        color: #fff;
      }
    }

    .btn-previous {
      width: 59px;
      height: 27px;
      font: normal normal normal 11px/15px 'Open Sans';
      border: 1px solid #e8e8e8;

      &.disabled {
        color: #b1afaf;
        cursor: default;
      }
    }

    .btn-next {
      width: 59px;
      height: 27px;
      font: normal normal normal 11px/15px 'Open Sans';
      border: 1px solid #e8e8e8;

      &.disabled {
        cursor: default;
        color: #b1afaf;
      }
    }
  }
`;

export const SelectContainer = styled.div`
  position: relative;

  & > label {
    bottom: 55px;
    color: #0c5faf;
    font-size: 15px;
    font-weight: 500;
    left: 15px;
    position: absolute;
  }

  & > div {
    & > select {
      border: none;
      color: #797979;
      width: 93%;

      option {
        color: #797979;
      }
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  text-align: left;
  border-spacing: inherit;
  margin: 0px 0px 15px 0px;
  background-color: #fff;

  border-radius: 18px;

  tbody tr:nth-child(odd) {
    background: #f9f9f9;
  }

  button {
    border: none;
    background: transparent;
    margin: 5px;
  }

  thead {
    border-radius: 18px;
    white-space: nowrap;
  }

  tr {
    font-size: 13px;
    text-align: center;
  }

  td {
    padding: 5px;
  }

  th {
    font-weight: normal;
    background: #3c505a;
    color: #fff;
    height: 36px;
    padding: 8px;
    min-width: 125px;

    &:first-child {
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
    }

    &:last-child {
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
    }
  }

  .large {
    width: 70%;
  }

  label {
    font-size: 12px;
    font-family: arial;
  }

  input {
    border: 1px solid #cfd7d9;
    padding: 5px;
    font-size: 12px;
  }

  button {
    font-weight: normal;
    background: #3c505a;
    color: #fff;
    min-width: 125px;
    border-radius: 24px;
    padding: 5px;
  }

  @media (max-width: 768px) {
    td {
      white-space: nowrap;
    }
  }
`;

export const Form = styled.form`
  background-color: #fff;
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  border-radius: 16px;
  margin-bottom: 20px;

  input,
  select {
    border: 1px solid #0c5faf;
    border-radius: 18px;
    padding: 5px 12px;
    width: 170px;
    margin: 8px 10px;
  }

  label {
    font-size: 14px;
    margin: 0 0 -8px 20px;
  }

  select {
    height: 38px;
  }

  button[type='submit'] {
    margin: 8px 10px;
  }

  .btn-clear-filter {
    color: #17536c;
    background: transparent;
    width: 50px;
  }

  .divLoading {
    padding: 10px;
    height: 48px;
    width: 40px;
  }

  .toggleShow {
    width: 100%;
  }

  @media (max-width: 533px) {
    flex-direction: column;
    width: 100%;
    align-items: center;

    input {
      margin: 8px 0;
      width: 100%;
    }
    select {
      width: 80%;
    }
  }
  @media (max-width: 425px) {
    padding: 15px;
  }
  @media (min-width: 426px) {
    .toggleShow-content {
      display: flex !important;
      align-items: end;
      flex-wrap: wrap;
    }
  }
`;

export const WrapperField = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 533px) {
    width: 80%;
  }
`;
