import React from 'react';

import { isLandingpagePath } from 'pages/LandingPages/utils/helpers';
import ResetpasswordPage from 'pages/LandingPages/pages/ResetpasswordPage';
import ResetPasswordPdc from 'pages/ResetPassword/ResetPasswordPdc';

function ResetPassword() {
  if (isLandingpagePath()) {
    return <ResetpasswordPage />;
  }
  return <ResetPasswordPdc />;
}

export default ResetPassword;
