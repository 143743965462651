import React, { FC, useState, useEffect, useCallback } from "react"
import moment from "moment"
import "moment/locale/pt-br"

import api from "../../services/api"
import { useAuth } from "../../hooks/auth"
import Header from "../../components/Header"
import Loading from "../../components/Loading"
import Title from '../../components/Title';

import { Container, WrapperInfo, Line } from "./styles"
import deleteIcon from "../../assets/profile/delete.png"
import { useToast } from "../../hooks/toast"

import { useHistory } from "react-router"

const Panel: FC = () => {
  const { userId } = useAuth()
  const [listNotification, setListNotification] = useState([])
  const [countLine, setCountLine] = useState(5)
  const [titleMoreLess, setTitleMoreLess] = useState("Mostrar tudo")
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const history = useHistory()
  const { addToast } = useToast()

  useEffect(() => {
    getNotification()
    setCountLine(5)
  }, [])

  const getNotification = useCallback(async () => {
    setIsFetching(true)
    await api
      .get(`/notifications/notification-by-customer/${userId}`)
      .then((response: any) => {
        setListNotification(response.data)
        setIsFetching(false)
      })
      .catch((err) => {
        addToast({
          title: "Aviso",
          type: "error",
          description: "Erro na busca de notificações",
        })
        setIsFetching(false)
      })
  }, [listNotification])

  const MoreLess = useCallback(
    (num: any) => {
      num === 5
        ? setTitleMoreLess("Mostrar menos")
        : setTitleMoreLess("Mostrar tudo")
    },
    [countLine, titleMoreLess]
  )

  const ShowAll = useCallback(() => {
    countLine === 5 ? setCountLine(9000) : setCountLine(5)
    MoreLess(countLine)
  }, [countLine])

  const ClearAll = useCallback(
    async (id: any, typeNotification: any) => {
      let newList = []
      if (id === 0) {
        listNotification.map((item: any, i) => {
          if (countLine > i) {
            newList.push(item.notificationId)
          }
        })
      } else {
        newList.push(id)
      }

      await api
        .post(`/notifications/insert-notification`, {
          userid: +userId,
          listnotification: newList,
        })
        .then(() => {
          getNotification()
          // if (id != 0) {
          //   if (typeNotification == "chamados") history.push(`/my-requests`)
          //   else if (typeNotification == "multas")
          //     history.push(`/fines-violations`)
          //   else history.push(`/financial`)
          // }
          // else
          //   getNotification()
        })
        .catch((error) => {
          throw error
        })
    },
    [listNotification, countLine]
  )

  const redirectToMode = (typeNotification: any) => {
    if (typeNotification == "chamados") history.push(`/my-requests`)
    else if (typeNotification == "multas") history.push(`/fines-violations`)
    else history.push(`/financial`)
  }

  const handleListNotification = () => {
    if (listNotification.length) {
      return listNotification.map((item: any, index: any) => {
        if (countLine > index) {
          return (
            <Line key={index}>
              <div>
                <span className="status-color" />
                <p onClick={() => redirectToMode(item.typeNotification)}>
                  {item.description}
                </p>
              </div>
              <div>
                <p>{moment(item.date).format("DD/MM/YYYY")}</p>
                <img
                  onClick={() =>
                    ClearAll(item.notificationId, item.typeNotification)
                  }
                  src={deleteIcon}
                  alt="bin icon"
                />
              </div>
            </Line>
          )
        }
      })
    } else {
      return (
        <Line>
          <div>
            <span className="status-color" />
            <p>Nenhuma notificação foi encontrada!</p>
          </div>
        </Line>
      )
    }
  }

  return (
    <>
      <Header />

      <Container>
        <Title title={'PAINEL'} subtitle={'Bem-vindo de volta à Fleet!'} /> 
        <WrapperInfo>
          <h3>Notificações</h3>
          {isFetching ? (
            <Loading loading={isFetching} />
          ) : (
            handleListNotification()
          )}

          {listNotification.length ? (
            <div className="actions">
              <button onClick={ShowAll}>{titleMoreLess}</button>
              <button onClick={() => ClearAll(0, "")}>Limpar tudo</button>
            </div>
          ) : (
            <></>
          )}
        </WrapperInfo>
      </Container>
    </>
  )
}

export default Panel
