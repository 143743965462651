import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Title from '../../../components/Title';
import api from '../../../services/api';
import deleteIcon from '../../../assets/profile/delete.png';
import { Container, WrapperTable, Table, Select, ErrorMessage } from './styles';
import { useToast } from '../../../hooks/toast';

const NewProfile: FC = () => {
  type SelectedMenu = {
    menuId: number;
    readonly: boolean;
  };
  const { id } = useParams<any>();

  const history = useHistory();
  const [menus, setMenus] = useState([]);
  const [name, setName] = useState('');
  const [funcionality, setFuncionality] = useState('');
  const [isEdit, setIsEdit] = useState<any>(false);
  const [lineTableCount, setLineTableCount] = useState(1);
  const [selectedMenus, setSelectedMenus] = useState<SelectedMenu[]>([]);
  const [roleId, setRoleId] = useState<string | null>();
  const [copyMenus, setCopyMenus] = useState([]);
  const [errorMessage, setErrorMessage] = useState<string>('Error message');
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [edit, setEdit] = useState(false);

  const { addToast } = useToast();
  const nameRef = useRef<any>(null);
  const functionalityRef = useRef<any>(null);

  useEffect(() => {
    setRoleId(localStorage.getItem('@FleetSolutions:roleId'));
  }, []);

  useEffect(() => {
    if (roleId) {
      // trazer todos os menus(funcionalidades)
      api.get(`/menus`).then((response: any) => {
        console.log('----- todos os menus ------');
        console.log(response.data);

        setMenus(response.data);
        setCopyMenus(response.data);
        // console.log("---- copyMenus ----")
        // console.log(copyMenus)
      });

      // api.get(`/roles/${roleId}`)
      // .then((response: any) => {
      //   console.log("----- response.data.menus ------")
      //   console.log(response.data.menus)

      //   setMenus(response.data.menus)
      //   setCopyMenus(response.data.menus)
      //   console.log("---- copyMenus ----")
      //   console.log(copyMenus)
      // })
    }

    if (id) {
      setEdit(true);
      api
        .get(`/roles/${id}`)
        .then((response: any) => {
          return response.data;
        })
        .then((data) => {
          const array: any = [];
          data.menus.map((menu: any) => {
            let res = {
              menuId: menu.menuId,
              readonly: menu.readonly,
            };
            array.push(res);
          });

          setSelectedMenus(array);
          setName(data.name);
          console.log('--- selectedMenus ----');
          console.log(array);
        });
    }
  }, [roleId, id]);

  const clearOptions = () => {
    setFuncionality('');
    setIsEdit(false);
  };

  const handleCancel = () => {
    clearOptions();
    setName('');

    history.push('/access-control');
  };

  let payload = {
    applicationId: 2,
    name: name,
    menus: selectedMenus,
  };

  const handleSubmitSignUp = useCallback(async () => {
    if (name === '') {
      addToast({
        title: 'Erro ao salvar',
        type: 'error',
        description: 'Digite o nome do perfil.',
      });
      nameRef.current?.focus();
    } else if (selectedMenus.length < 1) {
      addToast({
        title: 'Erro ao salvar',
        type: 'error',
        description: 'Adicione uma funcionalidade.',
      });
      functionalityRef.current?.focus();
    } else {
      setDisplayError(false);
      try {
        if (id) {
          let payloadEdited = {
            applicationId: 2,
            name: name,
            roleId: Number(id),
            menus: selectedMenus,
          };
          api.put('/roles', payloadEdited).then((response: any) => {
            if (response.status === 200) {
              history.push('/access-control');
            }
          });
        } else {
          api.post('/roles', payload).then((response: any) => {
            if (response.status === 200) {
              history.push('/access-control');
            }
          });
        }
      } catch (err) {
        addToast({
          title: id ? 'Erro ao editar' : 'Erro ao salvar',
          type: 'error',
          description: `Tivemos um erro ao ${
            id ? 'editar' : 'salvar'
          } o perfil, tente novamente.`,
        });
        throw err;
      }
    }
  }, [funcionality, isEdit, name, selectedMenus]);

  const addFuctionality = () => {
    if (funcionality === '') {
      setDisplayError(true);
      setErrorMessage('Selecione uma funcionalidade para adicionar.');
    } else {
      setDisplayError(false);
      setSelectedMenus([
        ...selectedMenus,
        {
          menuId: Number(funcionality),
          readonly: isEdit,
        },
      ]);
      setMenus(
        menus.filter((item: any) => {
          return item.menuId != funcionality;
        })
      );
      setLineTableCount(lineTableCount + 1);
      clearOptions();
    }
  };

  const removeFuncionalitySelected = (id: any) => {
    setSelectedMenus(
      selectedMenus.filter((arrayItem) => {
        return arrayItem.menuId != id;
      })
    );
    clearOptions();
  };

  const handleChangeName = useCallback((value) => {
    setName(value.target.value);
  }, []);

  const handleChangeFuncionality = useCallback((value) => {
    setFuncionality(value.target.value);
  }, []);

  const handleChangeEdit = useCallback((value) => {
    setIsEdit(value.target.checked);
  }, []);

  const handleTableLines_old = useCallback(() => {
    return selectedMenus.map((item, i) => (
      <tr key={i} id="rowToClone">
        <td>
          {item.menuId}
          <Select
            onChange={handleChangeFuncionality}
            value={item.menuId}
            name="functionality"
          >
            {copyMenus.map((menuOptions: any) => {
              if (menuOptions.menuId == item.menuId) {
                return (
                  <option key={item.menuId} selected value={item.menuId}>
                    {menuOptions.name}
                  </option>
                );
              } else {
                return (
                  <option key={menuOptions.menuId} value={menuOptions.menuId}>
                    {menuOptions.menuId} - {menuOptions.name}
                  </option>
                );
              }
            })}
          </Select>
        </td>
        <td>{item.readonly ? 'SIM' : 'NÃO'}</td>
        <td>
          <button onClick={() => removeFuncionalitySelected(item.menuId)}>
            <img src={deleteIcon} />
          </button>
        </td>
      </tr>
    ));
  }, [selectedMenus]);

  const handleTableLines = useCallback(() => {
    return selectedMenus.map((item, i) => (
      <tr key={i} id="rowToClone">
        <td>
          <Select
            onChange={handleChangeFuncionality}
            value={item.menuId}
            name="functionality"
          >
            {copyMenus.map((menuOptions: any) => {
              if (menuOptions.menuId == item.menuId) {
                return (
                  <option key={item.menuId} disabled value={item.menuId}>
                    {menuOptions.name}
                  </option>
                );
              }
              // else
              // {
              //   return (
              //     <option key={menuOptions.menuId} value={menuOptions.menuId}>
              //       {menuOptions.name}
              //     </option>
              //   )
              // }
            })}
          </Select>
        </td>
        <td>{item.readonly ? 'SIM' : 'NÃO'}</td>
        <td>
          <button onClick={() => removeFuncionalitySelected(item.menuId)}>
            <img src={deleteIcon} />
          </button>
        </td>
      </tr>
    ));
  }, [selectedMenus]);

  return (
    <>
      <Header />

      <Container>
        {!edit ? (
          <Title title={'NOVO PERFIL'} subtitle={'Crie um novo perfil'} />
        ) : (
          <Title
            title={'EDITAR PERFIL'}
            subtitle={'Visualize, edite e crie perfis'}
          />
        )}

        <WrapperTable>
          <p>Nome do perfil</p>

          <input
            name="name"
            className="name-profile"
            onChange={handleChangeName}
            value={name}
            ref={nameRef}
          />
          <div style={{ overflowX: 'auto' }}>
            <Table id="tableFuncionality">
              <thead>
                <tr>
                  <th className="large">Funcionalidade</th>
                  <th>Edição</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr id="rowToClone">
                  <td>
                    <Select
                      onChange={handleChangeFuncionality}
                      name="functionality"
                      value={funcionality}
                      ref={functionalityRef}
                    >
                      <option value="functionality">Selecione...</option>
                      {menus.length &&
                        menus.map((item: any) => (
                          <option key={item.menuId} value={item.menuId}>
                            {item.name}
                          </option>
                        ))}
                    </Select>
                  </td>
                  <td>
                    <input
                      onChange={handleChangeEdit}
                      type="checkbox"
                      name="edit"
                      id="edit"
                      value={isEdit}
                      checked={isEdit}
                    />
                  </td>
                  <td>
                    <button onClick={addFuctionality}>+</button>
                  </td>
                </tr>
                {handleTableLines()}
              </tbody>
            </Table>
          </div>
          <div className="wrapper-button">
            <ErrorMessage className={displayError ? 'active' : ''}>
              {errorMessage}
            </ErrorMessage>
            <button onClick={handleSubmitSignUp} className="btn-save">
              Salvar
            </button>

            <button onClick={handleCancel} className="btn-cancel">
              Cancelar
            </button>
          </div>
        </WrapperTable>
      </Container>
    </>
  );
};

export default NewProfile;
