import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  flex: 1 1 auto;
`;

type Props = {
  children: React.ReactNode;
};

function ListTextUI(props: Props) {
  return <StyledContainer>{props.children}</StyledContainer>;
}

export default ListTextUI;
