import React from 'react';

import { ITEMS_PER_PAGE } from 'pages/Reports/utils/constants';
import { ReportLayoutProps } from 'pages/Reports/types';
import TablePaginationUI from 'components/ui/TablePaginationUI';
import {
  ReportHeader,
  LayoutContainer,
  ReportTitle,
} from 'pages/Reports/styles';
import useFilters from 'pages/Reports/hooks/useFilters';
import useExportReport from 'pages/Reports/hooks/useExportExcel';
import useFetchData from 'pages/Reports/hooks/useFetchData';
import Filters from './Filters';
import ButtonUI from 'components/ui/ButtonUI';
import { getTheme } from 'pages/LandingPages/utils/helpers';

function ReportLayout({
  apiUrl,
  columns,
  customers,
  excelSample,
  reportTitle,
  createExcelRow,
}: ReportLayoutProps) {
  const {
    inputs,
    params,
    handleCustomerIdChange,
    handleDatesChange,
    setParams,
  } = useFilters({ customers });
  const { data, loadingData, page, handlePaginateClick, handleSearchClick } =
    useFetchData({
      apiUrl,
      inputs,
      params,
      setParams,
    });
  const { loadingExport, handleExportClick } = useExportReport({
    data,
    customers,
    excelSample,
    params,
    reportTitle,
    createExcelRow,
  });
  const theme = getTheme();

  return (
    <LayoutContainer>
      <ReportTitle $radius={theme.radius}>{reportTitle}</ReportTitle>
      <ReportHeader>
        <Filters
          customers={customers}
          inputs={inputs}
          loading={loadingData}
          onCustomerIdChange={handleCustomerIdChange}
          onDatesChange={handleDatesChange}
          onSearchClick={handleSearchClick}
        />
        <ButtonUI
          color="third"
          disabled={!data.length || loadingData}
          loading={loadingExport}
          loadingText="Exportar..."
          onClick={handleExportClick}
        >
          Exportar Relatório
        </ButtonUI>
      </ReportHeader>
      <TablePaginationUI
        columns={columns}
        data={data}
        itemsPerPage={ITEMS_PER_PAGE}
        loading={loadingData}
        noData="Não há registros para visualizar."
        page={page}
        total={data.length}
        onPaginate={handlePaginateClick}
      />
    </LayoutContainer>
  );
}

export default ReportLayout;
