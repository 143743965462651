import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from './styles';
import TableCellSortUI from './TableCellSortUI';
import Loading from 'components/Loading';
import { TableColumnI, TableI, TableRowT } from './types';
import { getTheme } from 'pages/LandingPages/utils/helpers';

function TableUI({ columns, data, loading, noData }: TableI) {
  const theme = getTheme();

  const renderColumn = (
    { onOrderDirection, orderDirection, title }: TableColumnI,
    columnIndex: number
  ) => {
    if (onOrderDirection && orderDirection) {
      return (
        <TableCellSortUI
          key={columnIndex}
          onClick={onOrderDirection}
          orderDirection={orderDirection}
        >
          {title}
        </TableCellSortUI>
      );
    }

    return <TableCell key={columnIndex}>{title}</TableCell>;
  };

  const renderRow = (row: TableRowT, rowIndex: number) => (
    <TableRow key={rowIndex}>
      {columns.map((column, columnIndex: number) => (
        <TableCell key={columnIndex}>
          {column.render
            ? column.render(row[column.key], row)
            : row[column.key]}
        </TableCell>
      ))}
    </TableRow>
  );

  const renderBody = () => {
    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={12}>
            <Loading loading={loading} />
          </TableCell>
        </TableRow>
      );
    }
    if (data.length) {
      return data.map(renderRow);
    }

    return (
      <TableRow>
        <TableCell colSpan={12}>{noData}</TableCell>
      </TableRow>
    );
  };

  return (
    <Table>
      <TableHead $radius={theme.radius}>
        <TableRow>{columns.map(renderColumn)}</TableRow>
      </TableHead>
      <TableBody>{renderBody()}</TableBody>
    </Table>
  );
}

export default TableUI;
