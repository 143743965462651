import React from "react"
import { Link, useParams } from "react-router-dom"

import Button from "../../components/Button"
import { Container } from "./styles"

import api from "../../services/api";

const ConfirmEmail: React.FC = () => {

  let { token } = useParams<any>();
  api.post(`/confirm-email/${token}`);

  return (
    <Container>
      <p>Seu e-mail foi verificado, agora você já pode utilizar a aplicação.</p>
      <Link to="/">
        <Button>Fazer login</Button>
      </Link>
    </Container>
  )
}

export default ConfirmEmail
