import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';

import Header from '../../components/Header';
import {
  StyledInfo,
  Container,
  StyledGreeting,
  StyledUserName,
  StyledWelcome,
  StyledBox,
  StyledServiceTitle,
  StyledIcon,
  StyledServiceInfo,
  StyledPhoneHours,
  StyledPhoneNumber,
  StyledContent,
  StyledDivider,
  StyledSupportBox,
  StyledSupportText,
  StyledPhone,
  StyledSupport,
  StyledSupportBoxContainer,
} from './styles';
import TermsOfUse from '../../components/TermsOfUse';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import RecallInformative from '../../components/RecallInformative';
import AntifraudInformative from '../../components/AntifraudInformative';
import AcceptCommunication from '../../components/AcceptCommunication';
import LmCommunication from 'components/LmCommunication';
import { getTheme } from 'pages/LandingPages/utils/helpers';
import 'pages/LandingPages/assets/styles/fonts.css';
import Programs from 'components/Programs';

const Welcome: React.FC = () => {
  const { userId, signOut, fullname } = useAuth();

  const [showModalTermOfUse, setShowModalTermOfUse] = useState<boolean>(false);
  const [showModalTermRecall, setShowModalTermRecall] =
    useState<boolean>(false);
  const [showModalTermAntifraud, setShowModalTermAntifraud] =
    useState<boolean>(false);
  const [acceptCommunication, setAcceptCommunication] = useState<boolean>(true);
  const [acceptLmCommunication, setAcceptLmCommunication] =
    useState<boolean>(true);

  const theme = getTheme();
  const customColor = theme.textColor === '#fff' ? '#17536c' : theme.textColor;
  let listOfPrograms = [];
  const storageListOfPrograms = localStorage.getItem(
    '@FleetSolutions:listOfPrograms'
  );

  try {
    listOfPrograms = JSON.parse(storageListOfPrograms || '[]');
  } catch (error) {
    console.error('JSON inválido:', error);
  }

  useEffect(() => {
    const chat = document.getElementById('znv-chat-auto-0');

    if (chat && userId) {
      chat.style.display = 'block';
    }

    return;
    //const url= "http://localhost:5000/api";
    api.get(`/users/${userId}`).then((res) => {
      const { data } = res;

      if (!data.acceptLmCommunication) {
        return setAcceptLmCommunication(false);
      } else if (!data.acceptTermsOfUse || data.acceptTermsOfUse === null) {
        setShowModalTermOfUse(true);
      } else if (!data.acceptRecallTerm || data.acceptRecallTerm === null) {
        return setShowModalTermRecall(true);
      } else if (
        !data.acceptAntifraudTerm ||
        data.acceptAntifraudTerm === null
      ) {
        return setShowModalTermAntifraud(true);
      } else if (!data.acceptCommunication) {
        setAcceptCommunication(false);
      }
    });
  }, [userId]);

  const handle = (term: boolean) => {
    console.log({ term });
    setShowModalTermOfUse(term);
  };

  const handleAcceptTerm = async () => {
    api.post(`/terms-of-use/${userId}/true/TermsOfUse`);

    setShowModalTermOfUse(false);

    await api.get(`/users/${userId}`).then((res) => {
      const { data } = res;

      if (!data.acceptRecallTerm || data.acceptRecallTerm === null) {
        return setShowModalTermRecall(true);
      } else if (
        !data.acceptAntifraudTerm ||
        data.acceptAntifraudTerm === null
      ) {
        return setShowModalTermAntifraud(true);
      }
    });
  };

  const handleDontAcceptTerm = () => {
    api.post(`/terms-of-use/${userId}/false/TermsOfUse`);
    signOut();
  };

  const handleAcceptTermRecall = async () => {
    api.post(`/terms-of-use/${userId}/true/Recall`);
    setShowModalTermRecall(false);

    await api.get(`/users/${userId}`).then((res) => {
      const { data } = res;

      if (!data.acceptAntifraudTerm || data.acceptAntifraudTerm === null) {
        return setShowModalTermAntifraud(true);
      }
    });
  };

  const handleDontAcceptTermRecall = () => {
    api.post(`/terms-of-use/${userId}/false/Recall`);
    signOut();
  };

  const handleAcceptTermAntifraud = async () => {
    setShowModalTermAntifraud(false);
    api.post(`/terms-of-use/${userId}/true/Antifraud`);
    setShowModalTermOfUse(false);

    await api.get(`/users/${userId}`).then((res) => {
      const { data } = res;

      setAcceptCommunication(data.acceptCommunication);
    });
  };

  const handleDontAcceptTermAntifraud = () => {
    api.post(`/terms-of-use/${userId}/false/Antifraud`);
    signOut();
  };

  return (
    <Container>
      <Header />
      <Modal
        okText="Aceito"
        cancelText="Não aceito"
        onOk={handleAcceptTermRecall}
        onCancel={handleDontAcceptTermRecall}
        visible={showModalTermRecall}
      >
        <RecallInformative />
      </Modal>

      <Modal
        okText="Aceito"
        cancelText="Não aceito"
        onOk={handleAcceptTermAntifraud}
        onCancel={handleDontAcceptTermAntifraud}
        visible={showModalTermAntifraud}
      >
        <AntifraudInformative />
      </Modal>

      <Modal
        okText="Aceito"
        cancelText="Não aceito"
        onOk={handleAcceptTerm}
        onCancel={handleDontAcceptTerm}
        bodyStyle={{ overflow: 'scroll', height: 500 }}
        visible={showModalTermOfUse}
      >
        <TermsOfUse />
      </Modal>

      {!acceptCommunication && <AcceptCommunication userId={userId} />}

      {!acceptLmCommunication && (
        <LmCommunication userId={userId} func={handle} />
      )}
      <StyledContent color={customColor}>
        <StyledBox $radius={theme.radius}>
          <StyledGreeting>
            Olá,{' '}
            <StyledUserName color={theme.firstButtonBgcolor}>
              {fullname}.
            </StyledUserName>
          </StyledGreeting>
          <StyledWelcome>
            Desejamos boas-vindas ao Portal do Cliente.
          </StyledWelcome>

          {listOfPrograms?.length > 0 ? (
            <>
              <StyledInfo>
                Clique no programa que você deseja acessar:
              </StyledInfo>
              <StyledInfo>
                <Programs urls={listOfPrograms} />
              </StyledInfo>
            </>
          ) : (
            <StyledInfo>
              Clique no menu superior à direita para acessar os serviços.
            </StyledInfo>
          )}
        </StyledBox>
        <StyledBox $radius={theme.radius}>
          <StyledServiceTitle>
            <StyledIcon color={customColor} $radius={theme.radius}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <mask
                  id="mask0_1873_17229"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_1873_17229)">
                  <path
                    d="M11 21V19H19V11.9C19 9.95 18.3208 8.29583 16.9625 6.9375C15.6042 5.57917 13.95 4.9 12 4.9C10.05 4.9 8.39583 5.57917 7.0375 6.9375C5.67917 8.29583 5 9.95 5 11.9V18H4C3.45 18 2.97917 17.8042 2.5875 17.4125C2.19583 17.0208 2 16.55 2 16V14C2 13.65 2.0875 13.3208 2.2625 13.0125C2.4375 12.7042 2.68333 12.4583 3 12.275L3.075 10.95C3.20833 9.81667 3.5375 8.76667 4.0625 7.8C4.5875 6.83333 5.24583 5.99167 6.0375 5.275C6.82917 4.55833 7.7375 4 8.7625 3.6C9.7875 3.2 10.8667 3 12 3C13.1333 3 14.2083 3.2 15.225 3.6C16.2417 4 17.15 4.55417 17.95 5.2625C18.75 5.97083 19.4083 6.80833 19.925 7.775C20.4417 8.74167 20.775 9.79167 20.925 10.925L21 12.225C21.3167 12.375 21.5625 12.6 21.7375 12.9C21.9125 13.2 22 13.5167 22 13.85V16.15C22 16.4833 21.9125 16.8 21.7375 17.1C21.5625 17.4 21.3167 17.625 21 17.775V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H11ZM9 14C8.71667 14 8.47917 13.9042 8.2875 13.7125C8.09583 13.5208 8 13.2833 8 13C8 12.7167 8.09583 12.4792 8.2875 12.2875C8.47917 12.0958 8.71667 12 9 12C9.28333 12 9.52083 12.0958 9.7125 12.2875C9.90417 12.4792 10 12.7167 10 13C10 13.2833 9.90417 13.5208 9.7125 13.7125C9.52083 13.9042 9.28333 14 9 14ZM15 14C14.7167 14 14.4792 13.9042 14.2875 13.7125C14.0958 13.5208 14 13.2833 14 13C14 12.7167 14.0958 12.4792 14.2875 12.2875C14.4792 12.0958 14.7167 12 15 12C15.2833 12 15.5208 12.0958 15.7125 12.2875C15.9042 12.4792 16 12.7167 16 13C16 13.2833 15.9042 13.5208 15.7125 13.7125C15.5208 13.9042 15.2833 14 15 14ZM6.025 12.45C5.90833 10.6833 6.44167 9.16667 7.625 7.9C8.80833 6.63333 10.2833 6 12.05 6C13.5333 6 14.8375 6.47083 15.9625 7.4125C17.0875 8.35417 17.7667 9.55833 18 11.025C16.4833 11.0083 15.0875 10.6 13.8125 9.8C12.5375 9 11.5583 7.91667 10.875 6.55C10.6083 7.88333 10.0458 9.07083 9.1875 10.1125C8.32917 11.1542 7.275 11.9333 6.025 12.45Z"
                    fill="white"
                  />
                </g>
              </svg>
            </StyledIcon>{' '}
            Assistência 24h
          </StyledServiceTitle>
          <StyledServiceInfo>
            Qualquer situação de urgência como roubo, furto ou sinistro,
            solicite atendimento através da assistência 24h.
          </StyledServiceInfo>
          <StyledSupport>
            <StyledSupportBoxContainer>
              <StyledSupportBox
                href={`https://wa.me/55${theme.phone}`}
                target="_blank"
                color={customColor}
                $radius={theme.radius}
              >
                <svg
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.50002 12C3.50002 7.30558 7.3056 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C10.3278 20.5 8.77127 20.0182 7.45798 19.1861C7.21357 19.0313 6.91408 18.9899 6.63684 19.0726L3.75769 19.9319L4.84173 17.3953C4.96986 17.0955 4.94379 16.7521 4.77187 16.4751C3.9657 15.176 3.50002 13.6439 3.50002 12ZM12 1.5C6.20103 1.5 1.50002 6.20101 1.50002 12C1.50002 13.8381 1.97316 15.5683 2.80465 17.0727L1.08047 21.107C0.928048 21.4637 0.99561 21.8763 1.25382 22.1657C1.51203 22.4552 1.91432 22.5692 2.28599 22.4582L6.78541 21.1155C8.32245 21.9965 10.1037 22.5 12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5ZM14.2925 14.1824L12.9783 15.1081C12.3628 14.7575 11.6823 14.2681 10.9997 13.5855C10.2901 12.8759 9.76402 12.1433 9.37612 11.4713L10.2113 10.7624C10.5697 10.4582 10.6678 9.94533 10.447 9.53028L9.38284 7.53028C9.23954 7.26097 8.98116 7.0718 8.68115 7.01654C8.38113 6.96129 8.07231 7.046 7.84247 7.24659L7.52696 7.52195C6.76823 8.18414 6.3195 9.2723 6.69141 10.3741C7.07698 11.5163 7.89983 13.314 9.58552 14.9997C11.3991 16.8133 13.2413 17.5275 14.3186 17.8049C15.1866 18.0283 16.008 17.7288 16.5868 17.2572L17.1783 16.7752C17.4313 16.5691 17.5678 16.2524 17.544 15.9269C17.5201 15.6014 17.3389 15.308 17.0585 15.1409L15.3802 14.1409C15.0412 13.939 14.6152 13.9552 14.2925 14.1824Z"
                      fill="currentColor"
                    ></path>
                  </g>
                </svg>{' '}
                Entrar em contato com a assistência 24h
              </StyledSupportBox>
            </StyledSupportBoxContainer>
            <StyledSupportText>Atendimento via whatsapp</StyledSupportText>
          </StyledSupport>
          <StyledDivider />
          <StyledPhone>
            <StyledPhoneNumber>
              {theme.phone.replace(/(\d{3})(\d{3})(\d{4})/, '0$1 $2 $3')}
            </StyledPhoneNumber>
            <StyledPhoneHours>
              Atendimento de segunda a sexta das 07h às 18h por ligação ou
              WhatsApp.
            </StyledPhoneHours>
          </StyledPhone>
        </StyledBox>
      </StyledContent>
    </Container>
  );
};

export default Welcome;
