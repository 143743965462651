import React from 'react';
import styled from 'styled-components';

import { CustomerSelectProps } from 'pages/Reports/types';
import InputFieldUI from 'components/ui/InputFieldUI';

const StyledInputField = styled(InputFieldUI)`
  width: 300px;
`;

function CustomerSelect({ customers, value, onChange }: CustomerSelectProps) {
  const options = customers.map((customer) => (
    <option key={customer.customerCMSId} value={customer.customerCMSId}>
      {customer.customerName}
    </option>
  ));

  return (
    <StyledInputField
      field="select"
      label="Contrato"
      id="customerId"
      name="customerId"
      value={value}
      onChange={onChange}
    >
      <option value="" disabled>
        Selecione um contrato
      </option>
      {options}
    </StyledInputField>
  );
}

export default CustomerSelect;
