import React from 'react';
import styled from 'styled-components';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import InputContainerUI from '../InputUI/InputContainerUI';

const defaultStatusStyles = `
  .MuiOutlinedInput-notchedOutline {
    border-color: #ccc;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #ccc;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #0606da;
  }
`;
const errorStatusStyles = `
.MuiOutlinedInput-notchedOutline {
  border-color: rgb(197, 48, 48);
}
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgb(197, 48, 48);
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgb(197, 48, 48);
  border-width: 3px;
}
`;
const StyledAutocomplete = styled(Autocomplete)<{ $error?: boolean }>`
  .MuiOutlinedInput-notchedOutline {
    border: 2px solid;
    border-radius: 18px;
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 7px 12px;
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
    .MuiAutocomplete-input {
    height: 26px;
    padding: 0;
  }
  ${(props) => (props.$error ? errorStatusStyles : defaultStatusStyles)};
`;

type Props = {
  error?: boolean;
  errorText?: string;
  loading?: boolean;
  loadingText?: string;
  noOptionsText?: string;
  open?: boolean;
  options: any[];
  value?: unknown;
  getOptionLabel?: (option: any) => string;
  getOptionSelected?: (option: any, value: any) => boolean;
  onChange?: (event: React.ChangeEvent<{}>, value: any) => void;
  onClose?: () => void;
  onInputChange?: (event: React.ChangeEvent<{}>, value: string) => void;
  onOpen?: () => void;
};

function AutocompleteBaseUI(props: Props) {
  const renderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          endAdornment: renderAdornment(params.InputProps),
        }}
        variant="outlined"
      />
    );
  };

  const renderAdornment = (InputProps: any) =>
    props.loading ? (
      <CircularProgress color="inherit" size={10} />
    ) : (
      InputProps.endAdornment
    );

  return (
    <InputContainerUI errorText={props.errorText}>
      <StyledAutocomplete
        $error={props.error}
        loadingText={props.loadingText}
        noOptionsText={props.noOptionsText}
        open={props.open}
        options={props.options}
        value={props.value}
        getOptionLabel={props.getOptionLabel}
        getOptionSelected={props.getOptionSelected}
        onChange={props.onChange}
        onClose={props.onClose}
        onInputChange={props.onInputChange}
        onOpen={props.onOpen}
        renderInput={renderInput}
      />
    </InputContainerUI>
  );
}

export default AutocompleteBaseUI;
