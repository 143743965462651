import api from './api';

export const changeDriverApi = async (payload: {
  contratoId: number;
  condutorId: number;
}) => {
  let error = 'Erro ao enviar os dados.';

  try {
    const response = await api.post('/crm/reports/change-driver_lm', payload);

    if (!response.data.success) {
      error = 'Erro ao tentar trocar o condutor!';
      if (response.data.items) {
        const [key, value] = Object.entries(response.data.items)[0];
        error = `[${key}] = ${value}`;
      }
      throw error;
    }
    return response.data;
  } catch {
    throw new Error(error);
  }
};

export const searchDriversApi = async (payload: {
  clienteId: number;
  nome: string;
}) => {
  const response = await api.post(`/crm/reports/driver-list_lm`, payload);
  return response.data;
};

export const searchVehiclesApi = async (payload: {
  clienteId: Array<number>;
  exibirTotais: boolean;
  itensPorPagina: number;
  ordemCampo: string;
  ordemDescendente: boolean;
  paginaAtual: number;
  possuiCondutor: Boolean;
  statusId: number;
  userId: number;
}) => {
  const response = await api.post(
    `/crm/reports/logistics-by-customer_lm/`,
    payload
  );
  return response.data;
};
