import { useState } from 'react';
import moment from 'moment';

import {
  fillReport,
  formatFileName,
  loadExcel,
  getCustomerName,
} from 'pages/Reports/utils/helpers';
import { UseExportReportProps } from 'pages/Reports/types';
import { downloadBuffer } from 'utils/downloadFile';
import { FORMAT_VALUES } from '../utils/constants';

function useExportReport({
  data,
  customers,
  excelSample,
  params,
  reportTitle,
  createExcelRow,
}: UseExportReportProps) {
  const [loadingExport, setLoadingExport] = useState(false);

  const handleExportClick = () => {
    setLoadingExport(true);
    loadExcel(excelSample)
      .then((workbook) => {
        return fillReport(
          workbook,
          getCustomerName(customers, Number(params.customerId)),
          data,
          `${moment(params.initialDate).format(
            FORMAT_VALUES.fullDate
          )} a ${moment(params.finalDate).format(FORMAT_VALUES.fullDate)}`,
          createExcelRow
        );
      })
      .then((workbook) => workbook.xlsx.writeBuffer())
      .then((buffer) =>
        downloadBuffer(
          buffer,
          formatFileName(`relatorio_de_${reportTitle}.xlsx`),
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
      )
      .finally(() => {
        setLoadingExport(false);
      });
  };

  return { loadingExport, handleExportClick };
}

export default useExportReport;
