import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Modal } from 'antd';

import ModalSegundaViaBoleto from '../../components/Modal';
import Header from '../../components/Header';
import Title from '../../components/Title';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import iconExcel from '../../assets/icons/excel.svg';
import IconBradesco from '../../assets/defaultImages/iconBradesco.png';
import IconItau from '../../assets/defaultImages/iconItau.png';
import iconPDF from '../../assets/icons/pdf.svg';
import {
  CloseModal,
  Container,
  ContentModal,
  Feature,
  FeatureTitle,
  ModalContent,
  TableContainer,
} from './styles';
import { useToast } from '../../hooks/toast';
import Pagination from '../../components/Pagination';
import FinancialFilter from './components/FinancialFilter';
import {
  INITIAL_FILTERS,
  INVOICE_TITLE,
  MEASURUMENT_TITLE,
  MOCK_DATA,
  getInvoiceStatusColor,
  getMeasurementStatusColor,
} from './utils';
import { TableColumnI } from 'components/ui/TableUI/types';
import TableUI from 'components/ui/TableUI';
import {
  actionsColumn,
  amountColumn,
  autoApprovalColumn,
  invoiceNumberColumn,
  companyNameColumn,
  costCenterColumn,
  cpfCnpjColumn,
  dueDateColumn,
  financialProductColumn,
  viewInvoice,
  issuanceDateColumn,
  issuanceTypeColumn,
  statusColumn,
} from './components/tableColumns';
import { InvoicesT } from './types';
import MobileFilter from 'components/business/MobileFilter';
import useIsMobile from 'hooks/useIsMobile';

const PER_PAGE = 10;

function Financial() {
  const { id } = useParams<any>();
  const { userId } = useAuth();
  const history = useHistory();
  const { addToast } = useToast();
  const isMobile = useIsMobile();

  const [data, setData] = useState<InvoicesT[]>([]);
  const [total, setTotal] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [itemMedicao, setItemMedicao] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [canFetch, setCanFetch] = useState(true);
  const [clientIds, setClientIds] = useState(null);

  const isMeasurement = id === 'M';

  useEffect(() => {
    api.get(`/crm/reports/${userId}/customers-cms-lm`).then((response) => {
      setClientIds(response.data.map((item: any) => item?.customerCMSId));
    });
  }, [userId]);

  useEffect(() => {
    if (canFetch && clientIds) {
      fetchFinancialData();
      setCanFetch(false);
    }
  }, [id, filters, clientIds]);

  const modalOk = () => {
    setOpenModal(false);
    Aprovar(itemMedicao);
  };

  const modalClose = () => setOpenModal(false);

  const getBillingMeasurements = async () => {
    setIsLoading(true);
    api
      .get(`/reports/billing_measurements-by-customer/${userId}`)
      .then((response) => {
        if (response.data.length === 1) {
          setData(response.data);
          return;
        }

        const indexOfLast = filters.paginaAtual * 15;
        const indexOfFirst = indexOfLast - 15;
        const currentData = response.data.slice(indexOfFirst, indexOfLast);

        setData(currentData.length ? currentData : MOCK_DATA);
      })
      .finally(() => setIsLoading(false));
  };

  const getInvoicesDebit = () => {
    setIsLoading(true);
    api
      .post('/crm/reports/PesquisaFatura_LM_Async', {
        exibirTotais: true,
        paginaAtual: filters.paginaAtual,
        itensPorPagina: PER_PAGE,
        ordemCampo: null,
        ordemDescendente: false,
        clienteId: clientIds,
        numeroFatura: Number(filters.numeroFatura),
        tipoPesquisaData: Number(filters.tipoPesquisaData),
        dataInicial: filters.dataInicial
          ? new Date(filters.dataInicial).toISOString()
          : null,
        dataFinal: filters.dataFinal
          ? new Date(filters.dataFinal).toISOString()
          : null,
        codigoStatusFatura: filters.codigoStatusFatura,
        codigoProdutoFinanceiro: filters.codigoProdutoFinanceiro,
      })
      .then((response) => {
        setData(response.data.itens || []);
        setTotal(response.data.totalItens);
      })
      .finally(() => setIsLoading(false));
  };

  const fetchFinancialData = () => {
    if (id === 'M') getBillingMeasurements();
    else getInvoicesDebit();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFilters({ ...filters, paginaAtual: 1 });
    fetchFinancialData();
  };

  const handleClearFilter = () => setFilters(INITIAL_FILTERS);

  const Aprovar = (item: any) => {
    api
      .post('/reports/WSMedicaoServicoAprovacao', {
        MedicaoServicoClientesDoc: item.codigoCliente,
        MedicaoServicoId: item.codigoMedicao,
        MedicaoServicoObservacao: '',
        MedicaoServicoStatusAprovacao: 'A',
      })
      .then((response) => {
        if (response.data.response.id === 0) {
          addToast({
            title: 'Sucesso',
            type: 'success',
            description: 'Medição aprovada com sucesso!',
          });
          getBillingMeasurements();
          handleMeasurementsCurrentPage(filters.paginaAtual);
          return;
        }
        addToast({
          title: 'Erro',
          type: 'error',
          description: response.data.response.erro,
        });
      })
      .catch(() => {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Erro ao tentar aprovar a medição!',
        });
      });
  };

  const handleMeasurementsCurrentPage = (i: number) => {
    setFilters({ ...filters, paginaAtual: i });
  };

  // const handleDownloadPDF = (value: string) => {
  //   const link = document.createElement('a');
  //   link.href = value;
  //   link.target = '_blank';
  //   link.click();
  // };

  const handleDownloadXLSX = (param: any) => {
    api
      .post(`/reports/getWSBillingDocument`, {
        DocumentoId: param.codigoMedicao,
        DocumentoTipo: 'MD',
        DocumentoFormato: param.tipoEmissao === 'FT' ? 0 : 1,
      })
      .then((response) => {
        const downloadLink = document.createElement('a');

        downloadLink.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data}`;
        downloadLink.download = 'Medicao_' + param.codigoMedicao + '.xlsx';
        downloadLink.click();
      })
      .catch(() => {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Ocorreu um erro ao gerar o Excel!',
        });
      });
  };

  const handleScreenMode = (e: React.ChangeEvent<HTMLSelectElement>) => {
    history.push(`/financial/${e.target.value}`);
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handlePaginate = (pageNumber: number) => {
    setFilters({ ...filters, paginaAtual: pageNumber });
    setCanFetch(true);
  };

  const handleApproveClick = (value: any) => {
    setOpenModal(true);
    setItemMedicao(value);
  };

  const handleReproveClick = () => history.push(`/new-request/administrativo`);

  const handleOrderBy = (columnName: 'dataEmissao' | 'dataVencimento') => {
    setFilters({
      ...filters,
      ordemCampo: columnName,
      ordemDescendente:
        filters.ordemCampo === columnName && filters.ordemDescendente === 'desc'
          ? 'asc'
          : 'desc',
    });
  };
  const measurementColumns: TableColumnI[] = [
    viewInvoice({
      alt: 'Baixar Excel',
      icon: iconExcel,
      onDownload: handleDownloadXLSX,
    }),
    invoiceNumberColumn,
    issuanceTypeColumn,
    issuanceDateColumn,
    dueDateColumn,
    autoApprovalColumn,
    companyNameColumn,
    cpfCnpjColumn,
    financialProductColumn,
    costCenterColumn,
    amountColumn,
    statusColumn(getMeasurementStatusColor),
    actionsColumn({
      onApprove: handleApproveClick,
      onReprove: handleReproveClick,
    }),
  ];
  const invoiceColumns: TableColumnI[] = [
    invoiceNumberColumn,
    issuanceDateColumn,
    dueDateColumn,
    companyNameColumn,
    cpfCnpjColumn,
    financialProductColumn,
    amountColumn,
    statusColumn(getInvoiceStatusColor),
    viewInvoice({
      alt: 'Baixar PDF',
      icon: iconPDF,
    }),
  ];
  const mobileInvoiceColumns: TableColumnI[] = [
    dueDateColumn,
    amountColumn,
    statusColumn(getInvoiceStatusColor),
    viewInvoice({
      alt: 'Baixar PDF',
      icon: iconPDF,
    }),
  ];
  let screenMode = {
    title: INVOICE_TITLE,
    columns: isMobile ? mobileInvoiceColumns : invoiceColumns,
  };

  if (isMeasurement) {
    screenMode = {
      title: MEASURUMENT_TITLE,
      columns: measurementColumns,
    };
  }

  return (
    <>
      <Header />
      <Container>
        <Title
          subtitle={'Veja aqui todos os dados financeiros de seu contrato'}
          title={'FINANCEIRO'}
        />
        {/* <SelectUI onChange={handleScreenMode} value={id}>
          <option value="F">{INVOICE_TITLE}</option>
          <option value="M">{MEASURUMENT_TITLE}</option>
        </SelectUI> */}
        <Feature>
          <FeatureTitle>{screenMode.title}</FeatureTitle>
          <MobileFilter>
            <FinancialFilter
              state={filters}
              isMeasurement={isMeasurement}
              onChange={handleFilterChange}
              onClear={handleClearFilter}
              onSubmit={handleSubmit}
            />
          </MobileFilter>
          <TableContainer>
            <TableUI
              columns={screenMode.columns}
              data={data}
              loading={isLoading}
              noData={`Sem dados de ${screenMode.title}`}
            />
          </TableContainer>
          <Pagination
            paginate={handlePaginate}
            perPage={PER_PAGE}
            currentPage={filters.paginaAtual}
            total={total}
          />
        </Feature>
        <Modal footer={null} title="" visible={openModal} onCancel={modalClose}>
          <ContentModal>
            <p>Deseja aprovar essa medição?</p>
            <div>
              <button onClick={modalOk}>Sim</button>
              <button onClick={modalClose}>Não</button>
            </div>
          </ContentModal>
        </Modal>
        <ModalSegundaViaBoleto isVisible={modalIsVisible}>
          <ModalContent>
            <CloseModal onClick={() => setModalIsVisible(false)}></CloseModal>
            <p>Escolha uma das opções abaixo:</p>
            <div>
              <a
                href="https://banco.bradesco/html/classic/produtos-servicos/mais-produtos-servicos/segunda-via-boleto.shtm"
                target="_blank"
              >
                <button>
                  <img src={IconBradesco} alt="icone calendário" />
                  <br />
                  <br />
                </button>
              </a>
              <a
                href="https://www.itau.com.br/servicos/boletos/segunda-via/"
                target="_blank"
              >
                <button>
                  <img src={IconItau} alt="icone prancheta" />
                </button>
              </a>
            </div>
          </ModalContent>
        </ModalSegundaViaBoleto>
      </Container>
    </>
  );
}

export default Financial;
