import React from 'react';
import useSearchCustomers from '../hooks/useSearchCustomers';
import AutocompleteUI from 'components/ui/AutocompleteUI';
import { CustomerT } from '../utils/types';

type Props = {
  customerError?: string;
  onContractChange: (value: any) => void;
};

function CustomerSearch(props: Props) {
  const { data, loading, setTerm } = useSearchCustomers();
  const [open, setOpen] = React.useState(false);

  return (
    <AutocompleteUI
      error={!!props.customerError}
      errorText={props.customerError}
      label="Contrato"
      loading={loading && open}
      loadingText="Carregando"
      noOptionsText="Sem dados para mostrar"
      open={open}
      options={data}
      getOptionLabel={(option) => option.descricao || ''}
      getOptionSelected={(option, value) =>
        option.customerId === value.customerId
      }
      onChange={(_: any, newValue: CustomerT) =>
        props.onContractChange(newValue)
      }
      onClose={() => setOpen(false)}
      onInputChange={(_: any, newValue: string) => setTerm(newValue)}
      onOpen={() => setOpen(true)}
    />
  );
}

export default CustomerSearch;
