import React from "react"
import { useTransition } from "react-spring"

import Toast from "./Toast"
import { ToastMessage } from "../../hooks/toast"
import { Container } from "./styles"

interface ToastContainerProps {
  messages: ToastMessage[]
  isSuperimposed: boolean
}

const ToastContainer: React.FC<ToastContainerProps> = ({
  messages,
  isSuperimposed,
}) => {
  const messagesWithTransactions = useTransition(
    messages,
    (message) => message.id,
    {
      from: { right: "-120%" },
      enter: { right: "0%" },
      leave: { right: "-120%" },
    }
  )

  return (
    <Container>
      {messagesWithTransactions.map(({ item, key, props }) => (
        <Toast
          isSuperimposed={isSuperimposed}
          key={key}
          style={props}
          message={item}
        />
      ))}
    </Container>
  )
}

export default ToastContainer
