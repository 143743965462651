import React from 'react';
import ButtonIconUI from '../ButtonIconUI';
import TrashIconUI from '../Icons/TrashIconUI';

type Props = {
  exclude?: 'default' | 'hidden' | 'disabled';
  onExclude?: () => void;
};

function Actions({ exclude = 'default', ...props }: Props) {
  const renderExclude = () =>
    exclude !== 'hidden' && (
      <ButtonIconUI disabled={exclude === 'disabled'} onClick={props.onExclude}>
        <TrashIconUI />
      </ButtonIconUI>
    );

  return <div>{renderExclude()}</div>;
}

export default Actions;
