import React from 'react';
import styled from 'styled-components';

const colors = {
  primary: `
    background-color: #fff;
    color: inherit;
  `,
  secondary: `
    background-color: #3c505a;
    color: #fff;
  `,
};

const StyledContainer = styled.div<{ $color: ColorT }>`
  ${(props) => colors[props.$color]};
  border-radius: 25px;
  padding: 10px;
`;

type ColorT = keyof typeof colors;

type Props = {
  children: React.ReactNode;
  color?: ColorT;
};

function BoxUI({ children, color = 'primary', ...props }: Props) {
  return (
    <StyledContainer $color={color} {...props}>
      {children}
    </StyledContainer>
  );
}

export default BoxUI;
