import React from 'react';

import FieldsetUI from 'components/ui/Fieldset';
import InputFieldUI from 'components/ui/InputFieldUI';
import ListUI from '../../../components/ui/List/ListUI';
import Actions from 'components/ui/Actions';
import ListItemUI from 'components/ui/List/ListItemUI';
import ListTextUI from 'components/ui/List/ListTextUI';
import CustomerSearch from './CustomerSearch';
import RoleField from './RoleField';
import { BasicDataT, CustomerPayloadT, CustomerT } from '../utils/types';

type ErrorsT = {
  CustomerCMS?: string;
  email?: string;
  fullname?: string;
  password?: string;
  roleID?: string;
};

type Props = {
  contracts: CustomerPayloadT[];
  errors: ErrorsT;
  values: BasicDataT;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => void;
  onContractChange: (value: CustomerT) => void;
  onRemoveContract: (id: number) => void;
};

function BasicDataFieldset(props: Props) {
  const renderContract = (contract: CustomerPayloadT) => (
    <ListItemUI key={contract.CustomerCMSId}>
      <ListTextUI>{contract.CustomerName}</ListTextUI>
      <Actions
        onExclude={() => props.onRemoveContract(contract.CustomerCMSId)}
      />
    </ListItemUI>
  );

  return (
    <FieldsetUI legend="Dados Básicos">
      <RoleField
        error={props.errors.roleID}
        value={props.values.roleId}
        onChange={props.onChange}
      />

      <InputFieldUI
        error={!!props.errors.fullname}
        errorText={props.errors.fullname}
        field="text"
        label="Nome"
        name="fullname"
        value={props.values.fullname}
        onChange={props.onChange}
      />

      <InputFieldUI
        error={!!props.errors.email}
        errorText={props.errors.email}
        field="text"
        label="E-mail"
        name="email"
        value={props.values.email}
        onChange={props.onChange}
      />

      <CustomerSearch
        customerError={props.errors.CustomerCMS}
        onContractChange={props.onContractChange}
      />

      {props.contracts.length ? (
        <ListUI>{props.contracts.map(renderContract)}</ListUI>
      ) : null}
    </FieldsetUI>
  );
}

export default BasicDataFieldset;
