import React, { useEffect, useState } from 'react';

import {
  Field,
  FieldType,
  Form,
  Label,
  Radio,
  StyledInvoiceNumberInput,
  Wrapper,
} from './styles';
import { FinancialFilterI } from './types';
import api from 'services/api';
import ButtonUI from 'components/ui/ButtonUI';
import InputFieldUI from 'components/ui/InputFieldUI';
import { getTheme } from 'pages/LandingPages/utils/helpers';

function FinancialFilter({
  state,
  isMeasurement,
  onChange,
  onClear,
  onSubmit,
}: FinancialFilterI) {
  const [statusOptions, setStatusOptions] = useState([]);
  const [produtoFinanceiroOptions, setProdutoFinanceiroOptions] = useState([]);

  const theme = getTheme();

  useEffect(() => {
    api.get(`/crm/reports/PopulaStatusFatura_LMAsync/1`).then((response) => {
      setStatusOptions(response.data.itens);
    });
    api
      .get(`/crm/reports/PopulaProdutoFinanceiro_LMAsync/1`)
      .then((response) => {
        setProdutoFinanceiroOptions(response.data.itens);
      });
  }, []);

  return (
    <Form onSubmit={onSubmit} $radius={theme.radius}>
      <Field>
        <Wrapper>
          <FieldType>
            <Label htmlFor="V">Vencimento</Label>
            <Radio
              checked={state.tipoPesquisaData === '1'}
              id="V"
              name="tipoPesquisaData"
              onChange={onChange}
              type="radio"
              value="1"
            />
          </FieldType>
          <FieldType>
            <Label htmlFor="E">Emissão</Label>
            <Radio
              checked={state.tipoPesquisaData === '2'}
              id="E"
              name="tipoPesquisaData"
              onChange={onChange}
              type="radio"
              value="2"
            />
          </FieldType>
        </Wrapper>
        <InputFieldUI
          field="date"
          id="dataInicial"
          label="Data Inicial"
          maxLength={10}
          name="dataInicial"
          onChange={onChange}
          size={10}
          value={state.dataInicial}
        />
      </Field>
      <InputFieldUI
        field="date"
        id="dataFinal"
        label="Data Final"
        maxLength={10}
        name="dataFinal"
        onChange={onChange}
        size={10}
        value={state.dataFinal}
      />
      <StyledInvoiceNumberInput
        field="text"
        id="numeroFatura"
        label="Nº Fatura"
        maxLength={10}
        name="numeroFatura"
        onChange={onChange}
        value={state.numeroFatura}
      />
      {isMeasurement && (
        <InputFieldUI
          field="text"
          id="costCenter"
          label="Centro de Custo"
          name="costCenter"
          onChange={onChange}
        />
      )}

      <InputFieldUI
        field="select"
        id="codigoStatusFatura"
        label="Status"
        name="codigoStatusFatura"
        onChange={onChange}
        value={state.codigoStatusFatura}
      >
        <option value=""></option>
        {statusOptions.map((option: { id: string; nome: string }) => (
          <option key={option.id} value={option.id}>
            {option.nome}
          </option>
        ))}
      </InputFieldUI>

      <InputFieldUI
        field="select"
        id="codigoProdutoFinanceiro"
        label="Tipo"
        name="codigoProdutoFinanceiro"
        onChange={onChange}
        value={state.codigoProdutoFinanceiro}
      >
        <option value=""></option>
        {produtoFinanceiroOptions.map(
          (option: { id: string; nome: string }) => (
            <option key={option.id} value={option.id}>
              {option.nome}
            </option>
          )
        )}
      </InputFieldUI>

      <ButtonUI type="submit">Buscar</ButtonUI>
      <ButtonUI onClick={onClear} type="button" color="third">
        Limpar
      </ButtonUI>
    </Form>
  );
}

export default FinancialFilter;
