import React from 'react';
import styled from 'styled-components';

import { inputStyles } from '../InputUI/styles';
import InputContainerUI from '../InputUI/InputContainerUI';
import { getTheme } from 'pages/LandingPages/utils/helpers';

const StyledSelect = styled.select<{ error?: boolean; $radius?: number }>`
  ${inputStyles}
  cursor: pointer;
`;

interface SelectUIProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  error?: boolean;
  errorText?: string;
}

function SelectUI({ errorText, ...props }: SelectUIProps) {
  const theme = getTheme();
  return (
    <InputContainerUI errorText={errorText}>
      <StyledSelect $radius={theme.radius} {...props} />
    </InputContainerUI>
  );
}

export default SelectUI;
