import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  height: 40px;
  width: 110px;

  img {
    height: 100%;
    width: 100%;
  }
`;

type Props = {
  src: string;
};

function Logo({ src, ...props }: Props) {
  return (
    <StyledContainer {...props}>
      <img src={src} />
    </StyledContainer>
  );
}

export default Logo;
