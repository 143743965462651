import React from "react"

import { Container } from "./styles"

const TermsOfUse = () => {
  return (
    <Container>
      <h1>
        TERMOS DE USO E CONDIÇÕES GERAIS DO PORTAL DO CLIENTE LM FROTAS
      </h1>
      <p className="date">Atualizado em 13 de abril de 2021</p>
      <strong>
        TERMOS DE USO E CONDIÇÕES GERAIS DO PORTAL DO CLIENTE LM FROTAS
      </strong>{" "}
      Atualizado em 13 de abril de 2021 A seguir apresentamos os Termos de Uso e
      Condições Gerais (“Termos”) contendo as principais regras que devem ser
      observadas por Você ao acessar e utilizar as funcionalidades do Portal do
      Cliente da
      <p>
        FLEETZIL LOCAÇÕES E SERVIÇOS LTDA. (“Lm Frotas” ou “Nós”) com sede
        na Alameda Doutor Carlos de Carvalho, n.º 373, 7º andar, conjunto 701 a
        705, Centro, Curitiba/PR, CEP 80.410-180 CNPJ/ME: 25.463.646/0001-36
      </p>
      <p>
        disponível eletronicamente através do link
        portalcliente.fleetbrasil.com.br (“Portal do Cliente”).
      </p>
      <p>
        Como condição para acesso e uso das funcionalidades do Portal do
        Cliente, Você declara que fez a leitura completa e atenta das regras
        deste documento, estando plenamente ciente e de acordo com elas.
      </p>
      <p>
        Em algumas áreas do Porta do Cliente, poderão ser requiridas informações
        adicionais relacionadas a Você para melhor lhe atendermos, mas não se
        preocupe! A Lm Frotas reitera o seu compromisso com a proteção dos
        seus dados pessoais, cujas principais regras aplicáveis ao tratamento de
        tais dados se encontram dispostas em nossa Política de Privacidade, que
        pode ser acessada por Você a qualquer momento.
      </p>
      <p className="title">1. FUNCIONALIDADES DO PORTAL DO CLIENTE</p>
      <p>
        1.1. Restrição para menores. As funcionalidades do Portal do Cliente são
        voltadas somente às pessoas físicas maiores de 18 (dezoito) anos. Por
        esse motivo, a criação de perfis para pessoas físicas que não atingem os
        requisitos de idade é proibida.
      </p>
      <p>
        1.1.1. A Lm Frotas poderá recusar, não aprovar, cancelar ou
        limitar quaisquer solicitações de cadastro caso Você não forneça os
        dados corretos. O mesmo será aplicável à Conta de Acesso já cadastrada
        caso seja verificado que ela está sendo utilizada de forma fraudulenta,
        bem como de modo que tente ou viole estes Termos.
      </p>
      <p>
        1.2. Elegibilidade. Nós podemos alterar os critérios de elegibilidade de
        acesso e utilização do Portal do Cliente a qualquer tempo e a nosso
        critério.
      </p>
      <p>
        1.3. Acesso simultâneo. Para proteger Você e a integridade dos dados no
        Portal do Cliente, não serão permitidos acessos simultâneos ao Portal do
        Cliente, logados pelo mesmo Usuário, em mais de um dispositivo ao mesmo
        tempo.
      </p>
      <p>
        1.4. Apresentação do Portal. O Portal do Cliente e suas funcionalidades
        são apresentados a Você na maneira como estão disponíveis no momento do
        acesso, mas podem passar por constantes aprimoramentos e atualizações
        sem a necessidade de prévio aviso.
      </p>
      <p>
        1.4.1. Neste caso, a Lm Frotas compromete-se a preservar a
        funcionalidade do Portal com esforços razoáveis, utilizando layout que
        respeita a usabilidade e navegabilidade, sempre que possível, bem como
        exibir as funcionalidades de maneira clara, completa, precisa e
        suficiente de modo que exista uma correta percepção do que está sendo
        praticado e proteger, por meio do estado da técnica disponível, os dados
        coletados pelas funcionalidades disponibilizadas.
      </p>
      <p className="title">2. CADASTRO NO PORTAL DO CLIENTE</p>
      <p>
        {" "}
        2.1. Informações necessárias. Para utilizar as funcionalidades do Portal
        do Cliente, Você deverá possuir um cadastro prévio, que será efetuado
        pelo Administrador, de forma a possibilitar o login­ bem-sucedido à sua
        respectiva Conta de Acesso.
      </p>{" "}
      <p>
        2.2. Integridade das informações. Você é o único responsável pela
        integridade e veracidade das informações disponibilizadas por Você para
        criação da sua Conta de Acesso, bem como por aquelas inseridas
        diretamente no Portal do Cliente, devendo fornecer os dados requeridos
        de forma precisa e atualizada, respondendo em âmbito civil,
        administrativo e criminal pelos efeitos advindos de uso irregular de
        informações de terceiros ou informações falsas.
      </p>
      <p>
        {" "}
        2.2.1. Você compromete-se a, sempre que houver alterações de seus dados,
        informar a Lm Frotas através da área logada do Portal do Cliente
        ou através dos Canais de Atendimento, para fins de atualização.
      </p>{" "}
      <p>
        {" "}
        2.3. Comunicação. No cadastro, Você deverá fornecer um e-mail válido e
        de sua propriedade, pois será através dele que a Lm Frotas
        realizará as comunicações necessárias. Nesse sentido, Você reconhece que
        todas as comunicações realizadas por e-mail são válidas e suficientes
        para a divulgação de qualquer assunto que se refira ao Portal do
        Cliente, comprometendo-se o Usuário, desde já, a consultar regularmente
        as suas mensagens recebidas.
      </p>
      <p>
        {" "}
        2.4. Cadastro Limitado. O cadastro no Portal é limitado a 01 (um)
        Usuário por CPF, sendo expressamente vedado que o mesmo Usuário possua
        mais cadastros.
      </p>
      <p className="title"> 3. USO DO PORTAL DO CLIENTE POR VOCÊ</p>
      <p>
        3.1. Sigilo dos dados do Cadastro. Você deverá manter o sigilo dos dados
        de sua Conta de Acesso em relação a terceiros e utilizá-lo de modo
        individual e intransferível, não disponibilizando, divulgando ou
        compartilhando seus mecanismos de autenticação com quem quer que seja.
        Caso suspeite que a confidencialidade de sua Conta de Acesso foi
        violada, Você deverá proceder com a troca dos meios de autenticação,
        quando possível, além de comunicar à Lm Frotas, através dos Canais
        de Atendimento, para que possam ser tomadas as devidas providências,
        quando aplicável.
      </p>
      <p>
        3.1.1. Você está ciente e reconhece que todos os acessos e operações
        realizadas no Portal do Cliente após seu acesso bem-sucedido são
        interpretados como sendo de sua responsabilidade, de forma
        incontestável, inclusive aqueles derivados de uso indevido ou de
        divulgação deste para terceiros.
      </p>
      <p>
        {" "}
        3.2. Acesso ao Portal. O acesso ao Portal será efetuado através do uso
        da internet, através do link portalcliente.lmfrotas.com.br, não sendo
        permitido o acesso por meio de nenhuma forma diversa não autorizada pela
        Lm Frotas.
      </p>
      <p>
        {" "}
        3.3. Funcionalidades do Portal. Você poderá usufruir da navegação do
        Portal do Cliente, onde terá acesso a diversas funcionalidades
        destinadas a facilitar a utilização dos serviços Lm Frotas
        contratados por Você, em especial para a gestão de sua frota, desde o
        acompanhamento de status de novos pedidos, visualização e alteração de
        condutores, emissão de relatórios, levantamento de histórico de infração
        e multas, avaliação das informações financeiras (notas fiscais, boletos,
        status de pagamentos).
      </p>{" "}
      <p>
        {" "}
        3.3.1. As funcionalidades do Portal do Cliente serão apresentadas para
        Você de acordo com nível de acesso, definido previamente pelo
        Administrador, à sua Conta de Acesso.
      </p>
      <p>
        {" "}
        3.3.2. Para a inclusão ou edição de condutor, Você declara que possui as
        autorizações necessárias, quando aplicáveis, para informar os dados
        pessoais dos respectivos condutores, estando estes plenamente cientes do
        uso de suas informações de caráter pessoal no Portal do Cliente.
      </p>
      <p>
        {" "}
        3.4. Central de Ajuda. Para quaisquer dúvidas a respeito das informações
        recebidas ou das etapas necessárias a um determinado procedimento, Você
        poderá acessar a Central de Ajuda disponível no Portal do Cliente ou
        entrar em contato conosco diretamente em nossos outros Canais de
        Atendimento.
      </p>
      <p className="title">
        {" "}
        4. CONTEÚDO, DADOS SUBMETIDOS E PROPRIEDADE INTELECTUAL{" "}
      </p>
      <p>
        4.1. Propriedade Intelectual. Ao acessar o Portal do Cliente, Você
        declara que irá respeitar todos os direitos de propriedade intelectual
        da Lm Frotas e de seus fornecedores, incluindo, mas não se
        limitando, aos programas de computador, direitos autorais e direitos de
        propriedade industrial sobre as marcas, patentes, nomes de domínio,
        denominação social e desenhos industriais, eventualmente depositados ou
        registrados em nome da LM Frotas, bem como de todos os direitos
        referentes a terceiros que porventura estejam, ou estiveram, de alguma
        forma, disponíveis no Portal do Cliente, sendo que a sua infração poderá
        ensejar o pagamento de indenização por perdas e danos judicialmente
        apurados.
      </p>
      <p>
        {" "}
        4.2. Seus Dados. Você é e continuará sendo o titular e proprietário de
        todos os dados, submetidos e/ou compartilhados no Portal por Você (“Seus
        Dados”).
      </p>
      <p>
        {" "}
        4.2.1. Você também se declara ciente de que o Administrador da conta
        poderá realizar a criação ou exclusão da sua Conta de Acesso, ou a
        alteração no nível das suas permissões, de acordo com a necessidade.{" "}
      </p>
      <p>
        4.3. Legalidade dos Dados. A Lm Frotas não se obrigará a tratar
        quaisquer dos dados inseridos no Portal do Cliente se houver razões para
        crer que tal tratamento possa imputar à Lm Frotas qualquer
        infração de qualquer lei aplicável ou que o Portal do Cliente esteja
        sendo utilizado, sob critério exclusivo da Lm Frotas, para
        quaisquer fins ilegais, ilícitos ou contrários à moralidade.{" "}
      </p>
      <p>
        4.4. Armazenamento. A Lm Frotas não presta e o Portal do Cliente
        não constitui serviço de armazenamento, portanto a Lm Frotas não
        possui qualquer obrigação de armazenar ou reter, a qualquer tempo, a
        cópia de qualquer conteúdo do Portal do Cliente.
      </p>{" "}
      <p>
        4.5. Não mineração. É vedada a utilização de softwares de mineração de
        dados no Portal do Cliente, de qualquer tipo ou espécie, além de outro
        aqui não tipificado, que atue de modo similar.{" "}
      </p>
      <p>
        4.6. Indenização. Na incidência de danos a Lm Frotas ou a
        terceiros, o responsável se compromete a arcar com todas as obrigações
        de indenizar o sujeito lesado, inclusive aqueles cuja origem for de atos
        praticados por meio de sua Conta de Acesso, assumindo o polo passivo de
        ação judicial ou procedimento administrativo e requerendo a exclusão da
        Fleet Solution, devendo arcar totalmente com as despesas e custas
        processuais atinentes, deixando-a livre de prejuízos e ônus.{" "}
      </p>
      <p>
        4.7. Integridade do Portal. Você compromete-se a não acessar áreas de
        programação do Portal, seu banco de dados, códigos fonte ou qualquer
        outro conjunto de dados disponíveis nestes ambientes, bem como não
        realizar ou permitir engenharia reversa, nem traduzir, decompilar,
        copiar, modificar, reproduzir, alugar, sublicenciar, publicar, divulgar,
        transmitir, emprestar, distribuir ou, de outra maneira, dispor
        inapropriadamente das funcionalidades do Portal do Cliente.
      </p>
      <p className="title"> 5. ISENÇÃO E LIMITAÇÕES DE RESPONSABILIDADE</p>
      <p>
        {" "}
        5.1. Disponibilidade do Portal. Nós envidamos nossos esforços para a
        manutenção da disponibilidade contínua e permanente do Portal. No
        entanto, pode ocorrer, eventualmente, alguma indisponibilidade
        temporária decorrente de manutenção necessária ou mesmo gerada por
        motivo de força maior, como desastres naturais, falhas nos sistemas de
        comunicação e acesso à internet, ataques cibernético invasivos ou
        quaisquer fatos de terceiro que fogem da esfera de vigilância e
        responsabilidade da Lm Frotas.
      </p>
      <p>
        {" "}
        5.1.1. Se isso ocorrer, Nós envidaremos nossos melhores esforços para
        reestabelecer o acesso ao Portal do Cliente o mais breve possível,
        dentro das limitações técnicas de seus serviços e serviços de terceiros,
        dos quais depende para ficar online. Por essa razão, Você fica ciente
        que não poderá pleitear indenização ou reparação de danos no caso de o
        Portal permanecer fora do ar, independente da motivação.
      </p>
      <p>
        {" "}
        5.2. Sem prejuízo de outras condições destes Termos, a Lm Frotas
        não se responsabilizará:
      </p>
      <p>
        {" "}
        (i) Por quaisquer problemas, bugs, glitches ou funcionamentos indevidos
        que ocorrerem nos seus dispositivos e equipamentos;
      </p>
      <p>
        {" "}
        (ii) Por qualquer dano direto ou indireto ocasionado por eventos de
        terceiros, a exemplo, mas não se limitando, a ataque de hackers, falha
        no sistema, no servidor ou na conexão à internet, inclusive por ações de
        softwares que possam, de algum modo, danificar os ativos físicos ou
        lógicos dos Usuários em decorrência do acesso ou utilização do Portal do
        Cliente, bem como a transferência de dados, arquivos, imagens, textos,
        áudios ou vídeos eventualmente contidos no Portal do Cliente;
      </p>
      <p>
        {" "}
        (iii) Pela navegação dos Usuários em quaisquer links externos
        disponibilizados no Portal, sendo dever deles a leitura dos Termos e
        Condições de Uso e da Política de Privacidade do recurso acessado e agir
        conforme o determinado; e
      </p>
      <p>
        {" "}
        (iv) Por verificar, controlar, aprovar ou garantir a adequação ou
        exatidão das informações ou dados disponibilizados em tais links, não
        sendo, portanto, responsável por prejuízos, perdas ou danos incorridos
        pela visita de tais sites, cabendo ao interessado verificar a
        confiabilidade das informações e dados ali exibidos antes de tomar
        alguma decisão ou praticar algum ato.
      </p>
      <p className="title">6. RECOMENDAÇÕES DE SEGURANÇA</p>
      <p>
        {" "}
        6.1. Acesso não autorizado. Nós não seremos responsáveis por eventual
        acesso de terceiros não autorizados à sua Conta de Acesso, cabendo
        somente à Você a responsabilidade pela guarda dos mecanismos de acesso,
        assim como o não compartilhamento destas informações com terceiros.
      </p>
      <p>
        {" "}
        6.1.1. Não caberá também à Lm Frotas a responsabilidade pelo
        acesso não autorizado proveniente de ataques realizados por hackers ou
        devido a ações de vírus que venham a acessar as suas informações e seu
        cadastro.
      </p>
      <p>
        {" "}
        6.1.2. Caso Você constate ou desconfie que seu cadastro está sendo
        utilizado por terceiros não autorizados ou que tenha sido vítima de
        ataque de hackers ou de programas e/ou arquivos maliciosos (vírus),
        entre em contato imediatamente com a Lm Frotas através dos canais
        disponíveis, para que possam ser tomadas as devidas providências.{" "}
      </p>
      <p>
        6.2. Acesso ao Portal. Sempre que acessar o Portal do Cliente, verifique
        se de fato está no ambiente disponibilizado pela Lm Frotas. Ações
        de hackers podem, em uma tentativa de roubar seus dados, replicarem o
        Portal do Cliente ou uma área dele. 6.2.1. Para verificar a integridade
        e veracidade do Portal, clique no cadeado presente na barra de endereço
        do navegador, verificando os certificados presentes e informados.
      </p>
      <p>
        6.2.2. Caso o navegador não apresente os certificados e/ou retorne com a
        mensagem de que o acesso não é seguro, interrompa imediatamente o uso do
        Portal do Cliente e comunique a Lm Frotas para que as devidas
        providências sejam tomadas.
      </p>
      <p>
        {" "}
        6.3. Encerramento da sessão. Como medida de segurança, sugerimos que
        Você encerre sua sessão após finalizar as operações. Nós não nos
        responsabilizamos por sessões não finalizadas em computadores públicos
        e/ou com acesso de terceiros.
      </p>
      <p className="title"> 7. PENALIDADES</p>
      <p>
        {" "}
        7.1. Penalidades. O descumprimento de qualquer cláusula destes Termos,
        poderá gerar:
      </p>
      <p>
        (i) suspensão temporária do Portal para seu login e consequente
        impossibilidade de usufruir do Portal;
      </p>{" "}
      <p> (ii) bloqueio e exclusão de sua Conta de Acesso e/ou </p>{" "}
      <p>(iii) responsabilização nas esferas cível e penal.</p>
      <p>
        7.1.1. Você reconhece desde já e se compromete, durante a utilização do
        Portal do Cliente, a não realizar quaisquer ações que atentem contra a
        moralidade, a ética e boa-fé, obrigando-se a em nenhuma hipótese
        divulgar, instigar ou auxiliar a prática de atos ilícitos ou
        fraudulentos, devendo combatê-los sempre que tomar conhecimento sob pena
        das previsões da cláusula 7.1 acima.
      </p>{" "}
      <p className="title">8. DISPOSIÇÕES GERAIS</p>
      <p>
        {" "}
        8.1. Canais de Atendimento. Você poderá utilizar os Canais de
        Atendimento disponibilizados no Portal do Cliente toda vez que
        presenciar ou verificar conteúdo impróprio no Portal do Cliente, seja
        ele notadamente ilícito ou contrário às regras de uso estipuladas nestes
        Termos.
      </p>
      <p>
        {" "}
        8.2. Atualização dos Termos. Estes Termos estão sujeitos a constante
        melhorias e aprimoramentos. Assim, Nós nos reservamos o direito de
        modificá-los a qualquer tempo, conforme sua finalidade ou conveniência,
        tal qual para adequação e conformidade legal de disposição de lei ou
        norma que tenha força jurídica equivalente, incluindo órgãos
        reguladores, ou seja, Você deverá verificá-lo sempre que efetuar o
        acesso ao Portal do Cliente.
      </p>
      <p>
        {" "}
        8.2.1. Ocorrendo atualizações substanciais nestes Termos, a Fleet
        Solution comunicará Você pelas ferramentas disponíveis no Portal do
        Cliente ou pelos meios de contato fornecidos por Você sobre eventuais
        alterações.
      </p>
      <p>
        {" "}
        8.3. Novação e renúncia. A tolerância do eventual descumprimento de
        quaisquer condições destes Termos não constituirá renúncia ou novação
        nem impedirá a Lm Frotas de exigir estas condições a qualquer
        tempo.
      </p>{" "}
      <p>
        8.4. Nulidade. Na hipótese de qualquer uma das disposições destes termos
        e condições vir a ser considerada contrária à lei brasileira, por
        qualquer autoridade governamental ou decisão judicial, as demais
        disposições não afetadas continuarão em vigor.
      </p>
      <p>
        {" "}
        8.5. Data e hora. O Portal tem como base a data e horários oficiais de
        Brasília (UTC/GMT – 03:00).
      </p>
      <p>
        {" "}
        8.6. Lei aplicável e foro. Estes Termos serão interpretados segundo a
        legislação brasileira, no idioma português, sendo eleito o foro do seu
        domicílio para dirimir qualquer controvérsia que envolva este documento,
        salvo ressalva específica de competência pessoal, territorial ou
        funcional pela legislação aplicável.
      </p>
      <p>
        {" "}
        8.6.1. Você, caso não possua domicílio no Brasil e em razão de os
        serviços oferecidos pela Lm Frotas serem voltados ao território
        nacional, se submete à legislação brasileira, concordando, portanto, que
        em havendo litígio a ser solucionado, a ação deverá ser proposta no Foro
        da Comarca de Curitiba, Estado do Paraná.
      </p>
      <p className="title"> 9. GLOSSÁRIO</p>
      <p>
        (i) Administrador: Conta de Acesso de um representante da sua empresa
        que possui poderes para criar e administrar as demais Contas de Acesso.
      </p>
      <p>
        {" "}
        (ii) Canais de Atendimento: Meios de contato disponibilizados pela Fleet
        Solutions para atendimento das demandas dos Usuários.
      </p>
      <p>
        (i) Conta de Acesso: Conta criada para a utilização das funcionalidades
        do Portal.
      </p>{" "}
      <p>
        {" "}
        (ii) Layout: Conjunto compreendido entre aparência, design e fluxos do
        Portal.
      </p>
      <p>
        {" "}
        (iii) Link: Terminologia para endereço de internet. (iv) Usuários ou
        Você: Pessoas naturais cadastradas no Portal.
      </p>
    </Container>
  )
}

export default TermsOfUse
