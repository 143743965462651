import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'antd/lib/modal/Modal';
import 'moment/locale/pt-br';

import Header from '../../../components/Header';
import Title from '../../../components/Title';
import Pagination from '../../../components/Pagination';
import LocationSpin from '../../../components/LoadingSpin';
import DriverSelector from '../DriverSelector';
import {
  Container,
  LineButtons,
  Table,
  BtnLink,
  WrapperForm,
  Select,
  SelectConductor,
  WrapperTable,
} from './styles';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { ConductorByCustomerI } from './types';
import { ContractI, CustomerOptionI } from '../DriverSelector/types';
import MobileFilter from 'components/business/MobileFilter';

const PER_PAGE = 10;

function Drivers() {
  const { addToast } = useToast();
  const { userId } = useAuth();

  const [conductor, setConductor] = useState('');
  const [contract, setContract] = useState<ContractI | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [customerId, setCustomerId] = useState('0');
  const [customersOptions, setCustomersOptions] = useState<CustomerOptionI[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [modalDriverSelector, setModalDriverSelector] = useState({
    open: false,
    isFirstDriver: false,
  });
  const [plaque, setPlaque] = useState('');
  const [total, setTotal] = useState(0);
  const [vehicleContractOptions, setVehicleContractOptions] = useState([]);

  useEffect(() => {
    api.get(`/crm/reports/${userId}/customers-cms-lm`).then((response) => {
      setCustomersOptions(response.data);
      setCustomerId(response.data?.[0]?.customerCMSId);
      getVehicleContractByCustomer(response.data?.[0]?.customerCMSId);
    });
  }, []);

  const getVehicleContractByCustomer = (clienteId: number, paginaAtual = 1) => {
    setLoading(true);
    api
      .post(`/crm/reports/conductor-by-customer_lm`, {
        clienteId: [clienteId],
        condutorId: [],
        condutorNome: conductor,
        exibirTotais: true,
        itensPorPagina: PER_PAGE,
        ordemCampo: '',
        ordemDescendente: false,
        paginaAtual: paginaAtual,
        placa: plaque,
        possuiCondutor: null,
        UserId: clienteId === 0 ? +userId : 0,
      })
      .then((response) => {
        setVehicleContractOptions(response.data.itens);
        setTotal(response.data.totalItens);
      })
      .catch(() => {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Erro ao tentar buscar a lista de condutores!',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSetPlaque = (value: string) => {
    setPlaque(value);
  };

  const handleSetConductor = (value: string) => {
    setConductor(value);
  };

  const handleCancel = () => {
    setModalDriverSelector({
      open: false,
      isFirstDriver: true,
    });
    setContract(undefined);
  };

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    getVehicleContractByCustomer(Number(customerId), pageNumber);
  };

  const handleDriverSelector = (contratoId: number) => {
    api
      .get(`/crm/reports/contract-driver_lm/${contratoId}`)
      .then((response) => {
        setContract(response.data);
        setModalDriverSelector({ open: true, isFirstDriver: false });
      });
  };

  const handleSearchClick = () => {
    setCurrentPage(1);
    getVehicleContractByCustomer(+customerId);
  };

  return (
    <>
      <Header />
      <Container>
        <Title
          subtitle="Visualize, troque e adicione novos condutores"
          title="CONDUTORES"
        />
        <LineButtons>
          <Link to="/edit-driver">
            <button>Editar Condutor</button>
          </Link>
          <Link to="/register-driver">
            <button>Cadastrar Novo Condutor</button>
          </Link>
        </LineButtons>
        <MobileFilter>
          <WrapperForm>
            <div className="form-item">
              <label>Cliente</label>
              <Select
                onChange={(e) => setCustomerId(e.target.value)}
                value={customerId}
              >
                <option value="0">Todos</option>
                {customersOptions.map((customer: CustomerOptionI) => (
                  <option
                    key={customer.customerCMSId}
                    value={customer.customerCMSId}
                  >
                    {customer.customerName}
                  </option>
                ))}
              </Select>
            </div>
            <div className="form-item">
              <label>Placa</label>
              <input
                className="name-profile"
                name="placa"
                onChange={(e) => handleSetPlaque(e.target.value)}
              />
            </div>
            <div className="form-item">
              <label>Condutor</label>
              <input
                className="name-profile"
                name="condutor"
                onChange={(e) => handleSetConductor(e.target.value)}
              />
            </div>

            <LocationSpin loading={loading} />
            <div className="wrapper-button">
              <button className="btn-new-profile" onClick={handleSearchClick}>
                Buscar
              </button>
            </div>
          </WrapperForm>
        </MobileFilter>
        <WrapperTable>
          <Table>
            <tbody>
              <tr>
                <th>Placa</th>
                <th>Chassi</th>
                <th>Modelo</th>
                <th>Condutor</th>
                <th>CPF</th>
                <th>Status</th>
                <th>Ação</th>
              </tr>
              {vehicleContractOptions.length ? (
                vehicleContractOptions.map(
                  (conductor: ConductorByCustomerI, i: number) => (
                    <tr key={i}>
                      <td>{conductor.veiculoPlaca}</td>
                      <td>
                        <span className="cell-chassi">
                          {conductor.veiculoChassi}
                        </span>
                      </td>
                      <td>{conductor.modeloNome}</td>

                      <td>{conductor.condutorNome}</td>
                      <td>{conductor.condutorCPF}</td>
                      <td>{conductor.statusVeiculoContratoNome}</td>
                      <td>
                        <BtnLink
                          onClick={() =>
                            handleDriverSelector(conductor.contratoId)
                          }
                        >
                          {conductor.condutorId ? (
                            'Trocar condutor'
                          ) : (
                            <SelectConductor>Vincular condutor</SelectConductor>
                          )}
                        </BtnLink>
                      </td>
                    </tr>
                  )
                )
              ) : (
                <tr>
                  <td colSpan={7} className="tdNoDate">
                    Nenhum condutor cadastrado!
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </WrapperTable>
        <Pagination
          currentPage={currentPage}
          paginate={paginate}
          perPage={PER_PAGE}
          total={total}
        />
        {contract && (
          <Modal
            footer={null}
            onCancel={handleCancel}
            open={modalDriverSelector.open}
            title=""
            width="38%"
          >
            <DriverSelector
              contract={contract}
              getVehicleContractByCustomer={getVehicleContractByCustomer}
            />
          </Modal>
        )}
      </Container>
    </>
  );
}

export default Drivers;
