import { ChangeEvent, useEffect, useReducer, useState } from 'react';

import { DEFAULT_DATES } from 'pages/Reports/utils/constants';
import { InputsT, UseFiltersProps } from 'pages/Reports/types';

function reducerInputs(state: InputsT, action: Partial<InputsT>) {
  return { ...state, ...action };
}

function useFilters({ customers }: UseFiltersProps) {
  const [inputs, setInputs] = useReducer(reducerInputs, {
    customerId: '',
    initialDate: DEFAULT_DATES.initial,
    finalDate: DEFAULT_DATES.final,
  });
  const [params, setParams] = useState({
    // os inputs podem não refletir os params da busca
    customerId: inputs.customerId,
    initialDate: inputs.initialDate,
    finalDate: inputs.finalDate,
  });

  useEffect(() => {
    const initializeDefaultCustomerId = () => {
      const firstCustomerId = customers[0].customerCMSId?.toString();
      setInputs({ customerId: firstCustomerId });
      setParams((prevParams) => ({
        ...prevParams,
        customerId: firstCustomerId,
      }));
    };

    if (customers.length) {
      initializeDefaultCustomerId();
    }
  }, [customers]);

  const handleCustomerIdChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setInputs({ customerId: e.target.value });
  };

  const handleDatesChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setInputs({ [e.target.name]: e.target.value });
    } else {
      setInputs({
        initialDate: DEFAULT_DATES.initial,
        finalDate: DEFAULT_DATES.final,
      }); // ao clicar no limpar
    }
  };

  return {
    inputs,
    params,
    handleCustomerIdChange,
    handleDatesChange,
    setParams,
  };
}

export default useFilters;
