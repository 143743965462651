import React from 'react';

import { isLandingpagePath } from 'pages/LandingPages/utils/helpers';
import SigninPage from 'pages/LandingPages/pages/SigninPage';
import HomePdc from 'pages/Home/HomePdc';

function LandingPage() {
  if (isLandingpagePath()) {
    return <SigninPage />;
  }
  return <HomePdc />;
}

export default LandingPage;
