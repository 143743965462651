import { useEffect, useState } from 'react';

import api from 'services/api';
import { useToast } from 'hooks/toast';
import { UseReportDateProps } from 'pages/Reports/types';
import { getFirstApiError } from 'pages/Reports/utils/helpers';

function useFetchData({
  apiUrl,
  inputs,
  params,
  setParams,
}: UseReportDateProps) {
  const { addToast } = useToast();

  const [canFetch, setCanFetch] = useState(true);
  const [data, setAllData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetchAllData = () => {
      setLoadingData(true);
      setCanFetch(false);
      setPage(1);

      api
        .get(apiUrl, {
          params: {
            clienteId: params.customerId,
            dataInicial: params.initialDate,
            dataFinal: params.finalDate,
          },
        })
        .then((response) => {
          setAllData(response?.data?.itens?.reverse());
        })
        .catch((error) => {
          setAllData([]);
          addToast({
            title: 'Erro',
            type: 'error',
            description:
              getFirstApiError(error?.response?.data?.errors) ||
              error?.response?.data,
          });
        })
        .finally(() => {
          setLoadingData(false);
        });
    };

    if (canFetch && params.customerId) {
      fetchAllData();
    }
  }, [canFetch, params]);

  const handleSearchClick = () => {
    setCanFetch(true);
    setParams(inputs);
  };

  const handlePaginateClick = (currentPage: number) => {
    setPage(currentPage);
  };

  return {
    data,
    canFetch,
    loadingData,
    page,
    handlePaginateClick,
    handleSearchClick,
  };
}

export default useFetchData;
