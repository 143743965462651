import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  ChangeEvent,
} from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import moment from 'moment';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import InputMask from 'react-input-mask';
import { useLocation, useParams } from 'react-router-dom';
import { cpf } from 'cpf-cnpj-validator';

import api from '../../../services/api';
import Header from '../../../components/Header';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useAuth } from '../../../hooks/auth';
import Title from '../../../components/Title';

import { Switch } from 'antd';

import LocationSpin from '../../../components/LoadingSpin';

import {
  Container,
  Line,
  SelectContainer,
  Button,
  InputMaskStyle,
  WrapperButton,
  CheckBoxContainer,
} from './styles';

interface RegisterConductor {
  ClienteId: number;
  ClientesDoc: string;
  CondutorCPF: string;
  CondutorNome: string;
  CondutorEmail: string;
  CondutorSexo: string;
  CondutorDataNascimento: string;
  CondutorRG: string;
  CondutorRGOrgaoEmissor: string;
  CondutorRGDataEmissao: string;
  CondutorEstadoCivilId: number;
  CondutorEstadoCivilNome: string;
  CondutorCNHRegistro: string;
  CondutorCNHData1Hab: string;
  CondutorCNHDataEmissao: string;
  CondutorCNHDataValidade: string;
  CondutorTelefone: string;
  Cidade: string;
}

interface PaisOptions {
  id: number;
  nome: string;
}
interface UFOptions {
  codigo: string;
  nome: string;
}
interface CityOptions {
  cidade: string;
  cidadeId: number;
}

interface CategoriaCNH {
  categoria: string;
}

interface Conductor {
  condutorId: number;
  condutorNome: string;
}
const RegisterDriver: React.FC = () => {
  const location = useLocation();
  const [disabled, setDisabled] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [genderOptions, setGenderOptions] = useState([]);
  const [civilStatusOptions, setCivilStatusOptions] = useState([]);
  const history = useHistory();

  const [conductorOptions, setConductorOptions] = useState<Conductor[]>();
  const [condutorId, setCondutorId] = useState<string>('');

  const [customersOptions, setCustomersOptions] = useState([]);
  const [customerId, setCustomerId] = useState('0');
  const { userId } = useAuth();
  const [cpfCNPF, setCPF] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [rg, setRg] = useState('');
  const [rgEmitter, setRgEmitter] = useState('');
  const [rgEmitterDate, setRgEmitterDate] = useState('');
  const [civilStatus, setCivilStatus] = useState('');
  const [cnhRegistry, setCnhRegistry] = useState('');
  const [cnhFirstDate, setCnhFirstDate] = useState('');
  const [cnhEmitterDate, setCnhEmitterDate] = useState('');
  const [cnhExpirationDate, setCnhExpirationDate] = useState('');
  const [ddd, setDDD] = useState('');
  const [phone, setPhone] = useState('');

  const [estrangeiro, setEstrangeiro] = useState(false);

  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [pais, setPais] = useState('0');
  const [estado, setEstado] = useState('0');
  const [cidade, setCidade] = useState('0');
  const [prontuario, setProntuario] = useState('');
  const [categoriaCNH, setCategoriaCNH] = useState('');

  const [ddiFax, setDDIFax] = useState('');
  const [dddFax, setDDDFax] = useState('');
  const [phoneFax, setPhoneFax] = useState('');

  const [ddiCelular, setDDICelular] = useState('');
  const [dddCelular, setDDDCelular] = useState('');
  const [phoneCelular, setPhoneCelular] = useState('');

  const [ufOptions, setUFOptions] = useState<UFOptions[]>();
  const [cidadeOptions, setCidadeOptions] = useState<CityOptions[]>();
  const [paisOptions, setPaisOptions] = useState<PaisOptions[]>();

  const [categoriaCNHOptions, setCategoriaCNHOptions] =
    useState<CategoriaCNH[]>();
  const [loading, setLoading] = useState(false);

  const [loadingDriver, setLoadinegDriver] = useState(false);
  const [moradi, setLoadinegDataDriver] = useState(false);

  const { add } = useParams<any>();

  useEffect(() => {
    console.log('add: ' + add);
    console.log(location.pathname);
    var aLoc = location.pathname.split('/');
    console.log({ aLoc });

    if (location.pathname == '/edit-driver') {
      setDisabled(true);
    }

    api.get('/civilstatusenum').then((response: any) => {
      setCivilStatusOptions(response.data);
    });

    api.get('/genderenum').then((response: any) => {
      setGenderOptions(response.data);
    });

    var url = 'http://localhost:5002/api';

    api.get(`/crm/reports/${userId}/customers-cms-lm`).then((response: any) => {
      console.log(response.data);
      setCustomersOptions(response.data);
      if (response.data.length > 0) {
        setCustomerId(response.data[0].customerCMSId);
        if (disabled) {
          handleSelectCustomer(response.data[0].customerCMSId);
        }
      }
    });
  }, [disabled]);

  useEffect(() => {
    // var arrayPais = [];
    // arrayPais.push( { paisId: 1, pais: "Brasil" });
    // setPaisOptions(arrayPais);

    getCountry();
    // getUFsCalls();

    var arrayCatenaCNH = [];
    arrayCatenaCNH.push({ categoria: 'A' });
    arrayCatenaCNH.push({ categoria: 'B' });
    arrayCatenaCNH.push({ categoria: 'C' });
    arrayCatenaCNH.push({ categoria: 'D' });
    arrayCatenaCNH.push({ categoria: 'E' });
    setCategoriaCNHOptions(arrayCatenaCNH);
  }, []);

  const handleSelectCustomer = (customerCMSId: any) => {
    if (customerCMSId == '') return;

    setCustomerId(customerCMSId);
    // var param = {
    //   "exibirTotais": true,
    //   "paginaAtual": 1,
    //   "itensPorPagina": 100,
    //   "ordemCampo": "",
    //   "ordemDescendente": false,
    //   "clienteId": [
    //     +customerCMSId
    //   ],
    //   "condutorId": [

    //   ],
    //   "condutorNome": "",
    //   "placa": ""
    // }

    var param = {
      clienteId: +customerCMSId,
    };

    setLoadinegDriver(true);
    clearFields();

    var url = 'http://localhost:5002/api';
    api
      //.get(`/reports/conductor-by-customer/${customerCMSId}`)
      .post(`/crm/reports/driver-list_lm`, param)
      .then((response: any) => {
        console.log(response.data);
        setConductorOptions(response.data);
        setLoadinegDriver(false);
      })
      .catch((err) => {
        setLoadinegDriver(false);
        console.log(err);
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Erro ao tentar buscar a lista de condutores!',
        });
      });
  };

  const handleKeyUpCPF = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCPF(convertoToNumberOnly(e.target.value));
  }, []);

  const handleKeyUpFullName = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFullName(e.target.value);
    },
    []
  );

  const handleKeyUpEmail = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }, []);

  const handleKeyUpBirthDate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setBirthdate(e.target.value);
    },
    []
  );

  const handleKeyUpRg = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setRg(e.target.value);
  }, []);

  const handleKeyUpRgEmitter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setRgEmitter(e.target.value);
    },
    []
  );

  const handleKeyUpRgEmitterDate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setRgEmitterDate(e.target.value);
    },
    []
  );

  const handleKeyUpCNHRegistry = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCnhRegistry(convertoToNumberOnly(e.target.value));
    },
    []
  );

  const handleKeyUpCNHFirstDate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCnhFirstDate(e.target.value);
    },
    []
  );

  const handleKeyUpCNHEmitterDate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCnhEmitterDate(e.target.value);
    },
    []
  );

  const handleKeyUpCNHExpirationDate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCnhExpirationDate(e.target.value);
    },
    []
  );

  const handleKeyUpDDD = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDDD(convertoToNumberOnly(e.target.value));
  }, []);

  const handleKeyUpPhone = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPhone(convertoToNumberOnly(e.target.value));
  }, []);

  const handleSelectGender = (event: any) => {
    setGender(event);
  };

  const handleSelectCivilStatus = (event: any) => {
    setCivilStatus(event);
  };

  const handleSelectConductor = useCallback(
    (condutorId: any) => {
      console.log(condutorId);
      setCondutorId(condutorId);

      setLoadinegDriver(true);
      clearFields();
      api
        //.get(`/reports/conductor-by-customer/${customerId}/${condutorId}`)
        .get(`/crm/reports/conductor-by-id-lm/${condutorId}`)
        .then((response: any) => {
          console.log(response.data);
          try {
            setFullName(response.data.nome != null ? response.data.nome : '');
            setEndereco(
              response.data.endereco != null ? response.data.endereco : ''
            );
            setEstrangeiro(response.data.indicadorEstrangeiro);
            setNumero(
              response.data.enderecoNumero != null
                ? response.data.enderecoNumero
                : ''
            );
            setComplemento(
              response.data.enderecoComplemento != null
                ? response.data.enderecoComplemento
                : ''
            );

            let pais =
              response.data.paisId === '0' ? '0' : response.data.paisId;
            setPais(pais);
            getUFsCalls(+pais);

            let estado =
              response.data.estadoId === '0' ? '0' : response.data.estadoId;
            setEstado(estado);
            getCityByUF(+estado);

            setCidade(
              response.data.cidadeId === '0' ? '0' : response.data.cidadeId
            );
            setRg(response.data.rg != null ? response.data.rg : '');
            setCPF(response.data.cpf != null ? response.data.cpf : '');
            setProntuario(response.data.numeroProntuario);
            setCnhRegistry(
              response.data.numeroCnh != null ? response.data.numeroCnh : ''
            );
            setCategoriaCNH(
              response.data.categoriaCnh != null
                ? response.data.categoriaCnh
                : ''
            );

            if (response.data.validadeCnh != '0001-01-01T00:00:00+00:00') {
              console.log('validadeCNH');
              setCnhExpirationDate(
                moment(response.data.validadeCnh).format('YYYY-MM-DD')
              );
            }

            setEmail(response.data.email != null ? response.data.email : '');
            setDDD(
              response.data.dddResidencial != null
                ? response.data.dddResidencial
                : ''
            );
            setPhone(
              response.data.telefone != null ? response.data.telefone : ''
            );
            setDDIFax(response.data.ddiFax != null ? response.data.ddiFax : '');
            setDDDFax(response.data.dddFax != null ? response.data.dddFax : '');
            setPhoneFax(response.data.fax != null ? response.data.fax : '');
            setDDICelular(
              response.data.ddiCelular != null ? response.data.ddiCelular : ''
            );
            setDDDCelular(
              response.data.dddCelular != null ? response.data.dddCelular : ''
            );
            setPhoneCelular(
              response.data.celular != null ? response.data.celular : ''
            );

            // setGender(
            //   response.data[0].sexo != ""
            //     ? response.data[0].sexo === "M"
            //       ? "1"
            //       : "2"
            //     : " "
            // )
            // if (response.data[0].dataNascimento != "0001-01-01T00:00:00") {
            //   setBirthdate(
            //     moment(response.data[0].dataNascimento).format("YYYY-MM-DD")
            //   )
            // }
            //setRgEmitter(response.data[0].rgOrgaoEmissor)/
            // if (response.data[0].rgDataEmissao != null)
            //   setRgEmitterDate(
            //     moment(response.data[0].rgDataEmissao).format("YYYY-MM-DD")
            //   )
            // else setRgEmitterDate("")

            //setCivilStatus(response.data[0].estadoCivilNome)

            // if (response.data[0].cnhData1Hab != "0001-01-01T00:00:00") {
            //   setCnhFirstDate(
            //     moment(response.data[0].cnhData1Hab).format("YYYY-MM-DD")
            //   )
            // }
            // if (response.data[0].cnhDataEmissao != "0001-01-01T00:00:00") {
            //   setCnhEmitterDate(
            //     moment(response.data[0].cnhDataEmissao).format("YYYY-MM-DD")
            //   )
            // }

            // if (response.data[0].cnhDataValidade != "0001-01-01T00:00:00") {
            //   setCnhExpirationDate(
            //     moment(response.data[0].cnhDataValidade).format("YYYY-MM-DD")
            //   )
            // }

            setLoadinegDriver(false);
          } catch (err) {
            setLoadinegDriver(false);
            console.error(err);
          }
        })
        .catch((error) => {
          setLoadinegDriver(true);
          addToast({
            title: 'Erro',
            type: 'error',
            description: 'Erro ao tentar buscar os dados do condutor.',
          });
        });
    },
    [customerId, pais, estado, cidade]
  );

  const clearFields = useCallback(() => {
    setFullName('');
    setEndereco('');
    setEstrangeiro(false);
    setNumero('');
    setComplemento('');
    setPais('1');
    setEstado('0');
    setCidade('0');
    setRg('');
    setCPF('');
    setProntuario('0');
    setCnhRegistry('');
    setCategoriaCNH('');
    setCnhExpirationDate('');

    setEmail('');
    setDDD('');
    setPhone('');
    setDDIFax('');
    setDDDFax('');
    setPhoneFax('');
    setDDICelular('');
    setDDDCelular('');
    setPhoneCelular('');
  }, []);

  const handleKeyUpEstrangeiro_ = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      //setEstrangeiro(e.target.value)
      setEstrangeiro(!estrangeiro);
    },
    []
  );

  const handleKeyUpEstrangeiro = () => {
    setEstrangeiro(!estrangeiro);
  };

  const handleKeyUpEndereco = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setEndereco(e.target.value);
    },
    []
  );

  const handleKeyUpNumero = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setNumero(e.target.value);
  }, []);

  const handleKeyUpComplemento = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setComplemento(e.target.value);
    },
    []
  );

  const handleSelectPais = useCallback((value: any) => {
    console.log(value);
    setPais(value);
    getUFsCalls(value);
  }, []);

  const handleSelectEstado = useCallback((value: any) => {
    setEstado(value);
  }, []);

  const handleSelectCidade = useCallback((value: any) => {
    setCidade(value);
  }, []);

  const handleKeyUpProntuario = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setProntuario(convertoToNumberOnly(e.target.value));
    },
    []
  );

  const handleSelectCategoriaCNH = useCallback((value: any) => {
    setCategoriaCNH(value);
  }, []);

  const handleKeyUpDDIFax = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDDIFax(e.target.value);
  }, []);

  const handleKeyUpDDDFax = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDDDFax(e.target.value);
  }, []);

  const handleKeyUpPhoneFax = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPhoneFax(e.target.value);
    },
    []
  );

  const handleKeyUpDDICelular = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDDICelular(e.target.value);
    },
    []
  );

  const handleKeyUpDDDCelular = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDDDCelular(e.target.value);
    },
    []
  );

  const handleKeyUpPhoneCelular = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPhoneCelular(e.target.value);
    },
    []
  );

  const handleSubmitRegisterDriver = useCallback(
    async (data: RegisterConductor) => {
      try {
        formRef.current?.setErrors({});
        data.ClienteId = parseInt(userId);
        data.ClientesDoc = '' + customerId;
        data.CondutorCPF = cpfCNPF;
        data.CondutorNome = fullName;
        data.CondutorEmail = email;
        data.CondutorSexo = gender != '' ? (gender === '1' ? 'M' : 'F') : '';
        data.CondutorDataNascimento = birthdate;
        data.CondutorRG = rg;
        data.CondutorRGOrgaoEmissor = rgEmitter;
        data.CondutorRGDataEmissao = rgEmitterDate;
        data.CondutorEstadoCivilId = 0;
        data.CondutorEstadoCivilNome = civilStatus;
        data.CondutorCNHRegistro = cnhRegistry;
        data.CondutorCNHData1Hab = cnhFirstDate;
        data.CondutorCNHDataEmissao = cnhEmitterDate;
        data.CondutorCNHDataValidade = cnhExpirationDate;
        data.CondutorTelefone = '' + ddd + '' + phone;
        data.Cidade = cidade;

        let currentDate = new Date();
        var param = {
          //"entityVersion": moment(currentDate).format("YYYY-MM-DDTHH:mm:ss"),
          id: +condutorId,
          clienteId: +customerId,
          nome: fullName,
          indicadorEstrangeiro: estrangeiro,
          endereco: endereco,
          enderecoNumero: numero,
          enderecoComplemento: complemento,
          cidadeId: cidade != '0' ? +cidade : null,
          rg: rg,
          cpf: cpfCNPF,
          numeroProntuario: +prontuario,
          numeroCNH: cnhRegistry,
          validadeCNH: cnhExpirationDate, //"2022-08-15T22:27:48.270Z",
          categoriaCNH: categoriaCNH,
          email: email,
          observacao: '',
          ddiResidencial: ddd,
          dddResidencial: ddd,
          telefone: phone,
          ddiFax: ddiFax,
          dddFax: dddFax,
          fax: phoneFax,
          ddiCelular: ddiCelular,
          dddCelular: dddCelular,
          celular: phoneCelular,
        };

        console.log(param);
        //return;

        // console.log(cpf.isValid(cpfCNPF))
        if (customerId == '0') {
          addToast({
            title: 'Erro',
            type: 'error',
            description: 'Informe a empresa',
          });
          return;
        }

        // if (!cpf.isValid(cpfCNPF)) {
        //   addToast({
        //     title: "Erro",
        //     type: "error",
        //     description: "Cpf inválido!",
        //   })
        //   return
        // }

        // if (fullName == "") {
        //   addToast({
        //     title: "Erro",
        //     type: "error",
        //     description: "Informe o nome!",
        //   })
        //   return
        // }

        // if (birthdate == "") {
        //   addToast({
        //     title: "Erro",
        //     type: "error",
        //     description: "Informe a data de nascimento!",
        //   })
        //   return
        // }

        // if (cnhRegistry == "") {
        //   addToast({
        //     title: "Erro",
        //     type: "error",
        //     description: "Informe a CNH - Registro!",
        //   })
        //   return
        // }

        // if (cnhEmitterDate == "") {
        //   addToast({
        //     title: "Erro",
        //     type: "error",
        //     description: "Informe a CNH - Data de emissão!",
        //   })
        //   return
        // }

        // if (cnhExpirationDate == "") {
        //   addToast({
        //     title: "Erro",
        //     type: "error",
        //     description: "Informe a CNH - Data de validade!",
        //   })
        //   return
        // }

        // if (email == "") {
        //   addToast({
        //     title: "Erro",
        //     type: "error",
        //     description: "Informe o e-mail!",
        //   })
        //   return
        // }

        // if (ddd == "") {
        //   addToast({
        //     title: "Erro",
        //     type: "error",
        //     description: "Informe o DDD!",
        //   })
        //   return
        // }

        // if (phone == "") {
        //   addToast({
        //     title: "Erro",
        //     type: "error",
        //     description: "Informe o telefone!",
        //   })
        //   return
        // }
        // console.log("cidade: " + cidade);
        // if (cidade == "0" || cidade == "") {
        //   addToast({
        //     title: "erro",
        //     type: "error",
        //     description: "informe a cidade!",
        //   })
        //   return;
        // }

        if (categoriaCNH === '') {
          addToast({
            title: 'erro',
            type: 'error',
            description: 'informe a categoria CNH!',
          });
          return;
        }

        const schema = Yup.object().shape({
          fullName: Yup.string().required('Informe o Nome Completo!'),
          //birthdate: Yup.string().required("Informe a Data de Nascimento!"),
          cnhRegistry: Yup.string().required('Informe a CNH - Registro!'),
          //cnhEmitterDate: Yup.string().required("Informe a CNH - Data de emissão!"),
          cnhExpirationDate: Yup.string().required(
            'Informe a CNH - Data de validade!'
          ),
          email: Yup.string().required('Informe o E-mail!'),
          ddd: Yup.string().required('Informe o DDD!'),
          phone: Yup.string().required('Informe o Telefone!'),
          ddiCelular: Yup.string().required('Informe o DDI do celular!'),
          dddCelular: Yup.string().required('Informe o DDD do celular!'),
          phoneCelular: Yup.string().required('Informe o Celular!'),
          cpf: Yup.string().required('Informe o CPF / Passaporte!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        //console.log(data);
        // return;

        setLoading(true);
        var url = 'http://localhost:5002/api';
        await api
          //.post("/reports/traffic-ticket-conductor-insertAsync", data)
          .post('/crm/reports/create-update-driver_lm', param)
          .then((response) => {
            console.log(response.data);
            setLoading(false);
            if (!response.data.success) {
              var msg = 'Erro ao tentar cadastrar o condutor.';
              if (response.data.items != null) {
                if (response.data.items.Cpf != null) {
                  msg = '[CPF] - ' + response.data.items.Cpf[0];
                } else if (response.data.items.NumeroCNH != null) {
                  msg = '[CNH REGISTRO] - ' + response.data.items.NumeroCNH[0];
                } else if (response.data.items.CategoriaCNH != null) {
                  msg =
                    '[CATEGORIA CNH] - ' + response.data.items.CategoriaCNH[0];
                }
              }
              addToast({
                title: 'Erro',
                type: 'error',
                description: msg,
              });
            } else {
              var aLoc = location.pathname.split('/');
              addToast({
                title:
                  aLoc[1] == 'register-driver'
                    ? 'Cadastro realizado'
                    : 'Edição realizada',
                type: 'success',
                description:
                  aLoc[1] == 'register-driver'
                    ? 'Seu cadastro foi realizado com sucesso.'
                    : 'Seu cadastro foi atualizado com sucesso.',
              });

              if (add == undefined) history.push(`/drivers`);
              else history.push(`/new-request/agendamento`);
            }
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
            addToast({
              title: 'Erro',
              type: 'error',
              description:
                window.location.pathname == '/register-driver'
                  ? 'Erro ao tentar cadastrar o condutor.'
                  : 'Erro ao tentar editar o condutor.',
            });
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
        addToast({
          type: 'error',
          title: 'Erro ao cadastrar',
          description: 'Verifique todos os campos obrigatórios',
        });
      }
    },
    [
      addToast,
      customerId,
      cpfCNPF,
      fullName,
      gender,
      birthdate,
      rg,
      rgEmitter,
      rgEmitterDate,
      civilStatus,
      cnhRegistry,
      cnhFirstDate,
      cnhEmitterDate,
      cnhExpirationDate,
      email,
      ddd,
      phone,
      phoneCelular,
      cidade,
      ddiCelular,
      dddCelular,
      ddiFax,
      dddFax,
      phoneFax,
      estrangeiro,
      condutorId,
      categoriaCNH,
    ]
  );

  const convertoToNumberOnly = (value: any) => {
    return value.replace(/([^\d])+/gim, '');
  };

  const handleCancelConductor = (e: any) => {
    e.preventDefault();
    if (add == undefined) history.push(`/drivers`);
    else history.push(`/new-request/agendamento`);
  };

  const getCountry = useCallback(async () => {
    await api
      .get('/crm/reports/getCountry_lm')
      .then((response) => {
        console.log(response.data);
        setPaisOptions(response.data);
      })
      .catch((error) => {
        console.error(error);
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao tentar buscar a lista de País!',
        });
      });
  }, []);

  const getUFsCalls = useCallback(
    async (paisId: number) => {
      //var url = "http://localhost:5002/api";
      console.log(pais);
      await //api.get(`/reports/getUFsCalls`)
      api
        .get(`/crm/reports/getUFsCalls_lm/${paisId}`)
        .then((response) => {
          //console.log(response.data);
          setUFOptions(response.data);
        })
        .catch((error) => {
          console.log(error);
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Erro ao tentar buscar os estados!',
          });
        });
    },
    [pais]
  );

  const getCityByUF = useCallback(
    async (uf: any) => {
      setEstado(uf);
      await //api.get(`/reports/getcitycallsbyuf/${uf}`)
      api
        .get(`/crm/reports/getCityCallsByUF_lm/${uf}`)
        .then((response) => {
          // console.log(response.data);
          setCidadeOptions(response.data);
        })
        .catch((error) => {
          console.log(error);
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Erro ao tentar buscar as cidades!',
          });
        });
    },
    [estado]
  );

  return (
    <>
      <Header />
      <Container>
        {!disabled ? (
          <>
            <Title
              title={'CADASTRAR CONDUTOR'}
              subtitle={'Cadastre aqui um novo condutor'}
            />
          </>
        ) : (
          <>
            <Title title={'EDITAR CONDUTOR'} subtitle={'Edite um condutor'} />
          </>
        )}

        <Form ref={formRef} onSubmit={handleSubmitRegisterDriver}>
          <Line>
            <SelectContainer>
              <label>Selecione a empresa para relacionar ao condutor *</label>
              <Select
                id="customerId"
                name="customerId"
                onChange={(e) => handleSelectCustomer(e.target.value)}
                value={customerId}
              >
                <option value="0">Empresas</option>
                {customersOptions &&
                  customersOptions.map((customer: any, i: number) => (
                    <option key={i} value={customer.customerCMSId}>
                      {customer.customerName}
                    </option>
                  ))}
              </Select>
            </SelectContainer>
          </Line>
          {disabled && (
            <Line>
              <SelectContainer>
                <label>Selecione o condutor a ser editado *</label>
                <Select
                  id="conductor"
                  name="conductor"
                  onChange={(e) => handleSelectConductor(e.target.value)}
                >
                  <option value="0">Condutor</option>
                  {conductorOptions &&
                    conductorOptions.map((conductor: any) => (
                      <option
                        key={conductor.condutorId}
                        value={conductor.condutorId}
                      >
                        {conductor.condutorNome}
                      </option>
                    ))}
                </Select>
              </SelectContainer>

              <div className="loadingCondutor">
                <LocationSpin loading={loadingDriver} />
              </div>
            </Line>
          )}
          <Line>
            <Input
              placeholder="Nome Completo"
              id="fullName"
              name="fullName"
              type="text"
              label="Nome Completo *"
              value={fullName}
              maxLength={150}
              onChange={handleKeyUpFullName}
            />

            {/* <Input
              placeholder="Estrangeiro"
              id="fullName"
              name="fullName"
              type="text"
              label="Estrangeiro"
              value={estrangeiro}
              maxLength={150}
              onChange={handleKeyUpEstrangeiro}
            /> */}

            <CheckBoxContainer>
              <label>Estrangeiro</label>
              <Switch onChange={handleKeyUpEstrangeiro} checked={estrangeiro} />
            </CheckBoxContainer>
          </Line>
          <Line>
            <Input
              placeholder="Endereço"
              id="endereco"
              name="endereco"
              label="Endereço"
              type="text"
              value={endereco}
              onChange={handleKeyUpEndereco}
            />

            <Input
              placeholder="Número"
              id="numero"
              name="numero"
              type="text"
              label="Número"
              value={numero}
              maxLength={15}
              onChange={handleKeyUpNumero}
            />
          </Line>
          <Line>
            <Input
              placeholder="Complemento"
              id="complemento"
              name="complemento"
              type="text"
              label="Complemento"
              value={complemento}
              onChange={handleKeyUpComplemento}
            />
          </Line>
          <Line>
            <SelectContainer>
              <label>País</label>
              <Select
                id="pais"
                name="pais"
                onChange={(e) => handleSelectPais(e.target.value)}
                value={pais}
              >
                <option value="">País</option>
                {paisOptions &&
                  paisOptions.map((pais: any) => (
                    <option key={pais.id} value={pais.id}>
                      {pais.nome}
                    </option>
                  ))}
              </Select>
            </SelectContainer>

            <SelectContainer>
              <label>Estado</label>
              <Select
                id="estado"
                name="estado"
                onChange={(e) => getCityByUF(e.target.value)}
                value={estado}
              >
                <option value="">Estado</option>
                {ufOptions != undefined
                  ? ufOptions.map((uf: any, i: number) => (
                      <option key={i} value={uf.codigo}>
                        {uf.nome}
                      </option>
                    ))
                  : ''}
              </Select>
            </SelectContainer>
          </Line>
          <Line>
            <SelectContainer>
              <label>Cidade</label>
              <Select
                id="cidade"
                name="cidade"
                onChange={(e) => handleSelectCidade(e.target.value)}
                value={cidade}
              >
                <option value="">Cidade</option>
                {cidadeOptions != undefined &&
                  cidadeOptions.map((city: any) => (
                    <option key={city.cidadeId} value={city.cidadeId}>
                      {city.cidade}
                    </option>
                  ))}
              </Select>
            </SelectContainer>
          </Line>
          <Line>
            <Input
              placeholder="RG"
              id="rg"
              name="r"
              label="RG"
              type="text"
              onChange={handleKeyUpRg}
              value={rg}
            />

            <Input
              placeholder="CPF / Passaporte"
              id="cpf"
              name="cpf"
              type="cpf"
              label="CPF / Passaporte"
              value={cpfCNPF}
              maxLength={14}
              onChange={handleKeyUpCPF}
            />
          </Line>

          <Line>
            <Input
              placeholder="Prontuário"
              id="prontuario"
              name="prontuario"
              type="prontuario"
              label="Prontuário"
              value={prontuario}
              maxLength={40}
              onChange={handleKeyUpProntuario}
            />
          </Line>

          <Line>
            <Input
              placeholder="CNH - Registro"
              id="cnhRegistry"
              name="cnhRegistry"
              type="text"
              label="CNH - Registro *"
              onChange={handleKeyUpCNHRegistry}
              value={cnhRegistry}
              maxLength={11}
            />

            <SelectContainer>
              <label>Categoria CNH *</label>
              <Select
                id="categoriaCNH"
                name="categoriaCNH"
                onChange={(e) => handleSelectCategoriaCNH(e.target.value)}
                value={categoriaCNH}
              >
                <option value="">Categoria CNH</option>
                {categoriaCNHOptions &&
                  categoriaCNHOptions.map((cat: any) => (
                    <option key={cat.categoria} value={cat.categoria}>
                      {cat.categoria}
                    </option>
                  ))}
              </Select>
            </SelectContainer>
          </Line>

          <Line>
            <Input
              placeholder="CNH - Data de validade"
              id="cnhExpirationDate"
              name="cnhExpirationDate"
              type="date"
              label="CNH - Data de validade *"
              className="date"
              onChange={handleKeyUpCNHExpirationDate}
              value={cnhExpirationDate}
            />

            {/* <Input
              placeholder="CNH - Data de emissão"
              id="cnhEmitterDate"
              name="cnhEmitterDate"
              type="date"
              label="CNH - Data de emissão *"
              max={moment().format("YYYY-MM-DD")}
              className="date"
              onChange={handleKeyUpCNHEmitterDate}
              value={cnhEmitterDate}
            /> */}
          </Line>
          <Line>
            <Input
              placeholder="E-mail"
              id="email"
              name="email"
              type="email"
              label="E-mail *"
              onChange={handleKeyUpEmail}
              value={email}
              maxLength={150}
            />
          </Line>

          <Line>
            <section className="phone-line">
              <Input
                placeholder="DDD"
                id="ddd"
                name="ddd"
                type="text"
                label="DDD *"
                className="ddd"
                onChange={handleKeyUpDDD}
                value={ddd}
                maxLength={2}
              />

              <Input
                placeholder="Telefone"
                id="phone"
                name="phone"
                type="text"
                label="Telefone *"
                className="phone"
                onChange={handleKeyUpPhone}
                value={phone}
                maxLength={9}
              />
            </section>
            <section>
              <Input
                placeholder="DDI"
                id="ddi"
                name="ddi"
                type="text"
                label="DDI"
                className="ddd"
                onChange={handleKeyUpDDIFax}
                value={ddiFax}
                maxLength={3}
              />
              <Input
                placeholder="DDDFax"
                id="dddFax"
                name="dddFax"
                type="text"
                label="DDD"
                className="ddd"
                onChange={handleKeyUpDDDFax}
                value={dddFax}
                maxLength={2}
              />
              <Input
                placeholder="Telefone Fax"
                id="phoneFax"
                name="phoneFax"
                type="text"
                label="Telefone Fax"
                className="phone"
                onChange={handleKeyUpPhoneFax}
                value={phoneFax}
                maxLength={9}
              />
            </section>
          </Line>

          <Line>
            <section className="phone-line">
              <Input
                placeholder="DDI"
                id="ddiCelular"
                name="ddiCelular"
                type="text"
                label="DDI *"
                className="ddd"
                onChange={handleKeyUpDDICelular}
                value={ddiCelular}
                maxLength={3}
              />

              <Input
                placeholder="DDD"
                id="dddCelular"
                name="dddCelular"
                type="text"
                label="DDD *"
                className="ddd"
                onChange={handleKeyUpDDDCelular}
                value={dddCelular}
                maxLength={2}
              />

              <Input
                placeholder="Celular"
                id="phoneCelular"
                name="phoneCelular"
                type="text"
                label="Celular *"
                className="phone"
                onChange={handleKeyUpPhoneCelular}
                value={phoneCelular}
                maxLength={9}
              />
            </section>
          </Line>

          <WrapperButton>
            <Button onClick={(e) => handleCancelConductor(e)}>Cancelar</Button>
            <div className="loading">
              <LocationSpin loading={loading} />
            </div>
            <Button>Salvar</Button>
          </WrapperButton>
        </Form>
      </Container>
    </>
  );
};

export default RegisterDriver;
