import React, { SelectHTMLAttributes } from "react"

import { Container, Error } from "./styles"
import { useField } from "@unform/core"
import { FiAlertCircle } from "react-icons/fi"

type SelectProps = SelectHTMLAttributes<HTMLSelectElement>

const Select: React.FC<SelectProps> = ({ children, ...rest }) => {

  let name: any = rest.name
  const { error } = useField(name)

  return (
    <Container>
      <select {...rest}>{children}</select>
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#C53030" size={20} />
        </Error>
      )}
    </Container>
  )
}

export default Select
