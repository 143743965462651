import React from 'react';
import styled from 'styled-components';

import { VehicleCardI } from './types';
import CarIcon from '../../../../assets/icons/carIcon.svg';

const Container = styled.table`
  width: 100%;
`;
const TableValue = styled.td`
  color: rgb(60, 80, 90);
  font-size: 12px;
  padding-top: 5px;
  vertical-align: top;
  width: 25%;
`;

function VehicleDetails({ vehicle }: VehicleCardI) {
  return (
    <Container>
      <tbody>
        <tr>
          <TableValue>Placa do veículo</TableValue>
          <TableValue>Tipo</TableValue>
          <TableValue>Marca</TableValue>
          <TableValue>Modelo</TableValue>
        </tr>
        <tr>
          <TableValue>{vehicle.veiculoPlaca}</TableValue>
          <TableValue>
            <img src={CarIcon} />
          </TableValue>
          <TableValue>{vehicle.fabricante}</TableValue>
          <TableValue>{vehicle.modeloNome}</TableValue>
        </tr>
      </tbody>
    </Container>
  );
}

export default VehicleDetails;
