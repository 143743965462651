import { createStore } from 'redux';
import rootReducer from './modules/rootReducer';
import { IFilterOffer } from './modules/offers/types';
import { ILogin } from './modules/login/types';
import { ILocation } from './modules/location/types';

export interface IState {
    offers: IFilterOffer;
    login: ILogin;
    location: ILocation;
    tabs: ILocation;
}

const store = createStore(rootReducer);

export default store;