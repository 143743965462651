import React from 'react';
import styled from 'styled-components';
import PaymentIcon, { ICONS } from './PaymentIcon';

const StyledContainer = styled.div`
  display: flex;
  gap: 20px;
  height: 54px;
`;
const StyledIcon = styled.div`
  img {
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 42px;
  }
`;
const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledMethod = styled.div`
  font-weight: 600;
  font-size: 18px;
`;
const StyledDescription = styled.div``;

type Props = {
  method: string;
  type: keyof typeof ICONS;
  description?: string;
};

function PaymentMethod({ method, type, description }: Props) {
  return (
    <StyledContainer>
      <StyledIcon>
        <PaymentIcon type={type} />
      </StyledIcon>
      <StyledInfo>
        <StyledMethod>{method}</StyledMethod>
        {description && <StyledDescription>{description}</StyledDescription>}
      </StyledInfo>
    </StyledContainer>
  );
}

export default PaymentMethod;
