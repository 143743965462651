import styled from 'styled-components';

export const Container = styled.div`
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;

  color: #3c505a;
`;

export const WrapperTable = styled.div`
  width: 80%;
  margin: 40px 0px 200px 0px;

  label {
    font: normal normal bold 14px/19px 'Open Sans';
  }

  .name-profile {
    border-radius: 16px;
    border: 1px solid #0c5faf;
    width: 300px;
    height: 31px;
    margin-right: 35px;
    padding: 15px;

    @media (max-width: 375px) {
      width: 100%;
    }
  }

  .wrapper-button {
    margin-top: 218px;

    .btn-save {
      float: right;
      margin-bottom: 11px;
      border: 2px solid #226fbb;
      color: #fff;
      background: #236fbc;
      border-radius: 24px;
      width: 155px;
      height: 39px;
      font-size: 14px;
      font-weight: 500;
    }

    .btn-cancel {
      float: right;
      margin: 0 20px 11px 0;
      border: 2px solid #3c505a;
      color: #3c505a;
      border-radius: 24px;
      width: 155px;
      height: 39px;
      font-size: 14px;
      font-weight: 500;
    }

    @media (max-width: 408px) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row-reverse;
      gap: 15px;
      margin-top: 20px;

      & > p {
        width: 100%;
      }
      .btn-save,
      .btn-cancel {
        width: auto;
        flex: 1;
      }
      .btn-cancel {
        margin-right: 0;
      }
    }
  }
`;

export const Select = styled.select`
  width: 300px;
  height: 31px;
  margin-left: 10px;
  font-size: 14px;
  color: #8994a0;
  border: 1px solid #cfd7d9;
  background: #fff;
  border-radius: 17px;

  @media (max-width: 665px) {
    margin-bottom: 5px;
  }
`;

export const Table = styled.table`
  width: 100%;
  text-align: left;
  border-spacing: inherit;
  margin: 15px 0px 15px 0px;
  border: 1pt solid #cfd7d9;

  tbody tr:nth-child(odd) {
    background: #f9f9f9;
  }

  tbody td:nth-child(2) {
    text-align: center;
  }

  tbody td:nth-child(3) {
    text-align: center;
  }

  button {
    border: none;
    background: transparent;
    margin: 5px;
    color: #007feb;
    font-size: 19px;

    img {
      margin-left: 3px;
      width: 16px;
      height: auto;
    }
  }

  thead {
    border-radius: 18px;

    th:nth-child(2) {
      text-align: center;
    }

    th:nth-child(3) {
      text-align: center;
    }
  }

  tr {
    font-size: 15px;
  }

  td {
    padding: 8px;
  }

  th {
    font-weight: normal;
    background: #3c505a;
    color: #fff;
    height: 36px;
    padding: 8px;
  }

  .large {
    width: 70%;
  }

  .wrapper-status {
    display: flex;
    align-items: center;
  }

  .status {
    background-color: #51ae30;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
  }
`;

export const ErrorMessage = styled.p`
  color: red !important;
  text-align: right;
  font-size: 12px;
  opacity: 0;
  transition: 0.3s;

  &.active {
    opacity: 1;
  }
`;
