import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.button`
  background: none;
  border: none;
  line-height: 1;
  padding: 9px;

  &:disabled {
    opacity: 0.3;
    cursor: default;
  }
`;

function ButtonIconUI({
  type = 'button',
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <StyledContainer type={type} {...props}>
      {props.children}
    </StyledContainer>
  );
}
export default ButtonIconUI;
