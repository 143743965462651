import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import iconSafe from '../../assets/icons/safe.svg';
import iconSupport from '../../assets/icons/support.svg';
import iconTruck from '../../assets/icons/truck.svg';
import iconAssistance from '../../assets/icons/assistance.svg';

import {
  Container,
  Content,
  WrapperButton,
  Benefits,
  CardBenefits,
} from './styles';

const HomePdc: React.FC = () => {
  return (
    <Container>
      <Content>
        <Header isPositionAbsolute={false} isPositionFixed={true} />
        <WrapperButton>
          {/* <div className="wrapper-h1">
            <p>Todo controle de sua</p>
            <p>frota de veículos</p>
            <p>em um único portal.</p>
          </div> */}
        </WrapperButton>
        <div className="img-banner"></div>
        <h1 className="title-benefits">Benefícios que garantimos para você</h1>

        <Benefits>
          <CardBenefits>
            <div className="header-card">
              <img src={iconSafe} alt="Safe Icon" style={{ width: '120px' }} />
              <span>Seguro</span>
            </div>
            <p>
              Oferecemos pacotes de seguro completos e competitivos, adaptados
              aos padrões de mercado e aos individuais.
            </p>
          </CardBenefits>
          <CardBenefits>
            <div className="header-card">
              <img
                src={iconSupport}
                alt="Support Icon"
                style={{ width: '140px', paddingLeft: '25px' }}
              />
              <span>Assistência 24 horas</span>
            </div>
            <p>
              Oferecemos pacotes de seguro completos e competitivos, adaptados
              aos padrões de mercado e aos individuais.
            </p>
          </CardBenefits>
          <CardBenefits>
            <div className="header-card">
              <img
                src={iconAssistance}
                alt="Assistance Icon"
                style={{ width: '160px', paddingLeft: '45px' }}
              />
              <span>Serviços de manutenção</span>
            </div>
            <p>
              Realizados por especialistas da rede de concessionárias
              Volkswagen, garantindo a agilidade, a qualidade dos serviços e a
              segurança do condutor.
            </p>
          </CardBenefits>
          <CardBenefits>
            <div className="header-card">
              <img
                src={iconTruck}
                alt="Truck Icon"
                style={{ width: '120px' }}
              />
              <span>Logística</span>
            </div>
            <p>
              Todo processo de compra, transporte, entrega e desmobilização dos
              veículos.
            </p>
          </CardBenefits>
        </Benefits>
      </Content>

      <Footer />
    </Container>
  );
};

export default HomePdc;
