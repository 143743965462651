import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import GlobalStyle from './styles/global';

import Routes from './routes';
import store from './store';
import moment from 'moment';
import AppProvider from 'hooks';
import useGtag from 'hooks/useGtag';

function App() {
  useGtag();
  // altera a mensagem 'Data inválida' para '' em toda a aplicação
  moment.updateLocale('en', {
    invalidDate: '',
  });

  return (
    <Provider store={store}>
      <AppProvider>
        <Router>
          <Routes />
          <GlobalStyle />
        </Router>
      </AppProvider>
    </Provider>
  );
}

export default App;
