import React from "react"

import { Container } from "./styles"

const AntifraudInformative = () => {
  return (
    <Container>
      <h1>Previna-se contra Fraude de Pagamento</h1>
      <p>
        Os boletos emitidos pela LM Frotas são registrados com nossa razão
        social como beneficiário, sempre verifique estas informações antes de
        finalizar seu pagamento:
      </p>
      <p>LM Transportes Interestaduais Serviços e Comercio S.A - 00.389.481/0029-70</p>
      <p>
        Nossos e-mails são identificados com domínio <b>“@lmfrotas</b>
        .com.br”, qualquer outra extensão de e-mail deverá ser desconsiderada.
        Caso considere algum e-mail suspeito a Lm Frotas deverá ser
        comunicada imediatamente para verificação.
      </p>

      <p>
        Sempre verifique os dados do sacado (contratante) e valide se estão
        corretos.
      </p>
      <p>
        Fizemos a Comunicação detalhada sobre o tema ao Gestor de Frota e Contas
        a Receber cadastrado. Para maiores informações basta contatar o nosso
        0800 775 5566 ou acionar o e-mail comercial@lmfrotas.com.br
      </p>
    </Container>
  )
}

export default AntifraudInformative
