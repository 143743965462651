import React from 'react';
import CheckboxUI from 'components/ui/CheckboxUI';
import FieldsetUI from 'components/ui/Fieldset';

type SituationsT = {
  deleted: boolean;
  inactive: boolean;
  confirmedEmail: boolean;
  validProfile: boolean;
};

type CheckboxT = {
  label: string;
  name: keyof SituationsT;
};

type Props = {
  situationsChecked: SituationsT;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

function SituationFieldset(props: Props) {
  const renderCheckbox = (checkbox: CheckboxT) => (
    <CheckboxUI
      checked={props.situationsChecked[checkbox.name]}
      label={checkbox.label}
      name={checkbox.name}
      key={checkbox.name}
      onChange={props.onChange}
    />
  );

  return (
    <FieldsetUI legend="Situação">{checkboxes.map(renderCheckbox)}</FieldsetUI>
  );
}

const checkboxes: CheckboxT[] = [
  { label: 'Bloqueado', name: 'deleted' },
  { label: 'Inativo', name: 'inactive' },
  { label: 'E-mail Confirmado', name: 'confirmedEmail' },
  { label: 'Perfil Válido', name: 'validProfile' },
];

export default SituationFieldset;
