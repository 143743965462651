import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

import EyeIcon from 'pages/LandingPages/assets/icons/eye-icon.svg';
import Input from 'pages/LandingPages/components/ui/Input';

// estilos
const StyledContainer = styled.div`
  position: relative;
  width: 100%;

  input {
    padding-right: 42px;
    width: 100%;
  }
`;
const StyledVisibityButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  right: 17px;
  top: 0;
  bottom: 0;
  margin: auto;
`;

type Props = {
  name: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

function PasswordInput(props: Props) {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  return (
    <StyledContainer>
      <Input type={showPassword ? 'text' : 'password'} {...props} />
      <StyledVisibityButton onClick={handleShowPasswordClick} type="button">
        <img src={EyeIcon} />
      </StyledVisibityButton>
    </StyledContainer>
  );
}

export default PasswordInput;
