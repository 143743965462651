import styled from "styled-components";
import bannerFive from "../../assets/banner/banner-5.png"
import bannerLM from "../../assets/banner/LM/banner-LM.png"

export const Container = styled.div`
  .drawer {
    div {
      position: relative;
    }

    .MuiDrawer-paperAnchorDockedLeft {
      border-right: 0;
    }

    @media (max-width: 800px) {
      position: absolute;
    }
  }

  @media (max-width: 340px) {
    .drawer {
      width: 100% !important;

      div {
        padding: 0;
      }
    }
  }
`;

export const Content = styled.div`
  .img-banner {
    /*background-image:url(${bannerFive});*/
    background-image:url(${bannerLM});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
  }

  .title-benefits {
    margin: 50px 0;
    color: #404e56;
    font-size: 26px;
    text-align: center;
  }

  @media (max-width: 475px) {
    .img-banner {
      img {
        height: auto;
        width: 100%;
      }
    }
  }

  @media (min-width: 476px) {
    .img-banner {
      img {
        height: auto;
        width: 100%;
      }
    }
  }

  @media (min-width: 1350px) {
    .img-banner {
      img {
        height: 100vh;
      }
    }
  }
`;

export const WrapperButton = styled.div`
  position: absolute;
  top: 300px;
  left: 150px;
  z-index: 1;

  h1 {
    text-align: left;
    font-size: 38px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    font-weight: bold;
    padding: 15px;
  }

  p {
    text-align: left;
    font-size: 20px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    font-weight: 500;
  }

  button {
    background: #0c5fae 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 1;
    width: 150px;
    height: 40px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    font-weight: 500;
    border: 0ch;
  }

  button:hover {
    background: #0c5fae 0% 0% no-repeat padding-box;
    color: #ffffff;
  }

  .wrapper-h1 {
    p{
      font-size: 33px;
      color: white;
      margin: 0;
      background: #17536c;
      width: max-content;
      padding: 0 10px;
    }
  }

  @media (max-width: 900px) {
    left: 20px;
    top: 200px;

    .wrapper-h1 {
      background: transparent;
    }
  }

  @media (max-width: 590px) {
    top: 90px;
    width: auto;
    height: auto;

    h1 {
      font-size: 20pt;
      padding: 0;
    }
  }
`;

export const Benefits = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;

  @media (max-width: 500px) {
    justify-content: center;
  }
`;

export const CardBenefits = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  text-align: center;
  width: 210px;
  height: 300px;
  margin-bottom: 40px;

  span {
    color: #0c5fb1;
    font-size: 18px;
  }

  p {
    margin-top: 15px;
  }

  .header-card {
    display: flex;
    flex-direction: column;
  }
`;

export const WrapperRange = styled.div`
  width: 260px;
  margin: 20px 10px 20px 20px;
  padding: 15px;

  span {
    text-align: left;
    font: normal normal bold 11px/12px Arial;
    letter-spacing: 0px;
    color: #0c5faf;
    opacity: 1;
  }

  .value-investiment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 11px;
  }

  .investment {
    text-align: left;
    font: normal normal normal 11px/12px Arial;
    letter-spacing: 0px;
    color: #3b3b3b;
    opacity: 1;
  }
`;

