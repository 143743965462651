import React from 'react';

import { StyledInput } from './styles';
import InputContainerUI from './InputContainerUI';
import { getTheme } from 'pages/LandingPages/utils/helpers';

interface InputUIProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  errorText?: string;
}

function InputUI({ errorText, ...props }: InputUIProps) {
  const theme = getTheme();
  return (
    <InputContainerUI errorText={errorText}>
      <StyledInput $radius={theme.radius} {...props} />
    </InputContainerUI>
  );
}

export default InputUI;
