
import styled from "styled-components"

export const Container = styled.div`
  h1{
    text-align: center;
    color: #17536c;
  }

  strong {
    color: #000;
    font-weight: bold;
  }

  div{
    padding-left: 15px;

    .canais {
      color: #4682B4;
      font-weight: bold;
      font-family: arial;
    }
  }
`
