import styled from 'styled-components';

export const Container = styled.div``;

export const StyledContent = styled.div<{ color: string }>`
  font-family: 'Audi Type Screen';
  color: ${(props) => props.color};
  padding: 60px 18px 40px;
  display: flex;
  flex-direction: column;
  gap: 26px;
  align-items: center;
  @media (max-width: 425px) {
    padding-top: 28px;
  }
`;

export const StyledBox = styled.div<{ $radius: number }>`
  display: flex;
  padding: 48px;
  flex-direction: column;
  gap: 24px;
  border-radius: ${(props) => props.$radius * 2}px;
  background: #fff;
  width: 90%;
  @media (max-width: 425px) {
    width: 100%;
    padding: 32px 24px;
  }
`;

export const StyledGreeting = styled.div`
  font-size: 22px;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  @media (max-width: 425px) {
    font-size: 18px;
  }
`;

export const StyledUserName = styled.span<{ color: string }>`
  color: ${(props) => props.color};
`;

export const StyledWelcome = styled.div`
  text-align: center;
  font-family: 'VW Head';
  font-size: 38px;
  font-weight: 700;
  line-height: 110%;
  @media (max-width: 425px) {
    font-size: 24px;
  }
`;

export const StyledInfo = styled.div`
  text-align: center;
  font-size: 14px;
  line-height: 120%;
  @media (max-width: 425px) {
    font-size: 12px;
  }
`;

export const StyledPrograms = styled.div`
  display: flex;
  aligm-items: center;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const StyledDivider = styled.div`
  height: 1px;
  background-color: #e4e4e4;
`;

export const StyledServiceTitle = styled.div`
  font-size: 22px;
  font-weight: 700;
  line-height: 120%;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
  @media (max-width: 425px) {
    font-size: 18px;
  }
`;

export const StyledIcon = styled.div<{ color: string; $radius: number }>`
  display: flex;
  padding: 8px;
  border-radius: ${(props) => props.$radius * 4}px;
  background: ${(props) => props.color};
`;

export const StyledServiceInfo = styled.div`
  font-size: 14px;
  font-style: normal;
  line-height: 120%;
  @media (max-width: 425px) {
    font-size: 12px;
  }
`;

export const StyledSupport = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledSupportBoxContainer = styled.div`
  display: flex;
`;

export const StyledSupportBox = styled.a<{ color: string; $radius: number }>`
  display: flex;
  min-height: 56px;
  padding: 20px 32px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: ${(props) => props.$radius}px;
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  font-family: 'Audi Type Screen';
  font-size: 14px;
  line-height: 120%;
  &:hover {
    color: inherit;
  }
  & > svg {
    width: 24px;
  }
  @media (max-width: 425px) {
    font-size: 12px;
  }
`;

export const StyledSupportText = styled.div`
  // color: #001e50;
  font-family: 'Audi Type Screen';
  font-size: 13px;
  font-weight: 400;
  line-height: 120%;
`;

export const StyledPhone = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledPhoneNumber = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  color: inherit;
  font-family: 'VW Head';
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  @media (max-width: 425px) {
    font-size: 24px;
  }
`;

export const StyledPhoneHours = styled.div`
  font-size: 13px;
  line-height: 120%;
  @media (max-width: 425px) {
    font-size: 11px;
  }
`;
