import React, { useCallback, useRef, useState, useEffect } from 'react';
import { FiMail } from 'react-icons/fi';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';

import { Container, ContentModal, WrapperActions } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
//import logoImg from "../../assets/logo-fleet-solutions-brasil.svg"
import logoImg from '../../assets/LM/Fundo_transparente_M.png';
import getValidationErrors from '../../utils/getValidationErrors';
import { loginApp } from '../../store/modules/login/actions';
import api from '../../services/api';
import { useToast } from 'hooks/toast';
import { postResetPasswordApi } from 'pages/Users/utils/services';

interface ForgotPasswordFormData {
  Email: string;
  webSiteUrl: string;
  IsPortalCliente: boolean;
}

type Props = {
  isForgotPassword: boolean;
  handleClosedModalForgotPassword: () => void;
};

const ForgotPassword = ({
  isForgotPassword,
  handleClosedModalForgotPassword,
}: Props) => {
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const { addToast } = useToast();

  const [email, setEmail] = useState('');
  const [infoAccount, setInfoAccount] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(isForgotPassword);
  }, [isForgotPassword]);

  const handleResendEmail = async () => {
    try {
      await postResetPasswordApi(email);
      addToast({
        isSuperimposed: true,
        title: 'Reenvio de e-mail',
        type: 'success',
        description: 'Enviamos um novo e-mail para redefinição de senha',
      });
    } catch (err) {
      addToast({
        isSuperimposed: true,
        title: 'Reenvio de e-mail',
        type: 'error',
        description:
          'Não foi possível reenviar o e-mail, tente novamente mais tarde.',
      });
    }
  };

  const handleSubmit = async (data: ForgotPasswordFormData) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        Email: Yup.string()
          .required('E-mail obrigatório')
          .email('Digite um e-mail válido'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      data.webSiteUrl = window.location.origin;
      data.IsPortalCliente = true;

      await postResetPasswordApi(data.Email)
        .then((response) => {
          setInfoAccount(true);
        })
        .catch((err) => {
          setInfoAccount(false);
          addToast({
            isSuperimposed: true,
            title: 'Envio de e-mail',
            type: 'error',
            description:
              'Não foi possível enviar o e-mail, tente novamente mais tarde.',
          });
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        setInfoAccount(false);
        return;
      } else {
        setInfoAccount(true);
      }
    }
  };

  const handleOk = () => {
    handleClosedModalForgotPassword();
    dispatch(loginApp({ status: false }));
  };

  const handleCancel = () => {
    handleClosedModalForgotPassword();
    dispatch(loginApp({ status: false }));
  };

  const handleCancelInfo = useCallback(() => {
    setInfoAccount(false);
    dispatch(loginApp({ status: false }));
  }, []);

  return (
    <Container>
      <Modal
        footer={null}
        title=""
        visible={isForgotPassword ? true : showModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <ContentModal>
          <img width="250" src={logoImg} alt="Fleet Solutions" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input
              icon={FiMail}
              placeholder="E-mail"
              id="Email"
              name="Email"
              type="text"
              onChange={(value) => setEmail(value.target.value)}
            />

            <Button type="submit">Recuperar senha</Button>
          </Form>
        </ContentModal>
      </Modal>
      {infoAccount && (
        <Modal
          footer={null}
          title=""
          visible={infoAccount}
          onCancel={handleCancelInfo}
        >
          <ContentModal>
            <img width="250" src={logoImg} alt="Fleet Solutions" />

            <p>Prezado cliente,</p>
            <p style={{ textAlign: 'center' }}>
              Se o endereço de e-mail informado estiver associado a uma conta
              registrada, você receberá um e-mail com instruções para
              recuperação de senha.
            </p>
            <p>Por favor, verifique a caixa de entrada.</p>

            <WrapperActions>
              <Button className="btn-modal-info" onClick={handleCancelInfo}>
                OK
              </Button>

              <Button className="btn-modal-info" onClick={handleResendEmail}>
                Reenviar E-mail
              </Button>
            </WrapperActions>
          </ContentModal>
        </Modal>
      )}
    </Container>
  );
};

export default ForgotPassword;
