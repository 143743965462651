import styled from "styled-components"
import bannerFive from "../../assets/banner/banner-5.png"

export const Container = styled.div`
  padding: 30px;
  max-width: 1000px;
  margin: 30px auto;
  font-family: arial;

  .title {
    font-weight: bold;
    font-size: 18px;
    color: #000;
  }

  .text-right {
    text-align: right;
  }

  .list {
    margin-left: 40px;
  }

  .text-strong {
    font-weight: bold;
    font-size: 16px;
    margin: 20px 0 0 0;
  }

  .table-list-style {
    tr:nth-child(even) {
      background-color: #b3cde0;
    }
  }

  table {
    margin-bottom: 20px;
    border: 1px solid #000;
    width: 100%;
    tr {
      text-align: center;

      .text-left {
        text-align: left;
      }

      .head {
        width: 150px;
      }

      th {
        padding: 6px;
        background-color: #003660;
        color: #fff;
      }

      .finalidade {
        width: 450px;
      }
      .nomeCookies{
        width: 250px;
      }
    }

    td,
    th {
      border: 1px solid #000;
      padding: 4px;
    }
  }

  h3 {
    color: #003660;
    font-weight: bold;
    letter-spacing: 3px;
  };

  .p_right {
    text-align: right;
    font-size: 12px;
    padding-right: 25px;
  }
  .sublinhado {
    text-decoration: underline;
    font-weight: bold;
  }

  ul {
     padding-left: 15px;
    li {
      color: #003660;
      padding-left: 10px;
      margin-top: 15px;
      .titulo {
        font-weight: bold;
      }
      .texto {
        color: #000;
      }
      .textoSublinhado{
        text-decoration: underline;
        color: #000;
      }
    }
  }

`
