import styled from 'styled-components';

export const Container = styled.div`
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  color: #3c505a;

  .spanButton {
    cursor: pointer;
  }
`;

export const Feature = styled.div`
  width: 80%;
  margin: 40px 0px 200px 0px;

  @media (max-width: 425px) {
    width: 100%;
  }
`;
export const FeatureTitle = styled.h3`
  text-align: center;
  color: #0c5faf;
`;

export const InvoiceDownload = styled.div`
  color: black;
  font-size: 12px;
  padding-right: 15px;
  padding-top: 15px;
  text-align: right;
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 20px 0 0 0;
    font-size: 20px;
    color: #3c505a;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    button {
      width: 78px;
      border-radius: 6px;
      box-shadow: 0px 3px 6px #00000029;
      background: #0c5faf;
      margin: 10px;
      color: white;
    }
  }
`;

export const ModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0px solid #707070;
  padding-bottom: 40px;

  & > h2 {
    font-size: 21px;
    color: #0c5faf;
    font-weight: 500;
    margin-top: 40px;
  }

  & > p {
    font-size: 15px;
    color: #3c505a;
    font-weight: 500;
    margin-top: 13px;
  }

  & > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 90%;

    @media (min-width: 768px) {
      flex-direction: row;
    }
    & > a {
      text-decoration: none;
      height: 165px;
      max-width: 225px;
      width: 47%;

      button {
        background-color: white;
        border: 2px solid #0c5faf;
        border-radius: 11px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        position: relative;
        width: 100%;
        height: 100%;
        transition: 0.3s;

        &:hover {
          background-color: #0c5faf4d;
        }

        h3 {
          font-size: 21px;
          font-weight: 500;
          color: #0c5faf;
          margin: 0;
        }

        & > p {
          color: #0c5faf;
          margin: 0;
          font-size: 16px;
        }

        img {
          position: absolute;
          height: 90px;
        }
      }
    }
  }
`;

export const CloseModal = styled.button`
  border: none;
  color: grey;
  background-color: transparent;
  position: absolute;
  right: 13px;
  top: 10px;
  font-size: 18px;

  &::after {
    content: 'x';
  }
`;

export const ActionButton = styled.button`
  background: none;
  border: none;
  font-size: inherit;
`;

export const TableContainer = styled.div`
  overflow-x: auto;
  margin-bottom: 15px;

  table {
    white-space: normal;

    tr {
      white-space: nowrap;
    }
  }
`;
