import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { getTheme } from 'pages/LandingPages/utils/helpers';
import useMessage from 'pages/LandingPages/utils/hooks/useMessage';
import Button from 'pages/LandingPages/components/ui/Button';
import Label from 'pages/LandingPages/components/ui/Label';
import Input from 'pages/LandingPages/components/ui/Input';
import FormInfo from 'pages/LandingPages/components/domain/FormInfo';
import api from 'services/api';

// estilos
const StyledBackButton = styled.button<{ bgColor: string; $radius: number }>`
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 4px;
  border-radius: ${(props) => props.$radius * 2}px;
  background-color: ${(props) => props.bgColor};
  color: #fff;
  font-family: 'Audi Type Screen';
  font-size: 12px;
  font-weight: 700;
  line-height: 120%;
  border: none;
`;
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 32px;
`;

type Props = {
  handleReturn: () => void;
};

function FirstaccessForm({ handleReturn }: Props) {
  const showMessage = useMessage();

  const [values, setValues] = useState({
    email: '',
    cpfCnpj: '',
  });

  const theme = getTheme();

  const handleForgotPasswordSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const schema = Yup.object().shape({
      email: Yup.string()
        .required('E-mail obrigatório')
        .email('Digite um e-mail válido'),
      cpfCnpj: Yup.string()
        .required('CPF obrigatório')
        .matches(/^\d{11}$/, 'CPF deve ter 11 dígitos'),
    });

    schema
      .validate(values, {
        abortEarly: false,
      })
      .then(() => {
        api
          .post('/crm/user/first-access', values)
          .then((response) => {
            showMessage.success({
              content: response?.data?.messages?.join(', '),
              duration: 7,
            });
            handleReturn();
          })
          .catch((error) => {
            showMessage.error({
              content: error?.response?.data?.messages?.join(', '),
            });
          });
      })
      .catch((error) => {
        if (error instanceof Yup.ValidationError) {
          showMessage.error({
            content: error.errors[0],
          });
        }
      });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <>
      <StyledBackButton
        bgColor={theme.secondButtonBgcolor}
        $radius={theme.radius}
        onClick={handleReturn}
      >
        Voltar
      </StyledBackButton>
      <FormInfo
        title="Primeiro acesso"
        description="Digite seu cpf e e-mail cadastrado para continuarmos com o processo de
          primeiro acesso."
      />
      <StyledForm onSubmit={handleForgotPasswordSubmit}>
        <Label label="E-mail">
          <Input
            type="text"
            name="email"
            placeholder="Digite seu e-mail"
            value={values.email}
            onChange={handleInputChange}
          />
        </Label>
        <Label label="CPF">
          <Input
            type="text"
            name="cpfCnpj"
            placeholder="Digite seu cpf"
            value={values.cpfCnpj}
            onChange={handleInputChange}
          />
        </Label>
        <Button>Primeiro acesso</Button>
      </StyledForm>
    </>
  );
}

export default FirstaccessForm;
