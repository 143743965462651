import React from 'react';
import {
  ToggleShow,
  ToggleShowButton,
  ToggleShowContent,
} from 'components/ui/ToggleShow';

const ButtonContent = styled.span`
  display: flex;
  align-items: center;
  font-weight: 500;
  img {
    margin-left: 10px;
    background: silver;
    border-radius: 4px;
    padding: 2px;
  }
`;

import filterIcon from 'assets/icons/filter.svg';
import styled from 'styled-components';

function MobileFilter({ children }: any) {
  return (
    <ToggleShow>
      <ToggleShowButton>
        <ButtonContent>
          Filtrar <img src={filterIcon} width="30" height="30" />
        </ButtonContent>
      </ToggleShowButton>
      <ToggleShowContent>{children}</ToggleShowContent>
    </ToggleShow>
  );
}

export default MobileFilter;
