import styled from 'styled-components';

export const Container = styled.div<{ variant?: string }>`
  button {
    background: ${(props) =>
      props.variant === 'secondary' ? '#fff' : '#0606da'};
    height: 39px;
    border: 0;
    padding: 0 16px;
    color: white;

    transition: background-color 0.2s;
    width: 155px;
    border-radius: 24px;
    font-size: 14px;
  }
`;
