import styled from "styled-components"

export const Container = styled.div`
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: #17536C;
    margin-top: 30px;
  }

  p {
    color: #3C505A;
    margin-top: 5px;
  }
`

export const WrapperInfo = styled.div`
  width: 80%;
  background: #fff;
  margin: 44px 44px;

  h3 {
    background: #17536c;
    color: #fff;
    padding: 8px;
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 30px;

    button {
      color: #003C65;
      border: none;
      background: transparent;
    }

    button:last-child {
      margin-left: 40px;
    }
  }

`


export const Line = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 15px;
  border-bottom: 1px solid #cfd7d9;
  align-items: center;
  color: #3C505A;

  @media(min-width: 768px){
    flex-direction: row;
  }

  div{
    align-items: center;
    display: flex;
    justify-content: flex-start
  }

  span {
    background-color: #FF0000;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 10px;
  }

  p{
    font-weight: 500;
    margin: 0;
    width: 95%;
    font-size: 13px;

    @media(min-width: 768px){
      font-size: 15px;
    }

    cursor: pointer;
  }
  img{
    cursor: pointer;
    margin-left: 15px;
  }


  `
