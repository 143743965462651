import React, {
  createContext,
  FC,
  useCallback,
  useState,
  useContext,
} from 'react';
import api from '../services/api';

interface SignInCredentials {
  login: string;
  password: string;
}

interface AuthState {
  token: string;
  fullname: string;
  userId: string;
  acceptTermsOfUse?: boolean;
  userCustomerCMS: string;
  contractType: string;
}

interface AuthContextData {
  fullname: string;
  signIn(credentials: SignInCredentials): Promise<any>;
  signOut(): void;
  userId: string;
  acceptTermsOfUse?: boolean;
  userCustomerCMS: string;
  contractType: string;
}

export const getToken = () => localStorage.getItem('@FleetSolutions:token');

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider: FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@FleetSolutions:token');
    const fullname = localStorage.getItem('@FleetSolutions:fullname');
    const userId = localStorage.getItem('@FleetSolutions:userId');
    const acceptTermsOfUse = localStorage.getItem(
      '@FleetSolutions:acceptTermsOfUse'
    );
    const isAcceptTermsOfUse = acceptTermsOfUse === 'true';
    const userCustomerCMS = localStorage.getItem(
      '@FleetSolutions:userCustomerCMS'
    );
    const contractType = localStorage.getItem('@FleetSolutions:ContractType');

    if (token && fullname && userId && userCustomerCMS && contractType) {
      return {
        token,
        fullname,
        userId,
        acceptTermsOfUse: isAcceptTermsOfUse,
        userCustomerCMS,
        contractType,
      };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ login, password }) => {
    // const url = "http://localhost:5000/api"
    const response = await api.post('/crm/login', {
      login,
      password,
    });

    const {
      token,
      fullname,
      userId,
      roleId,
      acceptTermsOfUse,
      userCustomerCMS,
      customerCMS,
      contractType,
    } = response.data;
    if (token) {
      localStorage.setItem('@FleetSolutions:token', token);
      localStorage.setItem('@FleetSolutions:fullname', fullname);
      localStorage.setItem('@FleetSolutions:userId', userId);
      localStorage.setItem('@FleetSolutions:roleId', roleId);
      localStorage.setItem(
        '@FleetSolutions:acceptTermsOfUse',
        acceptTermsOfUse
      );
      localStorage.setItem('@FleetSolutions:userCustomerCMS', userCustomerCMS);
      localStorage.setItem('@FleetSolutions:CustomerCMS', customerCMS);
      localStorage.setItem('@FleetSolutions:ContractType', contractType);
      localStorage.setItem(
        '@FleetSolutions:listOfPrograms',
        JSON.stringify(response.data.webSitesUrl)
      );

      setData({
        token,
        fullname,
        userId,
        acceptTermsOfUse,
        userCustomerCMS,
        contractType,
      });
    }
    return response;
  }, []);

  const signOut = useCallback(() => {
    const chat = document.getElementById('znv-chat-auto-0');

    setData({} as AuthState);

    localStorage.removeItem('@FleetSolutions:token');
    localStorage.removeItem('@FleetSolutions:userId');
    localStorage.removeItem('@FleetSolutions:roleId');
    localStorage.removeItem('@FleetSolutions:acceptTermsOfUse');
    localStorage.removeItem('@FleetSolutions:userCustomerCMS');
    localStorage.removeItem('@FleetSolutions:CustomerCMS');
    localStorage.removeItem('@FleetSolutions:ContractType');
    localStorage.removeItem('@FleetSolutions:fullname');
    localStorage.removeItem('@FleetSolutions:listOfPrograms');

    if (chat) {
      chat.style.display = 'none';
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        fullname: data.fullname,
        userId: data.userId,
        signIn,
        signOut,
        acceptTermsOfUse: data.acceptTermsOfUse,
        userCustomerCMS: data.userCustomerCMS,
        contractType: data.contractType,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
