import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { CustomersT, InputsT } from 'pages/Reports/types';
import SearchButton from 'pages/Reports/components/SearchButton';
import CustomerSelect from 'pages/Reports/components/CustomerSelect';
import Period from 'pages/Reports/components/Period';
import { FORMAT_VALUES } from '../utils/constants';

type FiltersProps = {
  customers: CustomersT[];
  inputs: InputsT;
  loading: boolean;
  onCustomerIdChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onDatesChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchClick: () => void;
};

const Container = styled.div`
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

function Filters(props: FiltersProps) {
  return (
    <Container>
      <CustomerSelect
        customers={props.customers}
        value={props.inputs.customerId}
        onChange={props.onCustomerIdChange}
      />
      <Period
        finalValue={props.inputs.finalDate}
        initialValue={props.inputs.initialDate}
        max={moment(props.inputs.initialDate)
          .add(1, 'year')
          .format(FORMAT_VALUES.fullDateApi)}
        min={moment(props.inputs.finalDate)
          .subtract(1, 'year')
          .format(FORMAT_VALUES.fullDateApi)}
        onChange={props.onDatesChange}
      />
      <SearchButton loading={props.loading} onClick={props.onSearchClick} />
    </Container>
  );
}

export default Filters;
