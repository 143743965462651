import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 10px;
  width: 100%;

  @media (min-width: 768px) {
    padding: 0 80px;
  }
  @media (max-width: 475px) {
    .toggleShow {
      width: 100%;
    }
  }
`;

export const LineButtons = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  width: 100%;

  a {
    text-decoration: none;
    &:nth-child(2) {
      button {
        background-color: #236fbc;
        color: white;
        margin-right: 0;
      }
    }
  }

  button {
    border: 2px solid #226fbb;
    border-radius: 24px;
    color: #0c5faf;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.2px;
    margin-right: 11px;
    padding: 10px 35px;
    width: 240px;
  }

  @media (min-width: 1024px) {
    justify-content: flex-end;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    button:first-child {
      margin-bottom: 10px;
    }
  }
`;

export const WrapperTable = styled.div`
  overflow-x: auto;
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;
  text-align: center;
  border-spacing: inherit;
  margin: 15px 0px;

  .tdNoDate {
    width: 250px !important;
  }
  tbody tr:nth-child(odd) {
    background: #f9f9f9;
  }

  tr {
    font-size: 14px;
  }

  td {
    padding: 2px 5px;
    white-space: nowrap;
  }

  th {
    font-weight: normal;
    background: #3c505a;
    color: #fff;
    height: 36px;

    &:first-child {
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
    }

    &:last-child {
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
    }
  }

  .large {
    width: 110px;
  }

  .cell-chassi {
    padding: 0 10px;
  }
`;

export const BtnLink = styled.p`
  text-decoration: underline;
  cursor: pointer;
  color: #007feb;
`;

export const SelectContainer = styled.div`
  select {
    width: 200px;
    height: 42px;
    margin-left: 15px;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 16px;
    border-color: #ccc;
    border: 2px solid #226fbb;
    border-radius: 250px;
  }
`;
export const WrapperForm = styled.div`
  width: 100%;
  margin: 10px 10px 10px 10px;

  .info {
    display: flex;
    flex-direction: row;
    margin-bottom: -35px;

    @media (max-width: 1112px) {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      margin-bottom: 30px;

      .btn-search {
        margin-top: 8px;
      }

      select,
      input {
        margin: 50px;
      }

      input {
        width: 265px;
      }
    }
  }

  .name-profile {
    border-radius: 16px;
    border: 1px solid #0c5faf;
    width: 190px;
    height: 38px;
    margin: 0 35px 0 10px;
    padding-left: 5px;
  }

  .btn-new-profile {
    float: right;
    // margin-bottom: 11px;
    border: 2px solid #226fbb;
    color: #fff;
    background: #236fbc;
    border-radius: 24px;
    width: 135px;
    height: 39px;
    font-size: 14px;
    font-weight: 500;
  }

  label {
    font-family: 'Roboto', sans-serif;
  }

  display: flex;
  .form-item {
    display: flex;
    align-items: center;
  }

  .wrapper-button {
    flex: 1;
  }

  @media (max-width: 1160px) {
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;

    .form-item {
      width: 49%;

      & > label {
        width: 75px;
      }
      & > select,
      & > input {
        flex: 1;
        margin: 0;
        width: 100%;
      }
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;

    & > .btn-new-profile {
      margin: 15px auto 0;
    }

    .form-item {
      width: 100%;
      & > label {
        width: 75px;
      }
      & > input,
      & > select {
        margin-bottom: 10px;
      }
    }
  }
`;

export const ModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0px solid #707070;
  padding-bottom: 40px;

  & > h2 {
    font-size: 21px;
    color: #0c5faf;
    font-weight: 500;
    margin-top: 40px;
  }

  & > p {
    font-size: 15px;
    color: #3c505a;
    font-weight: 500;
    margin-top: 13px;
  }

  & > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 90%;

    @media (min-width: 768px) {
      flex-direction: row;
    }
    & > a {
      text-decoration: none;
      height: 165px;
      max-width: 225px;
      width: 47%;

      button {
        background-color: white;
        border: 2px solid #0c5faf;
        border-radius: 11px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        position: relative;
        width: 100%;
        height: 100%;
        transition: 0.3s;

        &:hover {
          background-color: #0c5faf4d;
        }

        h3 {
          font-size: 21px;
          font-weight: 500;
          color: #0c5faf;
          margin-top: 60px;
        }

        img {
          width: 40px;
          position: absolute;
          height: 40px;
          top: 15px;
        }
      }
    }
  }

  .close {
    border: none;
    position: absolute;
    right: 13px;
    top: 10px;
  }
`;

export const Select = styled.select`
  border: 1px solid #0c5faf;
  border-radius: 18px;
  padding: 5px 12px;
  height: 38px;
  width: 270px;
  margin: 0px 35px 0 10px;
`;

export const SelectConductor = styled.span`
  color: green;
  text-decoration: underline;
  text-decoration-color: green;
`;
