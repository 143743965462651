import ExcelJs, { Workbook } from 'exceljs';
import { CustomersT } from '../types';
import moment from 'moment';
import { FORMAT_VALUES } from './constants';

export const getFirstApiError = (errors: any) => {
  let firstError = '';
  for (const key in errors) {
    firstError = errors?.[key];
    break;
  }
  return firstError;
};

export function replaceAccentedWords(text: string) {
  const accentsMap: Record<string, string> = {
    á: 'a',
    à: 'a',
    ã: 'a',
    ç: 'c',
    é: 'e',
    è: 'e',
    ê: 'e',
    í: 'i',
    ì: 'i',
    ó: 'o',
    ò: 'o',
    õ: 'o',
    ú: 'u',
    ù: 'u',
  };
  const pattern = new RegExp(`[${Object.keys(accentsMap).join('')}]`, 'g');
  return text.replace(pattern, (match) => accentsMap[match] || match);
}

export function formatFileName(
  value: string,
  config?: {
    accent?: boolean;
    capitalize?: 'lower' | 'none' | 'upper';
    preffix?: string;
    spacer?: string;
    suffix?: string;
  }
) {
  const {
    accent = false,
    capitalize = 'lower',
    preffix = '',
    spacer = '_',
    suffix = new Date().valueOf().toString(),
  } = config || {};
  const [name, extension] = value
    .replace(/[\s_+\-]+/g, spacer || '_')
    .split('.');
  let result = '';

  if (preffix) {
    result += preffix + spacer;
  }
  result += name;
  if (suffix) {
    result += spacer + suffix;
  }
  if (capitalize === 'lower') {
    result = result.toLowerCase();
  }
  if (capitalize === 'upper') {
    result = result.toUpperCase();
  }
  if (!accent) {
    result = replaceAccentedWords(result);
  }
  if (extension) {
    result += '.' + extension;
  }

  return result;
}

export function loadExcel(path: string) {
  return fetch(path)
    .then((response) => response.arrayBuffer())
    .then((buffer) => new ExcelJs.Workbook().xlsx.load(new Uint8Array(buffer)));
}

export function fillReport(
  workbook: Workbook,
  customerName: string,
  data: any[],
  period: string,
  createExcelRow: any
) {
  const worksheet = workbook.getWorksheet(1);

  // preenche parâmetros de busca
  worksheet.getCell('B5').value = customerName;
  worksheet.getCell('B6').value = period;

  // preenche as linhas do relatório
  data.forEach((item: any) => {
    worksheet.addRow(createExcelRow(item)).alignment = {
      horizontal: 'center',
      vertical: 'middle',
    };
  });

  return workbook;
}

export function findCustomerById(customers: CustomersT[], id: number) {
  return customers.find(({ customerCMSId }) => customerCMSId === id);
}

export function extractCustomerName(name: string) {
  return name.split(' - ')[1];
}

export function getCustomerName(customers: CustomersT[], id: number) {
  const found = findCustomerById(customers, id);
  return extractCustomerName(found?.customerName || '');
}

// dates
export function dateBase(value?: moment.MomentInput) {
  const date = moment(value);
  return {
    format: (format: keyof typeof FORMAT_VALUES = 'fullDate') => {
      return date.format(FORMAT_VALUES[format]);
    },
    add(amount: moment.DurationInputArg1, unit: moment.DurationInputArg2) {
      date.add(amount, unit);
      return this;
    },
    substract(
      amount: moment.DurationInputArg1,
      unit: moment.DurationInputArg2
    ) {
      date.subtract(amount, unit);
      return this;
    },
    valueOf: () => {
      return date.valueOf();
    },
  };
}
