import React from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import styled from 'styled-components';

import Tooltip from 'components/Tooltip';

// por que nomear usando a palavra Styled? diferenciar o que foi estilizado não foi
// por que usar styled components? remover lógica de estado do componente
const StyledContainer = styled.div`
  position: relative;
`;
const StyledError = styled(Tooltip)`
  background-color: #fff;
  bottom: 0;
  cursor: pointer;
  height: 20px;
  margin: auto;
  position: absolute;
  right: 14px; // deveria está aqui?
  text-align: center;
  top: 0;
  svg {
    margin: 0;
  }
  span {
    background: #c53030;
    color: #fff;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

type Props = {
  children: React.ReactNode;
  errorText?: string;
};

function InputContainerUI({ children, errorText, ...props }: Props) {
  return (
    <StyledContainer {...props}>
      {children}
      {errorText && (
        <StyledError className="InputContainer-error" title={errorText}>
          <FiAlertCircle color="#C53030" size={20} />
        </StyledError>
      )}
    </StyledContainer>
  );
}

export default InputContainerUI;
