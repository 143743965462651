import signanddriveLogoDark from 'pages/LandingPages/assets/images/signanddrive-logo-dark.svg';
import signanddriveLogoLight from 'pages/LandingPages/assets/images/signanddrive-logo-light.svg';
import signanddriveBanner from 'pages/LandingPages/assets/images/signanddrive-banner.jpg';
import audisignatureLogoDark from 'pages/LandingPages/assets/images/audisignature-logo-dark.svg';
import audisignatureLogoLight from 'pages/LandingPages/assets/images/audisignature-logo-light.svg';
import audisignatureBanner from 'pages/LandingPages/assets/images/audisignature-banner.jpg';
import rentalwayLogoDark from 'pages/LandingPages/assets/images/rentalway-logo-dark.svg';
import rentalwayLogoLight from 'pages/LandingPages/assets/images/rentalway-logo-light.svg';
import rentalwayBanner from 'pages/LandingPages/assets/images/rentalway-banner.jpg';
import assinecarLogoDark from 'pages/LandingPages/assets/images/assinecar-logo-dark.svg';
import assinecarLogoLight from 'pages/LandingPages/assets/images/assinecar-logo-light.svg';
import assinecarBanner from 'pages/LandingPages/assets/images/assinecar-banner.jpg';
import lmMobilidadeLogoLight from 'assets/LM/Logo-negativo-solucoes-mobilidade_LM.png';
import lmMobilidadeBanner from 'assets/banner/LM/banner-LM.png';

export const THEMES = {
  signanddrive: {
    checkedIconColor: '#00B0F0',
    textColor: '#001E50',
    firstButtonBgcolor: '#00B0F0',
    secondButtonBgcolor: '#001E50',
    innerHeaderBgcolor: '#fff',
    radius: 8,
    firstLogo: signanddriveLogoDark,
    secondLogo: signanddriveLogoLight,
    logoStyles: { width: 110, height: 40 },
    banner: signanddriveBanner,
    linkPrivacy: 'https://www.vwsignanddrive.com.br/politica-de-privacidade',
    phone: '8000718090',
  },
  audisignature: {
    checkedIconColor: '#F50537',
    textColor: '#000000',
    firstButtonBgcolor: '#F50537',
    secondButtonBgcolor: '#000000',
    innerHeaderBgcolor: '#fff',
    radius: 0,
    firstLogo: audisignatureLogoDark,
    secondLogo: audisignatureLogoLight,
    logoStyles: { width: 110, height: 40 },
    banner: audisignatureBanner,
    linkPrivacy: 'https://www.audisignature.com.br/politica-de-privacidade',
    phone: '8000718080',
  },
  rentalway: {
    checkedIconColor: '#00B0F0',
    textColor: '#001E50',
    firstButtonBgcolor: '#00B0F0',
    secondButtonBgcolor: '#001E50',
    innerHeaderBgcolor: '#fff',
    radius: 8,
    firstLogo: rentalwayLogoDark,
    secondLogo: rentalwayLogoLight,
    logoStyles: { width: 110, height: 40 },
    banner: rentalwayBanner,
    linkPrivacy: 'https://lmmobilidade.com.br/politica-de-privacidade',
    phone: '8008990001',
  },
  assinecar: {
    checkedIconColor: '#47BC00',
    textColor: '#00191E',
    firstButtonBgcolor: '#9A39D3',
    secondButtonBgcolor: '#9A39D3',
    innerHeaderBgcolor: '#fff',
    radius: 8,
    firstLogo: assinecarLogoDark,
    secondLogo: assinecarLogoLight,
    logoStyles: { width: 110, height: 40 },
    banner: assinecarBanner,
    linkPrivacy: 'https://lmmobilidade.com.br/politica-de-privacidade',
    phone: '8000755050',
  },
  pdc: {
    checkedIconColor: '#0606da',
    textColor: '#fff',
    firstButtonBgcolor: '#0606da',
    secondButtonBgcolor: '#0606da',
    innerHeaderBgcolor: '#0606da',
    radius: 8,
    firstLogo: lmMobilidadeLogoLight,
    secondLogo: lmMobilidadeLogoLight,
    logoStyles: undefined,
    banner: lmMobilidadeBanner,
    linkPrivacy: '',
    phone: '8007755566',
  },
};

export const landingpagePaths = [
  'assinecar',
  'audisignature',
  'rentalway',
  'signanddrive',
];

// mockLocation simula um window.location no localhost
// prefira adicionar o dns no arquivo C:\Windows\System32\drivers\etc\hosts
export const mockLocation = {
  // hostname: 'http://portaldocliente-dev.assinecar.com.br/',
  // hostname: 'http://portaldocliente-dev.rentalway.com.br/',
  // hostname: 'http://portaldocliente-dev.lmmobilidade.com.br/',
  // hostname: 'http://portaldocliente-dev.signanddrive.com.br/',
  hostname: 'http://portaldocliente-dev.audisignature.com.br/',
};

export const TYPE_ORDERS = {
  signanddrive: 500,
  audisignature: 551,
  rentalway: 504,
  assinecar: 506,
  pdc: 550,
};
