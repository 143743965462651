import React from 'react';

import Switch from '../../../../assets/icons/switch.svg';
import { OptionsI, TransferListI } from './types';
import { Container, Icon, List, Select } from './styles';

function TransferList({ data, onAdd, onRemove, targets }: TransferListI) {
  const renderList = (
    onChange: React.ChangeEventHandler<HTMLSelectElement>,
    options: OptionsI[],
    value: string
  ) => (
    <List>
      <Select multiple onChange={onChange} value={[value]}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </List>
  );

  return (
    <Container>
      {renderList(onAdd, data, targets[0]?.value)}
      <Icon>
        <img
          src={Switch}
          title="Para adicionar / remover da lista, clique no item escolhido."
        />
      </Icon>
      {renderList(onRemove, targets, '')}
    </Container>
  );
}

export default TransferList;
