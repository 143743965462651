import moment from 'moment';
import React from 'react';

import { ActionButton } from 'pages/Financial/styles';
import formatValue from 'utils/formatValue';
import iconApprove from 'assets/icons/icons8-selecionado.svg';
import iconDisapprove from 'assets/icons/icons8-cancelar.svg';
import { MeasurementsI } from 'pages/Financial/types';
import { StyledInvoiceAndBill, StyledStatus } from './styled';

export const viewInvoice = ({ alt, icon }: any) => ({
  key: 'linkFatura',
  render: (value: string, row: any) => (
    <StyledInvoiceAndBill>
      {row.linkBoleto && (
        <a href={row.linkBoleto} target="_blank">
          Boleto
        </a>
      )}
      {value && (
        <a href={value} target="_blank">
          Fatura
        </a>
      )}
    </StyledInvoiceAndBill>
  ),
  title: 'Ver',
});

export const invoiceNumberColumn = { key: 'numeroFatura', title: 'Nº Fatura' };

export const issuanceTypeColumn = {
  key: 'issuanceType',
  title: 'Tipo de emissão',
};

export const issuanceDateColumn = {
  key: 'dataEmissao',
  render: (value: string) => moment(value).format('DD/MM/YYYY'),
  title: 'Emissão',
};

export const dueDateColumn = {
  key: 'dataVencimento',
  render: (value: string) => moment(value).format('DD/MM/YYYY'),
  title: 'Vencimento',
};

export const autoApprovalColumn = {
  key: 'autoApproval',
  render: (value: string) => moment(value).format('DD/MM/YYYY'),
  title: 'Aprov. automática',
};

export const companyNameColumn = { key: 'razaoSocial', title: 'Razão Social' };

export const cpfCnpjColumn = { key: 'cpfCnpj', title: 'CPF/CNPJ' };

export const financialProductColumn = {
  key: 'produtoFinanceiro',
  title: 'Tipo',
};

export const costCenterColumn = { key: 'costCenter', title: 'Centro de Custo' };

export const amountColumn = {
  key: 'valorTotal',
  render: (value: number) => formatValue(value),
  title: 'Valor',
};

export const statusColumn = (getStatusColor: any) => ({
  key: 'statusFatura',
  render: (value: string) => (
    <StyledStatus bgColor={getStatusColor(value)}>{value}</StyledStatus>
  ),
  title: 'Status',
});

export const actionsColumn = ({ onApprove, onReprove }: any) => ({
  key: 'actions',
  render: (value: string, row: MeasurementsI) =>
    row.status === 'Pendente' ? (
      <>
        <ActionButton onClick={() => onApprove(value)}>
          <img src={iconApprove} alt="Aprovar" title="Aprovar medição" />
        </ActionButton>{' '}
        <ActionButton onClick={() => onReprove(value)}>
          <img src={iconDisapprove} alt="Reprovar" title="Reprovar medição" />
        </ActionButton>
      </>
    ) : null,
  title: 'Ação',
});
