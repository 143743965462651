import React, { useState } from 'react';
import PageviewIcon from '@material-ui/icons/Pageview';
import moment from 'moment';

import {
  Content,
  ListTitle,
  Message,
  SaveButton,
  Search,
  Title,
  WrapperModal,
} from './styles';
import { ConductorI, DriverSelectorI, MessageI, VehicleI } from './types';
import TransferList from './TransferList/TransferList';
import LocationSpin from '../../../components/LoadingSpin';
import VehicleDetails from './VehicleCard/VehicleCard';
import { changeDriverApi, searchDriversApi } from 'services/services';
import { ERROR, SUCCESS, validateConductor } from './utils';

function DriverSelector({
  contract,
  customersOptions,
  getVehicleContractByCustomer,
  isFirstDriver,
  setContract,
  userId,
}: DriverSelectorI) {
  const [conductorFilter, setConductorFilter] = useState('');
  const [conductors, setConductors] = useState<ConductorI[]>([]);
  const [isConductorModified, setIsConductorModified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<MessageI>({
    text: '',
    type: SUCCESS,
  });
  const [selectedConductors, setSelectedConductors] = useState<ConductorI[]>([
    {
      condutorId: contract.condutorId,
      condutorNome: contract.condutorNome,
      contratoId: contract.condutorId,
    },
  ]);
  const [vehicles, setVehicles] = useState<VehicleI[]>([]);

  const handleChangeCustomer = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setContract({ ...contract, clienteId: Number(e.target.value) });
    setVehicles([]);
    setConductors([]);
  };

  const handleChangeVehicle = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedVehicle = vehicles.find(
      (vehicle) => vehicle.vehicleId === Number(e.target.value)
    );

    selectedVehicle &&
      setContract({
        ...contract,
        veiculoPlaca: selectedVehicle.placa,
        modeloNome: selectedVehicle.modelo,
      });
  };

  const handleAddConductor = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedConductor = conductors.find(
      (conductor) => conductor.condutorId === parseInt(e.target.value, 10)
    );

    setIsConductorModified(true);
    setMessage({ text: '', type: SUCCESS });
    selectedConductor && setSelectedConductors([selectedConductor]);
  };

  const handleRemoveConductor = () => {
    setIsConductorModified(true);
    setMessage({ text: '', type: SUCCESS });
    setSelectedConductors([]);
  };

  const handleDriversSearch = async () => {
    if (conductorFilter === '') {
      setMessage({
        text: 'Favor digitar o nome do condutor para pesquisar!',
        type: ERROR,
      });
      return;
    }
    setLoading(true);
    setConductors(
      await searchDriversApi({
        clienteId: contract.clienteId,
        nome: conductorFilter,
      })
    );
    setLoading(false);
  };

  const handleSave = async () => {
    try {
      validateConductor(isConductorModified, selectedConductors);
      setLoading(true);
      setMessage({ text: 'Enviando dados, aguarde ...', type: SUCCESS });
      await changeDriverApi({
        contratoId: contract.contratoId,
        condutorId: selectedConductors[0]?.condutorId,
      });
      setIsConductorModified(false);
      setMessage({
        text: 'Relacionamento executado com sucesso.',
        type: SUCCESS,
      });
      getVehicleContractByCustomer(contract.clienteId);
    } catch (error) {
      if (error instanceof Error) {
        setMessage({ text: error.message, type: ERROR });
      }
    } finally {
      setLoading(false);
    }
  };

  const companyName = isFirstDriver ? (
    <select onChange={handleChangeCustomer}>
      {customersOptions.map((customer: any, i: number) => (
        <option key={i} value={customer.customerCMSId}>
          {customer.customerName}
        </option>
      ))}
    </select>
  ) : (
    contract.cliente
  );

  const driverSearch = (
    <Search>
      <div>
        <span className="Search-title">Condutor</span>
        <input
          className="Search-input"
          onChange={(e) => setConductorFilter(e.target.value)}
          type="text"
          value={conductorFilter}
        />
      </div>
      <LocationSpin loading={loading} />
      <div className="Search-button">
        <a onClick={handleDriversSearch}>
          <PageviewIcon color="primary" fontSize="large" />
        </a>
      </div>
    </Search>
  );

  return (
    <Content>
      <Title>Vincular condutores</Title>
      <WrapperModal>
        <p>
          Empresa: <span>{companyName}</span>
        </p>
        {isFirstDriver && (
          <p>
            Veículo:{' '}
            <span>
              <select onChange={handleChangeVehicle}>
                {vehicles.map((vehicle: VehicleI) => (
                  <option key={vehicle.vehicleId} value={vehicle.vehicleId}>
                    {vehicle.placa}
                  </option>
                ))}
              </select>
            </span>
          </p>
        )}
        <p>Detalhes do veículo</p>
        {contract.modeloNome && <VehicleDetails vehicle={contract} />}
        <div>
          <span>
            Assinatura em vigor desde:{' '}
            {moment(contract.dataInicioVigencia).format('DD/MM/YYYY')}
          </span>
        </div>
      </WrapperModal>
      <Message type={message.type}>{message.text}</Message>
      {driverSearch}
      <ListTitle>
        Selecione na lista à esquerda o condutor a ser vinculado ao veículo.
      </ListTitle>
      <TransferList
        data={conductors.map((conductor) => ({
          label: conductor.condutorNome,
          value: String(conductor.condutorId),
        }))}
        onAdd={handleAddConductor}
        onRemove={handleRemoveConductor}
        targets={selectedConductors.map((selected) => ({
          label: selected.condutorNome,
          value: String(selected.condutorId),
        }))}
      />
      <SaveButton onClick={handleSave}>Salvar</SaveButton>
    </Content>
  );
}

export default DriverSelector;
