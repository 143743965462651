import React from 'react';

import InputFieldContainerUI from '../InputFieldUI/InputFieldContainerUI';
import AutocompleteBaseUI from './AutocompleteBaseUI';

type Props = {
  error?: boolean;
  errorText?: string;
  id?: string;
  label: string;
  loading?: boolean;
  loadingText?: string;
  noOptionsText?: string;
  open?: boolean;
  options: any[];
  value?: unknown;
  getOptionLabel?: (option: any) => string;
  getOptionSelected?: (option: any, value: any) => boolean;
  onChange?: (event: React.ChangeEvent<{}>, value: any) => void;
  onClose?: () => void;
  onInputChange?: (event: React.ChangeEvent<{}>, value: string) => void;
  onOpen?: () => void;
};

function AutocompleteUI({ id, label, ...props }: Props) {
  return (
    <InputFieldContainerUI error={props.error} id={id} label={label}>
      <AutocompleteBaseUI {...props} />
    </InputFieldContainerUI>
  );
}

export default AutocompleteUI;
