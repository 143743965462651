import React from 'react';
import styled, { CSSProperties } from 'styled-components';

const sizes = {
  small: {
    wrapper: '26px',
    inner: '20px',
    border: '2px',
  },
};

const Container = styled.div`
  display: inline-block;
  position: relative;
  width: ${sizes.small.wrapper};
  height: ${sizes.small.wrapper};

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${sizes.small.inner};
    height: ${sizes.small.inner};
    margin: 4px;
    border: ${sizes.small.border} solid ${({ color }) => color};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ color }) => color} transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface LoadingUIProps {
  color?: string;
  style?: CSSProperties;
}

function LoadingUI({ color = '#666', ...props }: LoadingUIProps) {
  return (
    <Container color={color} {...props}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Container>
  );
}

export default LoadingUI;
