import { InvoiceStatusT, MeasurementStatusT } from './types';

// mocks
export const MOCK_DATA = [
  {
    id: 0,
    numeroFatura: 0,
    dataEmissao: '2023-07-10T12:16:44.164Z',
    dataVencimento: '2023-07-10T12:16:44.164Z',
    produtoFinanceiro: 'string',
    clienteId: 0,
    cliente: 'string',
    razaoSocial: 'string',
    cpfCnpj: 'string',
    statusFatura: 'string',
    codigoContrato: 0,
    sequenciaContrato: 0,
    demonstrativoId: 0,
    parcela: 0,
    valorTotal: 0,
    linkFatura: 'string',
    linkBoleto: 'string',
    indicadorParcelaRecebido: true,
  },
];

// constants
export const INVOICE_TITLE = 'Faturas e Boletos';
export const MEASURUMENT_TITLE = 'Medições';
export const INITIAL_FILTERS = {
  paginaAtual: 1,
  ordemCampo: '',
  ordemDescendente: 'asc', // payload bool
  numeroFatura: '',
  tipoPesquisaData: '1', // payload number
  dataInicial: '',
  dataFinal: '',
  codigoStatusFatura: '',
  codigoProdutoFinanceiro: '',
};
export const PER_PAGE = 10;
export const SUCCESS_COLOR = '#51AE30';
export const ERROR_COLOR = '#ff0000';
export const WARNING_COLOR = '#fcd042';
export const DEFAULT_COLOR = '#a69f9f';

// helpers
export const getMeasurementStatusColor = (status: MeasurementStatusT) => {
  switch (status) {
    case 'Aprovado':
      return SUCCESS_COLOR;
    case 'Reprovado':
      return ERROR_COLOR;
    case 'Pendente':
      return WARNING_COLOR;
    default:
      return DEFAULT_COLOR;
  }
};

export const getInvoiceStatusColor = (status: InvoiceStatusT) => {
  switch (status) {
    case 'Pago':
      return SUCCESS_COLOR;
    case 'Vencido':
      return ERROR_COLOR;
    case 'Pendente':
      return WARNING_COLOR;
    default:
      return DEFAULT_COLOR;
  }
};
