import { changeDriverApi } from 'services/services';
import { ConductorI } from './types';

// Constants
export const ERROR = 'error';
export const SUCCESS = 'success';
export const STATUS_VEHICLE_DELIVERED = 5;
export const MESSAGE_COLORS = {
  error: '#ff4040',
  success: '#7ccd7c',
};

// Helpers
export const validateConductor = (
  isConductorModified: boolean,
  selectedConductors: ConductorI[]
) => {
  if (!isConductorModified) {
    throw new Error('O condutor não foi alterado!');
  }
  if (selectedConductors.length === 0) {
    throw new Error('Favor escolher um condutor antes de salvar!');
  }
};
