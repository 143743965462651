import { MenuItem } from '@material-ui/core';
import { Button, Menu } from 'antd';
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { HiOutlineMenuAlt1 } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getTheme } from 'pages/LandingPages/utils/helpers';
import iconLogin from '../../assets/icons/login.svg';
import iconLogout from '../../assets/icons/logout.svg';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import SignIn from '../../pages/SignIn';
import api from '../../services/api';
import { loginApp } from '../../store/modules/login/actions';
import {
  Container,
  Content,
  Divider,
  MenuButton,
  MenuContainer,
  WrapperButton,
  WrapperLogo,
} from './styles';
import { AxiosResponse } from 'axios';
const { SubMenu } = Menu;

interface GetByUserIdI {
  checkedOnFleet: boolean;
  emailConfirmed: boolean;
  inactive: boolean;
  deleted: boolean;
}
type Props = {
  isPositionAbsolute?: boolean;
  isPositionFixed?: boolean;
};

function Header({ isPositionAbsolute, isPositionFixed }: Props) {
  const { fullname, signOut, userId } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToast();

  const [loginIsRequired, setLoginIsRequired] = useState(false);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [menus, setMenus] = useState<any>([]);
  const [CustomerCMS, setCustomerCMS] = useState<string>('');

  const theme = getTheme();

  useEffect(() => {
    const logoutInvalidProfile = () => {
      api
        .get(`/users/getbyuserid/${userId}/${userId}`)
        .then((response: AxiosResponse<GetByUserIdI>) => {
          if (
            response.data.inactive ||
            response.data.deleted ||
            !response.data.checkedOnFleet ||
            !response.data.emailConfirmed
          ) {
            signOut();
          }
        });
    };
    if (userId) logoutInvalidProfile();
  }, [userId]);

  useEffect(() => {
    document.addEventListener(
      'keydown',
      (e) => {
        if (e.key === 'Escape') {
          setOpenMenu(false);
        }
      },
      false
    );
  }, [openMenu]);

  useEffect(() => {
    setCustomerCMS(localStorage.getItem('@FleetSolutions:CustomerCMS') || '');

    var userId = localStorage.getItem('@FleetSolutions:userId') || 0;
    if (userId == 0) return;

    api
      .get(`/crm/reports/role_menu_submenu/${userId}`)
      .then((response) => {
        setMenus(response.data.menus);

        if (!history.location.pathname.includes('/welcome')) {
          const [_, pathlocation] = history.location.pathname.split('/');
          const permission = response.data.menus.filter((item: any) => {
            if (item.pathLocation != null) {
              return (
                item.pathLocation.includes(pathlocation) ||
                item.pathComplement.includes(pathlocation)
              );
            }
          });
          if (permission.length == 0) {
            addToast({
              title: 'permissão negada!',
              type: 'error',
              description: 'acesso retrito!',
            });
            history.push('/welcome');
          }
        }
      })
      .catch(() => {
        addToast({
          title: 'permissão negada!',
          type: 'error',
          description: 'Operação inválida!',
        });
      });
  }, []);

  const handleChangeLogin = useCallback(() => {
    setLoginIsRequired(true);
    dispatch(loginApp({ status: true }));
  }, []);

  const handleClick = (e: any) => {
    history.push(e.key);
  };

  const handleLogout = useCallback(() => {
    signOut();
    setOpenMenu(false);
  }, []);

  return (
    <Container
      isPositionAbsolute={isPositionAbsolute}
      isPositionFixed={isPositionFixed}
    >
      <Content style={{ backgroundColor: theme.innerHeaderBgcolor }}>
        {loginIsRequired && <SignIn />}
        <WrapperLogo>
          <img
            onClick={() => history.push('/welcome')}
            src={theme.firstLogo}
            alt="Logo LM Frotas"
            style={theme.logoStyles}
          />
        </WrapperLogo>
        <WrapperButton style={{ color: theme.textColor }}>
          {!fullname ? (
            <>
              <Button type="text" onClick={handleChangeLogin}>
                LOGIN <img src={iconLogin} alt="Icon Login" />
              </Button>
            </>
          ) : (
            <>
              <div className="fullname">
                <div>Bem vindo, {fullname}</div>
                <div>{CustomerCMS}</div>
              </div>
              <MenuButton>
                <span className={`spn-menu ${openMenu ? 'active' : ''}`}>
                  MENU
                </span>
                <Button
                  className={`btn-menu ${openMenu ? 'active' : ''}`}
                  onClick={() => setOpenMenu(!openMenu)}
                  style={{ color: theme.textColor }}
                >
                  <HiOutlineMenuAlt1 size="30" />
                </Button>
              </MenuButton>
              <MenuContainer className={openMenu ? 'active' : ''}>
                <Menu
                  onClick={handleClick}
                  style={{ width: 256 }}
                  mode="inline"
                >
                  {menus.map((menu: any, i: number) => (
                    <Fragment key={i}>
                      {menu.subMenu.length == 0 ? (
                        <Menu.Item key={menu.pathLocation}>
                          {menu.menuName}
                        </Menu.Item>
                      ) : (
                        <SubMenu key={menu.menuId} title={menu.menuName}>
                          {menu.subMenu.map((sm: any, idx: number) => (
                            <Menu.Item key={sm.pathLocation}>
                              {sm.name}
                            </Menu.Item>
                          ))}
                        </SubMenu>
                      )}
                    </Fragment>
                  ))}
                </Menu>
                <Divider>
                  <span />
                </Divider>
                <MenuItem onClick={handleLogout}>
                  <span className="btn-logout">Sair</span>
                  <img
                    style={{ position: 'absolute', right: 10 }}
                    className="icon-menu"
                    src={iconLogout}
                    alt="Icon Notification"
                  />
                </MenuItem>
              </MenuContainer>
            </>
          )}
        </WrapperButton>
      </Content>
    </Container>
  );
}

export default Header;
