import { useState, useEffect } from 'react';

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Define a largura máxima para dispositivos móveis
    };

    checkIsMobile(); // Verifica imediatamente ao montar o componente

    // Adiciona um event listener para reavaliar quando o tamanho da tela mudar
    window.addEventListener('resize', checkIsMobile);

    // Remove o event listener quando o componente é desmontado
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  return isMobile;
}

export default useIsMobile;
