import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
  padding: 0 10px;
  width: 100%;

  @media (min-width: 768px) {
    padding: 0 80px;
  }

  @media (min-width: 1200px) {
    padding: 0 180px;
  }

  & > form {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    width: 100%;
    max-width: 1024px;

    .sc-httYMd {
      padding-bottom: 8px;
    }

    .phone-line {
      div {
        margin: 10px 5px;
      }
    }

    @media (max-width: 767px) {
      .phone-line {
        margin-bottom: 20px;
        label {
          white-space: nowrap;
        }
      }
    }
  }
`;

export const Line = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 768px) {
    margin-bottom: 40px;
    flex-direction: row;
  }

  section {
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    max-width: 430px;

    @media (min-width: 768px) {
      max-width: 390px;
    }

    @media (min-width: 1080px) {
      max-width: 460px;
    }

    & > div {
      border-radius: 30px;
      border: 2.5px solid #cfd7d9;
      margin-bottom: 25px;
      max-width: 430px;
      width: 100%;

      @media (min-width: 768px) {
        margin: 4px !important;
        max-width: 460px;
        width: 460px;
      }

      input {
        font-size: 15px;
        width: 250px;

        &::placeholder {
          color: #797979;
        }
      }

      &.ddd {
        width: 100px;
      }

      &.phone {
        width: 320px;

        @media (min-width: 1080px) {
          width: 385px;
        }
      }
    }

    @media (max-width: 1080px) {
      & > div {
        margin-top: 0;
        & > input {
          width: 100%;
        }
      }
    }
  }

  & > div {
    border-radius: 30px;
    border: 2.5px solid #cfd7d9;
    margin-bottom: 35px;
    max-width: 430px;
    width: 100%;

    &.date {
      padding: 12px;
    }

    @media (min-width: 768px) {
      margin: 4px !important;
    }

    input {
      font-size: 15px;

      padding-right: 7px;

      &::placeholder {
        color: #797979;
      }
    }

    &.ddd {
      width: 70px;
    }

    &.phone {
      width: 320px;
    }
  }

  .loadingCondutor {
    border: 0px;
  }
`;

export const SelectContainer = styled.div`
  position: relative;

  & > label {
    bottom: 55px;
    color: #0c5faf;
    font-size: 15px;
    font-weight: 500;
    left: 15px;
    position: absolute;
  }

  & > div {
    & > select {
      border: none;
      color: #797979;
      width: 93%;

      option {
        color: #797979;
      }
    }
  }
`;

export const CheckBoxContainer = styled.div`
  position: relative;
  /*background-color: red !important;*/
  border: 0px !important;
  padding-left: 13px !important;

  & > label {
    bottom: 45px;
    color: #0c5faf;
    font-size: 15px;
    font-weight: 500;
    left: 15px;
    position: absolute;
  }

  & > div {
    & > checkbox {
      border: none;
      color: #797979;
      width: 93%;

      option {
        color: #797979;
      }
    }
  }
`;

export const Button = styled.button`
  align-self: flex-end;
  background-color: #236fbc;
  border: 2px solid #226fbb;
  border-radius: 24px;
  color: white;
  font-size: 12px;
  height: 39px;
  width: 208px;
`;
export const InputMaskStyle = styled.div`
  border-radius: 10px;
  border: 2px solid #ccc;
  padding: 13px;
  position: relative;
  width: 100%;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  input {
    flex: 1;
    background: transparent;
    border: 0;

    &::placeholder {
      color: #666360;
    }
  }

  svg {
    margin-right: 16px;
  }

  label {
    bottom: 55px;
    color: #0c5faf;
    font-size: 15px;
    font-weight: 500;
    position: absolute;
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  button {
    font-weight: 500;
    font-size: 13px;
    margin: 4px;
  }

  .loading {
    width: 100px;
    padding-left: 400px;
    padding-top: 15px;
  }

  @media (max-width: 767px) {
    & > .loading {
      flex: 1;
      padding-left: 0;
    }
  }
`;
