import React from 'react';
import PaymentMethod from './PaymentMethod';
import { ICONS } from './PaymentIcon';
import {
  BANK_SLIP_PAYMENT_METHOD_ID,
  CARDS_LABEL,
  CREDIT_CARD_PAYMENT_METHOD_ID,
} from '..';

type Props = {
  currentMethodId: number;
  cardFlag: keyof typeof CARDS_LABEL | '';
  cardNumber: string;
};

function CurrentPaymentMethod({
  currentMethodId,
  cardFlag,
  cardNumber,
}: Props) {
  const getPaymentMethodProps = (): {
    type: keyof typeof ICONS;
    method: string;
    description: string;
  } => {
    switch (currentMethodId) {
      case CREDIT_CARD_PAYMENT_METHOD_ID:
        return {
          type: cardFlag || 'default',
          method: 'Crédito',
          description: `${
            cardFlag ? CARDS_LABEL[cardFlag] : cardFlag
          } •••• ${cardNumber}`,
        };
      case BANK_SLIP_PAYMENT_METHOD_ID:
        return {
          type: 'boleto',
          method: 'Boleto',
          description: '',
        };
      default:
        return {
          type: 'default',
          method: 'xxxxxx',
          description: '',
        };
    }
  };

  const { method, type, description } = getPaymentMethodProps();
  return (
    <PaymentMethod method={method} type={type} description={description} />
  );
}

export default CurrentPaymentMethod;
