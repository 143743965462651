import React from 'react';
import styled from 'styled-components';

import LoadingUI from '../Icons/Animated';
import { getTheme } from 'pages/LandingPages/utils/helpers';

const colors = {
  primary: {
    backgroundColor: '#0606da',
    borderColor: 'transparent',
    color: '#fff',
  },
  secondary: {
    backgroundColor: '#fff',
    borderColor: '#17536c',
    color: '#17536c',
  },
  third: {
    backgroundColor: '#fff',
    borderColor: '#0606da',
    color: '#0606da',
  },
};

const StyledContainer = styled.button<{
  $color: keyof typeof colors;
  $loading: boolean;
  $radius: number;
}>`
  align-items: center;
  background-color: ${({ $color }) => colors[$color].backgroundColor};
  border: 2px solid ${({ $color }) => colors[$color].borderColor};
  border-radius: ${(props) => props.$radius}px;
  color: ${({ $color, $loading }) =>
    $loading ? 'transparent' : colors[$color].color};
  display: inline-flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  letter-spacing: 1.2px;
  line-height: 2.2;
  padding: 6px 35px;
  position: relative;

  &:disabled {
    opacity: 0.3;
    cursor: default;
  }
`;

const BoxLoading = styled.span<{ $color: keyof typeof colors }>`
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  color: ${({ $color }) => colors[$color].color};
  display: flex;
`;

interface ButtonUIProps {
  children: React.ReactNode;
  color?: keyof typeof colors;
  disabled?: boolean;
  loading?: boolean;
  loadingText?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit' | 'reset';
}

function ButtonUI({
  color = 'primary',
  children,
  disabled = false,
  loading = false,
  loadingText = 'Aguarde...',
  onClick,
  type = 'button',
  ...props
}: ButtonUIProps) {
  const theme = getTheme();

  const renderLoading = () => (
    <BoxLoading $color={color}>
      <LoadingUI color={colors[color].color} style={{ marginRight: 6 }} />{' '}
      {loadingText}
    </BoxLoading>
  );

  return (
    <StyledContainer
      $color={color}
      disabled={loading || disabled}
      $loading={loading}
      $radius={theme.radius}
      type={type}
      onClick={onClick}
      {...props}
    >
      {loading && renderLoading()}
      {children}
    </StyledContainer>
  );
}

export default ButtonUI;
