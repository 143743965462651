import { useEffect, useState } from 'react';

import { useAuth } from 'hooks/auth';
import api from 'services/api';
import { CustomersT } from '../types';
import { TRAFFIC_TICKET_REPORT_NAME } from 'pages/Reports/utils/constants';

function useReports() {
  const { userId } = useAuth();

  const [activeMenu, setActiveMenu] = useState(TRAFFIC_TICKET_REPORT_NAME);
  const [customers, setCustomers] = useState<CustomersT[]>([]);

  useEffect(() => {
    const fetchCustomers = () => {
      api.get(`/crm/reports/${userId}/customers-cms-lm`).then((response) => {
        setCustomers(response.data);
      });
    };

    fetchCustomers();
  }, []);

  const handleMenuClick = (item: string) => {
    setActiveMenu(item);
  };

  return { activeMenu, customers, handleMenuClick };
}

export default useReports;
