import styled, { css } from 'styled-components';

//qual é o padrão adequado para evoluir os status?
const inputBaseStyles = `
  border: 2px solid;
  color: #000;
  height: 40px;
  padding: 5px 12px;
  width: 100%;
`;
const inputDefaultStyles = `
  border-color: #ccc;
  &:focus {
    border-color: #0606da;
  }
`;
const inputErrorStyles = `
  border-color: rgb(197, 48, 48);
  &:focus {
    border-width: 3px;
  }
`;

// quando usar css helper? somente quando interpolar função. https://styled-components.com/docs/api#css
export const inputStyles = css<{ error?: boolean; $radius?: number }>`
  border-radius: ${(props) => props.$radius}px;
  ${inputBaseStyles}
  ${(props) => (props.error ? inputErrorStyles : inputDefaultStyles)}
`;

export const StyledInput = styled.input<{
  error?: boolean;
  $radius?: number;
}>`
  ${inputStyles}
`;
