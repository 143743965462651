import BoxUI from 'components/ui/BoxUI/BoxUI';
import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled(BoxUI)`
  border: 2px solid #0606da;
`;

type Props = {
  children: React.ReactNode;
};

function ListUI(props: Props) {
  return <StyledContainer>{props.children}</StyledContainer>;
}

export default ListUI;
