import axios, { AxiosResponse } from 'axios';
import { getToken } from '../hooks/auth';
import { getProductFromUrl } from 'pages/LandingPages/utils/helpers';

interface RefreshTokenI {
  token: string;
}

const BASE_URL = 'https://backend.vwsignanddrive.com.br/api';
const TOKEN_REFRESH_INTERVAL_MS = 15 * 60 * 1000; // 15 minutos em milissegundos
let nextTokenRefreshTime = 0;
let refreshTokenInProgress = false;

const api = axios.create({ baseURL: BASE_URL });

const logout = () => {
  localStorage.clear();
  window.location.replace(window.location.origin);
};

const isTokenRefreshTime = (): boolean => Date.now() > nextTokenRefreshTime;

const fetchRefreshToken = async (token: string): Promise<void> => {
  try {
    const response: AxiosResponse<RefreshTokenI> = await axios.post(
      `${BASE_URL}/users/RefreshToken`,
      null,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const newToken = response?.data?.token;
    if (newToken) {
      localStorage.setItem('@FleetSolutions:token', newToken);
      nextTokenRefreshTime = Date.now() + TOKEN_REFRESH_INTERVAL_MS;
    } else {
      throw new Error('Erro ao obter novo token.');
    }
  } catch {
    logout();
  } finally {
    refreshTokenInProgress = false;
  }
};

api.interceptors.request.use(async (config) => {
  const token = getToken();
  config.headers['x-product-name'] = getProductFromUrl();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;

    if (isTokenRefreshTime() && !refreshTokenInProgress) {
      refreshTokenInProgress = true;
      await fetchRefreshToken(token);
    }
  } else {
    nextTokenRefreshTime = Date.now() + TOKEN_REFRESH_INTERVAL_MS;
  }

  return config;
});

export default api;
