import React, { useCallback, useRef } from 'react';
import { FiLock } from 'react-icons/fi';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { Modal } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../services/api';
import { Container, Content, Background, AnimationContainer } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';
import logoImg from '../../assets/LM/Fundo_transparente_M.png';
import { useToast } from 'hooks/toast';

interface ResetFormData {
  email: string;
  newPassword: string;
  passwordConfirmation: string;
  PasswordResetToken: string;
}

const ResetPasswordPdc: React.FC = () => {
  let { token } = useParams<any>();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();

  const handleSubmit = useCallback(async (data: ResetFormData) => {
    data.PasswordResetToken = token;

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        newPassword: Yup.string().matches(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
          'Sua senha deve conter mais de 8 dígitos, números, letras minúsculas, letras maiúsculas e caracteres especiais'
        ),
        passwordConfirmation: Yup.string().oneOf(
          [Yup.ref('newPassword'), undefined],
          'Confirmação incorreta'
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      await api.post('/change-password', data);
      addToast({
        title: 'Sua senha foi alterada com sucesso.',
        type: 'success',
      });
      history.push('/welcome');
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
      addToast({
        title: 'Seu token de redefinição de senha expirou.',
        type: 'error',
      });
      history.push('/welcome');
    }
  }, []);

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img width="250" src={logoImg} alt="Fleet Solutions" />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h2>Redefinir senha</h2>
            <Input
              icon={FiLock}
              placeholder="Nova senha"
              id="newPassword"
              name="newPassword"
              type="password"
            />
            <Input
              icon={FiLock}
              placeholder="Confirmação de senha"
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
            />
            <Button type="submit">Alterar senha</Button>
          </Form>
        </AnimationContainer>
      </Content>

      <Background />
    </Container>
  );
};

export default ResetPasswordPdc;
