import React, { useCallback, useEffect, useRef, useState } from "react"
import { Form } from "@unform/web"
import { FormHandles } from "@unform/core"
import Select from "../../../../components/Select"
import api from "../../../../services/api"
import { useToast } from "../../../../hooks/toast"
import { useAuth } from "../../../../hooks/auth"
import { getCurrentDate } from "../../../../utils/getCurrentDate"
import { Line, WrapperSelect, TextArea, Button } from "../styles"

interface TipoChamado {
  tipoChamadoId: number
  tipoChamadoNome: string
  categoriaChamado: categoriaChamado[]
}

interface categoriaChamado {
  categoriaChamadoId: number
  categoriaChamadoNome: string
  subCategoria: subCategoria[]
}

interface subCategoria {
  subCategoriaChamadoId: number
  subCategoriaChamadoNome: string
}

const Administrative: React.FC = () => {
  const { userId, fullname } = useAuth()
  const { addToast } = useToast()

  const formRef = useRef<FormHandles>(null)

  const [report, setReport] = useState<string>("")

  const [cpfCnpj, setCpfCnpj] = useState<string>("")

  const [tipo, setTipo] = useState<string>("0")
  const [categoria, setCategoria] = useState<string>("0")
  const [subCategoria, setSubCategoria] = useState<string>("0")

  const [tipoChamadoOptions, setTipoChamadoOptions] = useState<TipoChamado[]>()
  const [categoriaChamadoOptions, setCategoriaChamadoOptions] = useState<
    categoriaChamado[]
  >()
  const [subCategoriaOptions, setSubCategoriaOptions] = useState<
    subCategoria[]
  >()

  useEffect(() => {
    api.get(`/users/${userId}`).then((response) => {
      console.log(response.data.cpfCnpj)
      setCpfCnpj(response.data.cpfCnpj)
    })

    getDadosChamado()
  }, [])

  const getDadosChamado = useCallback(async () => {
    await api
      .post(`/reports/WSDadosChamado/S`, null)
      .then((response) => {
        console.log(response.data.response)
        setTipoChamadoOptions(response.data.response.tipoChamado)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const handleSubmit = useCallback(
    async (data: any) => {
      if (tipo == "0") {
        addToast({
          type: "error",
          title: "Erro na criação de solicitação",
          description: "Informe a área!",
        })
        return
      }

      if (categoria == "0") {
        addToast({
          type: "error",
          title: "Erro na criação de solicitação",
          description: "Informe a categoria!",
        })
        return
      }

      if (subCategoria == "0") {
        addToast({
          type: "error",
          title: "Erro na criação de solicitação",
          description: "Informe a subcategoria!",
        })
        return
      }

      if (report == "") {
        addToast({
          type: "error",
          title: "Erro na criação de solicitação",
          description: "Informe o relato!",
        })
        return
      }

      const payload = {
        ClienteId: parseInt(userId),
        ChamadoClienteCNPJ: cpfCnpj,
        ChamadoPlaca: "",
        ChamadoKM: 0, //parseInt("km"),
        ChamadoTipoId: parseInt(tipo),
        ChamadoCategoriaId: parseInt(categoria),
        ChamadoSubCategoriaId: parseInt(subCategoria),
        ChamadoCondutorCPF: "",
        ChamadoBreveRelato: report,
        ChamadoCNPJOficina: "",
        ChamadoDataAgendamento: "",
        ChamadoIntegracaoId: 0,
        ChamadoResponsavel: fullname,
        ChamadoResponsavelDocumento: "",
        ChamadoResponsavelEmail: "",
        ChamadoResponsavelAgendamentoDDD: 0,
        ChamadoResponsavelAgendamentoTelefone: 0,
        ChamadoFilialCNPJ: "00389481002970" // fixo so para nao dar erro em homologa, sugestao do leo(fleet)
      }


      console.log(payload);

      const url = "http://localhost:5002/api";
      api
        .post(`/reports/PostCallUpdateAsync/`, payload)
        .then((response: any) => {
          if (response.data.response.id == 0) {
            addToast({
              title: "Solicitação criada",
              type: "success",
              description: "Sua solicitação foi criada com sucesso.",
            })
          } else {
            addToast({
              type: "error",
              title: "Erro na criação de solicitação",
              description: response.data.response.erro,
            })
          }
        })
    },
    [userId, cpfCnpj, fullname, report, tipo, categoria, subCategoria]
  )

  const handleTipoChamado = (value: any) => {
    setTipo(value)
    if (value == 0) {
      setCategoriaChamadoOptions([])
      setSubCategoriaOptions([])
      setCategoria("0")
      setSubCategoria("0")
    }
    tipoChamadoOptions?.forEach(function (tp, index) {
      if (tp.tipoChamadoId == value) {
        setCategoriaChamadoOptions(tp.categoriaChamado)
      }
    })
  }

  const handleCategoriaChamado = (value: any) => {
    setCategoria(value)
    if (value == 0) {
      setSubCategoriaOptions([])
      setSubCategoria("0")
    }

    categoriaChamadoOptions?.forEach(function (catCham, index) {
      if (catCham.categoriaChamadoId == value) {
        setSubCategoriaOptions(catCham.subCategoria)
      }
    })
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Line>
        <p>Data de abertura</p>
        <p>{getCurrentDate()}</p>
      </Line>

      <WrapperSelect>
        <p>Área</p>

        <Select
          id="Tipo"
          name="Tipo"
          onChange={(e) => handleTipoChamado(e.target.value)}
          value={tipo}
          style={{ width: "10" }}
        >
          <option value="0">Selecione uma Área</option>
          {tipoChamadoOptions != undefined
            ? tipoChamadoOptions.map((tp: any, i: number) => (
                <option key={i} value={tp.tipoChamadoId}>
                  {tp.tipoChamadoNome}
                </option>
              ))
            : ""}
        </Select>
      </WrapperSelect>
      <br />

      <WrapperSelect>
        <p>Categoria</p>
        <Select
          id="Categoria"
          name="Categoria"
          onChange={(e) => handleCategoriaChamado(e.target.value)}
          value={categoria}
          style={{ width: "10" }}
        >
          <option value="0">Selecione a categoria</option>
          {categoriaChamadoOptions != undefined
            ? categoriaChamadoOptions.map((cat: any, i: number) => (
                <option key={i} value={cat.categoriaChamadoId}>
                  {cat.categoriaChamadoNome}
                </option>
              ))
            : ""}
        </Select>
      </WrapperSelect>
      <br />

      <WrapperSelect>
        <p>SubCategoria</p>
        <Select
          id="SubCategoria"
          name="SubCategoria"
          onChange={(e) => setSubCategoria(e.target.value)}
          value={subCategoria}
          style={{ width: "10" }}
        >
          <option value="0">Selecione a sub categoria</option>
          {subCategoriaOptions != undefined
            ? subCategoriaOptions.map((sub: any, i: number) => (
                <option key={i} value={sub.subCategoriaChamadoId}>
                  {sub.subCategoriaChamadoNome}
                </option>
              ))
            : ""}
        </Select>
      </WrapperSelect>
      <br />

      <Line>
        <p>Breve relato</p>
        <TextArea
          id="relato"
          name="relato"
          value={report}
          onChange={(e) => setReport(e.target.value)}
        ></TextArea>
      </Line>
      <Line>
        <p />
        <Button type="submit">Salvar</Button>
      </Line>
    </Form>
  )
}

export default Administrative
