import styled from 'styled-components';

export const TableContainer = styled.div`
  overflow-x: auto;

  table {
    white-space: normal;

    tr {
      white-space: nowrap;
    }
  }
`;
