import React from 'react';
import styled from 'styled-components';
import { TableCellSortUII } from './types';
import { TableCell } from './styles';

const Container = styled(TableCell)`
  cursor: pointer;
  &:focus {
    background-color: #0606da;
  }
`;

function TableCellSortUI({
  children,
  onClick,
  orderDirection,
}: TableCellSortUII) {
  return (
    <Container onClick={onClick} tabIndex={0}>
      {children} &nbsp;
      <span>{orderDirection === 'asc' ? '↧' : '↥'}</span>
    </Container>
  );
}

export default TableCellSortUI;
