import React, { FC, useCallback, useState, useEffect } from 'react';

import Header from '../../components/Header';
import Title from '../../components/Title';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { fixDate } from '../../utils/stringHelper';
import Pagination from '../../components/Pagination';

import LocationSpin from '../../components/LoadingSpin';
import { useToast } from '../../hooks/toast';

import {
  Container,
  WrapperTable,
  Table,
  Select,
  Form,
  WrapperField,
  WrapperPagination,
} from './styles';
import formatValue from '../../utils/formatValue';
import Button from '../../components/Button';
import MobileFilter from 'components/business/MobileFilter';

type LogisticScheduleParams = {
  veiculoPlaca: string;
  multaAutoDataExpedicao: string;
  infracaoNome: string;
  multaAutoInfracao: string;
  multaAutoLocalMulta: string;
  multaAutoValorAPagar: string;
  condutorNome: string;
  address: string;
  multaAutoDataHora: string;
};

const FinesViolations: FC = () => {
  const [logisticSchedule, setLogisticSchedule] = useState<
    LogisticScheduleParams[]
  >([]);
  const [customersOptions, setCustomersOptions] = useState([]);
  const { userId } = useAuth();
  const [customerId, setCustomerId] = useState('0');

  const [plaque, setPlaque] = useState('');
  const [codigoAuto, setCodigoAuto] = useState('');
  const [conductor, setConductor] = useState('');

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [showClear, setShowClear] = useState(false);
  const { addToast } = useToast();

  useEffect(() => {
    api.get(`/crm/reports/${userId}/customers-cms-lm`).then((response: any) => {
      setCustomersOptions(response.data);
      // if (response.data.length > 0) {
      //   setCustomerId(response.data[0].customerCMSId)
      //   getLogisticsByCustomer(response.data[0].customerCMSId)
      // }

      getLogisticsByCustomer(customerId);
    });
  }, [userId, currentPage]);

  useEffect(() => {
    var isClear =
      plaque != '' || codigoAuto != '' || conductor != '' || customerId != '0';
    setShowClear(isClear);
  }, [plaque, codigoAuto, conductor, customerId]);

  const getLogisticsByCustomer_old = async (customerCMSId: string) => {
    setCustomerId(customerCMSId);

    var vConductor = conductor != '' ? `/${conductor}` : '/false';
    var vPlaque = plaque != '' ? `/${plaque}` : '/false';
    var vCodigoAuto = codigoAuto != '' ? `/${codigoAuto}` : '';
    var param = `${vConductor}/true${vPlaque}${vCodigoAuto}`;

    api
      .get(
        `/reports/calls-traffic-tickets/${customerCMSId}/01-01-0001/01-01-0001${param}`
      )
      .then((response) => {
        const { data } = response;
        if (data.length === 1) {
          setLogisticSchedule(data);
        } else {
          const indexOfLast = currentPage * 15;
          const indexOfFirst = indexOfLast - 15;
          const currentData = data.slice(indexOfFirst, indexOfLast);

          setLogisticSchedule(currentData);
        }
        setTotal(data.length);
      });
  };

  const getLogisticsByCustomer = async (customerCMSId: string) => {
    setCustomerId(customerCMSId);

    // var vConductor = conductor != "" ? `/${conductor}` : "/false"
    // var vPlaque = plaque != "" ? `/${plaque}` : "/false"
    // var vCodigoAuto = codigoAuto != "" ? `/${codigoAuto}` : ""
    //var param = `${vConductor}/true${vPlaque}${vCodigoAuto}`

    var param = {
      exibirTotais: true,
      paginaAtual: currentPage,
      itensPorPagina: 30,
      ordemCampo: '',
      ordemDescendente: true,
      userId: +userId,
      clienteFiltroId: +customerId,
      placa: plaque,
      condutorNome: conductor,
      codigoDoAuto: codigoAuto,
    };

    setLoading(true);
    console.log(param);
    var url = 'http://localhost:5002/api';
    api
      //.get(`/reports/calls-traffic-tickets/${customerCMSId}/01-01-0001/01-01-0001${param}`)
      .post(`/crm/reports/fines-violations_lm`, param)
      .then((response) => {
        const { data } = response;
        console.log(data);
        setLogisticSchedule(data.itens);
        setTotal(data.totalItens);
        setLoading(false);
      })
      .catch((error) => {
        setLogisticSchedule([]);
        setTotal(0);
        console.log(error);
        setLoading(false);
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Erro ao tentar buscar as multas!',
        });
      });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (currentPage == 1) {
      getLogisticsByCustomer(customerId);
    } else {
      setCurrentPage(1);
    }
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleClearFilter = () => {
    setPlaque('');
    setCodigoAuto('');
    setConductor('');
    //setCurrentPage(1)
    setCustomerId('0');
    //getLogisticsByCustomer(customerId)
  };

  const [toggle, setToggle] = useState(false);

  return (
    <>
      <Header />

      <Container>
        <Title
          title={'INFRAÇÕES E MULTAS'}
          subtitle={'Visualize e consulte infrações e multas'}
        />

        <Form onSubmit={handleSubmit}>
          <MobileFilter>
            <WrapperField>
              <label htmlFor="placa">Placa</label>
              <input
                value={plaque}
                name="placa"
                className="name-profile"
                onChange={(e: any) => setPlaque(e.target.value)}
                maxLength={10}
              />
            </WrapperField>
            <WrapperField>
              <label>Código do auto</label>
              <input
                value={codigoAuto}
                name="codigoAuto"
                className="name-profile"
                onChange={(e) => setCodigoAuto(e.target.value)}
                maxLength={20}
              />
            </WrapperField>
            <WrapperField>
              <label>Condutor</label>

              <input
                name="condutor"
                className="name-profile"
                onChange={(e) => setConductor(e.target.value)}
                maxLength={250}
                value={conductor}
              />
            </WrapperField>
            <Select
              name="status-orders"
              onChange={(e) => setCustomerId(e.target.value)}
              value={customerId}
            >
              <option value="0">Selecione uma Empresa</option>
              {customersOptions.map((customer: any, i: number) => (
                <option key={i} value={customer.customerCMSId}>
                  {customer.customerName}
                </option>
              ))}
            </Select>
            <Button type="submit">Buscar</Button>
            {showClear && (
              <Button
                onClick={() => handleClearFilter()}
                className="btn-clear-filter"
              >
                Limpar
              </Button>
            )}
          </MobileFilter>

          <div className="divLoading">
            <LocationSpin loading={loading} />
          </div>
        </Form>

        <WrapperTable>
          <Table>
            <thead>
              <tr>
                <th>Placa</th>
                <th>Data da infração</th>
                <th>Nº Registro Infração</th>
                <th>Nome do condutor</th>
                <th>Descrição</th>
                <th>Local</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              {logisticSchedule.length ? (
                logisticSchedule.map((item) => (
                  <tr>
                    <td>{item.veiculoPlaca}</td>
                    {/* <td>{fixDate(item.multaAutoDataExpedicao)}</td> */}
                    <td>{fixDate(item.multaAutoDataHora)}</td>
                    <td>{item.multaAutoInfracao}</td>
                    <td>{item.condutorNome}</td>
                    <td>{item.infracaoNome}</td>
                    {/* <td>{item.multaAutoLocalMulta}</td> */}
                    <td>{item.address}</td>
                    <td>{formatValue(Number(item.multaAutoValorAPagar))}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6}> Nenhum registro encontrado. </td>
                </tr>
              )}
            </tbody>
          </Table>
        </WrapperTable>
        {total > 0 && (
          <WrapperPagination>
            <Pagination
              paginate={paginate}
              currentPage={currentPage}
              total={total}
            />
          </WrapperPagination>
        )}
      </Container>
    </>
  );
};

export default FinesViolations;
