import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import moment from 'moment';

import Header from '../../components/Header';
import Modal from '../../components/Modal';
import formatValue from '../../utils/formatValue';
import {
  Container,
  LineButtons,
  Table,
  ModalContent,
  CloseModal,
  WrapperButtonModal,
  Form,
  DownloadButton,
  WrapperTable,
  TableContainer,
  CustomModal,
  SchedulingModalContent,
} from './styles';
import clipboardsIcon from '../../assets/icons/clipboards.svg';
import timetableIcon from '../../assets/icons/timetable.svg';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import Title from '../../components/Title';
import iconSearch from '../../assets/icons/icons8-pesquisar.svg';
import { useToast } from '../../hooks/toast';
import Button from '../../components/Button';

import Pagination from '../../components/Pagination';
import LocationSpin from '../../components/LoadingSpin';

import EventSharpIcon from '@material-ui/icons/EventSharp';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import ErrorIcon from '@material-ui/icons/Error';
import { green, yellow, red, orange } from '@material-ui/core/colors';
import MobileFilter from 'components/business/MobileFilter';

interface ValorTotalAprovado {
  itemId: number;
  valorTotalAprovado: number;
  id: number;
}

interface AttachedDocumentI {
  orcamentoId: number;
  documentoId: number;
  nome: string;
  urlEuro: string;
  documento: string;
  linkDocumento: string;
}

const MyRequests: React.FC = () => {
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
  const [statusSolicitationsOptions, setStatusSolicitationsOptions] =
    useState<any>([]);
  const [solicitations, setSolicitations] = useState<any>([]);
  const { userId, contractType } = useAuth();

  const [callId, setCallId] = useState(0);
  const [plaque, setPlaque] = useState('0');
  const [conductor, setConductor] = useState('0');
  const [callStatusId, setCallStatusId] = useState('0');

  const [clientesId, setClientesId] = useState('');
  const [modalBudgetIsVisible, setModalBudgetIsVisible] =
    useState<boolean>(false);
  const [manufactoryName, setManufactureName] = useState('');
  const [orcamentoId, setOrcamentoId] = useState(0);
  const [statusItem, setStatusItem] = useState('');

  const [listSchedule, setListSchedule] = useState<any>([]);
  const [listBugdet, setListBudget] = useState<any>([]);

  const [valorTotal, setValorTotal] = useState(0);
  const [obs, setObs] = useState('');
  const [textoObrigatorio, setTextoObrigatorio] = useState('');
  const [orcamentoChamadoAprovado, setOrcamentoChamadoAprovado] = useState('');
  const { addToast } = useToast();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);

  const [loadingBudget, setLoadingBudget] = useState(false);

  const [modalScheduleIsVisible, setModalScheduleIsVisible] = useState(false);
  const [schedureChamadoId, setSchedureChamadoId] = useState(0);

  const [statusBudget, setStatusBudget] = useState('');

  const [valorTotalAprovado, setValorTotalAprovado] = useState(0);

  const [listValorAprovado, setListValorAprovado] = useState<
    ValorTotalAprovado[]
  >([]);

  const history = useHistory();

  const [checkedState, setCheckedState] = useState(
    new Array(listBugdet.length).fill(false)
  );

  const [checkedAll, setCheckAll] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [textoObrigatorioBudget, SetTextoObrigatorioBudget] = useState('');

  useEffect(() => {
    var url = 'http://localhost:5002/api';
    api
      .get(`/crm/reports/status-call-by-customer_LM/${userId}`)
      .then((response) => {
        setStatusSolicitationsOptions(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    getCallsByCustomer();
  }, []);

  useEffect(() => {
    getCallsByCustomer();
  }, [currentPage]);

  const getCallsByCustomer = () => {
    var data = {
      exibirTotais: true,
      paginaAtual: currentPage,
      itensPorPagina: 30,
      ordemCampo: 'dataOcorrencia',
      ordemDescendente: true,
      clienteId: [+userId],
      placa: plaque != '0' ? plaque : '',
      statusOcorrenciaId: +callStatusId,
      ocorrenciaId: +callId,
    };

    var url = 'http://localhost:5002/api';
    //    api.get(`/reports/calls-by-customer/${userId}/${callId}/${plaque}/${conductor}/${callStatusId}/${false}`)

    setLoading(true);
    api
      .post(`/crm/reports/calls-by-customer_lm/`, data)
      .then((response) => {
        if (response.data.itens.length == 1)
          setSolicitations(response.data.itens);
        else {
          // const indexOfLast = currentPage * 15
          // const indexOfFirst = indexOfLast - 15
          // const currentData = response.data.itens.slice(indexOfFirst, indexOfLast)
          // setSolicitations(currentData)
          setSolicitations(response.data.itens);
        }

        //setTotal(response.data.itens.length)
        setTotal(response.data.totalItens);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleSetCallId = (value: any) => {
    let _value = value != '' ? value : '0';
    //console.log(_value);
    setCallId(_value);
  };

  const handleSetPlaque = (value: any) => {
    var _plaque = value != '' ? value : '0';
    setPlaque(_plaque);
  };

  const handleSetConductor = (value: any) => {
    var _conductor = value != '' ? value : '0';
    setConductor(_conductor);
  };

  const handleSetCallStatusId = (value: any) => {
    setCallStatusId(value);
  };

  // carregar a modal com os itens do agendamento
  const openModalSchedure = useCallback(
    async (item: any) => {
      //setObs("")
      setTextoObrigatorio('');
      //setManufactureName(item.chamadoOficinaNome)
      console.log(item);

      var param = {
        ocorrenciaId: [item.chamadoId],
      };

      var url = 'http://localhost:5002/api';
      api
        .post(`/crm/reports/schedule_my_request_lm`, param)
        .then((response) => {
          console.log(response.data.itens);
          setListSchedule(response.data.itens);

          setModalScheduleIsVisible(true);
          setSchedureChamadoId(item.chamadoId);
        })
        .catch((error) => {
          addToast({
            title: 'Erro',
            type: 'error',
            description: 'Erro ao tentar buscar os agendamentos!',
          });
        });
    },
    [manufactoryName, orcamentoId, valorTotal, clientesId.length]
  );

  const openModalBudget = useCallback(
    async (budget: any) => {
      console.log('openModalBudget');
      setCheckAll(false);
      setObs('');
      setTextoObrigatorio('');
      SetTextoObrigatorioBudget('');
      setValorTotal(0);
      setValorTotalAprovado(0);
      setListValorAprovado([]);
      setManufactureName('');

      var param = {
        orcamentoId: budget.orcamentoId,
      };

      setOrcamentoId(budget.orcamentoId);

      var url = 'http://localhost:5002/api';
      api
        .post(`/crm/reports/budget_itens_lm`, param)
        .then((response) => {
          console.log(response.data);

          if (response.data.itens === null) {
            addToast({
              title: 'Informação',
              type: 'info',
              description: 'Orçamento ainda não disponível!',
            });
            return;
          }

          setCheckedState(new Array(response.data.itens.length).fill(true));
          setListBudget(response.data.itens);

          let isAprovadoReprovado = false;
          if (response.data.itens.length > 0) {
            isAprovadoReprovado = response.data.itens[0].indicadorItemAprRepr;
            console.log(isAprovadoReprovado);

            setManufactureName(response.data.oficina);

            let valorTotal = 0;
            let valorTotalAprovado = 0;
            var newArray: boolean[] = [];
            let qtdeAprovado = 0;
            response.data.itens.map((item: any) => {
              valorTotal += item.valorTotal;
              valorTotalAprovado +=
                item.statusAprovacao === 'Aprovado' ||
                item.statusAprovacao === 'Reprovado'
                  ? item.valorTotal
                  : 0;
              newArray.push(
                item.statusAprovacao === 'Aprovado' ||
                  item.statusAprovacao === 'Reprovado'
                  ? true
                  : true
              );
              qtdeAprovado +=
                item.statusAprovacao === 'Aprovado' ||
                item.statusAprovacao === 'Reprovado'
                  ? 1
                  : 0;
            });

            setCheckedState(newArray);
            setValorTotal(valorTotal);
            setValorTotalAprovado(response?.data?.valorTotalAprovado);
            //setObs(response?.data?.observacaoReprova);
            setIsDisabled(isAprovadoReprovado);

            let statusOrcamento = response.data.status;
            if (isAprovadoReprovado) {
              if (response.data.status == 'Em Aberto')
                statusOrcamento =
                  response.data.valorTotalAprovado > 0
                    ? 'Aprovado pelo Cliente'
                    : 'Reprovado pelo Cliente';

              SetTextoObrigatorioBudget(
                'Não tem itens para aprovar /reprovar!'
              );
              if (response.data.observacaoReprova != null) {
                SetTextoObrigatorioBudget(
                  `Observação: ${response.data.observacaoReprova}`
                );
              }
            }

            setStatusBudget(statusOrcamento);
            setCheckAll(true);
          }

          setModalBudgetIsVisible(true);
        })
        .catch((error) => {
          console.log(error);
          addToast({
            title: 'Erro',
            type: 'error',
            description: 'Erro ao tentar buscar os orçamentos!',
          });
        });
    },
    [
      statusBudget,
      valorTotal,
      valorTotalAprovado,
      listValorAprovado,
      checkedState,
    ]
  );

  // const setValorAprovado_old = useCallback ((budget: any, index: number) => {
  //   console.log(budget);
  //   var item = { id: index, valorTotalAprovado: budget.valorTotal };
  //   var isExiste = false;

  //   if(listValorAprovado.length === 0){
  //     console.log("entrou: " + item.valorTotalAprovado)
  //     setValorTotalAprovado(item.valorTotalAprovado)
  //     listValorAprovado.push(item);
  //   }
  //   else{

  //     var listExist = listValorAprovado.find((it: any) => {
  //       return it.id === index;
  //     })

  //     console.log(listExist)

  //     if(listExist === undefined){
  //       setValorTotalAprovado(valorTotalAprovado + budget.valorTotal)
  //       listValorAprovado.push(item);
  //     }
  //     else{
  //       setValorTotalAprovado(valorTotalAprovado - budget.valorTotal)
  //       // listValorAprovado.splice(1, listExist);
  //     }
  //   }

  //   console.log(listValorAprovado);
  //   console.log(valorTotalAprovado);
  // }, [listValorAprovado, valorTotalAprovado])

  const handleOnChange = useCallback(
    (position: any) => {
      const updatedCheckedState = checkedState.map((item, index) =>
        index === position ? !item : item
      );

      setCheckedState(updatedCheckedState);

      const totalPrice = updatedCheckedState.reduce(
        (sum, currentState, index) => {
          if (currentState === true) {
            //newList.push(listBugdet[index]);
            return sum + listBugdet[index].valorTotal;
          } else setCheckAll(false);

          return sum;
        },
        0
      );
      setValorTotalAprovado(totalPrice);
      // verify if all checkboxes are checked
      if (updatedCheckedState.every((item) => item === true)) {
        setCheckAll(true);
      }
    },
    [listBugdet, checkedState, checkedAll]
  );

  const handleOnChangeAll = useCallback(() => {
    setCheckAll(!checkedAll);
    setCheckedState(new Array(listBugdet.length).fill(!checkedAll));

    let valorTotal = 0;
    if (!checkedAll) {
      console.log('Somar os totais...');
      listBugdet.map((item: any) => {
        valorTotal += item.valorTotal;
      });
    } else {
      var newArray: boolean[] = [];
      listBugdet.map((item: any) => {
        if (item.statusAprovacao === 'Aprovado') {
          valorTotal += item.valorTotal;
          newArray.push(true);
        } else newArray.push(false);
      });

      setCheckedState(newArray);
    }

    setValorTotalAprovado(valorTotal);
  }, [checkedAll, listBugdet]);

  const Aprovar_old = useCallback(async () => {
    if (obs == '') {
      setTextoObrigatorio('Campo Obrigatório');
      return;
    }
    setTextoObrigatorio('Enviar dados, aguarde ...');
    console.log(listValorAprovado);
    //return;

    var data = {
      ClientesDoc: '' + clientesId,
      OrcamentoChamadoId: orcamentoId,
      OrcamentoChamadoObservacaoCliente: obs,
      OrcamentoChamadoAprovadoCliente: 'A',
    };
    //var url = "http://localhost:5002/api"
    api
      .post('/reports/WSOrcamentoChamadoAprovacaoCliente', data)
      .then((response) => {
        console.log(response.data);
        setTextoObrigatorio('');
        if (response.data.response.id != 0) {
          addToast({
            title: 'Erro',
            type: 'error',
            description: response.data.response.erro,
          });
        } else {
          setModalBudgetIsVisible(false);
          addToast({
            title: 'Sucesso',
            type: 'success',
            description: 'Orçamento aprovado com sucesso!',
          });
        }
      })
      .catch((err) => {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Erro ao tentar aprovar o orçamento!',
        });
      });
  }, [obs, orcamentoId, clientesId, listValorAprovado]);

  const AprovarReprovar = useCallback(
    async (type: boolean) => {
      SetTextoObrigatorioBudget('Enviar dados, aguarde ...');
      setLoadingBudget(true);
      //console.log(checkedState);

      var itens: any[] = [];
      checkedState.map((item: any, index: number) => {
        if (
          item === true &&
          listBugdet[index].statusAprovacao != 'Aprovado' &&
          listBugdet[index].statusAprovacao != 'Reprovado'
        ) {
          var it = {
            itemId: listBugdet[index].itemId,
            indAprovado: type,
            observacaoReprova: obs,
          };
          itens.push(it);
        }
      });

      //console.log(type);
      console.log(itens);
      //console.log(listBugdet);
      //return;

      if (itens.length == 0) {
        let sTipo = type ? 'aprovar' : 'reprovar';
        addToast({
          title: 'Atenção',
          type: 'error',
          description: `Não existem itens para ${sTipo}`, //response.data.response.erro,
        });
        setLoadingBudget(false);
        return;
      }

      if (obs == '' && !type) {
        SetTextoObrigatorioBudget('Campo Obrigatório');
        setLoadingBudget(false);
        return;
      }

      var data = {
        orcamentoId: orcamentoId,
        itens: itens,
      };

      console.log(data);

      var url = 'http://localhost:5002/api';
      api
        //.post("/reports/WSOrcamentoChamadoAprovacaoCliente", data)
        .post('/crm/reports/budget_approve_reprove_lm', data)
        .then((response) => {
          var tipo = type ? 'aprovar' : 'reprovar';
          console.log(response.data);
          SetTextoObrigatorioBudget('');
          setLoadingBudget(false);
          if (!response.data.success) {
            var msgErro = `Erro ao tentar ${tipo} o orçamento!`;
            if (response.data.items.OrcamentoId != undefined) {
              msgErro = response.data.items.OrcamentoId[0];
            } else if (response.data.items.ItemId != undefined) {
              msgErro = response.data.items.ItemId[0];
            }
            addToast({
              title: 'Atenção',
              type: 'error',
              description: msgErro, //response.data.response.erro,
            });
          } else {
            setModalBudgetIsVisible(false);
            // Atualiza, após aprovação ou reprovação do orçamento,...
            // 1- a lista de solicitações
            getCallsByCustomer();
            // 2- a lista de orçamento
            api
              .post(`/crm/reports/schedule_my_request_lm`, {
                ocorrenciaId: [schedureChamadoId],
              })
              .then((response) => {
                setListSchedule(response.data.itens);
              }); // não usa o catch porque sobreescreve a mensagem de sucesso ao aprovar ou reprovar
            addToast({
              title: 'Sucesso',
              type: 'success',
              description: `Orçamento ${
                tipo === 'aprovar' ? 'aprovado' : 'reprovado'
              } com sucesso!`,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          addToast({
            title: 'Erro',
            type: 'error',
            description: 'Erro ao tentar aprovar o orçamento!',
          });
        });
    },
    [obs, orcamentoId, clientesId, listValorAprovado, checkedState]
  );

  const Reprovar = useCallback(() => {
    history.push(`/new-request/administrativo`);
  }, []);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setCurrentPage(1);
    getCallsByCustomer();
  };

  const handleClearFilter = () => {
    setPlaque('');
    setCallId(0);
    setCallStatusId('');
    setCurrentPage(1);
    getCallsByCustomer();
  };

  // não exibe valor para quando o formato da data é inválido (null, '',...)
  const parseValidDate = (value: string) => {
    return moment(value).isValid() && moment(value).format('DD/MM/yyyy HH:mm');
  };

  const handleAttachment = () => {
    api
      .get(`/crm/reports/GetAttachedDocuments_LMAsync/${orcamentoId}`)
      .then((response) => {
        response.data.itens.forEach((attachment: AttachedDocumentI) => {
          downloadFile(
            // 'https://get.geojs.io/v1/ip/geo.json',
            // 'teste.json'
            attachment.linkDocumento,
            attachment.nome
          );
        });
      });
  };

  const downloadFile = (url: string, filename: string) => {
    fetch(url).then((response) => {
      if (response.status !== 200) {
        throw new Error(
          `Não foi possível baixar o arquivo. HTTP status: ${response.status}`
        );
      }

      response.blob().then((blob) => {
        const downloadLink = document.createElement('a');

        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        URL.revokeObjectURL(downloadLink.href);
        document.body.removeChild(downloadLink);
      });
    });
  };

  return (
    <>
      <Header />
      <Container>
        <Title
          title={'MINHAS SOLICITAÇÕES'}
          subtitle={'Visualize e realize novas solicitações'}
        />

        <Form onSubmit={handleSubmit}>
          <LineButtons>
            <button onClick={() => setModalIsVisible(!modalIsVisible)}>
              Criar Nova Solicitação
            </button>
          </LineButtons>

          <div style={{ width: '100%' }}>
            <MobileFilter>
              <LineButtons>
                <div>
                  <label>Id Chamado</label>
                  <input
                    name="callId"
                    className="name-profile"
                    onChange={(e) => handleSetCallId(e.target.value)}
                  />
                </div>

                <div>
                  <label>Placa</label>
                  <input
                    name="placa"
                    className="name-profile"
                    onChange={(e) => handleSetPlaque(e.target.value)}
                  />
                </div>

                <div>
                  <label>Status</label>
                  <select
                    id="callStatusId"
                    name="callStatusId"
                    onChange={(e) => handleSetCallStatusId(e.target.value)}
                    value={callStatusId}
                  >
                    <option value="0">Status</option>
                    {statusSolicitationsOptions
                      ? statusSolicitationsOptions.map(
                          (customer: any, i: number) => (
                            <option key={i} value={customer.chamadoStatusId}>
                              {customer.chamadoStatusNome}
                            </option>
                          )
                        )
                      : ''}
                  </select>
                </div>
                <Button type="submit">Buscar</Button>
              </LineButtons>
              <LineButtons>
                <LocationSpin loading={loading} />
              </LineButtons>
            </MobileFilter>
          </div>
        </Form>
        <WrapperTable>
          <Table>
            <thead>
              <tr>
                <th>Agendamento</th>
                <th>Id</th>
                <th>Data de abertura</th>
                <th>Cliente</th>
                <th>Condutor</th>
                <th>Placa</th>
                <th>Tipo</th>

                {/* <th>SubCategoria</th>
              <th>Oficina</th> */}

                <th>Status</th>
              </tr>
            </thead>
            {solicitations.length !== 0 ? (
              <tbody>
                {solicitations.map((item: any, i: number) => (
                  <tr key={i}>
                    <td>
                      {/* <img
                      src={iconSearch}
                      style={{
                        cursor: "pointer",
                        display: item.qtdeCobraCliente == 0 ? "none" : "",
                      }}
                      title="Abrir orçamento"
                      onClick={() => openModalBudget(item)}
                    /> */}
                      {item.indicadorPossuiAgendamento > 0 ? (
                        <a>
                          <EventSharpIcon
                            color="action"
                            onClick={() => openModalSchedure(item)}
                          />
                        </a>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>{item.chamadoId}</td>
                    <td>{moment(item.dataAbertura).format('DD/MM/YYYY')}</td>
                    <td>{item.clientesNome}</td>
                    <td>{item.condutorNome}</td>
                    <td>{item.veiculoPlaca}</td>
                    <td>{item.tipoChamadoNome}</td>

                    {/* <td>{item.subCategoriaChamadoNome}</td>
                  <td>{item.chamadoOficinaNome}</td> */}

                    <td>{item.statusChamadoNome}</td>
                    <td>
                      {item.indicadorOrcamentoAguardandoAprovacao === true ? (
                        <AttachMoneyIcon
                          fontSize="small"
                          style={{ color: orange[500] }}
                        />
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tr>
                <td colSpan={8}>
                  <p>Não existem solicitações</p>
                </td>
              </tr>
            )}
          </Table>
        </WrapperTable>

        <Pagination
          paginate={paginate}
          currentPage={currentPage}
          total={total}
        />
      </Container>

      <Modal isVisible={modalIsVisible}>
        <ModalContent>
          {/* <CloseModal onClick={() => setModalIsVisible(false)}></CloseModal> */}
          <a>
            <CancelPresentationIcon
              className="close"
              color="primary"
              onClick={() => setModalIsVisible(false)}
            />
          </a>

          <h2>Abrir nova solicitação</h2>
          <p>Escolha o tipo de solicitação que deseja abrir abaixo:</p>
          <div>
            <Link to="/new-request/agendamento">
              <button>
                <WrapperButtonModal>
                  <img src={timetableIcon} alt="icone calendário" />
                  <h3>Para seu veículo</h3>
                </WrapperButtonModal>
              </button>
            </Link>
            {/* <Link to="/new-request/administrativo"> */}
            {/* <a>
              <a className="button-disabled">
                <WrapperButtonModal>
                  <img src={clipboardsIcon} alt="icone prancheta" />
                  <h3 className="h3">Para seu contrato</h3>
                </WrapperButtonModal>
              </a>
            </a> */}
            {/* </Link> */}
          </div>
        </ModalContent>
      </Modal>

      <CustomModal isVisible={modalScheduleIsVisible}>
        <SchedulingModalContent>
          <a>
            <CancelPresentationIcon
              className="close"
              color="primary"
              onClick={() => setModalScheduleIsVisible(false)}
            />
          </a>

          {/* <CloseModal
            onClick={() => setModalScheduleIsVisible(false)}
          ></CloseModal> */}
          <div style={{ paddingTop: '30px' }}>
            <p>
              <b>Agendamentos</b>
              {/* {orcamentoId} */}
            </p>
            {/* <p>
              <b>Oficina: </b> {manufactoryName}
            </p> */}
          </div>
          <TableContainer>
            <table style={{ width: '100%' }} className="tabBudget">
              <thead>
                <tr>
                  {/* <th>Item</th> */}
                  {contractType == 'PJ' && <th>Orçamento</th>}
                  <th>Data/Hora do Agendamento</th>
                  <th>Oficina</th>
                  <th>Previsão de Conclusão</th>
                  <th>Data de Conclusão do Serviço</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {listSchedule.length ? (
                  listSchedule.map((budget: any, i: number) => (
                    <tr key={i} className="trBudget">
                      {contractType == 'PJ' && (
                        <td>
                          {budget.orcamentoId > 0 ? (
                            <a>
                              <AttachMoneyIcon
                                color="primary"
                                fontSize="small"
                                onClick={() => openModalBudget(budget)}
                              />
                            </a>
                          ) : (
                            ''
                          )}
                        </td>
                      )}
                      <td>{parseValidDate(budget.dataAgendamento)}</td>
                      <td>{budget.oficina} </td>
                      <td>{parseValidDate(budget.previsaoEntrega)}</td>
                      <td>{parseValidDate(budget.dataConclusao)}</td>
                      <td>
                        {budget.indicadorPendenteAprovacao === true ? (
                          <ErrorIcon style={{ color: orange[500] }} />
                        ) : (
                          ''
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="tdNoDate">
                      Nenhum item encontrado!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </TableContainer>

          {/* <div style={{ padding: "10px" }}>
            <p>
              <b>Valor Total:</b> {formatValue(valorTotal)}
            </p>
          </div> */}
          <div>
            <p className="txtObrigado">{textoObrigatorio}</p>
          </div>
          {/* <div style={{ marginBottom: "10px", display: valorTotal == 0 ? "" : "" }}>
            <p>
              <textarea
                cols={50}
                rows={3}
                value={obs}
                onChange={(e) => setObs(e.target.value)}
                className={
                  textoObrigatorio != "" ? "campoObrigatorio" : "textArea"
                }
              ></textarea>
            </p>
          </div> */}
          {/* {orcamentoChamadoAprovado == 'P' ? 'mostrar' : 'nao mostrar'} */}
          {/* <div
            style={{ display: orcamentoChamadoAprovado == "P" ? "" : "none" }}
          >
            <button
              type="button"
              className="bottomBudget"
              onClick={() => Aprovar()}
            >
              {" "}
              Aprova
            </button>
            <button
              type="button"
              className="bottomBudget"
              onClick={() => Reprovar()}
            >
              Reprovar
            </button>
          </div> */}
        </SchedulingModalContent>
      </CustomModal>

      <Modal isVisible={modalBudgetIsVisible}>
        <ModalContent>
          <a>
            <CancelPresentationIcon
              className="close"
              color="primary"
              onClick={() => setModalBudgetIsVisible(false)}
            />
          </a>

          {/* <CloseModal
            onClick={() => setModalBudgetIsVisible(false)}
          ></CloseModal> */}
          <div style={{ paddingTop: '30px' }} className="theadBudget">
            <p>
              <b>Orçamento:</b>
              <br />
              {orcamentoId}
            </p>
            <p>
              <b>oficina: </b>
              <br />
              {manufactoryName}
            </p>
            <p>
              <b>Status:</b>
              <br />
              {statusBudget}
            </p>
          </div>
          <div>
            <table style={{ width: '100%' }} className="tabBudget">
              <thead>
                <tr>
                  <th>Peça/Serviço</th>
                  <th>Descrição</th>
                  <th>Qtde</th>
                  <th>Valor unitário</th>
                  <th>Valor Total</th>
                  <th style={{ display: 'none' }}>Status</th>
                  <th style={{ display: 'none' }}>
                    <input
                      type="checkbox"
                      checked={checkedAll}
                      onChange={handleOnChangeAll}
                      disabled={isDisabled}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {listBugdet.length ? (
                  listBugdet.map((budget: any, i: number) => (
                    <tr
                      key={i}
                      className="trBudget"
                      // style={{
                      //   display: budget.cobraCliente == true ? "" : "none",
                      // }}
                    >
                      <td>{budget.peca}</td>
                      <td>{budget.descricao + budget.itemId}</td>
                      <td>{budget.quantidade} </td>
                      <td>{formatValue(budget.valorUnitario)}</td>
                      <td> {formatValue(budget.valorTotal)}</td>
                      <td style={{ display: 'none' }}>
                        {budget.statusAprovacao}
                      </td>
                      <td style={{ display: 'none' }}>
                        <input
                          type="checkbox"
                          checked={checkedState[i]}
                          onChange={() => handleOnChange(i)}
                          disabled={
                            budget.statusAprovacao === 'Aprovado' ||
                            budget.statusAprovacao === 'Reprovado'
                              ? true
                              : false
                          }
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="tdNoDate">
                      Nenhum item encontrado!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="divValorTotal">
            <p className="pValorTotal">
              <b>Valor Total:</b> {formatValue(valorTotal)}
            </p>
          </div>
          <div>
            <p className="pValorTotal">
              <b>Valor Total Aprovado:</b> {formatValue(valorTotalAprovado)}
            </p>
          </div>

          <DownloadButton onClick={handleAttachment}>
            Baixar anexos
          </DownloadButton>

          <div>
            <p className="txtObrigado">
              {textoObrigatorioBudget}&nbsp;&nbsp;
              <LocationSpin loading={loadingBudget} />
            </p>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <p style={{ display: isDisabled == true ? 'none' : '' }}>
              <textarea
                cols={50}
                rows={3}
                value={obs}
                onChange={(e) => setObs(e.target.value)}
                className={
                  textoObrigatorioBudget != '' ? 'campoObrigatorio' : 'textArea'
                }
                disabled={isDisabled}
              ></textarea>
            </p>
          </div>

          <div style={{ display: isDisabled ? 'none' : '' }}>
            <button
              type="button"
              className={'bottomBudget'}
              onClick={() => AprovarReprovar(true)}
              disabled={isDisabled}
            >
              {' '}
              Aprovar
            </button>
            <button
              type="button"
              className={'bottomBudget'}
              onClick={() => AprovarReprovar(false)}
              disabled={isDisabled}
            >
              Reprovar
            </button>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MyRequests;
