import React from 'react';
import InputFieldUI from 'components/ui/InputFieldUI';
import useFetchRoles from '../hooks/useFetchRoles';

type Props = {
  error?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

function RoleField(props: Props) {
  const { roles } = useFetchRoles();

  const renderOptions = () => (
    <>
      <option value="0">Selecione</option>;
      {roles.map((role) => (
        <option key={role.roleId} value={role.roleId}>
          {role.name}
        </option>
      ))}
    </>
  );

  return (
    <InputFieldUI
      error={!!props.error}
      errorText={props.error}
      field="select"
      label="Perfil"
      name="roleId"
      value={props.value}
      onChange={props.onChange}
    >
      {renderOptions()}
    </InputFieldUI>
  );
}

export default RoleField;
