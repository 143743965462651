import React from 'react';
import { getSearchCustomerApi } from '../utils/services';
import { CustomerT } from '../utils/types';

function useSearchCustomers() {
  const [data, setData] = React.useState<CustomerT[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [term, setTerm] = React.useState('');

  React.useEffect(() => {
    let active = true;

    const fetchCustomers = () => {
      setLoading(true);
      getSearchCustomerApi(term)
        .then((data) => {
          if (active) setData(data);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    };

    const debounce = setTimeout(() => {
      if (term === '') setData([]);
      if (term.length >= 3) fetchCustomers();
    }, 500);

    return () => {
      active = false;
      clearTimeout(debounce);
    };
  }, [term]);

  return { data, loading, setTerm };
}

export default useSearchCustomers;
