import React from 'react';
import styled from 'styled-components';

import InputFieldUI from 'components/ui/InputFieldUI';

type Props = {
  finalLabel?: string;
  finalValue: string;
  initialLabel?: string;
  initialValue: string;
  max?: string;
  min?: string;
  style?: React.CSSProperties;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Container = styled.div`
  display: flex;
  gap: 8px;
`;

function Period({
  initialLabel = 'Data Inicial',
  finalLabel = 'Data Final',
  ...props
}: Props) {
  return (
    <Container style={props.style}>
      <InputFieldUI
        field="date"
        label={initialLabel}
        max={props.finalValue}
        min={props.min}
        name="initialDate"
        value={props.initialValue}
        onChange={props.onChange}
      />
      <InputFieldUI
        field="date"
        label={finalLabel}
        max={props.max}
        min={props.initialValue}
        name="finalDate"
        value={props.finalValue}
        onChange={props.onChange}
      />
    </Container>
  );
}

export default Period;
