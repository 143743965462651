import React, { useState } from "react"
import { Pagination as PaginationAntd } from "antd"

import { Container } from "./styles"

interface IPagination {
  total: number
  paginate: any
  currentPage: number
  perPage?: number
}

const Pagination = ({ paginate, total, currentPage, perPage = 30 }: IPagination) => {
  const pageNumbers = []

  for (let i = 1; i <= Math.ceil(total / perPage); i++) {
    pageNumbers.push(i)
  }

  const handlePaginate = (item: number) => {
    paginate(item)
  }

  return (
    <Container>
      <PaginationAntd
        current={currentPage}
        total={total}
        onChange={(e) => handlePaginate(e)}
        defaultPageSize={perPage}
        showSizeChanger={false}
      />
    </Container>
  )
}

export default Pagination
