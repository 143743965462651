import styled from "styled-components"

export const ModalFade = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  display: none;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  &.is-visible{
    display: flex;
  }
`
export const ModalContainer = styled.div`
  background-color: white;
  min-height: 340px;
  max-width: 550px;
  width: 90%;
`
