import styled from "styled-components"

export const Container = styled.div`
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;

  color: #3c505a;
`

export const WrapperTable = styled.div`
  width: 80%;
  margin: 40px 0px 200px 0px;

  label {
    font: normal normal bold 14px/19px "Open Sans";
  }

  .pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    button {
      margin: 2px;
    }

    .btn-page {
      width: 27px;
      height: 27px;
      border: 1px solid #e8e8e8;
      color: #E8E8E8;
      font: normal normal bold 11px/15px Open Sans;
      background-color: white;

      &.active{
        background: #0c5faf 0% 0% no-repeat padding-box;
        color: #fff;
      }
    }

    .btn-previous {
      width: 59px;
      height: 27px;
      font: normal normal normal 11px/15px "Open Sans";
      border: 1px solid #e8e8e8;

      &.disabled{
        cursor: default;
        color: #b1afaf;
      }
    }

    .btn-next {
      width: 59px;
      height: 27px;
      font: normal normal normal 11px/15px "Open Sans";
      border: 1px solid #e8e8e8;

      &.disabled{
        cursor: default;
        color: #b1afaf;
      }
    }
  }

  .btn-new-profile {
    float: right;
    margin-bottom: 11px;
    border: 2px solid #226fbb;
    color: #fff;
    background: #236fbc;
    border-radius: 24px;
    width: 155px;
    height: 39px;
    font-size: 14px;
    font-weight: 500;
  }
`

export const Table = styled.table`
  width: 100%;
  text-align: left;
  border-spacing: inherit;
  margin: 15px 0px 15px 0px;

  tbody tr:nth-child(odd) {
    background: #f9f9f9;
  }

  button {
    border: none;
    background: transparent;
    margin: 5px;
  }

  thead {
    border-radius: 18px;
  }

  tr {
    font-size: 15px;
  }

  td {
    padding: 8px;
  }

  th {
    font-weight: normal;
    background: #3c505a;
    color: #fff;
    height: 36px;
    padding: 8px;
    &:first-child {
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
    }

    &:last-child {
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
    }
  }

  .large {
    width: 70%;
  }

  .wrapper-status {
    display: flex;
    align-items: center;
  }

  .status {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
  }
`
export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 20px 0 0 0;
    font-size: 20px;
    color: #3c505a;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    button {
      width: 78px;
      border-radius: 6px;
      box-shadow: 0px 3px 6px #00000029;
      background: #0c5faf;
      margin: 10px;
      color: white;
    }
  }

`;
