import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import { getTheme } from 'pages/LandingPages/utils/helpers';

const StyledButton = styled.button<{ bgColor: string; $radius: number }>`
  background-color: ${(props) => props.bgColor};
  border: none;
  border-radius: ${(props) => props.$radius}px;
  display: flex;
  height: 56px;
  padding: 16px 32px;
  align-items: center;
  align-self: stretch;

  span {
    flex: 1 0 0;
    color: #fff;
    text-align: center;
    font-family: 'Audi Type Screen';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
`;

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: string;
}

function Button({ children, color, ...props }: Props) {
  const theme = getTheme();
  return (
    <StyledButton
      bgColor={color || theme.secondButtonBgcolor}
      $radius={theme.radius}
      {...props}
    >
      <span>{children}</span>
    </StyledButton>
  );
}

export default Button;
