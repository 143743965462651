import React from 'react';

import TableUI from 'components/ui/TableUI';
import Pagination from 'components/Pagination';
import { TableContainer } from 'components/ui/TablePaginationUI/styles';
import { usePaginateData } from 'components/ui/TablePaginationUI/hooks';
import { TablePaginationProps } from 'components/ui/TablePaginationUI/types';

function TablePaginationUI({
  columns,
  data,
  loading,
  noData,
  page,
  onPaginate,
  itemsPerPage,
  total,
}: TablePaginationProps) {
  const pageData = usePaginateData({ data, itemsPerPage, page });

  return (
    <>
      <TableContainer>
        <TableUI
          columns={columns}
          data={pageData}
          loading={loading}
          noData={noData}
        />
      </TableContainer>

      {!loading && (
        <Pagination
          currentPage={page}
          perPage={itemsPerPage}
          total={total}
          paginate={onPaginate}
        />
      )}
    </>
  );
}

export default TablePaginationUI;
