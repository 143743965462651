import styled from 'styled-components';
import { MESSAGE_COLORS } from './utils';
import { MessageTypesT } from './types';

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.h2`
  color: #0c5faf;
`;

export const SaveButton = styled.button`
  background: #0161af;
  border: 0;
  border-radius: 25px;
  color: white;
  font-size: 15px;
  font-weight: 500;
  height: 30px;
  margin: 25px auto;
  padding: 0 16px;
  transition: background-color 0.2s;
  width: 200px;
`;

export const Search = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  justify-items: end;
  width: 100%;

  .Search-title {
    padding-right: 10px;
  }

  .Search-input {
    border: 1px solid #0c5faf;
    border-radius: 25px;
    padding-left: 10px;
    width: 350px;
  }

  .Search-button {
    padding-top: 15px;
  }
`;

export const Message = styled.p<{ type: MessageTypesT }>`
  color: ${(props) => MESSAGE_COLORS[props.type]};
  margin: 0px;
  padding: 10px 0px 0px 0px;
`;

export const ListTitle = styled.h4`
  color: #3c505a;
  font-size: 14px;
  font-weight: 300;
`;

export const WrapperModal = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  p {
    width: 100%;
    color: #3c505a;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0.2em;
  }

  span {
    font-size: 12px;
    color: #3c505a;
    width: 25%;
    margin-top: 5px;
  }

  div {
    margin-top: 25px;
    span {
      width: 100%;
      margin-top: 10px;
    }
  }
`;
