import React from 'react';
import styled from 'styled-components';

import InputContainerUI from '../InputUI/InputContainerUI';
import InputLabelUI from '../InputLabelUI';

const StyledInputContainer = styled(InputContainerUI)`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-right: 24px;
  .InputContainer-error {
    right: 0;
  }
`;
const StyledCheckboxContainer = styled.span`
  display: inline-flex;
  padding: 9px;
`;
const StyledCheckbox = styled.input`
  cursor: pointer;
  height: 18px;
  width: 18px;
`;
//extende a funcionalidade da label
const StyledInputLabel = styled(InputLabelUI)`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

interface CheckboxUIProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  error?: boolean;
  errorText?: string;
  label: string;
}

function CheckboxUI({ errorText, label, ...props }: CheckboxUIProps) {
  return (
    <StyledInputContainer errorText={errorText}>
      <StyledInputLabel error={props.error} id={props.id}>
        <StyledCheckboxContainer>
          <StyledCheckbox type="checkbox" {...props} />
        </StyledCheckboxContainer>
        {label}
      </StyledInputLabel>
    </StyledInputContainer>
  );
}

export default CheckboxUI;
