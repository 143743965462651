import React, { SelectHTMLAttributes, useEffect, useState } from "react"

import { Container, Error, WrapperSelect } from "./styles"
import { useField } from "@unform/core"
import { FiAlertCircle } from "react-icons/fi"

import Select from "../Select"
import LocationSpin from '../LoadingSpin';
import api from "services/api"

type SelectProps = SelectHTMLAttributes<HTMLSelectElement>

type Props = {
  userId: string;
  clienteId: number | null;
  id: string,
  func: Function,
}

interface Plaques {
  veiculoPlaca: string
  veiculoId: number
}

const SelectVehicle = ({userId, clienteId, id, func}: Props) => {

  const [plaques, setPlaques] = useState<Plaques[]>()
  const [loadingSkin, setLoadingSkin] = useState(false);
  const [vehicleId, setPlaqueId] = useState("0");

  useEffect(() => {
    setLoadingSkin(true);
    var param = {
      "userId": +userId,
      "clienteId": clienteId == 0 ? null : clienteId
    }
    console.log(param);
    var url = "http://localhost:5002/api";
    api
    .post(`/crm/reports/vehicle_plaque-by-customer_LM`, param)
    .then((response) => {
      //console.log(response.data);
      setPlaques(response.data)
      setLoadingSkin(false);
    })
    .catch(error => {
      setLoadingSkin(false);
    })
  }, [clienteId]);

  return (
    <Container>
        <WrapperSelect>
          {/* <p>Placa</p> */}

          <select
            id="plaqueId"
            name="plaqueId"
            onChange={(e) => func(e.target.value)}
            value={id}
            style={{ width: "10" }}
          >
            <option value="0">Selecione uma Placa</option>
            {plaques != undefined
              ? plaques.map((plaque: any, i: number) => (
                  <option key={i} value={plaque.veiculoId}>
                    {plaque.veiculoPlaca}
                  </option>
                ))
              : ""}
          </select>

          <div className="loadingDataVehicle">
            <LocationSpin loading={loadingSkin} />
          </div>

        </WrapperSelect>
    </Container>
  )
}

export default SelectVehicle
