import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import InputUI from '../InputUI';

const StyledInput = styled(InputUI)`
  cursor: text;
  &::-webkit-calendar-picker-indicator {
    color: red;
    cursor: pointer;
  }
`;

function DatePickerUI(
  props: Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>
) {
  return <StyledInput {...props} type="date" />;
}

export default DatePickerUI;
