import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  button {
    width: 24px;
    background: #fff;
    border: 1px solid grey;
    margin: 2px;
    font-size: 12px;
    color: #b1afaf;
  }

  p {
    color: #767676;
    font-weight: 500;
    margin-right: 5px;
  }

  button:focus {
    background: #0c5faf;
    color: #fff;
  }
`;
