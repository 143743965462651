import { Spin } from 'antd';
import React, { FC } from 'react';

const LoadingSpin: FC<{ loading: boolean }> = ({ loading }) => (
  <>
    <Spin spinning={loading} />
  </>
)

export default LoadingSpin
