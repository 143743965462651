import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Select from '../../../../components/Select';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';
import { useAuth } from '../../../../hooks/auth';
import { getCurrentDate } from '../../../../utils/getCurrentDate';
import Input from '../../../../components/Input';
import getValidationErrors from '../../../../utils/getValidationErrors';

import InputMask from 'react-input-mask';

import LocationSpin from '../../../../components/LoadingSpin';

import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import PageviewTwoToneIcon from '@material-ui/icons/PageviewTwoTone';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';

import Modal from '../../../../components/Modal';

import {
  Line,
  WrapperSelect,
  TextArea,
  Button,
  DoubleLine,
  InputMaskStyle,
  LoadingSpinDataProvider,
  SelectC,
  ModalContent,
} from '../styles';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import moment from 'moment';
import SelectVehicle from 'components/selectVehicle';
import { AxiosError } from 'axios';

interface CallRequest {
  ChamadoId: number;
  ChamadoClienteCNPJ: string;
  ChamadoPlaca: string;
  ChamadoKM: number;
  ChamadoTipoId: number;
  ChamadoCategoriaId: number;
  ChamadoSubCategoriaId: number;
  ChamadoCondutorCPF: string;
  ChamadoBreveRelato: string;
  ChamadoCNPJOficina: string;
  ChamadoDataAgendamento: string;
  ChamadoIntegracaoId: number;
  ChamadoResponsavel: string;
  ChamadoResponsavelDocumento: string;
  ChamadoResponsavelEmail: string;
  ChamadoResponsavelAgendamentoDDD: number;
  ChamadoResponsavelAgendamentoTelefone: number;
  ChamadoFilialCNPJ: string;
}

interface CallLMRequest {
  entityVersion: string;
  veiculoId: number;
  tipoOcorrenciaId: number;
  kmAtual: number;
  cidadeId: number;
  breveRelato: string;
  responsavel: string;
  responsavelEmail: string;
  responsavelTelefone: string;
  fornecedorId: number;
  dataAgendaOficina: string;
}

interface Provider {
  id: number;
  clientesid: string;
  nome: string;
  razaosocial: string;
  doc: string;
  tipo: string;
  cep: string;
  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  uFsCodigo: string;
  site: string;
  classificacaoServicoNome: string;
}

interface Plaques {
  veiculoPlaca: string;
  veiculoId: number;
}

interface UFOptions {
  codigo: string;
  nome: string;
}

interface TipoChamado {
  tipoChamadoId: number;
  tipoChamadoNome: string;
  categoriaChamado: categoriaChamado[];
}

interface categoriaChamado {
  categoriaChamadoId: number;
  categoriaChamadoNome: string;
  subCategoria: subCategoria[];
}

interface subCategoria {
  subCategoriaChamadoId: number;
  subCategoriaChamadoNome: string;
}
interface CityOptions {
  cidade: string;
  cidadeId: number;
}

interface Customer {
  customerCMSId: number;
  customerName: string;
}

interface Conductor {
  condutorId: number;
  condutorNome: string;
}

const Scheduling: React.FC = () => {
  const history = useHistory();
  const { userId, contractType } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [providerOptions, setProviderOptions] = useState<Provider[]>();
  const [plaques, setPlaques] = useState<Plaques[]>();

  const [ufOptions, setUFOptions] = useState<UFOptions[]>();
  const [uf, setUF] = useState<string>('0');

  const [cityOptions, setCityOptions] = useState<CityOptions[]>();
  const [city, setCity] = useState<string>('0');

  const [provider, setProvider] = useState<number>(0);

  const [report, setReport] = useState<string>('');
  const [km, setKm] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [document, setDocument] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [ddd, setDdd] = useState<number>(0);
  const [phoneNumber, setPhoneNumber] = useState<number>(0);
  const [carModel, setCarModel] = useState<string>('');
  const [renavam, setRenavam] = useState<string>('');
  const [condutorCpf, setCondutorCpf] = useState<string>('');
  const [chassi, setChassi] = useState<string>('');

  const [plaqueId, setPlaqueId] = useState<string>('0');

  const [date, setDate] = useState<string>('');
  const [hora, setHora] = useState<string>('');

  const [cpfCnpj, setCpfCnpj] = useState<string>('');

  const [tipo, setTipo] = useState<string>('0');
  const [categoria, setCategoria] = useState<string>('0');
  const [subCategoria, setSubCategoria] = useState<string>('0');

  const [responsavel, setResponsavel] = useState<string>('');
  const [emailResponsavel, setEmailResponsavel] = useState<string>('');
  const [telefoneResponsavel, setTelefoneResponsavel] = useState<string>('');

  const [oficina, setOficina] = useState<Provider>();

  const [tiposChamados, setTiposChamados] = useState<TipoChamado[]>();
  const [categoriaChamadoOptions, setCategoriaChamadoOptions] =
    useState<categoriaChamado[]>();
  const [subCategoriaOptions, setSubCategoriaOptions] =
    useState<subCategoria[]>();

  const [odometer, setOdometer] = useState('');

  const [loadingSkinCity, setLoadingSkinCity] = useState(false);
  const [loadingSkinProvider, setLoadingSkinProvider] = useState(false);
  const [loadingSkinDataProvider, setLoadingSkinDataProvider] = useState(false);
  const [loadingSkinSave, setLoadingSkinSave] = useState(false);

  const [customersOptions, setCustomersOptions] = useState<Customer[]>([]);
  const [clienteId, setClienteId] = useState('');

  const [conductor, setConductor] = useState('');

  const [conductorItem, setConductorItem] = useState<Conductor>();

  const [open, setOpen] = useState(false);
  const [conductorOptions, setConductorOptions] = useState<Conductor[]>([]);
  const [loadingSkinSearchConductor, setLoadingSkinSearchConductor] =
    useState(false);

  const [loadingSkinSearchPlaque, setLoadingSkinSearchPlaque] = useState(false);
  const [showInputs, setShowInputs] = useState(false);

  const tipoChamadosOptions =
    contractType == 'PJ'
      ? tiposChamados
      : tiposChamados?.filter((item) => item.tipoChamadoId === 8);

  useEffect(() => {
    api.get(`/users/${userId}`).then((response) => {
      //console.log(response.data.cpfCnpj)
      setCpfCnpj(response.data.cpfCnpj);
    });
  }, []);

  useEffect(() => {
    api.get(`/crm/reports/${userId}/customers-cms-lm`).then((response) => {
      setCustomersOptions(response.data);
    });
  }, []);

  useEffect(() => {
    getUFsCalls();

    // chamada para carrega o combo de tipochamado, categoriachamado e subcategoria
    getDadosChamado();
  }, []);

  const getVehicleByPlaque = useCallback(
    async (plaqueId: string) => {
      setRenavam('');
      setCarModel('');
      setCondutorCpf('');
      setChassi('');
      setPlaqueId(plaqueId);
      setKm('');
      if (plaqueId === '0') {
        return;
      }

      api
        .get(
          `/crm/reports/vehicle_by_plaque-by-customer_LM/${userId}/${plaqueId}`,
          { headers: { 'x-opennewrequest-scheduling': true } }
        )
        .then((response) => {
          setRenavam(response.data[0].veiculoRenavam);
          setCarModel(response.data[0].modeloNome);
          setCondutorCpf(response.data[0].condutorCPF);
          setChassi(response.data[0].veiculoChassi);
          setKm(response.data[0].kmAtual);
          setShowInputs(true);
        })
        .catch((error) => {
          const axiosError = error as AxiosError;
          if (axiosError?.response?.status === 400) {
            setShowInputs(false);
            addToast({
              type: 'error',
              title: 'Canal de atendimento',
              description: axiosError?.response?.data,
            });
          }
        });
    },
    [userId, plaqueId]
  );

  const getUFsCalls = useCallback(async () => {
    var url = 'http://localhost:5002/api';
    await //api.get(`/reports/getUFsCalls`)
    api
      .get(`/crm/reports/getUFsCalls_lm`)
      .then((response) => {
        //console.log(response.data);
        setUFOptions(response.data);
      })
      .catch((error) => {
        console.log(error);
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao tentar buscar os estados!',
        });
      });
  }, []);

  const getCityByUF = useCallback(
    async (uf: any) => {
      setUF(uf);
      //console.log("buscar cidade por uf [ " + uf + " ] ");
      //var url = "http://localhost:5002/api";
      setLoadingSkinCity(true);
      await //api.get(`/reports/getcitycallsbyuf/${uf}`)
      api
        .get(`/crm/reports/getCityCallsByUF_lm/${uf}`)
        .then((response) => {
          // console.log(response.data);
          setCityOptions(response.data);

          getProviderByUFCity('0');
          setLoadingSkinCity(false);
        })
        .catch((error) => {
          console.log(error);
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Erro ao tentar buscar as cidades!',
          });
        });
    },
    [uf]
  );

  const getProviderByUFCity = useCallback(
    async (city: string) => {
      setCity(city);
      //console.log(city)
      var url = 'http://localhost:5002/api';

      setLoadingSkinProvider(true);
      await //api.get(`/reports/getprovider/${uf}/${city}`)
      api
        .get(`/crm/reports/getprovider_lm/${city}`)
        .then((response) => {
          console.log(response.data);
          setProviderOptions(response.data);
          setLoadingSkinProvider(false);
          setOficina(undefined);
        })
        .catch((error) => {
          console.log(error);
          setLoadingSkinProvider(false);
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Erro ao tentar buscar os fornecedores!',
          });
        });
    },
    [uf, city]
  );

  const getAdressProvider = useCallback(
    async (forncedorId: number) => {
      setProvider(forncedorId);
      setLoadingSkinDataProvider(true);
      setOficina(undefined);
      console.log(`doc: ${forncedorId}`);
      var url = 'http://localhost:5002/api';
      await api
        .get(`/crm/reports/getProvider-information_lm/${forncedorId}`)
        .then((response) => {
          console.log(response);
          setOficina(response.data);
          setLoadingSkinDataProvider(false);
        })
        .catch((err) => {
          console.log(err);
          setOficina({} as Provider);
          setLoadingSkinDataProvider(false);
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Erro ao tentar buscar os dados do fornecedor!',
          });
        });
    },
    [providerOptions]
  );

  const convertoToNumberOnly = (value: any) => {
    return value.replace(/([^\d])+/gim, '');
  };

  const handleSubmit = useCallback(
    async (data: CallLMRequest) => {
      // CallRequest
      let currentDate = new Date();
      let dataHora;

      try {
        formRef.current?.setErrors({});

        if (clienteId == '0') {
          addToast({
            type: 'error',
            title: 'Erro na criação de solicitação',
            description: 'Selecione o cliente!',
          });
          return;
        }

        if (plaqueId == '0') {
          addToast({
            type: 'error',
            title: 'Erro na criação de solicitação',
            description: 'Selecione a placa!',
          });
          return;
        }

        if (conductorItem == undefined) {
          addToast({
            type: 'error',
            title: 'Erro na criação de solicitação',
            description: 'Informe um motorista pela pesquisa!',
          });
          return;
        }

        if (km == '') {
          addToast({
            type: 'error',
            title: 'Erro na criação de solicitação',
            description: 'Informe o KM!',
          });
          return;
        }

        if (tipo == '0') {
          addToast({
            type: 'error',
            title: 'Erro na criação de solicitação',
            description: 'Informe a tipo chamado!',
          });
          return;
        }

        if (uf == '0') {
          addToast({
            type: 'error',
            title: 'Erro na criação de solicitação',
            description: 'Informe a UF!',
          });
          return;
        }

        if (city == '0') {
          addToast({
            type: 'error',
            title: 'Erro na criação de solicitação',
            description: 'Informe a cidade!',
          });
          return;
        }

        if (date == '' || hora == '') {
          addToast({
            type: 'error',
            title: 'Erro na criação de solicitação',
            description: 'Informe a data e hora de sua preferência!',
          });
          return;
        }

        dataHora =
          date != '' ? moment(date).format('YYYY-MM-DD') + 'T' + hora : '';
        console.log(dataHora);

        if (report == '') {
          addToast({
            type: 'error',
            title: 'Erro na criação de solicitação',
            description: 'Informe o relato!',
          });
          return;
        }

        // Request fleet
        // data.ChamadoClienteCNPJ = condutorCpf // cpfCnpj //"19.277.031/0001-12",
        // data.ChamadoPlaca = plaqueId
        // data.ChamadoKM = parseInt(km)
        // data.ChamadoTipoId = parseInt(tipo) // ids?.ChamadoTipoId
        // data.ChamadoCategoriaId = parseInt(categoria) // ids?.ChamadoCategoriaId
        // data.ChamadoSubCategoriaId = parseInt(subCategoria) //ids?.ChamadoSubCategoriaId
        // data.ChamadoCondutorCPF = condutorCpf
        // data.ChamadoBreveRelato = report
        // data.ChamadoCNPJOficina = provider
        // data.ChamadoDataAgendamento = dataHora
        // data.ChamadoIntegracaoId = 0
        // data.ChamadoResponsavel = name
        // data.ChamadoResponsavelDocumento = document
        // data.ChamadoResponsavelEmail = email
        // data.ChamadoResponsavelAgendamentoDDD = ddd
        // data.ChamadoResponsavelAgendamentoTelefone = phoneNumber
        // data.ChamadoFilialCNPJ = "00389481002970" // fixo so para nao dar erro em homologa, sugestao do leo(fleet)

        // request LM
        data.entityVersion = moment(currentDate).format('YYYY-MM-DDTHH:mm:ss');
        data.veiculoId = parseInt(plaqueId);
        data.tipoOcorrenciaId = parseInt(tipo);
        data.kmAtual = parseInt(km);
        data.cidadeId = parseInt(city);
        data.breveRelato = report;
        data.responsavel = name;
        data.responsavelEmail = email;
        data.responsavelTelefone = telefoneResponsavel;
        data.fornecedorId = provider;
        data.dataAgendaOficina = dataHora;

        var param = {
          entityVersion: moment(currentDate).format('YYYY-MM-DDTHH:mm:ss'),
          veiculoId: parseInt(plaqueId),
          tipoOcorrenciaId: parseInt(tipo),
          kmAtual: parseInt(km),
          cidadeId: parseInt(city),
          breveRelato: report,
          responsavel: responsavel,
          responsavelEmail: emailResponsavel,
          responsavelTelefone: telefoneResponsavel,
          // fornecedorId: provider,
          dataAgendaOficina: dataHora,
          motoristaId: conductorItem?.condutorId,
        };
        console.log(param);
        //console.log(JSON.stringify(data))
        // return;

        // const schema = Yup.object().shape({
        //   plaqueId: Yup.string().ensure().min(7, "Selecione a placa"),
        //   km: Yup.string().required("Informe o km"),
        //   report: Yup.string().required("Informe o relato"),
        // })

        // await schema.validate(data, {
        //   abortEarly: false,
        // })

        // return;

        setLoadingSkinSave(true);
        var url = 'http://localhost:5002/api';
        api
          //.post(`/reports/PostCallUpdateAsync/`, data)
          .post(`/crm/reports/create-my-request_lm/`, param)
          .then((response: any) => {
            console.log(response.data);
            setLoadingSkinSave(false);
            if (response.data.success == true) {
              addToast({
                title: 'Solicitação criada',
                type: 'success',
                description: 'Sua solicitação foi criada com sucesso.',
              });
              history.push('/my-requests');
            } else {
              var msgErro =
                response.data.items.kmAtual != undefined
                  ? response.data.items.kmAtual[0]
                  : '';
              addToast({
                type: 'error',
                title: 'Erro na criação de solicitação',
                description:
                  msgErro != ''
                    ? msgErro
                    : 'Erro ao tentar criar a solicitação.',
              });
            }

            //history.push("/new-request")
          })
          .catch((error) => {
            setLoadingSkinSave(false);
            console.log(error.response.data);
            var msgErro =
              error.response.data != undefined
                ? error.response.data
                : 'Verifique as informações inseridas';
            addToast({
              type: 'error',
              title: 'Erro na criação de solicitação',
              description: msgErro,
            });
          });
      } catch (err) {
        setLoadingSkinSave(false);
        addToast({
          type: 'error',
          title: 'Erro na criação do chamado',
          description: 'Verifique as informações inseridas',
        });

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          console.log(errors);
          return;
        }
      }
    },
    [
      plaqueId,
      report,
      km,
      cpfCnpj,
      condutorCpf,
      report,
      provider,
      date,
      hora,
      name,
      document,
      email,
      ddd,
      phoneNumber,
      tipo,
      categoria,
      subCategoria,
      telefoneResponsavel,
      city,
      uf,
      responsavel,
      emailResponsavel,
      conductor,
    ]
  );

  const getDadosChamado = useCallback(async () => {
    var url = 'http://localhost:5002/api';
    // await api.post(`/reports/WSDadosChamado/N`, null)
    await api
      .get(`/crm/reports/WSDadosChamado_lm`)
      .then((response) => {
        console.log(response);
        //setTiposChamados(response.data.response.tipoChamado);
        setTiposChamados(response.data.tipoChamado);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleTipoChamado = (value: any) => {
    setTipo(value);
    console.log(value);
    // if(value == 0){
    //   setCategoriaChamadoOptions([]);
    //   setSubCategoriaOptions([]);
    //   setCategoria("0")
    //   setSubCategoria("0")
    // }
    // tiposChamados?.forEach(function(tp, index){
    //   if(tp.tipoChamadoId == value){
    //     console.log(tp.categoriaChamado);
    //     setCategoriaChamadoOptions(tp.categoriaChamado);
    //   }
    // });
  };

  const handleCategoriaChamado = (value: any) => {
    setCategoria(value);
    console.log(value);
    if (value == 0) {
      setSubCategoriaOptions([]);
      setSubCategoria('0');
    }

    categoriaChamadoOptions?.forEach(function (catCham, index) {
      if (catCham.categoriaChamadoId == value) {
        console.log(catCham.subCategoria);
        setSubCategoriaOptions(catCham.subCategoria);
      }
    });
  };

  const getKM = async (chassi: string) => {
    setKm('');
    const url = 'http://localhost:5002/api';
    await api
      .get(`/reports/GetVehiclesScopeResponse/${chassi}`)
      .then((response) => {
        console.log(response.data.value);
        if (response.data.value.length > 0) {
          // addToast({
          //   title: "Erro",
          //   type: "error",
          //   description: "KM não encontrado!",
          // })
          //return;
          setKm(response.data.value[0].decimalOdometer.split('.')[0]);
        }
      });
  };

  const handleCliente = (value: any) => {
    setClienteId(value);
    setShowInputs(false);
  };

  const handleConductor = (value: any) => {
    setConductor(value);
    if (value === '') setConductorItem(undefined);
  };

  const handleListItemClick = (c: Conductor) => {
    setConductor(c.condutorNome);
    setConductorItem(c);
    setOpen(false);
  };

  const getConductor = useCallback(() => {
    if (+clienteId == 0 || conductor == '') {
      addToast({
        type: 'error',
        title: 'Erro',
        description:
          'Para pesquisar um motorista, favor informar o cliente e o nome do motorista!',
      });
      return;
    }
    setLoadingSkinSearchConductor(true);
    var param = { clienteId: +clienteId, nome: conductor, limite: 1000 };
    console.log(param);

    api
      .post('/crm/reports/condutor_autoComplete_LM', param)
      .then((res) => {
        console.log(res.data);
        setLoadingSkinSearchConductor(false);
        if (res.data.length > 0) {
          setConductorOptions(res.data);
          setOpen(true);
        } else {
          setConductor('');
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Nenhum motorista foi encontrado!',
          });
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingSkinSearchConductor(false);
      });
  }, [clienteId, conductor]);

  const addConductor = (e: any) => {
    e.preventDefault();
    history.push(`/register-driver/1`);
  };

  return (
    <>
      <Line>
        <p>Data de abertura</p>
        <p>{getCurrentDate()}</p>
      </Line>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <WrapperSelect>
          <p>Cliente</p>
          <SelectC onChange={(e) => handleCliente(e.target.value)}>
            <option value="0">Todos</option>
            {customersOptions.map((c: any, i: number) => {
              return (
                <option key={i} value={c.customerCMSId}>
                  {c.customerName}
                </option>
              );
            })}
          </SelectC>
          &nbsp;
          <div className="loadingSpin">
            <LocationSpin loading={loadingSkinSearchPlaque} />
          </div>
        </WrapperSelect>
        <br />
        <WrapperSelect>
          <p>Placa</p>
          <SelectVehicle
            userId={userId}
            clienteId={+clienteId}
            id={plaqueId}
            func={getVehicleByPlaque}
          />
        </WrapperSelect>
        <br />
        {showInputs && (
          <>
            <DoubleLine>
              <div>
                <p>Modelo</p>
                <p>{carModel}</p>
              </div>
              <div>
                <p>Renavam</p>
                <p>{renavam}</p>
              </div>
            </DoubleLine>
            <WrapperSelect>
              <p>Motorista</p>
              <Input
                placeholder="Motorista"
                id="conductor"
                name="conductor"
                type="text"
                value={conductor}
                onChange={(e) => handleConductor(e.target.value)}
                className="conductor inputText"
              />
              &nbsp;
              <a>
                <PageviewTwoToneIcon
                  style={{ fontSize: 40 }}
                  onClick={getConductor}
                >
                  PageviewTwoTone
                </PageviewTwoToneIcon>
              </a>
              <a>
                <AddCircleTwoToneIcon
                  style={{ fontSize: 40 }}
                  onClick={addConductor}
                >
                  AddCircleTwoTone
                </AddCircleTwoToneIcon>
              </a>
              &nbsp;
              <div className="loadingSpin">
                <LocationSpin loading={loadingSkinSearchConductor} />
              </div>
            </WrapperSelect>
            <br />
            <WrapperSelect>
              <p>Km Atual</p>
              <Input
                placeholder="Digite a quilometragem atual"
                id="km"
                name="km"
                type="text"
                value={km}
                onChange={(e) => setKm(convertoToNumberOnly(e.target.value))}
                maxLength={9}
                className="inputText"
              />
            </WrapperSelect>
            <br />
            <Line>
              <p>Chassi</p>
              <p>{chassi}</p>
            </Line>

            <WrapperSelect>
              <p>Tipo chamado</p>
              <Select
                id="Tipo"
                name="Tipo"
                onChange={(e) => handleTipoChamado(e.target.value)}
                value={tipo}
                style={{ width: '10' }}
              >
                <option value="0">Selecione o tipo</option>
                {tipoChamadosOptions?.map((tp: any, i: number) => (
                  <option key={i} value={tp.tipoChamadoId}>
                    {tp.tipoChamadoNome}
                  </option>
                ))}
              </Select>
            </WrapperSelect>
            <br />

            {/* <WrapperSelect>
        <p>Categoria</p>
        <Select
          id="Categoria"
          name="Categoria"
          onChange={(e) => handleCategoriaChamado(e.target.value)}
          value={categoria}
          style={{ width: "10" }}
        >
          <option value="0">Selecione a categoria</option>
          {categoriaChamadoOptions != undefined ?
            (categoriaChamadoOptions.map((cat: any, i: number) => (
              <option key={i} value={cat.categoriaChamadoId}>
                {cat.categoriaChamadoNome}
              </option>
            ))
            ): ("")
          }
        </Select>
      </WrapperSelect>
      <br /> */}

            {/* <WrapperSelect>
        <p>SubCategoria</p>
        <Select
          id="SubCategoria"
          name="SubCategoria"
          onChange={(e) => setSubCategoria(e.target.value)}
          value={subCategoria}
          style={{ width: "10" }}
        >
          <option value="0">Selecione a sub categoria</option>
          {subCategoriaOptions != undefined ?
            (subCategoriaOptions.map((sub: any, i: number) => (
              <option key={i} value={sub.subCategoriaChamadoId}>
                {sub.subCategoriaChamadoNome}
              </option>
            ))
            ): ("")
          }
        </Select>
      </WrapperSelect>
      <br /> */}

            <WrapperSelect>
              <p>Responsável</p>
              <Input
                placeholder="Digite o responsável"
                id="responsavel"
                name="responsavel"
                type="text"
                className="inputText"
                value={responsavel}
                onChange={(e) => setResponsavel(e.target.value)}
              />
            </WrapperSelect>
            <br />

            <WrapperSelect>
              <p>E-mail do responsável</p>
              <Input
                placeholder="Digite o e-mail do responsável"
                id="emailResponsavel"
                name="emailResponsavel"
                type="text"
                className="inputText"
                value={emailResponsavel}
                onChange={(e) => setEmailResponsavel(e.target.value)}
              />
            </WrapperSelect>
            <br />

            <WrapperSelect>
              <p>Telefone do responsável</p>
              <Input
                placeholder="Digite o telefone do responsável"
                id="telefoneResponsavel"
                name="telefoneResponsavel"
                type="text"
                className="inputText"
                value={telefoneResponsavel}
                maxLength={20}
                onChange={(e) => setTelefoneResponsavel(e.target.value)}
              />
            </WrapperSelect>
            <br />

            <WrapperSelect>
              <p>UF</p>
              <Select
                id="UF"
                name="UF"
                onChange={(e) => getCityByUF(e.target.value)}
                value={uf}
                style={{ width: '10' }}
              >
                <option value="0">Selecione a UF</option>
                {ufOptions != undefined
                  ? ufOptions.map((uf: any, i: number) => (
                      <option key={i} value={uf.codigo}>
                        {uf.nome}
                      </option>
                    ))
                  : ''}
              </Select>
            </WrapperSelect>
            <br />
            <WrapperSelect>
              <p>Cidade</p>
              <Select
                id="city"
                name="city"
                onChange={(e) => getProviderByUFCity(e.target.value)}
                value={city}
                style={{ width: '10' }}
              >
                <option value="0">Selecione a cidade</option>
                {cityOptions != undefined
                  ? cityOptions.map((city: any, i: number) => (
                      <option key={i} value={city.cidadeId}>
                        {city.cidade}
                      </option>
                    ))
                  : ''}
              </Select>
              &nbsp;
              <div className="loadingSpin">
                <LocationSpin loading={loadingSkinCity} />
              </div>
            </WrapperSelect>
            <br />
            {/* <WrapperSelect>
          <p>Oficina</p>
          <Select
            id="provider"
            name="provider"
            onChange={(e) => getAdressProvider(parseInt(e.target.value))}
            value={provider}
            style={{ width: '10' }}
          >
            <option value="0">Selecione a oficina</option>
            {providerOptions != undefined
              ? providerOptions.map((provider: any, i: number) => (
                  <option key={i} value={provider.id}>
                    {provider.nome}
                  </option>
                ))
              : ''}
          </Select>
          &nbsp;
          <div className="loadingSpin">
            <LocationSpin loading={loadingSkinProvider} />
          </div>
        </WrapperSelect>

        <LoadingSpinDataProvider>
          <LocationSpin loading={loadingSkinDataProvider} />
        </LoadingSpinDataProvider>

        {oficina != undefined ? (
          <>
            <br />
            <DoubleLine>
              <div>
                <p>Rua</p>
                <p>{oficina.endereco}</p>
              </div>
              <div>
                <p>Número</p>
                <p>{oficina.numero}</p>
              </div>
            </DoubleLine>
            <Line>
              <p>Bairro</p>
              <p>{oficina.bairro}</p>
            </Line>
          </>
        ) : (
          <br />
        )} */}

            <WrapperSelect>
              <p>Data Preferência</p>
              <Input
                placeholder="Data"
                id="data"
                name="data"
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className="divDataHora inputText"
              />
              {/* <Input
        placeholder="Hora"
        id="hora"
        name="hora"
        type="text"
        value={hora}
        onChange={(e) => setHora(e.target.value)}
        className="divDataHora"
        /> */}
              <InputMaskStyle>
                <InputMask
                  placeholder="Hora"
                  mask="99:99"
                  id="hora"
                  name="hora"
                  type="text"
                  value={hora}
                  onChange={(e) => setHora(e.target.value)}
                />
              </InputMaskStyle>
            </WrapperSelect>
            <br />
            <Line>
              <p>Breve relato</p>
              <TextArea
                id="relato"
                name="relato"
                value={report}
                onChange={(e) => setReport(e.target.value)}
              ></TextArea>
            </Line>
            <Line>
              <p />
              <Button>Salvar</Button>
              &nbsp;
              <div className="loadingSpinSave">
                <LocationSpin loading={loadingSkinSave} />
              </div>
            </Line>
          </>
        )}
      </Form>
      {/* <DialogConductor
        clienteId={clienteId}
        condutorNome={conductor}
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        conductorOptions={conductorOptions}
        /> */}

      <Modal isVisible={open}>
        <ModalContent>
          {/* <CloseModal onClick={() => setModalIsVisible(false)}></CloseModal> */}
          <a>
            <CancelPresentationIcon
              className="close"
              color="primary"
              onClick={() => setOpen(false)}
            />
          </a>
          <h2>Selecione um motorista na lista abaixo</h2>
          <ul>
            {conductorOptions.map((c: any, i: number) => (
              <li onClick={() => handleListItemClick(c)} key={i}>
                <label className="label">{c.condutorNome}</label>
              </li>
            ))}
          </ul>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Scheduling;
