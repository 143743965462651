import React from 'react';

import iconFacebook from '../../assets/icons/facebook.svg';
import iconLinkedin from '../../assets/icons/linkedin.svg';
import iconInstagram from '../../assets/icons/instagram.svg';

import { Container, Content, FooterF, Category, Contact, Info } from './styles';

function Footer() {
  return (
    <Container>
      <Content>
        <Contact>
          {/* <p style={{ textAlign: "center" }}>
            <strong>Serviço de Atendimento ao Cliente</strong>
            <br />
            0800 899 0001 <br />
            comercial@lmfrotas.com.br
          </p> */}
          <h3>Redes Sociais</h3>
          <div className="icons">
            <a
              href="https://www.facebook.com/lmmobilidade"
              target="_blank"
              rel="noreferrer"
            >
              <img src={iconFacebook} alt="Facebook Icon" />
            </a>
            <a
              href="https://www.linkedin.com/company/lmmobilidade"
              target="_blank"
              rel="noreferrer"
            >
              <img src={iconLinkedin} alt="Linkedin Icon" />
            </a>
            <a
              href="https://www.instagram.com/lmmobilidade_/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={iconInstagram} alt="Instagram Icon" />
            </a>

            {/* <img src={iconWhatsapp} alt="WhatsApp Icon" /> */}
          </div>
        </Contact>
        <Category>
          <h3>Institucional</h3>
          <ul>
            <li>
              <a
                href="https://lmmobilidade.com.br/quem-somos"
                target="_blank"
                rel="noreferrer"
              >
                Quem Somos
              </a>
            </li>
            {/* <li>
              <a
                href="https://lmfrotas.com.br/fleet-soluctions-news"
                target="_blank"
              >
                LM Frotas News
              </a>
            </li> */}
            <li>
              {/* <a href="https://fleetbrasil.com.br/a-fleet-solutions#TrabalheConosco" target="_blank" > */}
              <a
                href="https://lmvagas.gupy.io/"
                target="_blank"
                rel="noreferrer"
              >
                Trabalhe Conosco
              </a>
            </li>
          </ul>
        </Category>
        {/* <Category>
          <h3>Para você</h3>
          <ul>
            <li>
              <a href="https://www.vwsignanddrive.com.br/" target="_blank">
                Carro por Assinatura
              </a>
            </li>
          </ul>
        </Category> */}
        <Category>
          <h3>Para sua empresa</h3>
          <ul>
            <li>
              <a
                href="https://www.lmfrotas.com.br"
                target="_blank"
                rel="noreferrer"
              >
                Carros
              </a>
            </li>
            <li>
              <a
                href="https://www.lmfrotas.com.br"
                target="_blank"
                rel="noreferrer"
              >
                Caminhões
              </a>
            </li>
          </ul>
        </Category>
        <Category>
          <h3>Atendimento</h3>
          <ul>
            {/* <li>
              <a
                href="https://www.lmfrotas.com.br/duvidas-frequentes"
                target="_blank"
              >
                Dúvidas frequentes
              </a>
            </li> */}
            <li>
              <a
                href="https://lmmobilidade.com.br/#contato-mobilidade-home"
                target="_blank"
                rel="noreferrer"
              >
                Fale conosco
              </a>
            </li>
            {/* <li>
              <a href="https://www.lmfrotas.com.br/ouvidoria" target="_blank">
                Ouvidoria
              </a>
            </li> */}
            {/* <li>
              <a
                href="https://www.lmfrotas.com.br/area-de-concessionaria"
                target="_blank"
              >
                Área de concessionária
              </a>
            </li> */}
          </ul>
        </Category>
      </Content>
      <Info>
        <p>
          <a href="/">Informações Legais</a>
          &nbsp;|&nbsp;
          <a
            href="https://lmmobilidade.com.br/politica-de-privacidade/"
            target="_blank"
            rel="noreferrer"
          >
            Política de Privacidade
          </a>
          &nbsp;|&nbsp;
          {/* <Link to="/polcookies">Política de Cookies</Link> */}
          <a
            href="https://lmmobilidade.com.br/politica-de-cookies/"
            target="_blank"
            rel="noreferrer"
          >
            Política de Cookies
          </a>
          &nbsp;|&nbsp;
        </p>
      </Info>
      <FooterF>
        <hr />
        <p>2022 LM Soluções de Mobilidade® Todos os direitos reservados</p>
      </FooterF>
    </Container>
  );
}

export default Footer;
