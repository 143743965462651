import styled from "styled-components"
import Tooltip from "../Tooltip"

export const Container = styled.div`
  position: relative;

  select {
    width: 200px;
    height: 50px;
    margin-left: 15px;
    font-family: "Noto Sans JP", sans-serif;
    font-size: 16px;
    border-color: #ccc;
    border: 2px solid #ccc;
    border-radius: 5px;
    background: transparent;
  }
`

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  position: absolute;
  top: 15px;
  right: 0;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`
export const WrapperSelect = styled.div`
  align-items: center;
  display: flex;
  // margin-bottom: 30px;
  justify-content: flex-start;
  color: #0c5faf;
  width: 650px;

  .loadingDataVehicle {
    width: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0px 0px 0px 10px;
  }
`
