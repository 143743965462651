import React, { createContext, useState, useContext } from 'react';
import styled from 'styled-components';

const WrapperButton = styled.div`
  display: flex;
  justify-content: end;
  & > button {
    border: 0;
    background: none;
  }
  @media (min-width: 426px) {
    display: none;
  }
`;
const Content = styled.div`
  @media (min-width: 426px) {
    display: block !important;
  }
`;

const ToggleShowContext = createContext<any>(null);

function ToggleShowProvider({ children }: any) {
  const [hidden, setHidden] = useState(true);
  const onToggle = () => setHidden(!hidden);
  return (
    <ToggleShowContext.Provider value={{ hidden, onToggle }}>
      {children}
    </ToggleShowContext.Provider>
  );
}
export function ToggleShowButton({ children }: any) {
  const { onToggle } = useContext(ToggleShowContext);
  return (
    <WrapperButton className="toggleShow-button">
      <button type="button" onClick={onToggle}>
        {children}
      </button>
    </WrapperButton>
  );
}
export function ToggleShowContent({ children }: any) {
  const { hidden } = useContext(ToggleShowContext);
  return (
    <Content className="toggleShow-content" hidden={hidden}>
      {children}
    </Content>
  );
}
export function ToggleShow({ children }: any) {
  return (
    <ToggleShowProvider>
      <div className="toggleShow">{children}</div>
    </ToggleShowProvider>
  );
}
