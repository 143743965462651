import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import { loginApp } from 'store/modules/login/actions';
import { useAuth } from 'hooks/auth';
import { getTheme } from 'pages/LandingPages/utils/helpers';
import useMessage from 'pages/LandingPages/utils/hooks/useMessage';
import Logo from 'pages/LandingPages/components/domain/Logo';
import Button from 'pages/LandingPages/components/ui/Button';
import PasswordInput from 'pages/LandingPages/components/ui/PasswordInput';
import Input from 'pages/LandingPages/components/ui/Input';
import Label from 'pages/LandingPages/components/ui/Label';

// estilos
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 32px;
`;
const StyledLinkForgotPassword = styled.a<{ color: string }>`
  color: ${(props) => props.color};
  text-align: center;
  font-family: 'Audi Type Screen';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-top: 16px;
  margin-bottom: 16px;

  &: hover {
    color: ${(props) => props.color};
  }
`;

// tipagem
type Props = {
  onForgotPassword: () => void;
  onFirstAccess: () => void;
};

function LoginForm({ onForgotPassword, onFirstAccess }: Props) {
  const { signIn } = useAuth();
  const dispatch = useDispatch();
  const showMessage = useMessage();

  const [values, setValues] = useState({ email: '', password: '' });

  const theme = getTheme();

  const handleLoginSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const schema = Yup.object().shape({
      email: Yup.string()
        .required('E-mail obrigatório')
        .email('Digite um e-mail válido'),
      password: Yup.string().required('Senha obrigatória'),
    });

    schema
      .validate(values, {
        abortEarly: false,
      })
      .then(() => {
        signIn({
          login: values.email,
          password: values.password,
        })
          .then(() => {
            dispatch(loginApp({ status: false }));
          })
          .catch((error) => {
            showMessage.error({
              content: error.response.data.messages.join(', '),
            });
          });
      })
      .catch((error: any) => {
        if (error instanceof Yup.ValidationError) {
          showMessage.error({
            content: error.errors.join(', '),
          });
        }
      });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Logo src={theme.firstLogo} />
      <StyledForm onSubmit={handleLoginSubmit}>
        <Label label="E-mail">
          <Input
            type="text"
            name="email"
            placeholder="Digite seu e-mail"
            value={values.email}
            onChange={handleInputChange}
          />
        </Label>
        <Label label="Digite sua senha">
          <PasswordInput
            name="password"
            placeholder="Digite sua senha"
            value={values.password}
            onChange={handleInputChange}
          />
        </Label>
        <Button>Fazer login</Button>
        <StyledLinkForgotPassword
          color={theme.textColor}
          onClick={onForgotPassword}
        >
          Esqueci minha senha
        </StyledLinkForgotPassword>
        <Button color={theme.firstButtonBgcolor} onClick={onFirstAccess}>
          Primeiro acesso
        </Button>
      </StyledForm>
    </>
  );
}

export default LoginForm;
