import React from "react"

import { Container, Contact } from "./styles"

const RecallInformative = () => {
  return (
    <Container>
      <h1>Convocação para Recall confira!</h1>
      <p>
        Atualmente temos a Campanha de Recall ativa para correções necessárias.
        É essencial a verificação dos veículos elegíveis para garantir a
        segurança do condutor e dos passageiros durante a utilização do veículo.
      </p>
      {/* <p>
        Clique aqui para ter acesso às Placas e Chassis dos Veículos elegíveis.
        [possibilidade de link para download ou acesso à listagem online]
      </p> */}
      <p>
        Fizemos a Comunicação detalhada sobre o Recall ao Gestor de Frota
        cadastrado.
      </p>

      <p>
        Para maiores informações, com o Chassi do veículo, basta acessar o site
        da Volkswagen para verificação da necessidade do Recall em seu veículo:{" "}
        <a
          href="https://vwapps.volkswagen.com.br/Recall"
          target="_blank"
          rel="noopener noreferrer"
        >
          Recall | Volkswagen do Brasil
        </a>
      </p>
      <p>
        Entre em contato com nossa central e realize o agendamento para reparo
      </p>
      <Contact>
        <p>
          <b>Telefone:</b> 0800 899 0001
        </p>
        <p>
          <b>WhatsApp:</b> (41) 3090 6666
        </p>
        <p>
          <b>e-mail:</b> agendamento@lmfrotas.com.br
        </p>
      </Contact>
    </Container>
  )
}

export default RecallInformative
