import Programs from 'components/Programs';
import styled from 'styled-components';

export const ContentModal = styled.div`
  max-width: 350px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 20px;

  img {
    max-width: 100%;
    margin-bottom: 20px;
  }
`;

export const Button = styled.button`
  border-radius: 6px;
  box-shadow: 0px 3px 6px #00000029;
  background: #0606da; /*#0c5faf;*/
  display: block;
  text-align: center;
  padding: 8px 16px;
  transition: background-color 0.2s;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  border: none;
  width: 100%;
  margin-top: 10px;
`;

export const StylePrograms = styled(Programs)`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin: 0;
  }
`;
