import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IState } from 'store';
import { ILogin } from 'store/modules/login/types';
import { loginApp } from 'store/modules/login/actions';
import LandingpageLayout from 'pages/LandingPages/components/domain/LandingpageLayout';
import Modal from 'pages/LandingPages/components/ui/Modal';
import ForgotpasswordForm from 'pages/LandingPages/components/domain/ForgotpasswordForm';
import LoginForm from 'pages/LandingPages/components/domain/LoginForm';
import FirstaccessForm from '../components/domain/FirstaccessForm';

function SigninPage() {
  const dispatch = useDispatch();
  const status = useSelector<IState, ILogin>((state) => state.login);

  const [step, setStep] = useState<'login' | 'forgotPassword' | 'firstAccess'>(
    'login'
  );

  const handleCloseModal = () => {
    dispatch(loginApp({ status: false }));
  };

  const handleForgotPassword = () => {
    setStep('forgotPassword');
  };

  const handleReturn = () => {
    setStep('login');
  };

  const handleFirstAccess = () => {
    setStep('firstAccess');
  };

  const renderForm = () => {
    switch (step) {
      case 'login':
        return (
          <LoginForm
            onForgotPassword={handleForgotPassword}
            onFirstAccess={handleFirstAccess}
          />
        );
      case 'forgotPassword':
        return <ForgotpasswordForm handleReturn={handleReturn} />;
      case 'firstAccess':
        return <FirstaccessForm handleReturn={handleReturn} />;
      default:
        return null;
    }
  };

  return (
    <LandingpageLayout>
      <Modal open={status.status} onCancel={handleCloseModal}>
        {renderForm()}
      </Modal>
    </LandingpageLayout>
  );
}

export default SigninPage;
