import styled from "styled-components"

export const Container = styled.div`
  align-items: center;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) {
    top: 10px;
    position: sticky;
    margin-right: 50px;
    width: 225px;
  }

  button {
    background-color: white;
    border: 2px solid #d2dadb;
    border-radius: 11px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 0.3s;
    max-width: 225px;
    width: 100%;

    &:nth-child(1) {
      height: 120px;
    }

    &:nth-child(2) {
      height: 150px;
      margin-top: 30px;
    }

    .message-new-request {
      color: #d2dadb;
    }

    h3 {
      font-size: 18px;
      font-weight: 500;
      color: #d2dadb;
      margin-top: 35px;
    }

    & > p {
      color: #d2dadb;
      margin: 0;
      font-size: 14px;
    }

    img {
      width: 40px;
      position: absolute;
      height: 40px;
      top: 8px;
    }

    &.active {
      border-color: #0c5faf;

      & > h3 {
        color: #0c5faf;
      }

      p {
        color: red;
      }

      &:hover {
        background-color: #0c5faf4d;
      }
    }
  }
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-top: 30px;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
  }
`
export const Tab = styled.div`
  background-color: white;
  border-radius: 11px;
  margin-top: 50px;
  max-width: 880px;
  width: 100%;
  padding: 15px;

  @media (min-width: 768px) {
    padding: 30px;
    margin-top: 0;
    width: 90%;
  }
`

export const Line = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  justify-content: flex-start;

  @media (min-width: 768px) {
    margin-bottom: 30px;
  }

  .loadingSpinSave {
    margin-top: 10px;
    margin-left: 10px;
  }

  p {
    margin: 0;
    font-size: 13px;
    font-weight: 500;

    @media (min-width: 768px) {
      font-size: 15px;
    }

    &:nth-child(odd) {
      color: #0c5faf;
      width: 125px;

      @media (min-width: 768px) {
        text-align: end;
      }
    }

    &:nth-child(even) {
      margin-left: 15px;

      @media (min-width: 768px) {
        margin-left: 30px;
      }
    }
  }
`

export const DoubleLine = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  justify-content: flex-start;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    margin-bottom: 30px;
  }

  div {
    display: flex;

    &:first-child {
      margin-bottom: 20px;

      @media (min-width: 768px) {
        margin-bottom: 0;
      }
    }
  }
  p {
    margin: 0;
    font-size: 13px;
    font-weight: 500;

    @media (min-width: 768px) {
      font-size: 15px;
    }

    &:nth-child(odd) {
      color: #0c5faf;
      width: 125px;

      @media (min-width: 768px) {
        text-align: end;
      }
    }

    &:nth-child(even) {
      margin-left: 15px;

      @media (min-width: 768px) {
        margin-left: 30px;
      }
    }
  }
`

export const WrapperSelect = styled.div`
  align-items: center;
  display: flex;
  // margin-bottom: 30px;
  justify-content: flex-start;
  color: #0c5faf;

  .inputText {
    border: 1px solid #0c5faf;
    color: #0c5faf !important;
  }

  .conductor {
    width: 550px;
  }

  .divDataHora {
    width: 190px;
  }

  .divddd {
    width: 80px;
  }

  .divTelefone {
    width: 350px;
  }


  .loadingSpin {
    width: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 20px 0px 0px 0px;
  }

  p {
    margin: 0;
    font-weight: 500;
    color: #0c5faf;
    width: 125px;
    font-size: 13px;

    @media (min-width: 768px) {
      font-size: 15px;
      text-align: end;
    }
  }

  & > div {
    height: 41px;
    margin: 0 0 0 20px;
    width: 75%;
    max-width: 691px;

    @media (min-width: 768px) {
      margin-left: 30px;
    }

    select {
      border-radius: 21px;
      border: 1px solid #0c5faf;
      color: #0c5faf;
      height: 41px;
      max-width: 691px;
      padding-left: 18px;
      font-size: 13px;
      margin-left: 0;
      width: 100%;

      @media (min-width: 491px) {
        font-size: 15px;
      }
    }
  }
`

export const TextArea = styled.textarea`
  width: 75%;
  margin-left: 30px;
  border-radius: 21px;
  border: 1px solid #0c5faf;
  color: #0c5faf;
  padding: 5px 18px;
  height: 200px;
`

export const Button = styled.button`
  margin-left: 30px;
  align-self: flex-end;
  background-color: #236fbc;
  border: 2px solid #226fbb;
  border-radius: 24px;
  color: white;
  font-size: 12px;
  height: 39px;
  width: 208px;
`
export const InputMaskStyle = styled.div`
  border-radius: 10px;
  border: 1px solid #0c5faf;

  padding: 16px;
  position: flex;
  width: 80px !important;
  display: flex;
  align-items: center;

  input {
    flex: 1;
    background: transparent;
    border: 0;

    &::placeholder {
      color: #0c5faf;
    }
  }
`
export const LoadingSpinDataProvider = styled.div`
  /*width: 10px;*/
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0px 0px 0px 0px;
`
export const SelectC = styled.select`

  border-radius: 21px;
  border: 1px solid #0c5faf;
  color: #0c5faf;
  height: 41px;
  max-width: 691px;
  padding-left: 18px;
  font-size: 13px;
  margin-left: 25px;
  width: 77%;

  @media (min-width: 491px) {
    font-size: 15px;
  }
`

export const ModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 450px;
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  border: 0px solid #707070;
  padding-bottom: 10px;
  border-radius: 16px;
  margin-left: 20px;

  & > h2 {
    font-size: 21px;
    color: #0c5faf;
    font-weight: 500;
    margin-top: 10px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 530px;
    height: 450px;
    overFlow: auto;

    & li {
      margin-right: 35px;
      border-bottom: 1px solid #d5d5d5;
      cursor: pointer;
    }

    & li:hover {
      background-color: #DCDCDC;
      cursor: pointer;
    }
  }

  .label{
    font-size: 12px;
  }

  & > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 450px;

    @media (min-width: 768px) {
      flex-direction: row;
    }
    & > a {
      text-decoration: none;
      height: 165px;
      max-width: 225px;
      width: 47%;
    }
  }

  .close {
    border: none;
    position: absolute;
    right: 25px;
    top: 10px;
 }

 .search {
  cursor: pointer;
 }

`
