import React from 'react';
import ButtonUI from 'components/ui/ButtonUI';
import useResetPassword from '../hooks/useResetPassword';

const RESET_PASSWORD_TEXT = 'Redefinir Senha';

type Props = {
  email: string;
};

function ResetPasswordButton(props: Props) {
  const { resetPassword } = useResetPassword({
    email: props.email,
    messageTitle: RESET_PASSWORD_TEXT,
  });

  return (
    <ButtonUI color="third" onClick={resetPassword}>
      {RESET_PASSWORD_TEXT}
    </ButtonUI>
  );
}

export default ResetPasswordButton;
