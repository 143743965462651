import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label<{ error?: boolean }>`
  ${(props) => props.error && `color: rgb(197, 48, 48);`}
`;

interface Props
  extends Omit<React.LabelHTMLAttributes<HTMLLabelElement>, 'htmlFor'> {
  error?: boolean;
}

function InputLabelUI({ id, ...props }: Props) {
  return (
    <StyledLabel htmlFor={id} {...props}>
      {props.children}
    </StyledLabel>
  );
}

export default InputLabelUI;
