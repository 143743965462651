import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { postResetPasswordApi } from 'pages/Users/utils/services';
import { getTheme } from 'pages/LandingPages/utils/helpers';
import useMessage from 'pages/LandingPages/utils/hooks/useMessage';
import Button from 'pages/LandingPages/components/ui/Button';
import Label from 'pages/LandingPages/components/ui/Label';
import Input from 'pages/LandingPages/components/ui/Input';
import FormInfo from 'pages/LandingPages/components/domain/FormInfo';

// estilos
const StyledBackButton = styled.button<{ bgColor: string; $radius: number }>`
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 4px;
  border-radius: ${(props) => props.$radius * 2}px;
  background-color: ${(props) => props.bgColor};
  color: #fff;
  font-family: 'Audi Type Screen';
  font-size: 12px;
  font-weight: 700;
  line-height: 120%;
  border: none;
`;
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 32px;
`;

type Props = {
  handleReturn: () => void;
};

function ForgotpasswordForm({ handleReturn }: Props) {
  const showMessage = useMessage();

  const [emailValue, setEmailValue] = useState('');

  const theme = getTheme();

  const handleForgotPasswordSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const schema = Yup.object().shape({
      email: Yup.string()
        .required('E-mail obrigatório')
        .email('Digite um e-mail válido'),
    });

    schema
      .validate(
        { email: emailValue },
        {
          abortEarly: false,
        }
      )
      .then(() => {
        postResetPasswordApi(emailValue)
          .then(() => {
            showMessage.success({
              content: `Confira seu email. Enviamos um link de recuperação de senha no email ${emailValue}. Clique no link para registrar uma nova senha.`,
              duration: 7,
            });
            handleReturn();
          })
          .catch(() => {
            showMessage.error({
              content: 'Ocorreu um erro. Tente novamente mais tarde',
            });
          });
      })
      .catch((error) => {
        if (error instanceof Yup.ValidationError) {
          showMessage.error({
            content: error.errors[0],
          });
        }
      });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
  };

  return (
    <>
      <StyledBackButton
        bgColor={theme.secondButtonBgcolor}
        $radius={theme.radius}
        onClick={handleReturn}
      >
        Voltar
      </StyledBackButton>
      <FormInfo
        title="Esqueci minha senha"
        description="Digite seu e-mail cadastrado para continuarmos com o processo de
          recuperação do acesso."
      />
      <StyledForm onSubmit={handleForgotPasswordSubmit}>
        <Label label="E-mail">
          <Input
            type="text"
            name="email"
            placeholder="Digite seu e-mail"
            value={emailValue}
            onChange={handleInputChange}
          />
        </Label>
        <Button>Recuperar acesso</Button>
      </StyledForm>
    </>
  );
}

export default ForgotpasswordForm;
