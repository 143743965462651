import React from 'react';
import styled from 'styled-components';

// estilos
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 32px;
`;
const StyledTitle = styled.h2`
  font-family: 'Audi Type Screen';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
`;
const StyledDescription = styled.p`
  font-family: 'Audi Type Screen';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;
  width: 265px;
`;

// tipagem
type Props = {
  title: string;
  description?: string;
};

function FormInfo({ title, description }: Props) {
  return (
    <StyledContainer>
      <StyledTitle>{title}</StyledTitle>
      {description && <StyledDescription>{description}</StyledDescription>}
    </StyledContainer>
  );
}

export default FormInfo;
