import React from 'react';
import styled from 'styled-components';

import InputLabelUI from '../InputLabelUI';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledLabelText = styled.span`
  margin-left: 14px;
`;

type Props = {
  children: React.ReactNode;
  error?: boolean;
  id?: string;
  label: string;
};

function InputFieldContainerUI(props: Props) {
  return (
    <StyledContainer>
      <InputLabelUI error={props.error} id={props.id}>
        <StyledLabelText>{props.label}</StyledLabelText>
        {props.children}
      </InputLabelUI>
    </StyledContainer>
  );
}

export default InputFieldContainerUI;
