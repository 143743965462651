import { ILogin } from "./types";

export function loginApp(status: ILogin) {
  return {
    type: "LOGIN_REQUIRED",
    payload: {
      status,
    },
  };
}
