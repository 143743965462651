import styled from 'styled-components';
import ButtonUI from 'components/ui/ButtonUI';
import InputFieldUI from 'components/ui/InputFieldUI';

export const Form = styled.form<{ $radius: number }>`
  align-items: end;
  background-color: #fff;
  border-radius: ${(props) => props.$radius}px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 33px;

  @media (max-width: 425px) {
    justify-content: center;
    padding: 15px;
    & > div {
      width: 100%;
    }
  }
`;
export const Button = styled(ButtonUI)`
  margin: 8px 10px;
`;
export const Field = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;
export const FieldType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px;
  label {
    margin: 0;
  }
`;
export const Label = styled.label`
  font-size: 14px;
`;
export const Radio = styled.input`
  height: 14px;
  width: 33px;
`;
export const StyledInvoiceNumberInput = styled(InputFieldUI)`
  @media (min-width: 426px) {
    width: 110px;
  }
`;
