import styled from 'styled-components';

interface IProps {
  isPositionAbsolute?: boolean;
  isPositionFixed?: boolean;
}

export const Container = styled.div<IProps>`
  height: 73px;
  align-items: center;
  justify-content: center;
  background: rgba(23, 83, 108, 0.4) 0% 0% no-repeat padding-box;
  position: ${(props) => (props.isPositionAbsolute ? 'absolute' : 'inherit')};
  position: ${(props) => props.isPositionFixed && 'fixed'};
  width: 100%;
  z-index: 50;
  padding-left: 0px;

  h1 {
    font-size: 15px;
    font-weight: bold;
    margin-left: 150px;
    color: white;
    margin-bottom: 0px;
  }

  @media (max-width: 830px) {
    position: inherit;
    height: 63px;
  }
`;

export const WrapperLogo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 5px;

  img {
    cursor: pointer;
    height: 100%;
    width: auto;
    max-width: 100%;
  }

  @media (max-width: 830px) {
    h1 {
      display: none;
    }
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    margin-left: 10px;
  }

  @media (max-width: 830px) {
    .new-car,
    .fullname {
      display: none;
    }
  }

  button {
    font-size: 16px;
    color: white;
    font-weight: bold;
    margin: 10px;
  }

  a {
    color: #fff;
    font-weight: 500;
    margin-right: 200px;
    text-decoration: none;

    @media (max-width: 800px) {
      margin-right: 25px;
    }
  }

  button:hover {
    color: white;
    border-bottom: 1px solid white;
    background: transparent;
  }

  .icon-menu {
    margin-left: 50px;
  }

  .spn-menu {
    z-index: 1000;
    &.active {
      color: #3c505a;
    }
  }

  .btn-menu {
    background: transparent;
    border: 0;
    z-index: 1000;

    &.active {
      svg {
        color: #3c505a;
      }
    }
  }

  .fullname {
    padding-right: 25px;
    div {
      font-size: 12px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: inherit;
  margin: 0 auto;
  // background-color: #0606da;
`;

export const MenuContainer = styled.div`
  background-color: white;
  z-index: 99;
  position: fixed;
  right: -100%;
  bottom: 0;
  top: 0;
  padding: 65px 20px 0;
  transition: 0.3s;
  width: 284px;
  height: 100%;
  overflow-y: auto;
  p {
    margin: 0;
  }

  &.active {
    right: 0;
  }

  .btn-logout {
    font-size: 17px;
  }

  li {
    margin-bottom: 5px;

    span {
      color: #3c505a;
      font-size: 15px;
      font-weight: 400;
    }
  }
`;

export const MenuButton = styled.div`
  display: flex;
  align-items: center;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  display: flex;
  justify-content: center;
  margin: 25px 0;

  span {
    background-color: #cfd7d9;
    width: 85%;
    height: 100%;
  }
`;
