import React, { LegacyRef } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  .adyen-checkout__payment-method--selected {
    background-color: #fff;
  }
`;

type Props = {
  dropinRef: LegacyRef<HTMLDivElement> | undefined;
};

const CreditCard = ({ dropinRef }: Props) => {
  return (
    <Container>
      <div ref={dropinRef} />
    </Container>
  );
};

export default CreditCard;
