import React, { FC, useState, useEffect, useCallback } from 'react';

import Header from '../../components/Header';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';

import {
  Container,
  WrapperStatus,
  Select,
  WrapperFilterAndTable,
  Table,
  Form,
  WrapperTable,
} from './styles';

import Title from '../../components/Title';

import Pagination from '../../components/Pagination';
import LocationSpin from '../../components/LoadingSpin';
import { useToast } from '../../hooks/toast';
import MobileFilter from 'components/business/MobileFilter';

interface Customer {
  customerCMSId: number;
  customerName: string;
}
const Orders: FC = () => {
  const { userId } = useAuth();
  const [statusOrders, setStatusOrders] = useState([]);
  const [statusPCOrders, setStatusPCOrders] = useState([]);
  const [customerId, setCustomerID] = useState(0);
  const [orders, setOrders] = useState([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);

  const [loadingQtde, setLoadingQtde] = useState(false);
  const [statusSelId, setStatusSelId] = useState(4);
  const [customersOptions, setCustomersOptions] = useState<Customer[]>([]);

  const { addToast } = useToast();

  let url = 'http://localhost:5002/api';

  useEffect(() => {
    api.get(`/crm/reports/${userId}/customers-cms-lm`).then((response) => {
      setCustomersOptions(response.data);
    });
  }, []);

  // Return the count by status
  useEffect(() => {
    setLoadingQtde(true);
    api
      .get(`/crm/reports/status-count-order-by-customer_lm/${userId}`)
      .then((response) => {
        setStatusOrders(response.data);
        setLoadingQtde(false);
      });
  }, []);

  // Return the status by API of LM
  useEffect(() => {
    api
      .get(`/crm/reports/status-order-by-customer_lm/${userId}`)
      .then((response) => {
        setStatusPCOrders(response.data);
      });
  }, []);

  useEffect(() => {
    //getLogisticsByCustomer("0")
    //console.log("mudou pagina ou status: " + statusSelId);
    if (customersOptions.length > 0) getLogisticaByCustomer_LM();
  }, [currentPage, customersOptions]);

  const handleChangeCustomerID = useCallback(
    (e: any) => {
      setCustomerID(e.target.value);
    },
    [customerId]
  );

  const handleChangeStatus = useCallback(
    (status: any) => {
      setStatusSelId(status.target.value);
    },
    [orders, statusSelId]
  );

  // const getLogisticsByCustomer = useCallback(async (status: string) => {
  //   await api
  //     .get(`/reports/logistics-by-customer/${userId}/${status}`)
  //     .then((response: any) => {
  //       setOrders(response.data)
  //     })
  // }, [])

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log('handleSubmit: currentPAge [ ' + currentPage + ' ]');
    if (currentPage != 1) setCurrentPage(1);
    else getLogisticaByCustomer_LM();
  };

  const getLogisticaByCustomer_LM = useCallback(async () => {
    var aClienteId: number[] = [];

    if (customerId == 0) {
      customersOptions.map((c: Customer, i: number) => {
        aClienteId.push(c.customerCMSId);
      });
    } else {
      aClienteId.push(+customerId);
    }

    var data = {
      exibirTotais: true,
      paginaAtual: currentPage,
      itensPorPagina: 10,
      ordemCampo: '',
      ordemDescendente: false,
      userId: +userId,
      statusId: statusSelId != 0 ? +statusSelId : null,
      clienteId: aClienteId,
    };

    var url = 'http://localhost:5002/api';

    setLoading(true);
    await api
      .post(`/crm/reports/logistics-by-customer_lm/`, data)
      .then((response) => {
        if (response.data.itens.length == 1) setOrders(response.data.itens);
        else {
          setOrders(response.data.itens);
        }

        setTotal(response.data.totalItens);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setOrders([]);
        setTotal(0);
        console.log(err);
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Ocorreu um erro ao tentar buscar os pedidos!',
        });
      });
  }, [currentPage, customerId, statusSelId, customersOptions]);

  const getStyleRow = (i: string) => {
    switch (i) {
      case 'Aguardando Documentação':
        return 'status-color-license-plate';
      case 'Aguardando Faturamento':
        return 'status-color-prepar-wait';
      case 'Aguardando Preparação':
        return 'status-color-license-plate';
      case 'Aguardando Transporte':
        return 'status-color-available';
      case 'Veículo Entregue':
        return 'status-color-active';
      default:
        return 'tatus-color-wait';
    }
  };

  const getStyle = (i: string) => {
    switch (i) {
      case 'Aguardando Documentação':
        return 'box-finance box-finance license-plate';

      case 'Aguardando Faturamento':
        return 'box-finance wait-prepar';

      case 'Aguardando Preparação':
        return 'box-finance tatus-color-wait';

      case 'Aguardando Transporte':
        return 'box-finance available';

      case 'Veículo Disponível':
        return 'box-finance available';

      case 'Veículo Entregue':
        return 'box-finance active';

      default:
        return 'box-finance';
    }
  };

  const [hiddenFilter, setHiddenFilter] = useState(true);
  const handleFilterButton = () => {
    setHiddenFilter(!hiddenFilter);
  };

  return (
    <>
      <Header />
      <Container>
        <Title
          title={'MEUS PEDIDOS'}
          subtitle={'Aqui você visualiza todas as etapas do seu pedido'}
        />

        <WrapperStatus>
          {statusOrders.length ? (
            statusOrders.map((item: any, i) => {
              return (
                <div className="status" key={i}>
                  <div className={getStyle(item.status)}>
                    <p>{item.qtde}</p>
                  </div>
                  <p>{item.status}</p>
                </div>
              );
            })
          ) : (
            <LocationSpin loading={loadingQtde} />
          )}
        </WrapperStatus>

        <WrapperFilterAndTable>
          <Form onSubmit={handleSubmit}>
            <MobileFilter>
              <div className="filter-item">
                <label>Cliente</label>
                <Select onChange={handleChangeCustomerID}>
                  <option value="0">Todos</option>
                  {customersOptions.map((c: any, i: number) => {
                    return (
                      <option key={i} value={c.customerCMSId}>
                        {c.customerName}
                      </option>
                    );
                  })}
                </Select>
              </div>
              <div className="filter-item">
                <label htmlFor="status-orders">Status</label>
                <Select
                  name="status-orders"
                  onChange={handleChangeStatus}
                  value={statusSelId}
                >
                  <option value="0">Todos</option>
                  {statusPCOrders.map((item: any) => (
                    <option key={item.statusId} value={item.statusId}>
                      {item.status}
                    </option>
                  ))}
                </Select>
              </div>

              <button className="filter-button">Buscar</button>
            </MobileFilter>
            <div className="spin">
              <LocationSpin loading={loading} />
            </div>
          </Form>

          <WrapperTable>
            <Table>
              <thead>
                <tr>
                  <th className="large">Placa</th>
                  <th>Chassi</th>
                  <th>Modelo</th>
                  <th>Cor</th>
                  {/* <th className="large">Segmento</th> */}
                  <th>Local de Entrega</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {orders.length ? (
                  orders.map((item: any, i: number) => {
                    return (
                      <tr key={i}>
                        <td>{item.placa}</td>
                        <td>{item.chassi}</td>
                        <td>{item.modelo}</td>
                        <td>{item.cor}</td>
                        {/* <td>{item.segmento}</td> */}
                        <td>{item.localEntrega}</td>
                        <td className={getStyleRow(item.logistica)}>
                          {item.logistica}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7}> "Nenhum registro encontrado!" </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </WrapperTable>

          {total > 0 && (
            <Pagination
              paginate={paginate}
              currentPage={currentPage}
              total={total}
            />
          )}
        </WrapperFilterAndTable>
      </Container>
    </>
  );
};

export default Orders;
