import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledTitle = styled.div`
  font-size: 20px;
  color: #333;
  font-weight: 700;
  display: flex;
  justify-content: center;
  margin: 16px;
`;
const StyledSubtitle = styled.h3`
  margin: 0;
`;
const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  color: #17536c;
  font-weight: 600;
  margin: 16px;
`;

type Props = {
  pageTitle: string;
};

function ErrorLayout({ pageTitle }: Props) {
  const history = useHistory();

  return (
    <StyledContainer>
      <StyledTitle>{pageTitle}</StyledTitle>
      <div className="adyen-checkout__status adyen-checkout__status--error">
        <img
          className="adyen-checkout__status__icon adyen-checkout__image adyen-checkout__image--loaded"
          src="https://checkoutshopper-test.adyen.com/checkoutshopper/images/components/error.gif"
          alt="Cartão não cadastrado. Verifique os dados do cartão e tente novamente."
          height="88"
        />
        <StyledSubtitle>Tivemos um problema</StyledSubtitle>
        <span className="adyen-checkout__status__text">
          Por favor, aguarde um momento e tente novamente.
        </span>
        <StyledButton onClick={() => history.go(0)}>
          Tentar novamente
        </StyledButton>
      </div>
    </StyledContainer>
  );
}

export default ErrorLayout;
