import React, { FC } from 'react';
import { LoadingContainer } from './styles';

const Loading: FC<{ loading: boolean }> = ({ loading }) => (
  <>
    <LoadingContainer className={loading ? 'active' : ''}>
      <div />
      <div />
      <div />
      <div />
    </LoadingContainer>
  </>
);

export default Loading;
