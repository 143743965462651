import React, { useState } from "react"
import { Modal } from "antd"

import { Container } from "./styles"

import api from "services/api"
import { useAuth } from "hooks/auth"

type Props = {
  userId: string,
  func: Function
}

const LmCommunication = ({userId, func}: Props) => {
  const { signOut } = useAuth()

  const [showModal, setShowModal] = useState(true)
  const [lmCommunication, setLmCommunication] = useState<boolean>(true);

  //const url = "http://localhost:5000/api";

  const handleAcceptLmCommunication = async () => {
    setLmCommunication(false)

    api.post(`/users/${userId}/true/AcceptLmCommunication`)
    setLmCommunication(false)

    api.get(`/users/${userId}`).then((res) => {
      const { data } = res;

      if (!data.acceptTermsOfUse || data.acceptTermsOfUse === null) {
        func(true);
      }
    })


  }

  const handleDontAcceptLmCommunication = () => {
    api.post(`/users/${userId}/false/AcceptLmCommunication`)
    signOut()
  }

  return (
    <Modal
    visible={lmCommunication}
    onCancel={handleDontAcceptLmCommunication}
    okText="Aceito"
    onOk={handleAcceptLmCommunication}
    cancelText="Não aceito"
    >
    <Container>
      <h1>Mudamos nosso CNPJ!</h1>
      <p>
        Prezado (a) cliente, a Fleet Solutions se uniu com a LM Frotas com o
        objetivo de ofertar os melhores serviços em mobilidade para você.
      </p>

      <p>
        <strong>Fique Tranquilo. Tudo permanece com a qualidade e a facilidade que você já conhece no seu contrato de locação, </strong>
        com a única diferença que, a partir de 30/04/2022, tivemos a alteração do nosso CNPJ nas suas comunicações e boletos.
      </p>

      <p>
        <strong>Nosso novo CNPJ é o 00.389.481/0029-70</strong>
      </p>
      <p>
        Até Logo. <br />
        Equipe Fleet Solutions
      </p>

      <p>
        <div>
          <p className="canais">Canais de Atendimento</p>

          <p><strong>Telefone: </strong>0800 775 5566 <br />
          <strong>E-mail: </strong> <a href="mailTo:comercial@lmfrotas.com.br">comercial@lmfrotas.com.br</a> </p>

          <p><strong>Horário: segunda a sexta das 8h às 17h.</strong></p>
        </div>
      </p>
    </Container>
    </Modal>
  )
}

export default LmCommunication
