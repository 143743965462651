import styled from 'styled-components';

export const Container = styled.div`
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 73px);

  color: #3c505a;
`;

export const Content = styled.div`
  background: #fff;
  width: 90%;
  margin: 40px;
  padding: 25px;

  @media (min-width: 900px) {
    width: 830px;
  }

  h1,
  & > p,
  select {
    color: #0c5faf;
    font-weight: 500;
  }

  p:nth-child(3) {
    text-align: center;
    margin-top: 18px;
  }

  .wrapper-select {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    width: 100%;

    p {
      margin: 0 25px 0 0;
    }

    & > div {
      width: 100%;
      & > div {
        width: 100%;
      }
    }

    select {
      background-color: #fff;
      border: 2px solid #0c5faf;
      border-radius: 26px;
      width: 100%;
      color: #797979;
      padding-left: 15px;
      height: 40px;
    }
  }

  .loadingDataVehicle {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 20px 0px 0px 0px;
  }

  p {
    @media (max-width: 491px) {
      text-align: center;
    }
  }

  input {
    border: 2px solid #0c5faf;
    border-radius: 26px;
    padding-left: 10px;
  }

  button {
    border: 2px solid #0c5faf;
    border-radius: 26px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  div {
    color: #000000;
    width: 450px;

    .wrapper-info {
      margin: 20px;
      text-align: left;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      label {
        font-size: 15px;

        @media (max-width: 768px) {
          font-size: 13px;
        }

        &:last-child {
          width: 65%;
          text-align: left;

          @media (max-width: 491px) {
            width: 55%;
          }

          @media (max-width: 768px) {
            width: 60%;
          }
        }
      }
    }
  }

  .divClient {
    width: 500px !important;
  }

  .labelClient {
    width: 350px !important;
  }

  @media (max-width: 491px) {
    flex-direction: column;
  }
`;

export const DownloadDoc = styled.div`
  height: 90px;
  width: 90%;
  margin: 40px auto;
  border: 1px solid #0c5faf;
  border-radius: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  gap: 10px;

  svg {
    margin-right: 8px;
  }

  @media (max-width: 491px) {
    flex-direction: column;
    width: auto;
    height: auto;


    label {
      color: #575757
      margin: 0 15px;
      text-align: center;

      @media(max-width: 491px){
        font-size: 15px;
      }
    }
  }
`;

export const Tab = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 2px solid #0c5faf;
  border-radius: 26px;
  width: 250px !important;
  height: 51px;

  label {
    margin-left: 8px;
    font-size: 15px;
    color: #0c5faf;
  }

  @media (max-width: 491px) {
    margin-bottom: 35px;
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  @media (max-width: 1166px) {
    flex-direction: column;
    align-items: center;
    width: 100%;

    .ant-spin {
      line-height: 0;
    }

    @media (max-width: 491px) {
      div {
        width: 100%;
      }
    }
  }
`;

export const WrapperSelect = styled.div`
  align-items: center;
  display: flex;
  margin: 0 0;
  width: 100%;
  justify-content: flex-start;

  @media (max-width: 491px) {
    flex-direction: column;
  }

  p {
    font-size: 15px;
    color: #0c5faf;
    margin: 0 30px 0 0;
  }

  & > div {
    height: 41px;
    margin-top: 0;
    width: 100%;

    @media (max-width: 491px) {
      margin-top: 15px;
    }

    select {
      border-radius: 21px;
      border: 1px solid #0c5faf;
      color: #0c5faf;
      height: 41px;
      max-width: 691px;
      padding-left: 0px;
      width: 100%;

      @media (max-width: 491px) {
        margin-left: 0;
      }
    }
  }
`;

export const Title = styled.p`
  font-size: 15px;
  color: #0c5faf;
  font-weight: 500;
  text-align: center;
`;
