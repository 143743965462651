import React from 'react';

import ButtonUI from 'components/ui/ButtonUI';

interface FilterButtonProps {
  loading: boolean;
  onClick: () => void;
}

function SearchButton({ loading, onClick }: FilterButtonProps) {
  return (
    <ButtonUI
      loading={loading}
      loadingText="Buscar..."
      onClick={onClick}
      type="button"
    >
      Buscar
    </ButtonUI>
  );
}

export default SearchButton;
