import React from 'react';
import styled from 'styled-components';

interface Props {
  children?: React.ReactNode;
  value: string;
  label: string;
  instruction?: string;
  selected?: boolean;
  disabled?: boolean;
  onClick: (value: string) => void;
}

const StyledListItem = styled.li<{ disabled: boolean }>`
  list-style: none;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;

function AdyenPaymentMethod({
  children,
  value,
  label,
  instruction,
  selected = false,
  disabled = false,
  onClick,
}: Props) {
  const handleClick = () => {
    if (!selected) {
      onClick(value);
    }
  };
  return (
    <StyledListItem
      className={`adyen-checkout__payment-method adyen-checkout__payment-method--card ${
        selected
          ? 'adyen-checkout__payment-method--credit adyen-checkout__payment-method--selected'
          : 'adyen-checkout__payment-method--next-selected'
      }`}
      disabled={disabled}
      onClick={handleClick}
    >
      <div className="adyen-checkout__payment-method__header">
        <button
          className="adyen-checkout__payment-method__header__title"
          role="radio"
          type="button"
        >
          <span
            className={`adyen-checkout__payment-method__radio ${
              selected ? 'adyen-checkout__payment-method__radio--selected' : ''
            }`}
          ></span>
          <span className="adyen-checkout__payment-method__name_wrapper">
            <span
              className={`adyen-checkout__payment-method__name ${
                selected ? 'adyen-checkout__payment-method__name--selected' : ''
              }`}
            >
              {label}
            </span>
          </span>
        </button>
      </div>
      {selected && (
        <div className="adyen-checkout__payment-method__details">
          <div className="adyen-checkout__payment-method__details__content">
            <p className="adyen-checkout-form-instruction">{instruction}</p>
            {children}
          </div>
        </div>
      )}
    </StyledListItem>
  );
}

export default AdyenPaymentMethod;
