import moment from 'moment';

// reports name
export const ACTIVE_FLEET_REPORT_NAME = 'Frota Ativa';
export const TRAFFIC_TICKET_REPORT_NAME = 'Multas';
export const REQUEST_REPORT_NAME = 'Solicitações';
export const CONDUCTORS_REPORT_NAME = 'Condutores';

// date format

export const FORMAT_VALUES = {
  fullDate: 'DD/MM/YYYY',
  fullDateTime: 'DD/MM/YYYY HH:mm',
  fullDateApi: 'YYYY-MM-DD',
};

// default values
export const DEFAULT_DATES = {
  initial: moment().subtract(6, 'days').format(FORMAT_VALUES.fullDateApi),
  final: moment().format(FORMAT_VALUES.fullDateApi),
};
export const ITEMS_PER_PAGE = 10;

// menu
export const MENU_ITEMS = [
  ACTIVE_FLEET_REPORT_NAME,
  TRAFFIC_TICKET_REPORT_NAME,
  REQUEST_REPORT_NAME,
  CONDUCTORS_REPORT_NAME,
];
