import Modal from 'components/Modal';
import ButtonUI from 'components/ui/ButtonUI';
import styled from 'styled-components';

export const TableContainer = styled.div`
  overflow-x: auto;
`;

export const CustomModal = styled(Modal)`
  & > div {
    max-width: 960px;
  }
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 10px;
  width: 100%;

  @media (min-width: 768px) {
    padding: 0 80px;
  }

  .name-profile {
    border-radius: 16px;
    border: 1px solid #0c5faf;
    width: 190px;
    height: 38px;
    margin: 0 35px 0 10px;
    margin: 0;
    padding-left: 5px;
  }

  select {
    height: 42px;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 16px;
    border-color: #ccc;
    border: 1px solid #226fbb;
    border-radius: 250px;
  }
`;

export const Title = styled.h1`
  color: #17536c;
  font-size: 25px;
  font-weight: 500;
  margin-top: 25px;
`;

export const Subtitle = styled.p`
  color: #3c505a;
  font-size: 17px;
  font-weight: 400;
  margin: 5px 0 0;
`;

export const LineButtons = styled.div`
  align-items: center;
  display: flex;
  justify-content: end;
  margin-top: 10px;
  width: 100%;
  gap: 25px;
  flex-wrap: wrap;

  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  @media (min-width: 1024px) {
  }

  button {
    background-color: #236fbc;
    border: 2px solid #226fbb;
    border-radius: 24px;
    color: white;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.2px;
    padding: 10px 35px;
  }

  @media (max-width: 768px) {
    & > div {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const WrapperTable = styled.div`
  overflow-x: auto;
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;
  text-align: center;
  border-spacing: inherit;
  margin: 15px 0px 15px 0px;

  .tdNoDate {
    width: 250px !important;
  }
  tbody tr:nth-child(odd) {
    background: #f9f9f9;
  }

  tr {
    font-size: 14px;
  }

  th {
    font-weight: normal;
    background: #3c505a;
    color: #fff;
    height: 36px;

    &:first-child {
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
      padding-left: 10px;
    }

    &:last-child {
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
      padding-right: 10px;
    }
  }

  .large {
    width: 110px;
  }

  @media (max-width: 768px) {
    th,
    td {
      padding: 2px 5px;
      white-space: nowrap;
    }
  }
`;

export const ModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0px solid #707070;
  padding-bottom: 40px;

  & > h2 {
    font-size: 21px;
    color: #0c5faf;
    font-weight: 500;
    margin-top: 40px;
  }

  & > p {
    font-size: 15px;
    color: #3c505a;
    font-weight: 500;
    margin-top: 13px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
  }

  & > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 90%;

    @media (min-width: 768px) {
      flex-direction: row;
    }
    & > a {
      text-decoration: none;
      height: 165px;
      max-width: 225px;
      width: 47%;

      button {
        background-color: white;
        border: 2px solid #0c5faf;
        border-radius: 11px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        position: relative;
        width: 100%;
        height: 100%;
        transition: 0.3s;

        &:hover {
          background-color: #0c5faf4d;
        }

        h3 {
          font-size: 21px;
          font-weight: 500;
          color: #0c5faf;
          margin-top: 60px;
        }

        img {
          width: 40px;
          position: absolute;
          height: 40px;
          top: 15px;
        }
      }

      .button-disabled {
        background-color: #f5f5f5;
        border: 2px solid #d2dadb;
        border-radius: 11px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        position: relative;
        width: 100%;
        height: 100%;
        transition: 0.3s;

        .h3 {
          font-size: 21px;
          font-weight: 500;
          color: #d2dadb;
          margin-top: 60px;
        }
      }
    }
  }

  .theadBudget {
    font-size: 13px;
  }

  .tabBudget {
    border: 0px solid #226fbb;
    border-radius: 5px;

    tbody {
      tr {
        &:nth-child(odd) {
          background: #bcd2ee;
        }

        td {
          :first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }
          :last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
    }
  }

  .tabBudget th {
    font-size: 12px;
    background-color: #236fbc;
    color: #ffffff;
    font-family: arial;
    padding: 2px;
    text-align: center;
    padding-left: 3px;
    padding-right: 3px;

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  .trBudget td {
    font-size: 12px;
    padding: 2px;
    text-align: center;
  }

  .bottomBudget {
    margin-left: 30px;
    align-self: flex-end;
    background-color: #236fbc;
    border: 2px solid #226fbb;
    border-radius: 24px;
    color: white;
    font-size: 12px;
    height: 29px;
    width: 108px;
  }

  .bottomBudgetDisabled {
    background-color: #cacbcd;
    border: 2px solid #6e7b8b;
    border-radius: 24px;
  }

  .txtObrigado {
    font-size: 12px;
    font-weight: bold;
  }
  .textArea {
    border: 2px solid #226fbb;
    border-radius: 5px;
    width: 100%;
  }

  .campoObrigatorio {
    border: 2px solid red;
    border-radius: 5px;
  }

  .tdNoDate {
    width: 250px !important;
    text-align: center;
  }

  .divValorTotal {
    padding-top: 10px;
  }

  .pValorTotal {
    margin-bottom: 0px;
    font-size: 13px;
  }

  .close {
    border: none;
    position: absolute;
    right: 13px;
    top: 10px;
  }
`;

export const SchedulingModalContent = styled(ModalContent)`
  p {
    text-align: center;
    width: 100%;
  }
  > div {
    align-items: baseline;
  }
`;

export const CloseModal = styled.button`
  border: none;
  color: grey;
  background-color: transparent;
  position: absolute;
  right: 13px;
  top: 10px;
  font-size: 18px;

  &::after {
    content: 'x';
  }
`;

export const WrapperButtonModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .message-new-request {
    color: red;
  }
`;

export const Form = styled.form`
  background-color: #fff;
  padding: 33px;
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  border-radius: 16px;

  input,
  select {
    border: 1px solid #0c5faf;
    border-radius: 18px;
    padding: 5px 12px;
    width: 170px;
  }

  label {
    font-size: 14px;
  }

  select {
    height: 38px;
  }

  button {
    margin: 8px 10px;
  }

  .btn-clear-filter {
    color: #17536c;
    background: transparent;
    width: 50px;
  }

  @media (max-width: 768px) {
    label {
      margin: 0;
      width: 75px;
    }
    button {
      margin-left: 0;
    }
    select {
      width: 190px;
      margin: 0;
    }
  }
`;
export const DownloadButton = styled(ButtonUI)`
  font-weight: normal;
  margin-top: 10px;
  padding: 2px 30px;
`;
