import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';

import api from 'services/api';
import { getTheme } from 'pages/LandingPages/utils/helpers';
import useMessage from 'pages/LandingPages/utils/hooks/useMessage';
import { ReactComponent as CheckedIcon } from 'pages/LandingPages/assets/icons/checked-icon.svg';
import { ReactComponent as CloseIcon } from 'pages/LandingPages/assets/icons/close-icon.svg';
import Button from 'pages/LandingPages/components/ui/Button';
import PasswordInput from 'pages/LandingPages/components/ui/PasswordInput';
import Label from 'pages/LandingPages/components/ui/Label';
import FormInfo from 'pages/LandingPages/components/domain/FormInfo';

// estilos
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 32px;
`;
const StyledPasswordCriteria = styled.div`
  font-family: 'Audi Type Screen';
  font-size: 11px;
  font-weight: 400;
  line-height: 120%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const StyledPasswordCriteriaInfo = styled.p`
  margin: 0;
`;
const StyledPasswordCriteriaList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const StyledPasswordCriteriaItem = styled.span`
  display: flex;
  gap: 10px;
`;
const StyledPasswordCriteriaIcon = styled.span<{ color: string }>`
  color: ${(props) => props.color};
  text-align: center;
  width: 16px;
  height: 16px;
`;

// constantes
const SYMBOLS = '#?!@$%^&*-';
const VALIDATION_MESSAGES = {
  minLength: 'A senha deve ter pelo menos 8 caracteres',
  letters: 'A senha deve conter letras',
  uppercaseLetters: 'A senha deve conter pelo menos uma letra maiúscula',
  numbers: 'A senha deve conter pelo menos um número',
  symbols: `A senha deve conter pelo menos um símbolo ${SYMBOLS}`,
  passwordConfirmation: 'A confirmação da senha não corresponde',
};
const NEWPASSWORD_SCHEMA = Yup.string()
  .min(8, VALIDATION_MESSAGES.minLength)
  .matches(/[a-zA-Z]/, VALIDATION_MESSAGES.letters)
  .matches(/[A-Z]/, VALIDATION_MESSAGES.uppercaseLetters)
  .matches(/\d/, VALIDATION_MESSAGES.numbers)
  .matches(new RegExp(`[${SYMBOLS}]`), VALIDATION_MESSAGES.symbols);
const PASSWORD_CRITERIAS_ITEMS = [
  { id: VALIDATION_MESSAGES.minLength, label: '8 caracteres' },
  { id: VALIDATION_MESSAGES.letters, label: 'Letras' },
  { id: VALIDATION_MESSAGES.uppercaseLetters, label: 'Letras maiúsculas' },
  { id: VALIDATION_MESSAGES.numbers, label: 'Números' },
  { id: VALIDATION_MESSAGES.symbols, label: `Simbolos ${SYMBOLS}` },
];

function PasswordCriteriaItem(props: {
  isValid: boolean;
  label: React.ReactNode;
}) {
  const theme = getTheme();

  return (
    <StyledPasswordCriteriaItem>
      <StyledPasswordCriteriaIcon color={theme.checkedIconColor}>
        {props.isValid ? <CheckedIcon /> : <CloseIcon />}
      </StyledPasswordCriteriaIcon>
      {props.label}
    </StyledPasswordCriteriaItem>
  );
}

function ResetpasswordForm() {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const showMessage = useMessage();

  const [values, setValues] = useState({
    newPassword: '',
    passwordConfirmation: '',
  });
  const [validationErrors, setValidationErrors] = useState<String[]>([]);

  useEffect(() => {
    NEWPASSWORD_SCHEMA.validate(values.newPassword, {
      abortEarly: false,
    })
      .then(() => {
        setValidationErrors([]);
      })
      .catch((error: any) => {
        if (error instanceof Yup.ValidationError) {
          setValidationErrors(error.errors);
        }
      });
  }, [values.newPassword]);

  const handleCreatePasswordSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationSchema = Yup.object().shape({
      newPassword: NEWPASSWORD_SCHEMA,
      passwordConfirmation: Yup.string().oneOf(
        [Yup.ref('newPassword'), undefined],
        VALIDATION_MESSAGES.passwordConfirmation
      ),
    });

    validationSchema
      .validate(values, {
        abortEarly: false,
      })
      .then(() => {
        api
          .post('/change-password', { ...values, PasswordResetToken: token })
          .then(() => {
            showMessage.success({
              content: 'Senha editada com sucesso',
            });
            history.push('/');
          })
          .catch(() => {
            showMessage.error({
              content: 'Seu token de redefinição de senha expirou',
            });
          });
      })
      .catch((error: any) => {
        if (error instanceof Yup.ValidationError) {
          showMessage.error({
            content: error.errors[0],
          });
        }
      });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <>
      <FormInfo title="Registrar nova senha" />
      <StyledForm onSubmit={handleCreatePasswordSubmit}>
        <Label label="Senha">
          <PasswordInput
            name="newPassword"
            placeholder="Digite sua senha"
            value={values.newPassword}
            onChange={handleInputChange}
          />
          <PasswordInput
            name="passwordConfirmation"
            placeholder="Confirme sua senha"
            value={values.passwordConfirmation}
            onChange={handleInputChange}
          />
        </Label>
        <StyledPasswordCriteria>
          <StyledPasswordCriteriaInfo>
            Atenção sua senha nova deve conter no mínimo:
          </StyledPasswordCriteriaInfo>
          <StyledPasswordCriteriaList>
            {PASSWORD_CRITERIAS_ITEMS.map(({ id, label }) => (
              <PasswordCriteriaItem
                key={id}
                isValid={!validationErrors.includes(id)}
                label={label}
              />
            ))}
          </StyledPasswordCriteriaList>
        </StyledPasswordCriteria>
        <Button>Criar nova senha</Button>
      </StyledForm>
    </>
  );
}

export default ResetpasswordForm;
