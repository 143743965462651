import React from "react"
import { TitleContainer } from "./styles"

type Props = {
  title: string
  subtitle: string
}

const Title: React.FC<Props> = ({ title, subtitle }: Props) => (
  <TitleContainer>
    <h1>{title}</h1>
    <h3>{subtitle}</h3>
  </TitleContainer>
)

export default Title
