import styled from 'styled-components';

export const Main = styled.div`
  background: #f1f1f1;
  display: flex;
  flex-direction: row;
  height: 100%;
  color: #3c505a;
`;

export const LayoutContainer = styled.div`
  margin-bottom: 200px;
  width: 100%;
`;

export const ReportTitle = styled.p<{ $radius: number }>`
  background-color: #3c505a; // pegar de um tema
  border-radius: ${(props) => props.$radius}px;
  color: #fff;
  margin-bottom: 0;
  padding: 10px;
  text-align: center;
`;

export const ReportHeader = styled.div`
  background-color: #fff;
  border-radius: 25px;
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 12px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  width: 100%;

  @media (min-width: 768px) {
    padding-left: 30px;
    padding-right: 15px;
  }
`;
