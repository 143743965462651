import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: 'Audi Type Screen';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;

type Props = {
  label: string;
  children: React.ReactNode;
};

function Label({ label, children }: Props) {
  return (
    <StyledLabel>
      {label}
      {children}
    </StyledLabel>
  );
}

export default Label;
