import styled from 'styled-components';

export const StyledInvoiceAndBill = styled.div`
  @media (min-width: 426px) {
    a + a {
      margin-left: 10px;
    }
  }
  @media (max-width: 425px) {
    a + a {
      display: block;
      margin-top: 6px;
    }
  }
`;

export const StyledStatus = styled.span<{ bgColor: string }>`
  background-color: ${(props) => props.bgColor};
  color: #fff;
  padding: 2px 4px;
`;
