import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import { getTheme } from 'pages/LandingPages/utils/helpers';

const StyledInput = styled.input<{ $radius: number }>`
  padding: 16px;
  height: 56px;
  font-family: 'Audi Type Screen';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  border-radius: ${(props) => props.$radius}px;
  border: 1px solid #e4e4e4;
  background: #f2f2f2;
`;

function Input(props: InputHTMLAttributes<HTMLInputElement>) {
  const theme = getTheme();
  return <StyledInput $radius={theme.radius} {...props} />;
}

export default Input;
