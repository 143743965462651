import React from 'react';
import { message } from 'antd';

import { ReactComponent as CheckedIcon } from 'pages/LandingPages/assets/icons/checked-icon.svg';
import { ReactComponent as CloseIcon } from 'pages/LandingPages/assets/icons/close-icon.svg';

type ConfigT = {
  content: any;
  duration?: number;
};

function useMessage() {
  const renderIcon = (children: React.ReactNode) => (
    <span role="img" className="anticon">
      {children}
    </span>
  );

  return {
    success: (config: ConfigT) =>
      message.success({
        className: 'toast-landingpage',
        icon: renderIcon(<CheckedIcon />),
        ...config,
      }),
    error: (config: ConfigT) =>
      message.error({
        className: 'toast-landingpage',
        icon: renderIcon(<CloseIcon />),
        ...config,
      }),
  };
}

export default useMessage;
