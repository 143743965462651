import React from 'react';

import LandingpageLayout from 'pages/LandingPages/components/domain/LandingpageLayout';
import Modal from 'pages/LandingPages/components/ui/Modal';
import ResetpasswordForm from 'pages/LandingPages/components/domain/ResetpasswordForm';

function ResetpasswordPage() {
  return (
    <LandingpageLayout>
      <Modal open={true}>
        <ResetpasswordForm />
      </Modal>
    </LandingpageLayout>
  );
}

export default ResetpasswordPage;
