import React from 'react';
import styled from 'styled-components';
import BoxUI from '../BoxUI/BoxUI';

const StyledContainer = styled.fieldset`
  margin-bottom: 15px;
`;

const StyledLegend = styled.legend`
  font-size: inherit;
  margin-bottom: 0;
  text-align: center;
`;

type Props = {
  children: React.ReactNode;
  legend: string;
};

function FieldsetUI({ children, legend, ...props }: Props) {
  return (
    <StyledContainer {...props}>
      <BoxUI color="secondary">
        <StyledLegend>{legend}</StyledLegend>
      </BoxUI>
      <BoxUI>{children}</BoxUI>
    </StyledContainer>
  );
}

export default FieldsetUI;
