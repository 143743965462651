import React from "react"
import Header from "../../components/Header"
import { Link, useHistory } from "react-router-dom"
import { Container } from "./styles"

const CoockiesPolicy: React.FC = () => {
  return (
    <div>

    <Header isPositionAbsolute={false} isPositionFixed={true} />
    <br />
    <Container>

      {/* <Link to="/">Voltar</Link> */}

      <h3>POLÍTICA DE COOKIES DO PORTAL DO CLIENTE DA FLEET SOLUTIONS (“POLÍTICA”).</h3>
      <p className="p_right">Atualizada em 08 de junho de 2021</p>

      <h3>1. INTRODUÇÃO</h3>
      <div>
        A presente Política de Cookies aplica-se ao Portal do Cliente, no site <a href="https://www.portalcliente.fleetbrasil.com.br" target="_blank">https://www.portalcliente.fleetbrasil.com.br</a> da <br />
        <b>FLEETZIL LOCAÇÕES E SERVIÇOS LTDA. (“Fleet Solutions” ou “Nós”)</b>, inscrita no CNPJ/ME sob o nº 25.463.646/0001-36 e visa proporcionar uma experiência ainda mais transparente em nosso <b>Site</b>,
        explicando o conceito de Cookies, como os utilizamos para melhorar sua navegação e, ainda, as possibilidades de customização do uso.<br />
        A continuidade da sua navegação no <b>Site</b> implicará na sua concordância às disposições desta Política em sua integralidade.
        Caso não concorde com o uso de Cookies da forma apresentada, <b>Você</b> poderá: <br />(i) ajustar as configurações de seu navegador de internet para não permitir o uso de Cookies ou (ii) não acessar o <b>Site</b>.
        <br /><br />
        Lembramos que desabilitar o uso de Cookies poderá impactar sua experiência ao navegar no <b>Site</b>.
        <br /><br />
        Para quaisquer questões ou dúvidas sobre esta Política, <b>Você</b> poderá entrar em contato pelos canais de atendimento a seguir:<br />
        &nbsp;&nbsp;&nbsp;&nbsp;(i)	  &nbsp;&nbsp;&nbsp;E-mail: lgpd@fleetbrasil.com.br<br />
        &nbsp;&nbsp;&nbsp;&nbsp;(ii)	&nbsp;&nbsp;&nbsp;Site: https://fleetbrasil.com.br/contato/ <br />
        Recomendamos também a leitura da nossa <a href="https://fleetbrasil.com.br/politica-de-privacidade" target="_blank">Política de Privacidade</a>,
        na qual trazemos a <b>Você</b> mais informações sobre nosso compromisso com a sua privacidade e a proteção dos seus dados pessoais.

      </div>
      <br />

      <h3>2. O QUE SÃO COOKIES</h3>
      <div>
        Cookies são pequenos arquivos digitais em formato de texto que são armazenados no seu dispositivo
        (computador, smartphone, tablet, etc.) pelo navegador de internet e que guardam informações relacionadas
        às suas preferências, como idioma preferido, localização, recorrência das suas sessões,
        e outras variáveis que os desenvolvedores consideram relevantes para tornar a sua experiência muito mais
        eficiente. Usamos o termo “Cookies” nesta Política para referir todos os arquivos que recolhem informações
        desta forma.
      </div>
      <br />

      <h3>3.	PARA QUE SERVEM OS COOKIES?</h3>
      <div>
        Os <i>Cookies</i> servem para aprimorar a sua experiência, tanto em termos de performance,
        como em termos de usabilidade, uma vez que os conteúdos disponibilizados serão direcionados às suas
        necessidades e expectativas.<br />
        Também poderão ser utilizados para compilar estatísticas anônimas e agregadas que permitem entender como
        &nbsp; <b>Você</b> utiliza o <b>Site</b>, bem como para aprimorar suas estruturas e conteúdo. <span className="sublinhado">Por serem estatísticas anônimas,
        não é possível identificá-lo pessoalmente por meio desses dados.</span><br />
        A utilização de <i>Cookies</i> é algo muito comum em qualquer plataforma digital atualmente.
        O seu uso não prejudica de forma alguma os dispositivos em que são armazenados.
      </div>
      <br />
      <h3>4.	QUE TIPO DE COOKIES UTILIZAMOS</h3>
      <div>
        <b>Nós</b> utilizamos Cookies próprios e de terceiros no <b>Site</b>, os quais podem ser classificados quanto à sua temporalidade:
        <ul>
          <li>
            <span className="titulo">Cookies de Sessão</span> - <span className="texto">são os Cookies temporários que permanecem arquivados até que <b>Você</b> saia do <b>Site</b> ou encerre o navegador.</span>
          </li>
          <li>
            <span className="titulo">Cookies Persistentes </span> - <span className="texto">são os Cookies que ficam armazenados no seu dispositivo até que sejam excluídos (o tempo que o Cookie permanecerá no dispositivo depende do “tempo de vida” dele e das configurações do seu navegador de <i>internet</i>).</span>
          </li>
        </ul>

        Os <i>Cookies</i> – de Sessão ou Persistentes – ainda podem ser categorizados de acordo com a sua função:
        <ul>
          <li>
            <span className="titulo">Cookies Estritamente Necessários </span><span className="texto"> - permitem a navegação e utilização das aplicações, bem como o acesso a áreas seguras do <b>Site</b>.</span> <span className="textoSublinhado">Sem estes Cookies, o <b>Site</b> não funciona corretamente.</span>
          </li>
          <li>
            <span className="titulo">Cookies Analíticos</span><span className="texto"> - coletam dados estatísticos anônimos com a finalidade de analisar a utilização do <b>Site</b> e seu respectivo desempenho. </span>
          </li>
          <li>
            <span className="titulo">Cookies de Funcionalidade</span><span className="texto"> - são utilizados para assegurar a disponibilização de funcionalidades adicionais do <b>Site</b> ou para guardar as preferências definidas pelo usuário ao navegar na internet, sempre que utilizar o mesmo dispositivo. </span>
          </li>
          <li>
            <span className="titulo">Cookies de Publicidade</span><span className="texto"> - coletam as informações de sua visita no <b>Site</b> para que as propagandas nele dispostas sejam mais relevantes para <b>Você</b> e de acordo com os seus interesses. Geralmente estão atrelados a Cookies de propriedade de terceiros. Caso Você desabilite estes cookies, note que <b>Você</b> ainda visualizará as publicidades, mas elas não serão personalizadas às suas preferências. </span>
          </li>
        </ul>
      </div>
      <br />
      <h3>5.	É POSSÍVEL CONTROLAR OU EXCLUIR COOKIES?</h3>
      <div>
        Para que nosso <b>Site</b> opere corretamente, <b>Você</b> deverá habilitar o uso dos Cookies. Caso decida não os habilitar,&nbsp;
        <b>Você</b> ainda poderá navegar no <b>Site</b>, mas algumas funcionalidades poderão não estar disponíveis.<br />
        Lembrando que a maioria dos navegadores de internet é configurada para aceitar automaticamente os <i>Cookies</i>.<br />
        <b>Você</b> pode alterar as configurações para bloquear o uso de Cookies ou alertá-lo quando um Cookie estiver sendo enviado para o seu dispositivo.
        Existem várias formas de gerenciar Cookies, consulte as instruções de seu navegador ou a seção de ajuda para saber mais sobre como ajustar ou
        alterar essas configurações.<br />
        Caso <b>Você</b> utilize dispositivos diferentes para acessar o <b>Site</b>, deverá assegurar-se de que cada navegador, de cada
        dispositivo, esteja ajustado para atender às suas preferências em relação aos <i>Cookies</i>.<br />
        Para que <b>Você</b> possa, de forma simples e intuitiva, gerir suas preferências a partir do seu navegador, poderá utilizar um dos <i>links</i> abaixo:<br />
        <ul>
          <li>
            <span className="texto">Para mais informações sobre gestão de <i>Cookies</i> no <b>Firefox</b>, <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank" rel="noopener noreferrer">clique aqui</a>;</span>
          </li>
          <li>
            <span className="texto">Para mais informações sobre gestão de <i>Cookies</i> no <b>Chrome</b>, <a href="https://support.google.com/chrome/answer/95647?hl=pt" target="_blank" rel="noopener noreferrer">clique aqui</a>;</span>
          </li>
          <li>
            <span className="texto">Para mais informações sobre gestão de <i>Cookies</i> no <b>Internet Explorer</b>, <a href="https://support.microsoft.com/pt-pt/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener noreferrer">clique aqui</a>;</span>
          </li>
          <li>
            <span className="texto">Para mais informações sobre gestão de <i>Cookies</i> no <b>Safari</b>, <a href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">clique aqui</a>;</span>
          </li>
          <li>
            <span className="texto">Para mais informações sobre gestão de <i>Cookies</i> no <b>Opera</b>, <a href="https://help.opera.com/en/latest/security-and-privacy/#privateWindow" target="_blank" rel="noopener noreferrer">clique aqui</a>;</span>
          </li>
          <li>
            <span className="texto">Para mais informações sobre gestão de <i>Cookies</i> no <b>Microsoft Edge</b>, <a href="https://support.microsoft.com/pt-br/help/4026200/microsoft-edge-browse-inprivate" target="_blank" rel="noopener noreferrer">clique aqui</a>.</span>
          </li>
        </ul>
      </div>

      <h3>6.	INFORMAÇÕES DETALHADAS SOBRE OS COOKIES</h3>
      <div>
        Apresentamos abaixo os detalhes sobre o uso dos Cookies no <b>Site</b>, bem como as suas finalidades:
      </div>
      <br />

      <h3>6.1.	Cookies Estritamente Necessários</h3>
      <table>
        <tr>
          <th className="head">Nome do Cookie</th>
          <th>Finalidade</th>
          <th>Validade</th>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td></td>
          <td></td>
        </tr>
      </table>
      <br />

      <h3>6.2.	Cookies Analíticos</h3>
      <table>
        <tr>
          <th className="head nomeCookies">Nome do Cookie</th>
          <th className="finalidade">Finalidade</th>
          <th>Validade</th>
        </tr>
        <tr>
          <td><b>_zid</b></td>
          <td rowSpan={3} >Este Cookie registra um ID único que é usado para gerar dados estatísticos sobre como os visitantes utilizam o site.</td>
          <td>Sessão</td>
        </tr>
        <tr>
          <td><b>_ga</b></td>
          <td>2 anos</td>
        </tr>
        <tr>
          <td><b>_gid</b></td>
          <td>1 dia</td>
        </tr>
        <tr>
          <td><b>_gat</b></td>
          <td>Este Cookie é utilizado pelo Google Analytics para acelerar as chances de requisição.</td>
          <td>1 minuto</td>
        </tr>
        <tr>
          <td><b>_gcl_au</b></td>
          <td>Este Cookie é usado para gerar dados estatísticos sobre como os visitantes utilizam o site.</td>
          <td>3 meses</td>
        </tr>
      </table>
    </Container>
    </div>
  )
}

export default CoockiesPolicy
