import React from 'react';

import SelectUI from '../SelectUI';
import DatePickerUI from '../DatePickerUI/DatePicker';
import InputUI from '../InputUI';
import { FieldProps, InputProps, SelectProps } from './types';
import InputFieldContainerUI from './InputFieldContainerUI';

function InputFieldUI({ field, label, ...props }: FieldProps) {
  const renderField = () => {
    switch (field) {
      case 'date':
        return <DatePickerUI {...(props as InputProps)} />;
      case 'select':
        return <SelectUI {...(props as SelectProps)} />;
      default:
        return <InputUI {...(props as InputProps)} />;
    }
  };

  return (
    <InputFieldContainerUI error={props.error} id={props.id} label={label}>
      {renderField()}
    </InputFieldContainerUI>
  );
}

export default InputFieldUI;
