import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useDispatch } from 'react-redux';

import { loginApp } from 'store/modules/login/actions';
import { getTheme } from 'pages/LandingPages/utils/helpers';
import 'pages/LandingPages/assets/styles/fonts.css';
import Logo from 'pages/LandingPages/components/domain/Logo';
import Button from 'pages/LandingPages/components/ui/Button';

// estilos
const GlobalStyles = createGlobalStyle<{
  color: string;
  $iconColor: string;
  $radius: number;
}>`
  .toast-landingpage {
    .ant-message-notice-content {
      padding: 24px 16px;
      width: 342px;
      text-align: left;
      border-radius: ${(props) => props.$radius};
      color: ${(props) => props.color};
      font-family: 'Audi Type Screen';
      font-size: 11px;
      font-weight: 700;
      line-height: 120%;
    }
    .ant-message-custom-content {
      display: flex;
      align-items: center;
    }
    .anticon {
      color: ${(props) => props.$iconColor};
      margin-right: 16px;
      font-size: 11px;
      line-height: 120%;
    }
  }
`;
const StyledContainer = styled.div`
  height: 100vh;
  min-height: 780px;
  display: flex;
  flex-direction: column;
`;
const StyledBackground = styled.div<{ banner: string; bgColor: string }>`
  background-color: ${(props) => props.bgColor};
  background-image: url('${(props) => props.banner}');
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 100%;

  &:before {
    content: '';
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 37.11%,
      ${(props) => props.bgColor} 82.47%
    );
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;
const StyledBox = styled.div`
  margin: 0 auto;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 431px) {
    width: 75%;
  }
  @media (max-width: 430px) {
    width: 90%;
  }
`;
const StyledHeader = styled.header`
  padding-top: 20px;
  padding-bottom: 20px;
`;
const StyledIntro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 70px;
  height: 100%;
  justify-content: end;
  max-width: 326px;
`;
const StyledIntroTitle = styled.h2`
  color: #fff;
  font-family: 'VW Head';
  font-size: 32px;
  font-weight: 700;
  line-height: 110%;
  margin: 0;

  @media (max-width: 430px) {
    font-size: 24px;
  }
`;
const StyledIntroSubtitle = styled.p`
  color: #fff;
  margin: 0;
  font-family: 'Audi Type Screen';
  font-size: 12px;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
`;
const StyledFooter = styled.footer<{ color: string }>`
  font-family: 'Audi Type Screen';
  align-items: center;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 8px 5%;
  color: ${(props) => props.color};

  @media (max-width: 430px) {
    flex-direction: column;
  }
`;
const StyledRightsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 9px;
  padding: 16px 0px;
  width: 324px;
`;
const StyledRightsText = styled.div`
  flex: 1;
  text-align: right;
  font-size: 11px;
  font-weight: 400;
`;
const StyledTermsContainer = styled.div`
  display: inline-flex;
  gap: 16px;

  @media (max-width: 430px) {
    height: 40px;
    align-items: center;
    width: 100%;
    justify-content: space-around;
  }
`;
const StyledTermsLink = styled.a<{ color: string }>`
  font-size: 11px;
  font-weight: 700;
  color: ${(props) => props.color};

  &:hover {
    color: ${(props) => props.color};
  }
`;

// tipagem
type Props = {
  children: React.ReactNode;
};

function LandingpageLayout({ children }: Props) {
  const dispatch = useDispatch();
  const theme = getTheme();

  const handleShowSigninModal = () => {
    dispatch(loginApp({ status: true }));
  };

  return (
    <>
      <GlobalStyles
        color={theme.textColor}
        $iconColor={theme.checkedIconColor}
        $radius={theme.radius}
      />
      <StyledContainer>
        <StyledBackground banner={theme.banner} bgColor={theme.textColor}>
          <StyledBox>
            <StyledHeader>
              <Logo src={theme.secondLogo} />
            </StyledHeader>
            <StyledIntro>
              <StyledIntroTitle>Acompanhe aqui sua assinatura</StyledIntroTitle>
              <StyledIntroSubtitle>
                Tenha controle total sobre sua assinatura, muito mais
                praticidade para cuidar do veículo.
              </StyledIntroSubtitle>
              <Button
                color={theme.firstButtonBgcolor}
                onClick={handleShowSigninModal}
              >
                Acesse já
              </Button>
            </StyledIntro>
          </StyledBox>
        </StyledBackground>
        <StyledFooter color={theme.textColor}>
          <StyledRightsContainer>
            <Logo src={theme.firstLogo} />
            <StyledRightsText>Todos os direitos reservados</StyledRightsText>
          </StyledRightsContainer>
          <StyledTermsContainer>
            <StyledTermsLink
              href={theme.linkPrivacy}
              target="_blank"
              color={theme.textColor}
            >
              Privacidade e segurança
            </StyledTermsLink>
            <StyledTermsLink color={theme.textColor}>
              Termos de uso
            </StyledTermsLink>
          </StyledTermsContainer>
        </StyledFooter>
      </StyledContainer>
      {children}
    </>
  );
}

export default LandingpageLayout;
