import React, { FC, useEffect, useCallback, useState } from 'react';
import api from '../../services/api';

import Header from '../../components/Header';
import Title from '../../components/Title';
import iconCar from '../../assets/icons/car.svg';

import { useAuth } from '../../hooks/auth';

import {
  Container,
  Content,
  Tab,
  Info,
  DownloadDoc,
  WrapperContent,
} from './styles';
import formatValue from '../../utils/formatValue';
import moment from 'moment';
import fileDownload from 'js-file-download';
import { useToast } from '../../hooks/toast';
import CommunicationStayPrimaryLandscape from 'material-ui/svg-icons/communication/stay-primary-landscape';
import { number } from 'yup';

import LocationSpin from '../../components/LoadingSpin';
import LocationSpinCrlv from '../../components/LoadingSpin';

import SelectVehicle from './../../components/selectVehicle/index';
import { createBlob, decodeBase64, downloadBlob } from 'utils/downloadFile';

const MyVehicles: FC = () => {
  const [statusContracts, setStatusContracts] = useState<any>();
  const [customersOptions, setCustomersOptions] = useState([]);
  const [plaqueId, setPlaqueId] = useState<string>('');
  const [renavam, setRenavam] = useState('');
  const [odometer, setOdometer] = useState('');
  const [ufEmplacamento, setUfEmplacamento] = useState('');

  const { addToast } = useToast();
  const { userId } = useAuth();

  const [loadingSkinCrlv, setLoadingSkinCrlv] = useState(false);
  const [loadingSkinDataVehicle, setLoadingSkinDataVehicle] = useState(false);

  useEffect(() => {
    // Buscar as placas para carregar no combo
    // var url = "http://localhost:5002/api";
    // setLoadingSkin(true);
    // api
    //   .get(`/crm/reports/vehicle_plaque-by-customer_LM/${userId}`)
    //   .then((response) => {
    //     console.log(response.data)
    //     setCustomersOptions(response.data)
    //     setLoadingSkin(false);
    //   })
    //   .catch(error => {
    //     setLoadingSkin(false);
    //   })
  }, []);

  // useEffect(() => {
  //   getVehicleByPlaque()
  // }, [plaqueId])

  const getVehicleByPlaque = useCallback(
    async (plaqueId: string) => {
      setPlaqueId(plaqueId);
      console.log(`plaqueId: ${plaqueId}`);
      if (plaqueId == '') {
        setRenavam('');
        setStatusContracts(null);
        return;
      }
      setStatusContracts(null);
      setLoadingSkinDataVehicle(true);
      var url = 'http://localhost:5002/api';
      api
        .get(
          `/crm/reports/vehicle_by_plaque-by-customer_LM/${userId}/${plaqueId}`
        )
        .then((response) => {
          console.log(response.data[0]);
          if (response.data.length == 0) {
            addToast({
              title: 'Erro',
              type: 'error',
              description: 'A placa informada não foi encontrada!',
            });
            setLoadingSkinDataVehicle(false);
            return;
          }
          setRenavam(response.data[0].veiculoRenavam);

          setStatusContracts(response.data[0]);
          setUfEmplacamento(response.data[0].ufEmplacamento);

          // buscar o km
          //getKM(response.data[0].veiculoChassi)
          setOdometer(response.data[0].kmAtual);
          setLoadingSkinDataVehicle(false);
        })
        .catch((error) => {
          setLoadingSkinDataVehicle(false);
          console.log(error);
        });
    },
    [userId, plaqueId, renavam, ufEmplacamento]
  );

  const getKM = async (chassi: string) => {
    setOdometer('');
    const url = 'http://localhost:5002/api';
    await api
      .get(`/reports/GetVehiclesScopeResponse/${chassi}`)
      .then((response) => {
        console.log(response.data.value);
        if (response.data.value.length > 0) {
          // addToast({
          //   title: "Erro",
          //   type: "error",
          //   description: "KM não encontrado!",
          // })
          //return;
          setOdometer(response.data.value[0].decimalOdometer.split('.')[0]);
        }
      });
  };

  const search = () => {
    if (plaqueId == '') {
      addToast({
        title: 'Erro',
        type: 'error',
        description: 'Informar uma placa !',
      });
      return;
    }
    getVehicleByPlaque(plaqueId);
  };

  const setPlaque = (value: string) => {
    setPlaqueId(value);
    if (value == '') {
      setRenavam('');
      setStatusContracts(null);
    }
  };

  const downloadCRLV = (base64: string) => {
    // precisa decodificar base64 antes
    downloadBlob(
      createBlob(decodeBase64(base64), 'application/pdf'),
      'CRLVe_' + renavam + '.pdf'
    );
  };

  const getCRLV = useCallback(
    (endpoint = 'getCRLV') => {
      setLoadingSkinCrlv(true);
      api
        .get(`/crm/reports/${endpoint}/${renavam}`)
        .then((response) => {
          if (response.data.base64) {
            downloadCRLV(response.data.base64);
          } else {
            throw 'Não foi possível baixar o CRLV.';
          }
        })
        .catch(() => {
          addToast({
            title: 'Erro',
            type: 'error',
            description: 'Não foi possível baixar o CRLV.',
          });
        })
        .finally(() => setLoadingSkinCrlv(false));
    },
    [renavam, ufEmplacamento]
  );

  const handle = useCallback((placa: number) => {
    console.log(placa);
  }, []);

  return (
    <>
      <Header />

      <Container>
        <Title
          title={'meus veículos'}
          subtitle={'Consulte aqui todos os dados do seu contrato'}
        />

        <WrapperContent>
          {/* <Tab>
            <img src={iconCar} alt="iconCar" />
            <label>Dados dos Veículos</label>
          </Tab> */}

          <Content>
            {/* <div className="wrapper-select">
              <p>Placa</p>
              <select
                id="plaqueId"
                name="plaqueId"
                onChange={(e) => getVehicleByPlaque(e.target.value)}
                value={plaqueId}
                style={{ width: "10" }}
              >
                <option value="">Selecione uma Placa</option>
                {customersOptions.map((statusContracts: any, i: number) => (
                  <option key={i} value={statusContracts.veiculoId}>
                    {statusContracts.veiculoPlaca}
                  </option>
                ))}
              </select>
              &nbsp;&nbsp;
              <LocationSpin loading={loadingSkin} />
            </div> */}

            {/* select vehicle */}

            <div className="wrapper-select">
              <p>Placa</p>
              <SelectVehicle
                userId={userId}
                clienteId={null}
                id={plaqueId}
                func={getVehicleByPlaque}
              />
            </div>
            {/* fim select vehicle */}

            {statusContracts ? (
              <div className="info-car">
                {/* <p style={{ textAlign: "center" }}>Cliente</p> */}
                <DownloadDoc>
                  {/* <VscFilePdf size="25" /> */}
                  <LocationSpinCrlv loading={loadingSkinCrlv} />
                  <a onClick={() => getCRLV()}>Clique aqui</a>{' '}
                  <label>para realizar o download do seu CRLV Digital.</label>
                </DownloadDoc>
                <Info>
                  <div>
                    <div className="wrapper-info">
                      <label>Cliente</label>
                      <label>
                        {statusContracts?.clientesNome
                          ? statusContracts?.clientesNome
                          : '--'}
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="wrapper-info">
                      <label>Status</label>
                      <label>
                        {statusContracts?.statusVeiculoContratoNome
                          ? statusContracts?.statusVeiculoContratoNome
                          : '--'}
                      </label>
                    </div>
                  </div>
                </Info>
                {/* <p style={{ textAlign: "center" }}>Veículo</p> */}
                <Info>
                  <div>
                    <div className="wrapper-info">
                      <label>Placa</label>
                      <label>
                        {statusContracts?.veiculoPlaca
                          ? statusContracts?.veiculoPlaca
                          : '--'}
                      </label>
                    </div>

                    <div className="wrapper-info">
                      <label>Renavam</label>
                      <label>
                        {statusContracts?.veiculoRenavam
                          ? statusContracts?.veiculoRenavam
                          : '--'}
                      </label>
                    </div>

                    <div className="wrapper-info">
                      <label>Condutor</label>
                      <label>
                        {statusContracts?.condutorNome
                          ? statusContracts?.condutorNome
                          : '--'}
                      </label>
                    </div>

                    <div className="wrapper-info">
                      <label>Apuração KM</label>
                      <label>{odometer}</label>
                    </div>
                  </div>
                  <div>
                    <div className="wrapper-info">
                      <label>Chassi</label>
                      <label>
                        {statusContracts?.veiculoChassi
                          ? statusContracts?.veiculoChassi
                          : '--'}
                      </label>
                    </div>

                    <div className="wrapper-info">
                      <label>Modelo</label>
                      <label>
                        {statusContracts?.modeloNome
                          ? statusContracts?.modeloNome
                          : '--'}
                      </label>
                    </div>

                    <div className="wrapper-info">
                      <label>Status</label>
                      <label>
                        {statusContracts?.statusVeiculoContratoNome
                          ? statusContracts?.statusVeiculoContratoNome
                          : '--'}
                      </label>
                    </div>
                  </div>
                </Info>

                {/* <p style={{ textAlign: "center" }}>Financeiro</p>
                <Info>
                  <div>
                    <div className="wrapper-info">
                      <label>Dias para pagamento</label>
                      <label>
                        {statusContracts?.pagamento
                          ? statusContracts?.pagamento
                          : "--"}
                      </label>
                    </div>
                    <div className="wrapper-info">
                      <label>Dias para fechamento</label>
                      <label>
                        {statusContracts?.fechamento
                          ? statusContracts?.fechamento
                          : "--"}
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="wrapper-info">
                      <label>Centro de Custo</label>
                      <label>
                        {statusContracts?.centralcusto
                          ? statusContracts?.centralcusto
                          : "--"}
                      </label>
                    </div>
                  </div>
                </Info> */}
                {/* <p style={{ textAlign: "center" }}>Veículo no Contrato</p> */}
                <Info>
                  <div>
                    {/* <div className="wrapper-info">
                      <label>Status Frota</label>
                      <label>
                        {statusContracts?.frota ? statusContracts?.frota : "--"}
                      </label>
                    </div> */}
                    <div className="wrapper-info">
                      <label>Início da Vigência</label>
                      <label>
                        {statusContracts?.contratoVeiculoDataAbertura
                          ? moment(
                              statusContracts?.contratoVeiculoDataAbertura
                            ).format('DD/MM/YYYY')
                          : '--'}
                      </label>
                    </div>
                    <div className="wrapper-info">
                      <label>Valor Mensal</label>
                      <label>
                        {statusContracts?.contratoVeiculoValorMensal
                          ? formatValue(
                              statusContracts?.contratoVeiculoValorMensal
                            )
                          : '--'}
                      </label>
                    </div>
                    <div className="wrapper-info">
                      <label>KM Excedente</label>
                      <label>
                        {statusContracts?.propostaItemManutencaoKmExcedente
                          ? statusContracts?.propostaItemManutencaoKmExcedente
                          : '--'}
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="wrapper-info">
                      <label>Final da Vigência</label>
                      <label>
                        {statusContracts?.contratoVeiculoDataFinal
                          ? moment(
                              statusContracts?.contratoVeiculoDataFinal
                            ).format('DD/MM/YYYY')
                          : '--'}
                      </label>
                    </div>

                    <div className="wrapper-info">
                      <label>KM Franquia Mensal</label>
                      <label>
                        {statusContracts?.contratoItemKmFranquia
                          ? statusContracts?.contratoItemKmFranquia
                          : '--'}
                      </label>
                    </div>
                    <div className="wrapper-info">
                      <label></label>
                      <label>&nbsp;</label>
                    </div>
                  </div>
                </Info>
              </div>
            ) : (
              <div className="loadingDataVehicle">
                <LocationSpin loading={loadingSkinDataVehicle} />
              </div>
            )}
          </Content>
        </WrapperContent>
      </Container>
    </>
  );
};

export default MyVehicles;
