import React, { useState } from "react"
import { Modal } from "antd"

import * as S from "./styles"
import api from "services/api"
import { useAuth } from "hooks/auth"

type Props = {
  userId: string
}

const AcceptCommunication = ({ userId }: Props) => {
  const { signOut } = useAuth()

  const [showModal, setShowModal] = useState(true)

  const handleAccept = async () => {
    await api.post(`/users/${userId}/true/AcceptCommunication`)
    setShowModal(false)
  }

  const handleCancel = async () => {
    await api.post(`/users/${userId}/false/AcceptCommunication`)
    setShowModal(false)
    signOut()
  }

  return (
    <Modal
      visible={showModal}
      onCancel={handleCancel}
      okText="Aceito"
      onOk={handleAccept}
      cancelText="Não aceito"
    >
      <S.Container>
        <img
          src="https://signanddrive.s3.sa-east-1.amazonaws.com/Portal+do+Cliente/imagens/CRLV+_+31_01.jpg"
          alt="Banner"
        />
      </S.Container>
    </Modal>
  )
}

export default AcceptCommunication
