import React from 'react';
import { ADM_POS_VENDA_ID, GESTOR_CONTRATO_ID } from '../utils/constants';
import { getRolesApi } from '../utils/services';

type RolesT = {
  roleId: number;
  name: string;
  inactive: boolean;
  menus: null;
};

function useFetchRoles() {
  const [loading, setLoading] = React.useState(false);
  const [roles, setRoles] = React.useState<RolesT[]>([]);

  React.useEffect(() => {
    const fetchRoles = () => {
      setLoading(true);
      getRolesApi()
        .then((data: RolesT[]) => {
          const currentRoleId = localStorage.getItem('@FleetSolutions:roleId');
          let rolesData = data;

          if (currentRoleId === process.env.REACT_APP_POST_SALES_ADM) {
            rolesData = data.filter(
              (role) =>
                role.roleId === Number(process.env.REACT_APP_CONTRACT_MANAGER)
            );
          }
          setRoles(rolesData);
        })
        .finally(() => setLoading(false));
    };

    fetchRoles();
  }, []);

  return { loading, roles };
}

export default useFetchRoles;
