import React from 'react';
import styled from 'styled-components';
import { Modal as ModalAntd } from 'antd';

import { getTheme } from 'pages/LandingPages/utils/helpers';

// estilos
const StyledModal = styled(ModalAntd)<{ color: string; radius: number }>`
  color: ${(props) => props.color};
  h2 {
    color: ${(props) => props.color};
  }
  .ant-modal-content {
    border-radius: ${(props) => props.radius}px;
  }
  .ant-modal-body {
    padding: 32px 32px 64px;
  }
`;

type Props = {
  children: React.ReactNode;
  open: boolean;
  onCancel?: any;
};

function Modal({ children, open, ...props }: Props) {
  const theme = getTheme();
  return (
    <StyledModal
      open={open}
      closable={false}
      footer={null}
      width={360}
      centered
      color={theme.textColor}
      radius={theme.radius}
      {...props}
    >
      {children}
    </StyledModal>
  );
}

export default Modal;
