import React from 'react';
import { Drawer, MenuItem } from '@material-ui/core';
import styled from 'styled-components';

import { MENU_ITEMS } from 'pages/Reports/utils/constants';
import { getTheme } from 'pages/LandingPages/utils/helpers';

export const Container = styled(Drawer)<{ $radius?: number }>`
  .MuiPaper-root {
    position: relative;
    padding: 10px 15px;
  }

  .MuiDrawer-paperAnchorDockedLeft {
    border-right: 0;
    li {
      width: 160px;
      margin-bottom: 10px;
      font-size: 0.9rem;
      justify-content: center;
      font-weight: 500;
    }
  }

  .MuiListItem-root {
    border-radius: ${(props) => props.$radius}px;
    &.Mui-selected {
      background-color: #0606da;
      color: #fff;
      &:hover {
        background-color: #0606da;
      }
    }
  }

  .MuiTouchRipple-root @media (max-width: 650px) {
    flex-direction: column;

    .active {
      height: auto;
      padding: 15px 0;
      margin: 0;
    }

    .MuiDrawer-paperAnchorDockedLeft {
      flex-direction: row;
      flex-wrap: wrap;
      z-index: 1;

      p {
        width: 100%;
      }

      li {
        width: auto;
        margin: 0;
      }
    }
  }
`;

export const MenuTitle = styled.p`
  font-size: 17px;
  font-weight: 500;
  padding: 15px;
  text-align: center;

  &:after {
    background-color: #ccc;
    content: '';
    display: block;
    height: 1px;
  }
`;

type ReportsMenuProps = {
  active: string;
  onClick: (item: string) => void;
};

function ReportsMenu({ active, onClick }: ReportsMenuProps) {
  const theme = getTheme();

  return (
    <Container
      open={true}
      variant="persistent"
      anchor="left"
      $radius={theme.radius}
    >
      <MenuTitle>Relatórios</MenuTitle>

      {MENU_ITEMS.map((item) => (
        <MenuItem
          key={item}
          selected={item === active}
          onClick={() => onClick(item)}
        >
          <span style={{ marginRight: 10 }}>{item}</span>
        </MenuItem>
      ))}
    </Container>
  );
}

export default ReportsMenu;
