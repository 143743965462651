import styled from "styled-components"

export const Container = styled.div`
  margin: 8px;

  h1 {
    color: #17536c;
    line-height: 1.3em;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 26px;
  }

  .date {
    font-size: 12px;
    text-align: right;
  }

  .title {
    font-weight: bold;
    color: #17536c;
  }
`
