import React, { useState } from 'react';
import '@adyen/adyen-web/dist/adyen.css';
import Header from 'components/Header';
import ContractSelect from './components/ContractSelect';
import ChangePaymentMethod from './components/ChangePaymentMethod';
import CurrentPaymentMethod from './components/CurrentPaymentMethod';
import styled from 'styled-components';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Spin } from 'antd';
import { getProductFromUrl } from 'pages/LandingPages/utils/helpers';
import ErrorLayout from './components/ErrorLayout';

const StyledContainer = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    width: 50%;
    margin: 0 auto;
  }
`;
const StyledTitle = styled.div`
  font-size: 20px;
  color: #333;
  font-weight: 700;
  display: flex;
  justify-content: center;
  margin: 16px;
`;
const StyledBox = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
`;
const StyledContractNumber = styled.p`
  font-size: 20px;
  color: #17536c;
  font-weight: 600;
  margin-bottom: 10px;
`;
const StyledWrapperCurrentMethod = styled.div`
  padding: 16px;
`;

export interface CurrentPaymentMethodI {
  numeroContrato: string;
  clienteCpf: string;
  formaPagamentoId: number;
  cartaoToken: string;
}
interface CurrentPaymentMethodResponseI {
  success: boolean;
  statusCode: number;
  totalRecords: number;
  title: string;
  message: string;
  data: CurrentPaymentMethodI;
}
interface CreditCardI {
  clientDocumentNumber: string;
  contractNumber: string;
  cardNumber: string;
  paymentMethodFlag: keyof typeof CARDS_LABEL;
}
interface CreditCardResponseI {
  data: CreditCardI[];
  statusCode: number;
  success: boolean;
}
export interface DisabledOptionsI {
  bankSlip: boolean;
  creditCard: boolean;
}

export const CREDIT_CARD_PAYMENT_METHOD_ID = 36;
export const BANK_SLIP_PAYMENT_METHOD_ID = 19;
export const CARDS_LABEL = {
  visa: 'Visa',
  mc: 'Mastercard',
  amex: 'Amex',
  cup: 'China UnionPay',
  diners: 'Diners',
  elo: 'Elo',
  hipercard: 'Hipercard',
  maestro: 'Maestro',
};

const PaymentMethodsPage = () => {
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [payment, setPayment] = useState<CurrentPaymentMethodI>({
    clienteCpf: '',
    formaPagamentoId: 0,
    numeroContrato: 'xxxxxxxx',
    cartaoToken: '',
  });
  const [cardNumber, setCardNumber] = useState('');
  const [cardFlag, setCardFlag] = useState<keyof typeof CARDS_LABEL | ''>('');
  const [currentMethodId, setCurrentMethod] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [disabledOptions, setDisabledOptions] = useState<DisabledOptionsI>({
    bankSlip: true,
    creditCard: true,
  });

  const fetchCurrentPaymentMethod = async (contractNumber: string) => {
    try {
      const response: AxiosResponse<CurrentPaymentMethodResponseI> =
        await axios.get(
          `${process.env.REACT_APP_HOST_PAYMENT}/v2/EuroIT/Payments/Methods?contractNumber=${contractNumber}`
        );
      return response?.data?.data;
    } catch (error) {
      const axiosError = error as AxiosError;
      // maneira de identificar contratos S&D anterior Dez/2023
      if (axiosError?.response?.status === 404) {
        return {
          numeroContrato: contractNumber,
          clienteCpf: '',
          formaPagamentoId: BANK_SLIP_PAYMENT_METHOD_ID,
          cartaoToken: '',
        };
      }
      return {
        clienteCpf: '',
        formaPagamentoId: 0,
        numeroContrato: 'xxxxxxxx',
        cartaoToken: '',
      };
    }
  };

  const fetchCreditCard = async (contractNumber: string, cpfCnpj: string) => {
    // retorna o último cartão utilizado
    const response: AxiosResponse<CreditCardResponseI> = await axios.post(
      `${process.env.REACT_APP_HOST_PAYMENT}/v2/Payment/CreditCardUsed`,
      {
        contractNumber,
        clientDocumentNumber: cpfCnpj,
      }
    );
    setCardNumber(response?.data?.data?.[0]?.cardNumber);
    setCardFlag(response?.data?.data?.[0]?.paymentMethodFlag);
  };

  const showCurrentMethod = async (
    paymentMethodId: number,
    contractNumber: string,
    cpfCnpj: string
  ) => {
    if (paymentMethodId === CREDIT_CARD_PAYMENT_METHOD_ID) {
      await fetchCreditCard(contractNumber, cpfCnpj);
      setCurrentMethod(CREDIT_CARD_PAYMENT_METHOD_ID);
      return;
    }
    if (paymentMethodId === BANK_SLIP_PAYMENT_METHOD_ID) {
      setCurrentMethod(BANK_SLIP_PAYMENT_METHOD_ID);
    }
  };

  const determineDisabledOptions = (cpfCnpj: string) => {
    const productKey = getProductFromUrl();
    if (!cpfCnpj || productKey === 'rentalway') {
      return {
        bankSlip: true,
        creditCard: true,
      };
    }
    if (productKey === 'assinecar') {
      return {
        bankSlip: true,
        creditCard: false,
      };
    }
    return {
      bankSlip: false,
      creditCard: false,
    };
  };

  const initPaymentMethod = async (contractNumber: string) => {
    try {
      setLoading(true);
      // buscar método de pagamento atual
      const currentMethodData = await fetchCurrentPaymentMethod(contractNumber);
      setPayment(currentMethodData);
      // mostrar o método de pagamento atual
      await showCurrentMethod(
        currentMethodData?.formaPagamentoId,
        currentMethodData?.numeroContrato,
        currentMethodData?.clienteCpf
      );
      // esconder opção selecionada
      setSelectedOption('');
      // habilitar métodos de pagamento
      setDisabledOptions(
        determineDisabledOptions(currentMethodData?.clienteCpf)
      );
    } finally {
      setLoading(false);
    }
  };

  const pageTitle = 'Método de pagamento';

  return (
    <>
      <Header />
      {showError ? (
        <ErrorLayout pageTitle={pageTitle} />
      ) : (
        <Spin spinning={loading}>
          <StyledContainer>
            <StyledTitle>{pageTitle}</StyledTitle>
            <ContractSelect
              initPaymentMethod={initPaymentMethod}
              setLoading={setLoading}
            />
            <StyledBox>
              <StyledWrapperCurrentMethod>
                <StyledContractNumber>
                  {payment?.numeroContrato}
                </StyledContractNumber>
                <CurrentPaymentMethod
                  currentMethodId={currentMethodId}
                  cardFlag={cardFlag}
                  cardNumber={cardNumber}
                />
              </StyledWrapperCurrentMethod>
              <StyledTitle>Alterar método de pagamento</StyledTitle>
              <ChangePaymentMethod
                payment={payment}
                selectedOption={selectedOption}
                disabledOptions={disabledOptions}
                onSelectMethod={(value: string) => setSelectedOption(value)}
                initPaymentMethod={initPaymentMethod}
                setLoading={setLoading}
              />
            </StyledBox>
          </StyledContainer>
        </Spin>
      )}
    </>
  );
};

export default PaymentMethodsPage;
