import { useMemo } from 'react';
import { UsePaginateDataProps } from './types';

export function usePaginateData({
  data,
  itemsPerPage,
  page,
}: UsePaginateDataProps) {
  const paged = useMemo(() => {
    const end = page * itemsPerPage;
    const start = end - itemsPerPage;

    return data.slice(start, end);
  }, [data, page]);

  return paged;
}
