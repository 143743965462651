import React, { FC, useEffect, useState, useCallback } from "react"
import { useHistory, useParams } from "react-router"

import Header from "../../components/Header"
import Title from "../../components/Title"
import iconEdit from "../../assets/icons/edit.svg"
import iconTrash from "../../assets/icons/trash.svg"
import { useToast } from "../../hooks/toast"
import { Container, WrapperTable, Table, ContentModal } from "./styles"
import api from "../../services/api"
import { Modal } from "antd"
import Pagination from "../../components/Pagination"

const AccessControl: FC = () => {
  const history = useHistory()
  const { id } = useParams<any>()

  const [roles, setRoles] = useState<any>([])
  const [limit, setLimit] = useState<number>(0)
  const [totalPerPage] = useState<number>(15)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [total, setTotal] = useState(0)

  const [openModal, setOpenModal] = useState(false)
  const [roleId, setRoleId] = useState(0)

  const { addToast } = useToast()

  useEffect(() => {
    getAllRoles()
  }, [currentPage])

  const getAllRoles = async () => {
    await api.get("/roles").then((response: any) => {
      const { data } = response

      if (data.length === 1) {
        setRoles(data)
      } else {
        const indexOfLast = currentPage * 15
        const indexOfFirst = indexOfLast - 15
        const currentData = data.slice(indexOfFirst, indexOfLast)

        setRoles(currentData)
      }
      setTotal(data.length)
    })
  }

  const createProfile = () => {
    history.push("/new-profile")
  }

  const modalOk = () => {
    disableRole(roleId)
  }

  const modalClose = () => {
    setOpenModal(false)
  }

  const disableRole = useCallback(async (id: any) => {
    await api
      .patch(`/roles/disable/${id}`)
      .then(() => {
        setOpenModal(false)

        addToast({
          title: "Usuário excluído",
          type: "success",
          description: "Perfil excluído com sucesso.",
        })
        getAllRoles()
      })
      .catch((error: any) => {
        setOpenModal(false)
        addToast({
          title: "Erro",
          type: "error",
          description: "Erro ao tentar excluir o perfil.",
        })
      })
  }, [])

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber)

  return (
    <>
      <Header />

      <Container>
        <Title
          title={"PERFIS DE USUÁRIO"}
          subtitle={"Visualize, edite e crie perfis de usuário"}
        />

        <WrapperTable>
          <button onClick={createProfile} className="btn-new-profile">
            Novo Perfil
          </button>
          <Table>
            <thead>
              <tr>
                <th className="large">Nome</th>
                <th>Status</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
              {roles.length ? (
                roles.map((role: any) => (
                  <tr>
                    <td>{role.name}</td>
                    <td className="wrapper-status">
                      <div
                        className="status"
                        style={{
                          background: role.inactive ? "red" : "green",
                        }}
                      ></div>
                      {!role.inactive ? "Ativo" : "Inativo"}
                    </td>
                    <td>
                      <button
                        onClick={() =>
                          history.push(`/new-profile/${role.roleId}`)
                        }
                      >
                        <img src={iconEdit} alt="Edit" />
                      </button>
                      <button
                        onClick={() => (
                          setOpenModal(true), setRoleId(role.roleId)
                        )}
                      >
                        <img src={iconTrash} alt="Trash" />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3}>
                    <p>Nenhum perfil encontrado</p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <Pagination
            paginate={paginate}
            currentPage={currentPage}
            total={total}
          />
        </WrapperTable>

        <Modal footer={null} title="" visible={openModal} onCancel={modalClose}>
          <ContentModal>
            <p>Deseja desabilitar esse perfil?</p>
            <div>
              <button onClick={() => modalOk()}>Sim</button>
              <button onClick={() => modalClose()}>Não</button>
            </div>
          </ContentModal>
        </Modal>
      </Container>
    </>
  )
}

export default AccessControl
