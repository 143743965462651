import { Modal, Spin } from 'antd';
import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { loginApp } from 'store/modules/login/actions';
import { useDispatch } from 'react-redux';
import logoImg from '../../assets/LM/Fundo_transparente_M.png';
import { ContentModal, Button } from './style';
import { FormHandles } from '@unform/core';
import Input from '../../components/Input';
import { FiMail, FiFile } from 'react-icons/fi';
import * as Yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';
import api from '../../services/api';
import { useToast } from 'hooks/toast';

interface FirstAccessFormData {
  email: string;
  cpfCnpj: string;
}

type Props = {
  isFirstAccess: boolean;
  handleClosedModalFirstAccess: () => void;
};

function FirstAccess({ isFirstAccess, handleClosedModalFirstAccess }: Props) {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleOk = () => {
    handleClosedModalFirstAccess();
    dispatch(loginApp({ status: false }));
  };

  const handleCancel = () => {
    handleClosedModalFirstAccess();
    dispatch(loginApp({ status: false }));
  };

  const handleSubmitFirstAccess = (data: FirstAccessFormData) => {
    const schema = Yup.object().shape({
      email: Yup.string()
        .required('E-mail obrigatório')
        .email('Digite um e-mail válido'),
      cpfCnpj: Yup.string()
        .required('CNPJ obrigatório')
        .matches(/^\d{14}$/, 'CNPJ deve ter 14 dígitos'),
    });

    formRef.current?.setErrors({});

    schema
      .validate(data, {
        abortEarly: false,
      })
      .then(() => {
        setIsLoading(true);
        api
          .post('/crm/user/first-access', {
            email: data.email,
            cpfCnpj: data.cpfCnpj,
          })
          .then((response) => {
            addToast({
              isSuperimposed: true,
              title: 'Solicitação feita com sucesso!',
              type: 'success',
              description: response?.data?.messages?.join(', '),
            });
            setIsLoading(false);
          })
          .catch((error) => {
            addToast({
              isSuperimposed: true,
              title: 'Erro ao fazer solicitação',
              type: 'error',
              description: error?.response?.data?.messages?.join(', '),
            });
            setIsLoading(false);
          });
      })
      .catch((err) => {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
      });
  };

  return (
    <Modal
      footer={null}
      title=""
      open={isFirstAccess}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Spin spinning={isLoading}>
        <ContentModal>
          <img src={logoImg} alt="Fleet Solutions" />
          <Form ref={formRef} onSubmit={handleSubmitFirstAccess}>
            <Input
              icon={FiMail}
              placeholder="E-mail"
              id="email"
              name="email"
              type="text"
            />
            <Input
              icon={FiFile}
              placeholder="CNPJ"
              id="cpfCnpj"
              name="cpfCnpj"
              type="text"
            />
            <Button type="submit">Primeiro acesso</Button>
          </Form>
        </ContentModal>
      </Spin>
    </Modal>
  );
}

export default FirstAccess;
