import styled from 'styled-components';

export const Container = styled.div`
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;

  color: #3c505a;

  h1 {
    color: #17536c;
    margin-top: 30px;
  }

  p {
    color: #3c505a;
    margin-top: 5px;
  }

  @media (max-width: 425px) {
    .toggleShow {
      width: 100%;
    }
    .toggleShow-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  @media (min-width: 426px) {
    .toggleShow-content {
      display: flex !important;
    }
  }
`;

export const WrapperStatus = styled.div`
  width: 80%;
  height: 77px;
  background: #fff;
  margin: 20px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  border-radius: 16px;

  padding: 15px;

  .status {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      margin-bottom: 0px;
    }
    .box-finance {
      width: 49px;
      height: 44px;
      background: #ce6509;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      font-weight: 500;

      p {
        color: #fff;
        font-size: 20px;
        margin-bottom: 0px;
      }
    }

    .license-plate {
      background: #ba1818;
    }

    .available {
      background: #369614;
    }

    .active {
      background: #167c9d;
    }

    .wait-prepar {
      background: #007feb;
    }
  }

  @media (max-width: 857px) {
    flex-wrap: wrap;
    height: auto;

    .status {
      width: 50%;
    }
  }

  @media (max-width: 425px) {
    width: 90%;
    .status {
      width: 100%;
    }
  }
`;

export const Select = styled.select`
  width: 265px;
  height: 31px;
  margin-left: 10px;
  font-size: 14px;
  color: #8994a0;
  border: 1px solid #cfd7d9;
  background: #fff;
`;

export const WrapperFilterAndTable = styled.div`
  width: 80%;
  @media (max-width: 425px) {
    width: 90%;
  }
`;

export const WrapperTable = styled.div`
  overflow-x: auto;
`;

export const Table = styled.table`
  width: 100%;
  text-align: center;
  border-spacing: inherit;
  margin: 15px 0px 15px 0px;

  .tdNoDate {
    width: 250px !important;
  }
  tbody tr:nth-child(odd) {
    background: #f9f9f9;
  }

  tr {
    font-size: 14px;
  }

  td {
    padding: 5px;
    white-space: nowrap;
  }

  th {
    font-weight: normal;
    background: #3c505a;
    color: #fff;
    height: 36px;
    padding: 5px;
    white-space: nowrap;

    &:first-child {
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
    }

    &:last-child {
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
    }
  }

  .large {
    width: 110px;
  }
`;

export const Form = styled.form`
  background-color: #fff;
  padding: 33px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 16px;

  input,
  select {
    border: 1px solid #0c5faf;
    border-radius: 18px;
    padding: 5px 12px;
    width: 170px;
    margin: 8px 10px;
  }

  label {
    font-size: 14px;
    // margin: 0 0 15px 20px;
  }

  select {
    height: 38px;
  }

  .filter-button {
    margin: 8px 10px;
    background-color: #236fbc;
    border: 2px solid #226fbb;
    border-radius: 24px;
    color: white;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.2px;
    padding: 10px 35px;
  }

  .btn-clear-filter {
    color: #17536c;
    background: transparent;
    width: 50px;
  }

  .spin {
    padding: 10px;
    height: 48px;
    width: 40px;
  }

  .ant-spin-spinning {
    line-height: 0;
  }

  @media (max-width: 425px) {
    justify-content: center;
    padding: 15px;
  }
`;
