import React from 'react';
import signanddriveLogoDark from 'pages/LandingPages/assets/images/signanddrive-logo-dark.svg';
import audisignatureLogoDark from 'pages/LandingPages/assets/images/audisignature-logo-dark.svg';
import rentalwayLogoDark from 'pages/LandingPages/assets/images/rentalway-logo-dark.svg';
import assinecarLogoDark from 'pages/LandingPages/assets/images/assinecar-logo-dark.svg';
import styled from 'styled-components';

const StyledPrograms = styled.div`
  display: flex;
  aligm-items: center;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

type ProgramProps = {
  url: string;
};

function Program({ url }: ProgramProps) {
  const icons = {
    signanddrive: signanddriveLogoDark,
    audisignature: audisignatureLogoDark,
    rentalway: rentalwayLogoDark,
    assinecar: assinecarLogoDark,
  };
  const [_, product] = url.split('.');

  return (
    <a href={url} target="_blank">
      <img src={icons[product as keyof typeof icons]} width="110" height="40" />
    </a>
  );
}

type ProgramsProps = {
  urls: string[];
};

function Programs({ urls, ...props }: ProgramsProps) {
  return (
    <StyledPrograms {...props}>
      {urls?.map((item: string) => (
        <Program url={item} />
      ))}
    </StyledPrograms>
  );
}

export default Programs;
