import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const List = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 190px;
  border: 1px solid #0c5faf;
  border-top: 20px solid #0c5faf;
  border-radius: 10px;
  padding: 5px 0px 0px 10px;
  overflow-y: auto;
`;

export const Select = styled.select`
  border: 0px;
  height: 190px;
`;

export const Icon = styled.span`
  width: 10%;
  text-align: center;
  img {
    width: 60%;
  }
`;
