import { combineReducers } from "redux";
import offers from "./offers/reducer";
import login from "./login/reducer";
import location from "./location/reducer";
import tabs from "./tabsRegisterUser/reducer";

export default combineReducers({
  offers,
  login,
  location,
  tabs
});
