import React from "react";

import Button from "../../components/Button";
import { Container } from "./styles";

const ConfirmRegister: React.FC = () => {
  return (
    <Container>
      <p>
        Em instantes você receberá um e-mail com um link para confirmar o seu
        cadastro.
      </p>
      <Button>Reenviar e-mail</Button>
    </Container>
  );
};

export default ConfirmRegister;
