import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../../components/Header';
import Scheduling from './Scheduling';
import Administrative from './Administrative';
import Title from '../../../components/Title';

import { Buttons, Container, Content, Tab } from './styles';

import clipboardsIcon from '../../../assets/icons/clipboards.svg';
import timetableIcon from '../../../assets/icons/timetable.svg';

const NewRequest: React.FC = () => {
  const history = useHistory();

  let { tabName }: any = useParams();
  const [tab, setTab] = useState<string>(tabName);

  useEffect(() => {
    setTab(tabName);
  }, [tabName]);

  return (
    <>
      <Header />
      <Container>
        <Title
          title={`ABRIR NOVA SOLICITAÇÃO - ${tab}`}
          subtitle={
            'Por favor, preencha os dados abaixo para abrir uma nova solicitação'
          }
        />
        <Content>
          <Buttons>
            <button
              className={tab === 'agendamento' ? 'active' : ''}
              onClick={() => history.push('/new-request/agendamento')}
            >
              <img src={timetableIcon} alt="icone calendário" />
              <h3>Para seu veículo</h3>
              {/* <p className="message-new-request">Para seu veículo</p> */}
            </button>
            {/* <button
              // className={tab === "administrativo" ? "active" : ""}
              // onClick={() => history.push("/new-request/administrativo")}
            >
              <img src={clipboardsIcon} alt="icone prancheta" />
              <h3>Para seu contrato</h3>
            </button> */}
          </Buttons>
          <Tab>
            {tab === 'agendamento' ? <Scheduling /> : <Administrative />}
          </Tab>
        </Content>
      </Container>
    </>
  );
};

export default NewRequest;
