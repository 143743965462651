import { useAuth } from 'hooks/auth';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

interface PrivateRouteProps extends RouteProps {
  component: any;
}

function PrivateRoute({ component: Component, ...rest }: PrivateRouteProps) {
  const { fullname } = useAuth();
  const isLogged = !!fullname;

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged ? <Component {...props} /> : <Redirect to={''} />
      }
    />
  );
}

export default PrivateRoute;
