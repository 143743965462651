import styled from 'styled-components';

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-item: center;
  justify-content: center;
  flex-direction: column;
  margin: 25px 0 40px;

  h1 {
    font-size: 25px;
    color: #17536c;
    font-weight: 600;
    letter-spacing: 2px;
    margin: 0 0 5px !important;
    text-align: center;
    text-transform: uppercase;
  }

  h3 {
    font-weight: 400;
    text-align: center;
    margin: 0;
    color: #3c505a;
    font-size: 17px;
    padding-left: 15px;
    padding-right: 15px;
  }
`;
