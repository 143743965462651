import React from 'react';
import { ModalFade, ModalContainer } from './styles';

const Modal: React.FC<{
  isVisible: boolean;
  children: any;
  className?: string;
}> = ({ children, isVisible, className = '' }) => {
  return (
    <ModalFade className={`${isVisible ? 'is-visible ' : ''}${className}`}>
      <ModalContainer>{children}</ModalContainer>
    </ModalFade>
  );
};

export default Modal;
