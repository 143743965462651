import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background: #f1f1f1;
  color: #3c505a;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;

  form {
    width: 575px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: end;
  margin-top: 15px;

  @media (max-width: 540px) {
    display: flex;
    width: 100%;

    .btn-save,
    .btn-cancel {
      width: 100%;
    }
  }
`;
