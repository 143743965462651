import styled from 'styled-components';
export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  .firstAccessButton {
    border: 1px solid #0606da;
    display: block;
    text-align: center;
    padding: 5px 16px;
    transition: background-color 0.2s;
    color: #0606da;
    border-radius: 6px;
    font-size: 14px;
    background-color: #fff;
  }
`;
export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-top: 15px;
    margin-bottom: 10px;
    max-width: 90%;
  }

  input {
    width: 290px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin: 20px 0 0 0;
      font-size: 20px;
      color: #3c505a;
    }

    button {
      width: 350px;
      border-radius: 6px;
      box-shadow: 0px 3px 6px #00000029;
      background: #0606da; /*#0c5faf;*/
    }
  }

  .btn-create-account {
    background: #51ae30;
    width: 350px;
    border-radius: 6px;
    box-shadow: 0px 3px 6px #00000029;
  }

  @media (max-width: 411px) {
    form {
      input {
        width: 80%;
      }
      button {
        width: 250px;
      }
    }
    .btn-create-account {
      width: 250px;
    }
  }
`;
