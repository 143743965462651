import React from 'react';
import visaIcon from 'assets/payment/visa-icon.svg';
import mastercardIcon from 'assets/payment/mastercard-icon.svg';
import amexIcon from 'assets/payment/amex-icon.svg';
import cupIcon from 'assets/payment/cup-icon.svg';
import dinersIcon from 'assets/payment/diners-icon.svg';
import eloIcon from 'assets/payment/elo-icon.svg';
import hipercardIcon from 'assets/payment/hipercard-icon.svg';
import maestroIcon from 'assets/payment/maestro-icon.svg';
import barcodeIcon from 'assets/payment/barcode-icon.svg';
import defaultIcon from 'assets/payment/card-icon.svg';

export const ICONS = {
  visa: visaIcon,
  mc: mastercardIcon,
  amex: amexIcon,
  cup: cupIcon,
  diners: dinersIcon,
  elo: eloIcon,
  hipercard: hipercardIcon,
  maestro: maestroIcon,
  boleto: barcodeIcon,
  default: defaultIcon,
};

type Props = {
  type: keyof typeof ICONS;
};

function PaymentIcon({ type }: Props) {
  const iconSrc = ICONS[type] || ICONS.default;
  return <img src={iconSrc} alt={type} />;
}

export default PaymentIcon;
