import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Modal } from 'antd';

import Header from '../../components/Header';
import iconEdit from '../../assets/icons/edit.svg';
import iconTrash from '../../assets/icons/trash.svg';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import {
  Container,
  WrapperTable,
  Table,
  Select,
  ContentModal,
  TableMobile,
} from './styles';
import Title from '../../components/Title';
import Pagination from '../../components/Pagination';
import MobileFilter from 'components/business/MobileFilter';

interface UserRoleI {
  userId: number;
  fullName: string;
  roleId: number;
  role: string;
  dealership: null;
  checkedOnFleet: boolean;
  email: string;
  customerName: string;
  recallTerm: string;
  termsOfUse: string;
  antifraudTerm: string;
  qtdeAccess: number;
  inactive: boolean;
}

const PER_PAGE = 30;
const STATUS_OPTIONS = [
  { label: 'Ativo', value: '1' },
  { label: 'Inativo', value: '0' },
];

const Users = () => {
  const history = useHistory();
  const location = useLocation();
  const { userId } = useAuth();
  const { addToast } = useToast();

  // Os filtros são adicionados na url porque após acessar a página de editar, ao clicar
  // no cancelar, retorne aos mesmos filtros
  const searchParams = new URLSearchParams(location.search);
  const nameParam = searchParams.get('name') || '';
  const roleIdParam = searchParams.get('roleId') || '';
  const emailParam = searchParams.get('email') || '';
  const statusParam = searchParams.get('status') || '';

  const [canFetchUsersRoles, setCanFetchUsersRoles] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [idRemove, setIdRemove] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [roleOptions, setRoleOptions] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [usersRoles, setUsersRoles] = useState<UserRoleI[]>([]);
  const [filters, setFilters] = useState({
    name: nameParam,
    email: emailParam,
    roleId: roleIdParam,
    status: statusParam,
  });

  useEffect(() => {
    api.get('/roles').then((response) => {
      setRoleOptions(response.data);
    });
  }, []);

  useEffect(() => {
    const fetchUsersRole = () => {
      api
        .get(`/users/by-filters/${userId}${location.search}`)
        .then((response) => {
          const uniqueUsers = response.data.reduce(
            (usersData: UserRoleI[], userData: UserRoleI) => {
              const isDuplicate = usersData.find(
                (user) => user.userId === userData.userId
              );
              return isDuplicate ? usersData : [...usersData, userData];
            },
            []
          );
          const indexOfLast = currentPage * PER_PAGE;
          const indexOfFirst = indexOfLast - PER_PAGE;

          setUsersRoles(uniqueUsers.slice(indexOfFirst, indexOfLast));
          setTotal(uniqueUsers.length);
        });
    };

    if (canFetchUsersRoles) {
      fetchUsersRole();
      setCanFetchUsersRoles(false);
    }
  }, [canFetchUsersRoles, currentPage, nameParam, roleIdParam, userId]);

  const handleNewUser = () => {
    history.push('/user-maintenance');
  };

  const handleEditUser = (id: number) => {
    history.push(`/user-maintenance/${id}`);
  };

  const disableUser = (id: any) => {
    api.patch(`/users/disable/${userId}/${id}`).then(() => {
      setCanFetchUsersRoles(true);
      setOpenModal(false);
      addToast({
        title: 'Usuário excluído',
        type: 'success',
        description: 'Usuário excluído com sucesso.',
      });
    });
  };

  const modalOk = () => disableUser(idRemove);
  const modalClose = () => setOpenModal(false);

  const updateURLParams = (name: string, value: string) => {
    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }
    history.push({ search: searchParams.toString() });
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleSearch = () => {
    Object.entries(filters).forEach(
      ([key, value]: [string, string | boolean]) => {
        updateURLParams(key, value ? String(value) : '');
      }
    );
    setCurrentPage(1);
    setCanFetchUsersRoles(true);
  };

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    setCanFetchUsersRoles(true);
  };

  const renderTableRow = usersRoles.map((user: UserRoleI) => (
    <tr key={user?.userId}>
      <td>{user?.fullName}</td>
      <td>{user?.email}</td>
      <td>{user?.role}</td>
      <td>{user?.inactive ? 'Inativo' : 'Ativo'}</td>
      <td>
        <button onClick={() => handleEditUser(user?.userId)}>
          <img src={iconEdit} alt="Edit" />
        </button>
        {!user?.inactive && (
          <button
            onClick={() => (setOpenModal(true), setIdRemove(user?.userId))}
          >
            <img src={iconTrash} alt="Trash" />
          </button>
        )}
      </td>
    </tr>
  ));

  return (
    <>
      <Header />
      <Container>
        <Title
          title={'Cadastro de Usuários'}
          subtitle={'Associe um perfil de acesso à um usuário'}
        />

        <WrapperTable>
          <MobileFilter>
            <div className="info">
              <div className="field">
                <p>Nome</p>
                <input
                  name="name"
                  className="name-profile"
                  onChange={handleInputChange}
                  value={filters.name}
                />
              </div>

              <div className="field">
                <p>E-mail</p>
                <input
                  name="email"
                  className="name-profile"
                  onChange={handleInputChange}
                  value={filters.email}
                />
              </div>

              <div className="field">
                <p>Perfil</p>
                <Select
                  name="roleId"
                  id="roleId"
                  onChange={handleInputChange}
                  value={filters.roleId}
                >
                  <option value="0">Sem Perfil</option>
                  <option value="">Todos com Perfil</option>
                  {roleOptions.map((profile: any) => (
                    <option key={profile.roleId} value={profile.roleId}>
                      {profile.name}
                    </option>
                  ))}
                </Select>
              </div>

              <div className="field">
                <p>Status</p>
                <Select
                  name="status"
                  id="status"
                  onChange={handleInputChange}
                  value={filters.status}
                >
                  <option value="">Todos</option>
                  {STATUS_OPTIONS.map((status) => (
                    <option key={status.value} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </Select>
              </div>

              <div className="btn-search">
                <button onClick={handleSearch}>Buscar</button>
              </div>
            </div>
          </MobileFilter>

          <div className="divBotoes">
            <button onClick={handleNewUser} className="btn-new-profile">
              Novo Usuário
            </button>
          </div>

          <TableMobile>
            <Table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Perfil</th>
                  <th>Status</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                {usersRoles.length ? (
                  renderTableRow
                ) : (
                  <tr>
                    <td colSpan={3}>
                      <p>Nenhum perfil cadastrado</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </TableMobile>
          <Pagination
            paginate={paginate}
            currentPage={currentPage}
            total={total}
            perPage={PER_PAGE}
          />
        </WrapperTable>
        <Modal footer={null} title="" visible={openModal} onCancel={modalClose}>
          <ContentModal>
            <p>Deseja desabilitar esse usuário?</p>
            <div>
              <button onClick={modalOk}>Sim</button>
              <button onClick={modalClose}>Não</button>
            </div>
          </ContentModal>
        </Modal>
      </Container>
    </>
  );
};

export default Users;
