import { Modal, Spin } from 'antd';
import React, { useState } from 'react';

import logoImg from '../../assets/LM/Fundo_transparente_M.png';
import { ContentModal, StylePrograms } from './styles';

type Props = {
  listOfPrograms: Array<string>;
  handleClose: () => void;
};

function ListOfPrograms({ listOfPrograms, handleClose }: Props) {
  const [open, setOpen] = useState(true);

  return (
    <Modal
      footer={null}
      title=""
      open={open}
      onOk={() => setOpen(true)}
      onCancel={handleClose}
    >
      <ContentModal>
        <img src={logoImg} alt="Fleet Solutions" />
        <p>Desejamos boas-vindas ao Portal do Cliente.</p>
        <p>Clique no programa que deseja acessar:</p>
        <StylePrograms urls={listOfPrograms} />
      </ContentModal>
    </Modal>
  );
}

export default ListOfPrograms;
