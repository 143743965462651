import React, { createContext, useContext, useCallback, useState } from "react"
import { uuid } from "uuidv4"

import { v4 as uuidv4 } from 'uuid';

import ToastContainer from "../components/ToastContainer"

export interface ToastMessage {
  id: string
  type?: "success" | "error" | "info"
  title: string
  description?: string
  isSuperimposed?: boolean
}

interface ToastContextData {
  addToast(message: Omit<ToastMessage, "id">): void
  removeToast(id: string): void
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData)

const ToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<ToastMessage[]>([])
  const [stateIsSuperimposed, setIsSuperimposed] = useState(false)

  const addToast = useCallback(
    ({
      type,
      title,
      description,
      isSuperimposed,
    }: Omit<ToastMessage, "id">) => {
      //const id = uuid()
      const id = uuidv4()

      const toast = {
        id,
        type,
        title,
        description,
      }

      setIsSuperimposed(isSuperimposed !== undefined && isSuperimposed)
      setMessages((oldMessage) => [...oldMessage, toast])
    },
    [messages]
  )

  const removeToast = useCallback((id: string) => {
    setMessages((state) => state.filter((message) => message.id !== id))
  }, [])

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer
        isSuperimposed={stateIsSuperimposed}
        messages={messages}
      />
    </ToastContext.Provider>
  )
}

function useToast(): ToastContextData {
  const context = useContext(ToastContext)

  if (!context) {
    throw new Error("useToast must be used within a ToastProvider")
  }

  return context
}

export { ToastProvider, useToast }
