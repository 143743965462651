import {
  TYPE_ORDERS,
  THEMES,
  landingpagePaths,
} from 'pages/LandingPages/utils/constants';

export function getProductFromUrl() {
  const [_, product] = window.location.hostname.split('.');
  return landingpagePaths.includes(product) ? product : '';
}

export function isLandingpagePath() {
  return landingpagePaths.includes(getProductFromUrl());
}

export function getTheme() {
  const productKey = getProductFromUrl();
  return THEMES[(productKey as keyof typeof THEMES) || 'pdc'];
}

export function getTypeOrder() {
  const productKey = getProductFromUrl();
  return TYPE_ORDERS[(productKey as keyof typeof TYPE_ORDERS) || 'pdc'];
}
