export function decodeBase64(data: string) {
  const decodedData = atob(data);
  const arrayBuffer = new ArrayBuffer(decodedData.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  return uint8Array.map((_, i) => decodedData.charCodeAt(i));
}

export function createBlob(blobParts: BlobPart, type: string) {
  return new Blob([blobParts], { type });
}

export function downloadFile(url: string, name: string) {
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  link.click();
  link.remove();
}

export function downloadBlob(blob: Blob, name: string) {
  const url = URL.createObjectURL(blob);
  downloadFile(url, name);
  URL.revokeObjectURL(url);
}

export function downloadBuffer(
  buffer: BlobPart,
  fileName: string,
  fileType: string
) {
  downloadBlob(createBlob(buffer, fileType), fileName);
}
