import { Reducer } from "redux";
import { IFilterOffer } from "./types";

const INITIAL_STATE: IFilterOffer = {
  modelCode: 0,
  color: "",
};

const offers: Reducer<IFilterOffer> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FILTER_OFFERS": {
      const { filters } = action.payload;

      return {
        ...state,
        color: filters.color,
        modelCode: filters.modelCode,
      };
    }
    default: {
      return state;
    }
  }
};

export default offers;
