import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import api from 'services/api';
import { AxiosResponse } from 'axios';
import { useAuth } from 'hooks/auth';
import InputFieldUI from 'components/ui/InputFieldUI';

const StyledContainer = styled.div`
  padding: 16px;
`;

interface CustomerI {
  userId: number;
  customerCMSId: number;
  customerName: string;
  contractNumbers: string[];
}
type Props = {
  initPaymentMethod: (contractNumber: string) => void;
  setLoading: (value: boolean) => void;
};

function ContractSelect({ initPaymentMethod, setLoading }: Props) {
  const { userId } = useAuth();

  const [contractNumberValue, setContractNumberValue] = useState('0');
  const [customers, setCustomers] = useState<CustomerI[]>([]);

  const firstContractNumber = customers?.[0]?.contractNumbers?.[0];

  useEffect(() => {
    const getCustomers = async () => {
      try {
        setLoading(true);
        const response: AxiosResponse<CustomerI[]> = await api.get(
          `/crm/reports/${userId}/customers-cms-lm`
        );
        setCustomers(response?.data);
      } finally {
        setLoading(false);
      }
    };
    getCustomers();
  }, []);

  useEffect(() => {
    if (firstContractNumber) {
      setContractNumberValue(firstContractNumber);
      initPaymentMethod(firstContractNumber);
    }
  }, [firstContractNumber]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setContractNumberValue(e.target.value);
    if (e.target.value !== '0') {
      initPaymentMethod(e.target.value);
    }
  };

  const renderOption = (value: string, customerName: string) => (
    <option key={value} value={value}>
      {value + ' - ' + customerName?.split(' - ')[1]}
    </option>
  );

  return (
    <StyledContainer>
      <InputFieldUI
        field="select"
        label="Contrato"
        onChange={handleChange}
        value={contractNumberValue}
      >
        <option value="0" hidden>
          Selecione um contrato
        </option>
        {customers?.map(({ contractNumbers, customerName }: CustomerI) =>
          contractNumbers.map((contractNumber) =>
            renderOption(contractNumber, customerName)
          )
        )}
      </InputFieldUI>
    </StyledContainer>
  );
}

export default ContractSelect;
